import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllSprints = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/sprints`,
    authConfig()
  );
  return response.data;
};

export const fetchSprints = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/sprints/${id}`,
    authConfig()
  );
  return response.data;
};

export const createSprints = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/sprints`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateSprints = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/sprints/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteSprints = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/sprints/${id}`,
    authConfig()
  );
  return response.data;
};

const sprintsAPI = {
  fetchAllSprints,
  fetchSprints,
  createSprints,
  updateSprints,
  deleteSprints,
};
export default sprintsAPI;
