import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllFacts = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/facts`,
    authConfig()
  );
  return response.data.results;
};

export const fetchFacts = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/facts/${id}`,
    authConfig()
  );
  return response.data;
};

export const createFacts = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/facts`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateFacts = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/facts/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteFacts = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/facts/${id}`,
    authConfig()
  );
  return response.data;
};

const factsAPI = {
  fetchAllFacts,
  fetchFacts,
  createFacts,
  updateFacts,
  deleteFacts,
};
export default factsAPI;
