import React, { Fragment, useEffect, createContext } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Section } from "./Section";
import { filter } from "lodash"
import { fetchAllSections } from "./../../redux/toolkit/features/roadmapsection";
import { fetchAllRows } from "./../../redux/toolkit/features/roadmaprows";
import { fetchAllCells } from "./../../redux/toolkit/features/roadmapcells";
import { fetchAllGrowth } from "./../../redux/toolkit/features/roadmapgrowth";
import { fetchAllCagr } from "./../../redux/toolkit/features/roadmapcagr";
import { uncheckCellUpdate } from "./../../redux/toolkit/features/roadmaps_state";

export const celldatasets = createContext();

function Roadmap(props) {
    const {indent} = props;
    const isEditRowsModalOpen = useSelector((state) => state.roadmaps_state.isEditRowsModalOpen);
    const isSectionsModalOpen = useSelector((state) => state.roadmaps_state.isOpen);
    const isCellUpdate = useSelector((state) => state.roadmaps_state.isCellUpdate);
    const sections = Object.values(useSelector((state) => state.sections.entities)).sort((a, b) => a.order - b.order);
    const rows = Object.values(useSelector((state) => state.roadmaprows.entities)).sort((a, b) => a.order - b.order);
    const cells = Object.values(useSelector((state) => state.roadmapcells.entities));
    const cellstatus = useSelector((state) => state.roadmapcells.status);
    const growth = Object.values(useSelector((state) => state.roadmapgrowth.entities));
    const cagr = Object.values(useSelector((state) => state.roadmapcagr.entities));
    const category = useSelector((state) => state.roadmaps_state.category);
    const showRowNoteModal = useSelector((state) => state.roadmaprows.isOpen);
    const dispatch = useDispatch();

    async function fetchSections() {
        dispatch(fetchAllSections());
        dispatch(fetchAllRows());
        dispatch(fetchAllCells());
      }

    async function fetchGrowthData() {
        dispatch(fetchAllGrowth());
        dispatch(fetchAllCagr());
    }

    useEffect(() => {
        if (cellstatus==='idle'){
            fetchGrowthData();
            //fetchChartData();
        }
    }, [cellstatus]);

    useEffect(() => {
        if (isCellUpdate===true){
            fetchGrowthData();
            fetchSections();
        }
        dispatch(uncheckCellUpdate());
    }, [isCellUpdate]);

    useEffect(() => {
        if (!isEditRowsModalOpen){
            fetchSections();
            fetchAllRows();
        }
    }, [isEditRowsModalOpen]);


    useEffect(() => {
        if (!isSectionsModalOpen){
            fetchSections();
        }
    }, [isSectionsModalOpen]);


    useEffect(() => {
        if (!showRowNoteModal){
            fetchSections()
        }
    }, [showRowNoteModal]);

    useEffect(() => {
        fetchSections();
    }, []);

    var sectionsfiltered=[]

    if (category === null) {
        sectionsfiltered=sections;
    } else {
        sectionsfiltered = filter(sections,{category:category});
    }

    if(sections.length===0) {
        return null;
    }

    return (
        <celldatasets.Provider value={{ cells, growth, cagr, rows}}>
        <Fragment>

            { sectionsfiltered && (sectionsfiltered.sort((a, b) => a.order - b.order)).map(sectiondata => (
            <div key={sectiondata.pk}>
               
                <Section 
                    sectiondata={sectiondata}
                    rows={rows}
                    indent={indent}
                    cells={cells}
                />

            </div>
            ))}
        </Fragment>
        </celldatasets.Provider>
    )
}

export { Roadmap };
