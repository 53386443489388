import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter, invokeMap } from "lodash";
import { createBaseline, updateBaseline, fetchAllBaseline } from "./../../redux/toolkit/features/success/baseline"
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import './numericinput.css';

function BaselineValue(props) {
    const {rowPk, year, baselinevalues,type, baselinedelta, decimalplaces, mediumalert, severealert, editbaselinestatus, classstring} = props;
    const [numericvalue, setNumericValue] = useState(null);   
    const [existingvalue, setExistingValue] = useState(null);   
    let dataarray = filter(baselinevalues,{year: year, row_id: rowPk})
    const dispatch = useDispatch();

    useEffect(() => {
        setNumericValue(dataarray[0]?.numeric_value || null)
        setExistingValue(dataarray[0]?.numeric_value || null)
    }, [baselinevalues]);

    var format="n0"
    switch(type) {
        case 'Number':
            format="n"+decimalplaces.toString()
            break;
        case 'Percent':
            format="p"+decimalplaces.toString()
            break;
    }


    async function handleCellChange(e) {
        if ((e.type==='blur' || e.keyCode === 13)) {
            if (e.target.value !== existingvalue) {
                if (dataarray.length===0) {
                    async function createcelldispatch() {
                        dispatch(createBaseline({numeric_value: e.target.value, year: year, row: rowPk}))
                        .unwrap()
                        .then(() => {
                            dispatch(fetchAllBaseline());
                            setExistingValue(e.target.value)
                        })
                    }
                    createcelldispatch()
                } else {
                    dispatch(updateBaseline({id:dataarray[0].pk, numeric_value: e.target.value, year: year, row: rowPk}));
                    dispatch(fetchAllBaseline());
                    setExistingValue(e.target.value)
                }
        } else {
            setNumericValue(e.target.value)
        }
        }
    }


    function renderFields() {

        
        let key = `${rowPk}_${year}`;
        let textValue = null
        let baselinerow = filter(baselinedelta, {year: year, row_id: rowPk})

        let delta = null;
        if (baselinerow.length !== 0) {
            delta=baselinerow[0].growth
        }
        let alertstatus=null;
        if ((severealert < 0 && delta < severealert) || (severealert > 0 && delta > severealert)){
            alertstatus = "high";
        } else if ((mediumalert < 0 && delta < mediumalert) || (mediumalert > 0 && delta > mediumalert)){
            alertstatus = "moderate";
        }

        let rowstyle=null;
        
        if (alertstatus === "high") {
            rowstyle={border:"2px red solid"}
        } 
        
        if (alertstatus === "moderate") {
            rowstyle={border:"2px gold solid"}
        }

        switch(type) {
            case ("Number" || null || undefined):
                return (
                    <Fragment>
                    {editbaselinestatus && <NumericTextBoxComponent key={rowPk} className={classstring} format={format} showSpinButton={false} style={{border: '1px solid darkgrey'}} type="text" value={numericvalue} onChange={(e) =>  handleCellChange(e)} onKeyDown={(e) =>  handleCellChange(e)}/>}
                    {!editbaselinestatus && <NumericTextBoxComponent key={rowPk} className={classstring} format={format} showSpinButton={false} style={{border: 'none'}}type="text" value={numericvalue} onChange={(e) =>  handleCellChange(e)} onKeyDown={(e) =>  handleCellChange(e)}/>}

                    </Fragment>
               )


            case "Percent":
                    return (
                        <NumericTextBoxComponent key={key} className={classstring} showSpinButton={false} type="text" style={rowstyle} value={numericvalue} onChange={(e) =>  handleCellChange(e)} onKeyDown={(e) =>  handleCellChange(e)}/>
                    )         
            default:      return(<p>Invalid</p>)
        } 
    }

    return (
        <Fragment>
        <td style={{textAlign:"center"}} key={'test'}>
            {renderFields()}
        </td>

        </Fragment>
    )
}

export { BaselineValue }; 
