import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllSprintCycle, updateSprintCycle, createSprintCycle }  from "./../../../redux/toolkit/features/sprintcycle";
import { closeSprintDateModal }  from "./../../../redux/toolkit/features/sprints_state";

function SprintDateModal() {
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const isSprintDateModalOpen = useSelector((state) => state.sprints_state.isSprintDateModalOpen)
    const [datevalue, setDateValue] = useState(null);
    const datevaluerecord = useSelector((state) => (Object.values(state.sprintcycle.entities)[0]?Object.values(state.sprintcycle.entities)[0].sprintenddate:null));
    const datevalueid = useSelector((state) => (Object.values(state.sprintcycle.entities)[0]?Object.values(state.sprintcycle.entities)[0].pk:null));
    const dispatch = useDispatch();
  
    useEffect(() => {
        dispatch(fetchAllSprintCycle())
      }, [dispatch]);

    useEffect(() => {
        setDateValue(datevaluerecord)
      }, [datevaluerecord])

    let modalClass = "modal";
    if (isSprintDateModalOpen) {
        modalClass += " is-active";
    }

    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                        <input 
                            type="date" 
                            className="input"
                            value={datevalue}
                            onChange={(e) => setDateValue(e.target.value)}
                        />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    if (!datevaluerecord) {
        return null;
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard" style={{width:"300px"}}>
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>SPRINT CYCLE END DATE</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeSprintDateModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        {datevalueid &&
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(updateSprintCycle({id: datevalueid,sprintenddate: datevalue}))
                                .unwrap()
                                    dispatch(fetchAllSprintCycle());
                                    dispatch(closeSprintDateModal());
                            }}
                        >
                            Save changes
                        </button>
                        }

                        {!datevalueid &&
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(createSprintCycle({sprintenddate: datevalue}))
                                .unwrap()
                                    dispatch(fetchAllSprintCycle());
                                    dispatch(closeSprintDateModal());
                            }}
                        >
                            Save Changes
                        </button>
                        }

                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeSprintDateModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SprintDateModal;
