import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllThemes = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/themes`,
    authConfig()
  );
  return response.data;
};

export const fetchThemes = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/themes/${id}`,
    authConfig()
  );
  return response.data;
};

export const createThemes = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/themes`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateThemes = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/themes/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteThemes = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/themes/${id}`,
    authConfig()
  );
  return response.data;
};

const themesAPI = {
  fetchAllThemes,
  fetchThemes,
  createThemes,
  updateThemes,
  deleteThemes,
};
export default themesAPI;
