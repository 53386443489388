import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class TitleService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/organization/titles`;
    }
}

export const titleService = new TitleService();
