import React, {Fragment, useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { CompensationDetail } from "./CompensationDetail"
import { fetchAllRoles} from "./../../redux/toolkit/features/roles";
import { fetchAllCompensationindex, updateCompensationindex, createCompensationindex} from "./../../redux/toolkit/features/organization/compensationindex";
import { fetchAllTenants } from "./../../redux/toolkit/features/tenants";
import { fetchAllLocalcurrency } from "./../../redux/toolkit/features/organization/localcurrency";
import { openDeleteModal, closeDeleteModal } from "./../../redux/toolkit/features/modals/deleteConfirmModal";
import {filter} from "lodash"


function Compensation() {
    const positions = Object.values(useSelector((state) => state.roles.entities));
    const compensationindex = Object.values(useSelector((state) => state.compensationindex.entities));
    const tenants = Object.values(useSelector((state) => state.tenants.entities))[0];
    const roadmap = Object.values(useSelector((state) => state.roadmap.entities));
    const localcurrency = Object.values(useSelector((state) => state.localcurrency.entities));
    const showModalCompetencies = useSelector((state) => state.competencies.isOpen)
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);  
    const deleteModalStatus = useSelector((state) => state.deleteConfirmModal.isOpen);
    const [order, setOrder] = useState(true);
    const [editmode, setEditMode] = useState(false);
    const [currency, setCurrency] = useState('local');
    const [base, setBase] = useState(null);
    const [sti, setSTI] = useState(null);
    const [lti, setLTI] = useState(null);
    const [benefits, setBenefits] = useState(null);
    const [textfilter, setfilterquery] = useState('');
    const dispatch = useDispatch(); 
    const [positionsordered, setPositionsordered]=([]);
    var [filteredPositions] = [];
    var basecurrency=tenants.basecurrency!==null?tenants.basecurrency:'USD'
    useEffect(() => {
      dispatch(fetchAllRoles())
      dispatch(fetchAllCompensationindex())
      dispatch(fetchAllTenants())
      dispatch(fetchAllLocalcurrency())
    },[]);

    useEffect(() => {
    },[currency]);

    function handleChangeFilter(event) {
      setfilterquery(event.target.value);
    }

    if (currency==="standard") {
      filteredPositions = localcurrency.sort((a, b) => {return order ? b.compensationbase - a.compensationbase : a.compensationbase - b.compensationbase}).filter((position) => {
          if (position.title.toLowerCase().includes(textfilter.toLowerCase())){
            return true;
          }
          return filteredPositions;
        })
    }
    if (currency==="local") {
        if(editmode) {
          filteredPositions = positions.sort((a, b) => a.title.localeCompare(b.title)).filter((position) => {
            if (position.title.toLowerCase().includes(textfilter.toLowerCase())){
              return true;
            }
            return filteredPositions;
          })
        } else {
        filteredPositions = positions.sort((a, b) => {return order ? b.compensationbase - a.compensationbase : a.compensationbase - b.compensationbase}).filter((position) => {
          if (position.title.toLowerCase().includes(textfilter.toLowerCase())){
            return true;
          }
          return filteredPositions;
        })
      }
    }

      async function setIndexvalue(value, year, id) {
        if (id !== null) {
          await dispatch(updateCompensationindex({id:id, indexvalue: value}))
          .unwrap()
          dispatch(fetchAllCompensationindex());
        } else {
          await dispatch(createCompensationindex({year:year, indexvalue: value}))
          .unwrap()
          dispatch(fetchAllCompensationindex());
        }
      }

      var options=[]
      for (let i = 0; i < 10; i += 0.1) {
          options.push(<option key={((Math.round(i*10))/10).toFixed(1)} value={((Math.round(i*10))/10).toFixed(1)}>{((Math.round(i*10))/10).toFixed(1)}%</option>)
      }

      function renderSelect(year) {
          let indexlist = filter(compensationindex, {year:year})
          let indexvalue=indexlist[0]?indexlist[0].indexvalue:null
          let PK=indexlist[0]?indexlist[0].pk:null
        return (
          <select className="ml-0" value={indexvalue} style={{border:'none'}} onChange={(e)=> setIndexvalue(e.target.value, year,PK)}>
          {options}
          </select>
        )
      }


    function renderYears() {
      let yearrows=[];
      for (let year = new Date().getFullYear(); year <= 2028; year++) {
        if ((year === new Date().getFullYear())) {
          if (!editmode) {
          {order && yearrows.push(<th className="has-text-centered" onClick={()=> setOrder(false)}>{year}<span style={{fontSize:'0.8em', color:backgroundcolor}}> <sup>▼</sup></span></th>)} 
          {!order && yearrows.push(<th className="has-text-centered" onClick={()=> setOrder(true)}>{year}<span style={{fontSize:'0.8em', color:backgroundcolor}}> <sup>▲</sup></span></th>)}   
          } else {
            yearrows.push(<th className="has-text-centered">{year}</th>)
          }        
        } else {
          yearrows.push(<th className="has-text-centered">{year}<br/><span style={{fontWeight:'normal', fontSize:'0.9em'}}>Index{renderSelect(year)}</span></th>); 
        }
      }
      return (
        yearrows
      )
    }


    if (!positions) {
      return null
    }

    return (
        <Fragment>
          <section>
              <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "20px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        COMPENSATION 
                                        {editmode && <span className="ml-4" style={{fontSize:'0.75em', color: backgroundcolor}} onClick={() =>setEditMode(false)}><i className="far fa-edit" style={{cursor:'pointer'}}/></span>}
                                        {!editmode && <span className="ml-4" style={{fontSize:'0.75em'}} onClick={() =>setEditMode(true)}><i className="far fa-edit" style={{cursor:'pointer'}}/></span>}                                       
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="level-right">

                        {!editmode && 
                          <Fragment>
                            <div className="level-item pt-4 mr-5">
                              <div class="control">
                                <label class="radio">
                                  <input className="mr-2" type="radio" name="local" checked={currency==="local"} value={currency} onClick={()=>setCurrency("local")} />
                                  <span style={{fontWeight:'400', fontSize:'.9em'}}>Local Currency</span>
                                  </label>
                                <label class="radio">
                                  <input className="mr-2" type="radio" name="standard" checked={currency==="standard"} value={currency} onClick={()=>setCurrency("standard")} />
                                  <span style={{fontWeight:'400', fontSize:'.9em'}}>{basecurrency}</span>
                                </label>
                              </div>
                            </div>
                            </Fragment>
                          }

                            <div className="level-item pt-4 pr-0">
                                <div className="field has-addons">
                                    <div className="control is-rounded is-small" style={{width: "140px",userSelect: "none"}}>
                                        <span> <input className="input is-rounded is-small"  onChange={handleChangeFilter} type="search" placeholder="Filter" style={{ color: "rgb(128,128,128)",userSelect: "none"}}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </nav>
                    
                    <hr className="pt-0 mt-0 pb-0 mb-0" style={{borderTop: "2px solid lightgrey"}} />
              <table className="table is-fullwidth" style={{fontSize:"0.93em"}}>
                <tbody>
                <tr>
                  <th className="pl-0">Role</th>
                  <th className="has-text-centered">Currency</th>
                  <th className="has-text-centered">Base Salary</th>
                  <th className="has-text-centered">STI/Bonus</th>
                  <th className="has-text-centered">LTI/STock</th>
                  <th className="has-text-centered">Benefits</th>
                  {renderYears()}
                </tr>

              {filteredPositions.length !== 0 && 
                <Fragment>
                  {filteredPositions.map(row => (
                  // positionlist(row)
                  <CompensationDetail 
                    row={row}
                    currency={currency}
                    compensationindex = {compensationindex}
                    editmode={editmode}
                    basecurrency={basecurrency}
                  />
                  ))}
                </Fragment>
              }

              {filteredPositions.length === 0 && 
                <Fragment> 

                    <p className="pt-4" style={{fontWeight:'500'}}>No Roles Found</p>

                </Fragment>
              }
              </tbody>
              </table>
                


            </section>
        </Fragment>
    )
}

export {Compensation};