import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class BusinessunitService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/organization/business-units`;
    }
}

export const businessunitService = new BusinessunitService();
