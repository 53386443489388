import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllPersons = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/personname`,
    authConfig()
  );
  return response.data.results;
};

export const fetchPersons = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/personname/${id}`,
    authConfig()
  );
  return response.data;
};

export const createPersons = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/personname`,
    data,
    authConfig()
  );
  return response.data;
};

export const updatePersons = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/personname/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deletePersons = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/personname/${id}`,
    authConfig()
  );
  return response.data;
};

const personsAPI = {
  fetchAllPersons,
  fetchPersons,
  createPersons,
  updatePersons,
  deletePersons,
};
export default personsAPI;
