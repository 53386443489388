import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllFX = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/fx`,
    authConfig()
  );
  return response.data.results;
};

export const fetchFX = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/fx/${id}`,
    authConfig()
  );
  return response.data;
};

export const createFX = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/fx`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateFX = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/fx/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteFX = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/fx/${id}`,
    authConfig()
  );
  return response.data;
};

const fxAPI = {
  fetchAllFX,
  fetchFX,
  createFX,
  updateFX,
  deleteFX,
};
export default fxAPI;
