import {
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

const roadmaps_stateAdapter = createEntityAdapter();

const initialState = roadmaps_stateAdapter.getInitialState({
  status: "idle", 
  isCategory: true, 
  category: null, 
  isOpen: false, 
  sectionpk: null, 
  isDetailModalOpen: false, 
  isAddNewSection: true,
  isEditRowsModalOpen: false, 
  editrowpk: null, 
  editsectionpk: null,
  isGraphModalOpen: false, 
  chartsectionpk: null,
  isGraphEditModalOpen: false, 
  chartpk: null, 
  charteditsectionpk: null,
  isEditFormula: false,
  isCellUpdate: false,
  isAddNewRow: false,
  isGanttModalOpen: false,
  ganttsectionpk:null,
  ganttrowpk:null

});

const roadmaps_stateSlice = createSlice({
  name: "roadmaps_state",
  initialState,
  reducers: {
    setCategory(state, action) {
      state.isCategory = true;
      state.category = action.payload.category;
    },
    openRoadmapSectionsModal(state) {
      state.isOpen = true;
    },
    closeRoadmapSectionsModal(state) {
      state.isOpen = false;
    },
    openRoadmapSectionsDetailModal(state, action) {
      state.isDetailModalOpen = true;
      //state.isAddNewSection = action.payload.isAddNewSection;
      state.sectionpk = action.payload.sectionpk;
    },
    closeRoadmapSectionsDetailModal(state) {
      state.isDetailModalOpen = false;
      //state.isAddNewSection = true;
      state.sectionpk = null;
    },
    openEditRowsModal(state, action) {
      state.isEditRowsModalOpen = true;
      state.isAddNewRow = action.payload.isAddNewRow;
      state.editrowpk = action.payload.editrowpk;
      state.editsectionpk = action.payload.editsectionpk;
    },
    closeEditRowsModal(state) {
      state.isEditRowsModalOpen = false;
      state.isAddNewRow = false;
      state.editrowpk = null;
    },
    openGraphsModal(state, action) {
      state.isGraphModalOpen = true;
      state.chartsectionpk = action.payload.chartsectionpk;
    },
    closeGraphsModal(state) {
      state.isGraphModalOpen = false;
      state.chartsectionpk = null;
    },
    openGraphsEditModal(state, action) {
      state.isGraphEditModalOpen = true;
      state.chartpk = action.payload.chartpk;
      state.charteditsectionpk = action.payload.charteditsectionpk;
    },
    closeGraphsEditModal(state) {
      state.isGraphEditModalOpen = false;
      state.chartpk = null;
      state.charteditsectionpk = null;
    },
    openEditFormula(state) {
      state.isEditFormula = true;
    },
    closeEditFormula(state) {
      state.isEditFormula = false;
    },
    checkCellUpdate(state) {
      state.isCellUpdate = true;
    },
    uncheckCellUpdate(state) {
      state.isCellUpdate = false;
    },
    openGanttModal(state, action) {
      state.isGanttModalOpen = true;
      state.ganttsectionpk = action.payload.ganttsectionpk;
      state.ganttrowpk = action.payload.ganttrowpk;      
    },
    closeGanttModal(state) {
      state.isGanttModalOpen = false;
      state.ganttsectionpk = null;
      state.ganttrowpk = null;      
    },

  },
});

export const { 
  setCategory, 
  openRoadmapSectionsModal,
  closeRoadmapSectionsModal, 
  openRoadmapSectionsDetailModal, 
  closeRoadmapSectionsDetailModal, 
  openEditRowsModal, 
  closeEditRowsModal, 
  openGraphsModal, 
  closeGraphsModal,
  openGraphsEditModal, 
  closeGraphsEditModal,
  openEditFormula,
  closeEditFormula,
  checkCellUpdate,
  uncheckCellUpdate,
  openGanttModal,
  closeGanttModal
} = roadmaps_stateSlice.actions;

export default roadmaps_stateSlice.reducer;



