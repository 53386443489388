import update from 'immutability-helper'
import React, { useCallback, useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
// import { useSelector } from 'react-redux'
import {Card} from './card.js'
//import {sectionsService} from "../../../../services/SectionsService";
import { orderSections } from '../../../../redux/toolkit/features/roadmapsection.js';


export const Container = (props) => {
        const [sections, setSections] = useState([]);
        const dispatch = useDispatch();

    useEffect(() => {
        setSections(props.sections);
    }, [props]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setSections((prevSections) => {
                const newSections = update(prevSections, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevSections[dragIndex]],
                    ],
                });
                const newSectionsPks = newSections.map(section => section.pk);
                dispatch(orderSections({sorted_pks:newSectionsPks}))
                //sectionsService.sortSections(newSectionsPks)//.then(() => getFactsOnMainPage());
                return newSections;
            }
        )
    }, [])

    const renderSection = useCallback(
        (section, index) => {
            return (
                <Card
                    key={section.pk}
                    index={index}
                    id={section.pk}
                    text={section.name}
                    moveCard={moveCard}
                    rowPk={section.pk}
                />
            )
        },
        [],
    )

    return (
        <>
            <div className="m-0 p-0">{sections.map((card, i) => renderSection(card, i))}</div>
        </>
    )
}