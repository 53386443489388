import api from "../api/roles";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const rolesAdapter = createEntityAdapter({
  selectId: (role) => role.pk,
  sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = rolesAdapter.getInitialState({
  status: "idle", isOpen: false, roleId: null, isActiveRole: true
});

export const fetchAllRoles = createAsyncThunk("roles/fetch", () =>
  api.fetchAllRoles()
);

export const updateRoles = createAsyncThunk("roles/update", api.updateRoles);

export const createRoles = createAsyncThunk("roles/create", api.createRoles);

export const deleteRoles = createAsyncThunk("roles/delete", api.deleteRoles);


const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    openRoles(state, action) {
      state.isOpen = true;
      state.roleId = action.payload.roleId;
    },
    closeRoles(state) {
      state.isOpen = false;
      state.roleId = null;
    },
    checkCurrentRole(state) {
      state.isActiveRole = true;
    },
    uncheckCurrentRole(state) {
      state.isActiveRole = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRoles.fulfilled, (state, { payload }) => {
        rolesAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateRoles.fulfilled, rolesAdapter.updateOne)
      .addCase(deleteRoles.fulfilled, rolesAdapter.removeOne)
      .addCase(createRoles.fulfilled, rolesAdapter.addOne);
  },
});

export const { openRoles, closeRoles, checkCurrentRole, uncheckCurrentRole} = rolesSlice.actions;

export default rolesSlice.reducer;

const selectState = (state) => state.roles;

export const {
  selectAll: selectRoles,
  selectById: selectRoleById,
} = rolesAdapter.getSelectors(selectState);

export const selectRolesStatus = createSelector(
  selectState,
  (state) => state.status
);

