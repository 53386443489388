import api from "../../api/success/successinnovationlinks";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const innovationlinksAdapter = createEntityAdapter({
  selectId: (innovationlinks) => innovationlinks.pk,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = innovationlinksAdapter.getInitialState({
  status: "idle"
});

export const fetchAllInnovationlinks = createAsyncThunk("innovationlinks/fetch", () =>
  api.fetchAllInnovationlinks()
);

export const updateInnovationlinks = createAsyncThunk("innovationlinks/update", api.updateInnovationlinks);

export const createInnovationlinks = createAsyncThunk("innovationlinks/create", api.createInnovationlinks);

export const deleteInnovationlinks = createAsyncThunk("innovationlinks/delete", api.deleteInnovationlinks);


const innovationlinksSlice = createSlice({
  name: "innovationlinks",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInnovationlinks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllInnovationlinks.fulfilled, (state, { payload }) => {
        innovationlinksAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateInnovationlinks.fulfilled, innovationlinksAdapter.updateOne)
      .addCase(deleteInnovationlinks.fulfilled, innovationlinksAdapter.removeOne)
      .addCase(createInnovationlinks.fulfilled, innovationlinksAdapter.addOne);
  },
});

export const { } = innovationlinksSlice.actions;

export default innovationlinksSlice.reducer;

const selectState = (state) => state.innovationlinks;

export const {
  selectAll: selectInnovationlinks,
  selectById: selectInnovationlinkById,
} = innovationlinksAdapter.getSelectors(selectState);

export const selectInnovationlinksStatus = createSelector(
  selectState,
  (state) => state.status
);

