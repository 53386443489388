import api from "../../api/success/successsprintlinks";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const sprintlinksAdapter = createEntityAdapter({
  selectId: (sprintlinks) => sprintlinks.pk,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = sprintlinksAdapter.getInitialState({
  status: "idle"

});

export const fetchAllSprintlinks = createAsyncThunk("sprintlinks/fetch", () =>
  api.fetchAllSprintlinks()
);

export const updateSprintlinks = createAsyncThunk("sprintlinks/update", api.updateSprintlinks);

export const createSprintlinks = createAsyncThunk("sprintlinks/create", api.createSprintlinks);

export const deleteSprintlinks = createAsyncThunk("sprintlinks/delete", api.deleteSprintlinks);


const sprintlinksSlice = createSlice({
  name: "sprintlinks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSprintlinks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllSprintlinks.fulfilled, (state, { payload }) => {
        sprintlinksAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateSprintlinks.fulfilled, sprintlinksAdapter.updateOne)
      .addCase(deleteSprintlinks.fulfilled, sprintlinksAdapter.removeOne)
      .addCase(createSprintlinks.fulfilled, sprintlinksAdapter.addOne);
  },
});

export const { } = sprintlinksSlice.actions;

export default sprintlinksSlice.reducer;

const selectState = (state) => state.sprintlinks;

export const {
  selectAll: selectSprintlinks,
  selectById: selectSprintlinkById,
} = sprintlinksAdapter.getSelectors(selectState);

export const selectSprintlinksStatus = createSelector(
  selectState,
  (state) => state.status
);

