import api from "../../api/organization/personview";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const personsviewAdapter = createEntityAdapter({
  selectId: (person) => person.pk,
  sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = personsviewAdapter.getInitialState({status: "idle"});

export const fetchAllPersonsview = createAsyncThunk("personsview/fetch", () => api.fetchAllPersonsview());

export const updatePersonsview = createAsyncThunk("personsview/update", api.updatePersonsview);

export const createPersonsview = createAsyncThunk("personsview/create", api.createPersonsview);

export const deletePersonsview = createAsyncThunk("personsview/delete", api.deletePersonsview);

const personsviewSlice = createSlice({
  name: "personsview",
  initialState,
  reducers: {
    openPeopleModal(state, action) {
      state.isOpen = true;
      state.peoplepk = action.payload;
    },
    closePeopleModal(state) {
      state.isOpen = false;
      state.peoplepk = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPersonsview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPersonsview.fulfilled, (state, { payload }) => {
        personsviewAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updatePersonsview.fulfilled, personsviewAdapter.updateOne)
      .addCase(deletePersonsview.fulfilled, personsviewAdapter.removeOne)
      .addCase(createPersonsview.fulfilled, personsviewAdapter.addOne);
  },
});

export const { openPeopleModal, closePeopleModal } = personsviewSlice.actions;

export default personsviewSlice.reducer;

const selectState = (state) => state.personsview;

export const {
  selectAll: selectPersonsview,
  selectById: selectPersonviewById,
} = personsviewAdapter.getSelectors(selectState);

export const selectPersonsviewStatus = createSelector(
  selectState,
  (state) => state.status
);
