import React, {Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { closeProgressModal } from "../../../redux/toolkit/features/sprints_state";
import { createProgressbaritem, fetchAllProgressbaritem, selectProgressbaritemById, updateProgressbaritem } from  "../../../redux/toolkit/features/sprints/progressbaritem";


function SprintProgressModal() {
    const isProgressModalOpen = useSelector((state) => state.sprints_state.isProgressModalOpen)
    const progressPK = useSelector((state) => state.sprints_state.progressPK)
    const progresssprintPK = useSelector((state) => state.sprints_state.progresssprintPK)
    const progressbaritembyid = useSelector((state)=>selectProgressbaritemById(state, progressPK));  
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch();

    const [item, setItem] = useState("")
    let modalClass = "modal";
    if (isProgressModalOpen) {
        modalClass += " is-active";
    }

    useEffect(() => {
        setItem(progressbaritembyid?progressbaritembyid.item:"")
      }, [progressPK,progressbaritembyid]);

      useEffect(() => {
        dispatch(fetchAllProgressbaritem())
      }, []);

      function renderForm() {
        return (
            <Fragment>
                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Value</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <input 
                                className="input"
                                value={item}
                                onChange = {(e) => setItem(e.target.value)}
                                />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

//     const currentDate = new Date()
//     const datelog = moment(currentDate, 'MM/DD/YYYY')
//     if (key) {
//       await registersService.patch(key, {comment: sprintcommentvalue, date: currentDate});
//   } else {
//       let username = currentUser.first_name + ' ' + currentUser.last_name
//       await registersService.post({username: username, comment: sprintcommentvalue,date: currentDate,sprint: sprintid});
//   }
//     await fetchSprints();
//   }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none", zIndex:100}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" style={{width:"500px"}} key="modalCard">
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                <p className="modal-card-title"style={{fontSize:"17px", color: textcolor}}>PROGRESS BAR ITEM</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeProgressModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        {progressPK === null &&
                            <button
                                className="button is-success is-small"
                                style={{borderRadius:"4px"}}
                                key="save"
                                onClick={async () => {
                                    await dispatch(createProgressbaritem({item:item, sprint: progresssprintPK }))
                                    .unwrap()
                                    dispatch(fetchAllProgressbaritem());
                                    dispatch(closeProgressModal());
                                }}
                            >
                                Save changes
                            </button>
                        }

                        {progressPK !== null &&
                            <button
                                className="button is-success is-small"
                                style={{borderRadius:"4px"}}
                                key="save"
                                onClick={async () => {
                                    await dispatch(updateProgressbaritem({id:progressPK,item:item, sprint: progresssprintPK }))
                                    .unwrap()
                                    dispatch(fetchAllProgressbaritem());
                                    dispatch(closeProgressModal());
                                }}
                            >
                                Save changes
                            </button>
                        }

                        <button className="button is-small" style={{borderRadius:"4px"}} key="cancel" onClick={() => dispatch(closeProgressModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SprintProgressModal;
