import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllSuccess = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/success`,
    authConfig()
  );
  return response.data.results;
};

export const fetchSuccess = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/success/${id}`,
    authConfig()
  );
  return response.data;
};

export const createSuccess = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/success`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateSuccess = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/success/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteSuccess = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/success/${id}`,
    authConfig()
  );
  return response.data;
};

const successAPI = {
  fetchAllSuccess,
  fetchSuccess,
  createSuccess,
  updateSuccess,
  deleteSuccess,
};
export default successAPI;
