import api from "../api/roadmaprows";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const roadmaprowsAdapter = createEntityAdapter({
  selectId: (row) => row.pk,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = roadmaprowsAdapter.getInitialState({
  status: "idle", rowpk: null, note: null, rowname: null, section: null
});

export const fetchAllRows = createAsyncThunk("rows/fetch", () =>
  api.fetchAllRows()
);

export const updateRows = createAsyncThunk("rows/update", api.updateRows);

export const createRows = createAsyncThunk("rows/create", api.createRows);

export const deleteRows = createAsyncThunk("rows/delete", api.deleteRows);

export const orderRows = createAsyncThunk("rows/sort", api.orderRows);

const roadmaprowsSlice = createSlice({
  name: "roadmaprows",
  initialState,
  reducers: {
    openRowNoteModal(state, action) {
      state.isOpen = true;
      state.rowpk = action.payload.rowpk;
      state.note = action.payload.note;
      state.rowname = action.payload.rowname;
      state.section = action.payload.section;
    },
    closeRowNoteModal(state) {
      state.isOpen = false;
      state.rowpk = null;
      state.note = null;
      state.rowname = null;
      state.section = null;
    },
   },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRows.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRows.fulfilled, (state, { payload }) => {
        roadmaprowsAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateRows.fulfilled, roadmaprowsAdapter.updateOne)
      .addCase(deleteRows.fulfilled, roadmaprowsAdapter.removeOne)
      .addCase(createRows.fulfilled, roadmaprowsAdapter.addOne);
  },
});

export const { openRowNoteModal, closeRowNoteModal } = roadmaprowsSlice.actions;

export default roadmaprowsSlice.reducer;

const selectState = (state) => state.roadmaprows;

export const {
  selectAll: selectroadmaprows,
  selectById: selectRowById,
} = roadmaprowsAdapter.getSelectors(selectState);

export const selectRowsStatus = createSelector(
  selectState,
  (state) => state.status
);
