import api from "../../api/success/baselinedelta";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const baselinedeltaAdapter = createEntityAdapter({
  selectId: (baselinedelta) => baselinedelta.pk,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = baselinedeltaAdapter.getInitialState({status: "idle"});

export const fetchAllBaselinedelta = createAsyncThunk("baselinedelta/fetch", () =>
  api.fetchAllBaselinedelta()
);

export const updateBaselinedelta = createAsyncThunk("baselinedelta/update", api.updateBaselinedelta);

export const createBaselinedelta = createAsyncThunk("baselinedelta/create", api.createBaselinedelta);

export const deleteBaselinedelta = createAsyncThunk("baselinedelta/delete", api.deleteBaselinedelta);


const baselinedeltaSlice = createSlice({
  name: "baselinedelta",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBaselinedelta.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllBaselinedelta.fulfilled, (state, { payload }) => {
        baselinedeltaAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateBaselinedelta.fulfilled, baselinedeltaAdapter.updateOne)
      .addCase(deleteBaselinedelta.fulfilled, baselinedeltaAdapter.removeOne)
      .addCase(createBaselinedelta.fulfilled, baselinedeltaAdapter.addOne);
  },
});

export const { } = baselinedeltaSlice.actions;

export default baselinedeltaSlice.reducer;

const selectState = (state) => state.baselinedelta;

export const {
  selectAll: selectbaselinedelta,
  selectById: selectbaselinedeltaById,
} = baselinedeltaAdapter.getSelectors(selectState);

export const selectbaselinedeltaStatus = createSelector(
  selectState,
  (state) => state.status
);

