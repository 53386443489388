import React, { Fragment, useState, useEffect, useContext} from "react";
import { useDispatch, useSelector } from 'react-redux'
import { filter } from "lodash";
import { cellValuesService } from "./../../services/CellValuesService";
import { FormatString } from "./../common/FormatCell";
import { celldatasets } from './Roadmap';
import {fetchAllCells, updateCells, createCells} from "./../../redux/toolkit/features/roadmapcells";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { checkCellUpdate } from "./../../redux/toolkit/features/roadmaps_state";

function CellValue(props) {
    const {baselinedelta, dataarray, rowPk, year, type, formula, percentages, fontweight, fontstyle, isbaseline, numericcell, textcell, decimalplaces, mediumalert, severealert, editmode, stylestring, borderbottomsetting, bordertopsetting} = props;
    const growth = (useContext(celldatasets)).growth;
    const cagr = (useContext(celldatasets)).cagr;
    const [numericvalue, setNumericValue] = useState(null); 
    const [existingvalue, setExistingValue] = useState(null);     
    const [textvalue, setTextValue] = useState(textcell);   
    var cagrdata = filter(cagr,{year: year, row_id: rowPk})
    let growthdata = filter(growth,{year: year, row_id: rowPk})


    let baselinedata = filter(baselinedelta,{year: year, row_id: rowPk})
    var alertstatus=(baselinedata[0]? baselinedata[0].alertstatus:'None')
    var alertcolor='inherit';
    if (alertstatus==='Severe') {
        alertcolor='Red'
    } 
    if (alertstatus==='Moderate') {
        alertcolor='#fcba03'
    } 

    useEffect(() => {
    }, [alertcolor]);


    const dispatch = useDispatch();
    var format="n0"
    var rowstyle;

    useEffect(() => {
        setNumericValue(dataarray[0]?.numeric_value || null)
        setExistingValue(dataarray[0]?.numeric_value || null)
    }, [dataarray]);


    switch(type) {
        case 'Number':
            format="n"+decimalplaces.toString()
            break;
        case 'Percent':
            format="p"+decimalplaces.toString()
            break;
    }
 
    async function handleCellChange(e) {
        if ((e.type==='blur' || e.keyCode === 13)) {
            if (e.target.value !== existingvalue) {
                if (dataarray.length===0) {
                    async function createcelldispatch() {
                        await dispatch(createCells({numeric_value:e.target.value, year: year, row: rowPk}))
                        .unwrap()
                            dispatch(fetchAllCells());
                            setExistingValue(e.target.value)
                        }
                    createcelldispatch()
                } else {
                    async function createcellupdate() {
                    await dispatch(updateCells({id:dataarray[0].pk, numeric_value:e.target.value, year: year, row: rowPk}))
                    .unwrap()
                        dispatch(fetchAllCells());
                        setExistingValue(e.target.value)
                    }
                    createcellupdate();
                }
            dispatch(checkCellUpdate());
        } else {
            setNumericValue(e.target.value)
        }
        }
    }
   
    
    async function handleCellTextChange(event) {

        setTextValue(event.target.value)
        if (event.type==='blur' && event.target.value !== "") {
            //let data = filter(cellvalues.results, {year: year, row_id: rowPk});
            if (dataarray.length===0) {
                await cellValuesService.post({text_value: textvalue, year: year, row: rowPk});
            } else {
                await cellValuesService.patch(dataarray[0].pk, {text_value: textvalue});
            }
        }
    }


    function GrowthValue() {
        if (growthdata[0] && type !== 'Text') {
            var growthtextvalue=(Number(growthdata[0].growth).toFixed(2))
        return (
                <input style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.7rem", fontWeight:'600'}} value={growthtextvalue+"%"} readOnly/>
        )
        }
        else {
            return (
                <input style={{border:"none", textAlign: "center", backgroundColor: "transparent", pointerEvents: "none", fontSize: "0.7rem"}} value={null} readOnly/>
            )
        }

    }

    function CAGRValue() {
        if (cagrdata[0] && type !== 'Text') {
            var cagrtextvalue=(Number(cagrdata[0].growth).toFixed(2))
        return (
                <input style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.7rem", fontWeight:'600'}} value={cagrtextvalue+"%"} readOnly/>
        )
        }
        else {
            return (
                <input style={{border:"none", textAlign: "center", backgroundColor: "transparent", pointerEvents: "none", fontSize: "0.7rem"}} value={null} readOnly/>
            )
        }

    }

    function BaselineValue() {
        if ((filter(baselinedelta, {row_id: rowPk})).length !== 0 && isbaseline) {
            if (baselinedata[0]) {
                var deltatextvalue=(Number(baselinedata[0].growth).toFixed(2))

            }
            if (baselinedata[0]) {

            return (
                <Fragment>
                    <input className="mt-2" style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.7rem", fontWeight:'600'}} value={FormatString(baselinedata[0].baseline, type, decimalplaces)} readOnly/>
                    <input style={{border:"none", color:alertcolor, textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.7rem"}} value={FormatString(baselinedata[0].difference, type, decimalplaces)} readOnly/>
                    <input className="mb-3" style={{border:"none", color:alertcolor, textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.7rem"}} value={deltatextvalue+'%'} readOnly/>
                </Fragment>
            )
            }
            else {
                return (
                    <Fragment>
                    <input style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.7rem", fonWeight:'600'}} value={null} readOnly/>
                    <input style={{border:"none", color: alertcolor, textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.7rem"}} value={null} readOnly/>
                    <input className="mb-3" style={{border:"none", color: alertcolor, textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.7rem"}} value={null} readOnly/>
                    </Fragment>
                )
            }
        }
    }


   
    function renderFields() {
        let key = `${rowPk}_${year}`;
        let baselinerow = filter(baselinedelta, {year: year, row_id: rowPk})
        let delta = null;
        if (baselinerow.length !== 0) {
            delta=baselinerow[0].growth
        }
        let alertstatus=null;
        if ((severealert < 0 && delta < severealert) || (severealert > 0 && delta > severealert)){
            alertstatus = "high";
        } else if ((mediumalert < 0 && delta < mediumalert) || (mediumalert > 0 && delta > mediumalert)){
            alertstatus = "moderate";
        }

        if (alertstatus === "high") {
            rowstyle={ textAlign:"center", borderBottom:'none', fontWeight:fontweight, fontStyle: fontstyle, backgroundColor:'transparent', pointerEvents:"none", border:"2px red solid"}
        } 
        
        if (alertstatus === "moderate") {
            rowstyle={ textAlign:"center", borderBottom:'none', fontWeight:fontweight, fontStyle: fontstyle, backgroundColor:'transparent', pointerEvents:"none", border:"2px gold solid"}
        }

        switch(type) {
            case "Text":
                return (
                    <Fragment>
                        {textcell &&
                            <textarea key={key} className="mb-0 pl-2 pr-2" style={{resize: "none"}} rows="5" value={textvalue} onChange={() =>handleCellChange} onBlur={handleCellTextChange}/> 
                        }
                        {!textcell &&
                            <textarea key={key}  className="mb-0 pl-2 pr-2" style={{resize: "none"}} rows="5" value={null} onChange={() =>handleCellChange} onBlur={handleCellTextChange}/>
                        }
                    </Fragment>
                )

            case "Number":
                return (
                    <Fragment>
                    {(editmode===true && formula===null) && <NumericTextBoxComponent className="nboxon mb-0 p-0 has-text-centered" style={{border: '1px solid darkgrey'}} showSpinButton={false} format={format} value={numericvalue} onChange={(e) => handleCellChange(e)} onKeyDown={(e) => handleCellChange(e)}  />}
                    {(editmode===false || formula !==null) && <NumericTextBoxComponent className="nboxoff mb-0 has-text-centered" style={{border: 'none'}} showSpinButton={false} format={format} value={numericvalue} onChange={(e) => handleCellChange(e)} onKeyDown={(e) => handleCellChange(e)}  />}

                    </Fragment>
                )

            case "Percent":
                return (
                    <Fragment>
                    {(editmode===true && formula===null) && <NumericTextBoxComponent className="nboxon mb-0 has-text-centered" style={{border: '1px solid darkgrey'}} showSpinButton={false} format={format} value={numericvalue} onChange={(e) => handleCellChange(e)} onKeyDown={(e) => handleCellChange(e)}  />}
                    {(editmode===false || formula !==null) && <NumericTextBoxComponent className="nboxoff mb-0 has-text-centered" style={{border: 'none'}} showSpinButton={false} min={0} max={100} step={0.01} format={format} value={numericvalue} onChange={(e) => handleCellChange(e)} onKeyDown={(e) => handleCellChange(e)}  />}
                    </Fragment>
                )
            }
        }

    return (
        <Fragment>
        <td key={'cellrender'} style={{textAlign:"center", justifyContent:"center", alignItems: "center", alignContent:"center", borderBottom: borderbottomsetting ,borderTop: bordertopsetting }}>
            {renderFields()}
            {isbaseline && <Fragment>{BaselineValue()} </Fragment>}
            {(percentages && type !== "Text") && <Fragment>{GrowthValue()} {CAGRValue()} </Fragment>}

        </td>

        </Fragment>
    )
}

export { CellValue }; 
