import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import {openStakeholdersModal} from "./../../redux/toolkit/features/stakeholder_state";

function Header(props) {
  const {stakeholders} = props;
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const dispatch = useDispatch(); 

  return (
    <tr className='th' style={{backgroundColor: backgroundcolor}}>
      <td> </td>
      <td style={{textAlign: 'center', fontWeight:'600', color:textcolor, userSelect: 'none'}}>Company</td>
      {stakeholders.map((stakeholder) => (
        <td key={stakeholder.name} style={{textAlign: 'center', fontWeight:'600'}}>
          <a
            style={{color:textcolor, userSelect: 'none'}}
            onClick={() => dispatch(openStakeholdersModal({stakeholderId:stakeholder.pk}))}
          >
            {stakeholder.name}
          </a>
        </td>
      ))}
    </tr>
  );
}

export default Header;
