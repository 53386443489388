import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllSponsors = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/sponsorsview`,
    authConfig()
  );
  return response.data.results;
};

export const fetchSponsors = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/sponsorsview/${id}`,
    authConfig()
  );
  return response.data;
};

export const createSponsors = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/sponsors`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateSponsors = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/sponsorsview/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteSponsors = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/sponsors/${id}`,
    authConfig()
  );
  return response.data;
};

const sponsorsAPI = {
  fetchAllSponsors,
  fetchSponsors,
  createSponsors,
  updateSponsors,
  deleteSponsors,
};
export default sponsorsAPI;
