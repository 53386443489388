import React from "react";
import { useDispatch } from 'react-redux'
import {openCompanyPurposeModal} from "./../../redux/toolkit/features/company_state"

function CompanyPurposeSectionWithData(props) {
    const {purpose, statement} = props;
    const dispatch = useDispatch();

    const inlineMode = {
        enable: false,
        onSelection: false
    };


    return (
            <section style={{
                paddingLeft: "17%",
                paddingRight: "17%",
                backgroundColor: "RGB(240,240,240)",
                paddingTop: "100px",
                paddingBottom: "130px",
                userSelect: "none"
                }} 
                onDoubleClick={() => dispatch(openCompanyPurposeModal())}>
                <p style={{paddingBottom: "40px"}}>
                    <span style={{fontFamily: "Gotham Extra", fontSize: "59px", lineHeight: "56px"}}>
                        {purpose}
                    </span>
                </p>
                <div className="purposecontainer" style={{textAlign: "justify"}}>
                    <span style={{fontFamily: "Gotham Extra", fontSize: "16.5px", lineHeight: "1.8em"}}>
                        <div dangerouslySetInnerHTML={{__html:statement}}/>
                    </span>
                </div>
            </section>
    );
}

export default CompanyPurposeSectionWithData;
