import api from "../../api/company/facts";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const factsAdapter = createEntityAdapter({
  selectId: (fact) => fact.pk,
  sortComparer: (a, b) => a.fact.localeCompare(b.fact),
});

const initialState = factsAdapter.getInitialState({status: "idle"});

export const fetchAllFacts = createAsyncThunk("facts/fetch", () => api.fetchAllFacts());

export const updateFacts = createAsyncThunk("facts/update", api.updateFacts);

export const createFacts = createAsyncThunk("facts/create", api.createFacts);

export const deleteFacts = createAsyncThunk("facts/delete", api.deleteFacts);

const factsSlice = createSlice({
  name: "facts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFacts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllFacts.fulfilled, (state, { payload }) => {
        factsAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateFacts.fulfilled, factsAdapter.updateOne)
      .addCase(deleteFacts.fulfilled, factsAdapter.removeOne)
      .addCase(createFacts.fulfilled, factsAdapter.addOne);
  },
});

export const {} = factsSlice.actions;

export default factsSlice.reducer;

const selectState = (state) => state.facts;

export const {
  selectAll: selectFacts,
  selectById: selectFactById,
} = factsAdapter.getSelectors(selectState);

export const selectFactsStatus = createSelector(
  selectState,
  (state) => state.status
);
