import React, {Fragment, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { selectSuccessById, updateSuccess, createSuccess, fetchAllSuccess} from '../../../redux/toolkit/features/success/success';
import { closeSuccessRecordModal, openSuccessModal} from '../../../redux/toolkit/features/success_state';


function SuccessModalDetail() {
    const isSuccessRecordModalOpen = useSelector((state) => state.success_state.isSuccessRecordModalOpen);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const successid = useSelector((state) => state.success_state.successRecordModalPK)
    const successbyid = useSelector((state)=>selectSuccessById(state, successid))  
    const[name, setName] = useState("");
    const[description, setDescription] = useState("");
    const dispatch = useDispatch();
    
    let modalClass = 'modal';
    if (isSuccessRecordModalOpen) {
        modalClass += ' is-active';
    }

    useEffect(() => {
        if (successbyid) {
            setName(successbyid.name)
            setDescription(successbyid.description)
        } else {
            setName("")
            setDescription("")
        }
      }, [successid]);


    function renderDetail() {
        return (
            <Fragment>
            <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Vision</p>
            <div className='field is-horizontal is-align-items-center'>
                <div className='field-body is-flex'>
                    <div className='control is-flex-grow-1'>
                        {name !== undefined &&
                        <input
                            className='input'
                            type='text'
                            placeholder='Vision'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />}
                    </div>
                </div>
            </div>

            <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Description</p>
            <div className='field is-horizontal is-align-items-center'>
                <div className='field-body is-flex'>
                    <div className='control is-flex-grow-1'>
                    {description !== undefined &&
                    <textarea 
                        className='textarea' 
                        value={description} 
                        rows='6'
                        placeholder='Description'
                        onChange={(e) => setDescription(e.target.value)}
                    ></textarea>}
                    </div>
                </div>
            </div>
 
        </Fragment>
        )
    }

    return (
        <Fragment>
        <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
            <div className='modal-background' key='modalBackground'>
                {' '}
            </div>
            <div className='modal-card' key='modalCard'>
                <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                    <p className='modal-card-title' style={{fontSize:"17px", color: textcolor}}>EDIT SUCCESS VISION</p>
                    <button className='delete' aria-label='close' onClick={() => {
                        dispatch(closeSuccessRecordModal());
                        dispatch(openSuccessModal());  
                        }}>
                        {' '}
                    </button>
                </header>
                <section className='modal-card-body' key='modalBody' style={{minHeight: '250px'}}>
                {renderDetail()}
                </section>
                <footer className='modal-card-foot is-flex pt-0 pb-0' key='modalFooter' style={{height:"60px"}}>

                    {successid!== null &&
                        <button
                            style={{borderRadius:"4px"}}
                            className='button is-success is-small'
                            key='save'
                            onClick={async () => {
                                await dispatch(updateSuccess({id:successid, name:name, description: description}))
                                .unwrap()
                                    dispatch(fetchAllSuccess());
                                    dispatch(closeSuccessRecordModal());
                                    dispatch(openSuccessModal());  
                            }}
                        >
                            Save Changes
                        </button>
                    }

                    {successid=== null &&
                        <button
                            style={{borderRadius:"4px"}}
                            className='button is-success is-small'
                            key='save'
                            onClick={async () => {
                                await dispatch(createSuccess({name:name, description: description}))
                                .unwrap()
                                    dispatch(fetchAllSuccess());
                                    dispatch(closeSuccessRecordModal());
                                    dispatch(openSuccessModal());  
                            }}
                        >
                            Save Changes
                        </button>
                    }





                    <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => {
                        dispatch(closeSuccessRecordModal());
                        dispatch(openSuccessModal());                  
                        }}>
                        Cancel
                    </button>
                </footer>
            </div>
        </div>

    </Fragment>
    );
}

export default SuccessModalDetail;
