import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllRoadmap = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmaps`,
    authConfig()
  );
  return response.data.results;
};

export const fetchRoadmap = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmaps/${id}`,
    authConfig()
  );
  return response.data;
};

export const createRoadmap = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roadmaps`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateRoadmap = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/roadmaps/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteRoadmap = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/roadmaps/${id}`,
    authConfig()
  );
  return response.data;
};

const roadmapAPI = {
  fetchAllRoadmap,
  fetchRoadmap,
  createRoadmap,
  updateRoadmap,
  deleteRoadmap,
};
export default roadmapAPI;
