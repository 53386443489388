import React, {Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {Container} from "./SuccessOrder/Container";
import { fetchAllSuccess } from "./../../../redux/toolkit/features/success/success";
import { closeSuccessModal, openSuccessRecordModal } from "./../../../redux/toolkit/features/success_state";

function SuccessModal() {
    const isSuccessModalOpen = useSelector((state) => state.success_state.isSuccessModalOpen)
    const success = Object.values(useSelector((state) => state.success.entities)).sort((a, b) => a.order - b.order);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch();

    let modalClass = 'modal';
    if (isSuccessModalOpen) {
        modalClass += ' is-active';
    }
   
    useEffect(() => {
        async function fetchSuccess() {
            dispatch(fetchAllSuccess());
        }
        fetchSuccess();
    },[]);


    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card' key='modalCard'>
                    <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className='modal-card-title' style={{fontSize:"17px", color: textcolor}}>SUCCESS VISIONS</p>
                        <button className='delete' aria-label='close' onClick={() => dispatch(closeSuccessModal())}>
                            {' '}
                        </button>
                    </header>
                    <section className='modal-card-body' key='modalBody' style={{minHeight: '330px'}}>
                      
                    <Container success={success}/>

                    </section>
                    <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>
                        <button
                            style={{color: textcolor, backgroundColor: backgroundcolor, borderRadius:"4px"}}
                            className="button is-small"
                            key="new"
                            onClick={async () => {
                                dispatch(openSuccessRecordModal({successRecordModalPK:null}))
                                dispatch(closeSuccessModal())
                            }}
                        >
                            Add New
                        </button>
                        <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => dispatch(closeSuccessModal())}>
                            Cancel
                        </button>

                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SuccessModal;
