import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllProgressbaritem = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/progressbaritem`,
    authConfig()
  );
  return response.data.results;
};

export const fetchProgressbaritem = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/progressbaritem/${id}`,
    authConfig()
  );
  return response.data;
};

export const createProgressbaritem = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/progressbaritem`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateProgressbaritem = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/progressbaritem/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteProgressbaritem = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/progressbaritem/${id}`,
    authConfig()
  );
  return response.data;
};

export const orderProgressbaritem = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/progressbaritem/sort-items`,
    data,
    authConfig()
  );
  return response.data;
};

const progressbaritemAPI = {
  fetchAllProgressbaritem,
  fetchProgressbaritem,
  createProgressbaritem,
  updateProgressbaritem,
  deleteProgressbaritem,
  orderProgressbaritem
};
export default progressbaritemAPI;
