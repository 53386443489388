import React from "react";
import CompanyHeroSectionOnlyButton from "./CompanyHeroSectionOnlyButton";
import CompanyHeroSectionWithData from "./CompanyHeroSectionWithData";

function CompanyHeroSection(props) {
    const {herotitle, herosubtitle, heroimage} = props;
    const showDetail = herotitle || herosubtitle || heroimage;
    if (showDetail) {
        return <CompanyHeroSectionWithData
                    herotitle={herotitle}
                    herosubtitle={herosubtitle}
                    heroimage={heroimage}
        />;
    } else {
        return <CompanyHeroSectionOnlyButton/>;
    }
}

export default CompanyHeroSection;
