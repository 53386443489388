import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {TalentPosition} from "./TalentPosition";
import { Chart } from "react-google-charts";
import { fetchAllRoles } from "./../../redux/toolkit/features/roles";
import { fetchAllRoleCompetencies } from "./../../redux/toolkit/features/rolecompetencies";
import { fetchAllCompetencies } from "./../../redux/toolkit/features/competencies";
import { fetchAllRolePerson } from "./../../redux/toolkit/features/roleperson";
import { fetchAllPersons} from "./../../redux/toolkit/features/organization/persons";
import { fetchAllCompetencyScores} from "./../../redux/toolkit/features/organization/competencyscores";
import { fetchAllOverallscores} from "./../../redux/toolkit/features/organization/overallscores";
import {filter} from "lodash"



function Talent(props) {
    const {sankey} = props;
    const positions = Object.values(useSelector((state) => state.roles.entities));
    const competencies = Object.values(useSelector((state) => state.competencies.entities));
    const roleperson = Object.values(useSelector((state) => state.roleperson.entities));
    const persons = Object.values(useSelector((state) => state.persons.entities));
    const rolecompetencies = Object.values(useSelector((state) => state.rolecompetencies.entities));
    const competencyscores = Object.values(useSelector((state) => state.competencyscores.entities));
    const overallscores = Object.values(useSelector((state) => state.overallscores.entities));
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
    const [isCurrent, setIsCurrent] = useState(false)
    const [isTable, setIsTable] = useState(true)
    const [textfilter, setTextfilter] = useState('')
    const dispatch = useDispatch();  
    var [filteredpositions] = [];

    function toggleButton() {
        if (isTable) {
            setIsTable(false)
        } else {
            setIsTable(true)
        }
    }

    async function getRoles() {
        dispatch(fetchAllRoles());
        dispatch(fetchAllRoleCompetencies());
        dispatch(fetchAllCompetencies());
        dispatch(fetchAllRolePerson());
        dispatch(fetchAllPersons());
        dispatch(fetchAllCompetencyScores());
        dispatch(fetchAllOverallscores());
      }

    useEffect(() => {
        getRoles()
    },[]);


    filteredpositions = positions.filter((position => {
        let positiontitle=(position.title!==null?position.title:"Random 83839292")
        if (position.issuccession===true && positiontitle.toLowerCase().includes(textfilter.toLowerCase())){
            if (!isCurrent || (isCurrent && (position.startdate===null))) {
                return true;
            }
            
            return filteredpositions;
        }
    }));

    function renderChart() {
        if (!sankey) {
            return null;
        }
        let sankeydata = [['From', 'To', 'Readiness',{ role: "tooltip", type: "string", 'p': {html: true}} ]]
        {filteredpositions.map(row => {
            let rolepersonfiltered = filter(roleperson, {role:row.pk})
                 if (rolepersonfiltered.length===0) {
                sankeydata.push([row.title,"No Candidates Identified",  Number(0.3),"No candidates identied for " + row.title])
            } else {

                rolepersonfiltered.map(rp => {
                  var person=filter(persons, {pk:rp.person})
                  var personname = person[0]?person[0].firstname + " " + person[0].secondname: 'Unidentified'
                  let overallscore = filter(overallscores, {position_id:row.pk, people_id:rp.person})
                  let overallscorevalue=overallscore[0]?overallscore[0].score:0.8
                  let overallscoretext=overallscore[0]?overallscore[0].score:"N/A"
                  sankeydata.push([row.title, personname, Number(overallscorevalue===null?2:overallscorevalue),personname + ' → ' + row.title + '  [ Readiness Rating: ' + overallscoretext + " ]"])

                })
            }
        })}
        let chartheight="900px"
        if (sankey && sankey.length !== 0) {
            let chartpixels = sankey.length*100
            if (chartpixels > chartheight) {
                chartheight = chartpixels
            }
        }


        return (
            <Fragment>
            {filteredpositions.length!==0 &&
            <Chart className="mt-2"
            width={'100%'}
            height={chartheight}
            chartType="Sankey"
            loader={<div>Loading Chart</div>}
            data={sankeydata}
            options={{
                sankey: {
                    tooltip: {isHtml: true},
                    node: { label: { fontName: "Gotham Extra",
                                     fontSize: 14,
                 } } },

            }}
            //rootProps={{ 'data-testid': '1' }}
            />
            }
            {filteredpositions.length===0 &&
            <Fragment>
                <br/>
                <p style={{fontWeight:500, backgroundColor:'transparent'}}>No Records Returned</p>
            </Fragment>
             }
            </Fragment>


        )
    }
  
    return (
        <Fragment>
            <section>
              <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "20px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        TALENT PIPELINE / KEY ROLES  ({filteredpositions.length})<button style={{cursor:'pointer'}} onClick={() => toggleButton()} className="mr-3 is-right" ><i className="fas fa-project-diagram" style={{color: backgroundcolor}}></i></button><br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="level-right">
                            <div className="level-item pt-4 pr-4">
                                <label className="checkbox subtitle" style={{fontWeight: "400", fontFamily: "Gotham Extra", fontSize: "16px"}}>
                                    <span className="mr-2">
                                        <small>Show Current</small>
                                    </span>

                                    <input
                                        type="checkbox" checked={isCurrent}  onClick={() =>{isCurrent?setIsCurrent(false):setIsCurrent(true)}}
                                    />
                                </label>
                            </div>

                            <div className="level-item pt-4 pr-0">
                                <div className="field has-addons">
                                    <div className="control is-rounded is-small" style={{width: "140px",userSelect: "none"}}>
                                        <span> <input className="input is-rounded is-small"  type="search" placeholder="Filter" onChange={(e) => setTextfilter(e.target.value)} style={{ color: "rgb(128,128,128)",userSelect: "none"}}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    
                    <hr className="pt-0 mt-0 pb-0 mb-0" style={{borderTop: "2px solid lightgrey"}} />
              </section>

            <section className="p-0 m-0">

                {isTable &&
                    <Fragment>
                        <table className="table is-fullwidth"  >
                            <tbody>
                                {filteredpositions.length!==0 && filteredpositions.map(row => (
                                    <TalentPosition
                                        positionrow = {row}
                                        persons={persons}
                                        rolecompetencies={rolecompetencies}
                                        roleperson={roleperson}
                                        competencies={competencies}
                                        competencyscores={competencyscores}
                                        overallscores={overallscores}
                                        />
                                ))}

                                {filteredpositions.length===0 && 
                                    <Fragment> 
                                        <p className="pt-4" style={{fontWeight:'500'}}>No Roles Found</p>
                                    </Fragment>
                                }
                            </tbody>
                        </table>
                </Fragment>
                }

                {!isTable && 
                    renderChart()
                }

            </section>
        </Fragment>
    )
}

export {Talent};