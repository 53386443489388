import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllValues } from "./../../redux/toolkit/features/company/values";
import { openValuesModal } from "./../../redux/toolkit/features/company_state";

function CompanyValuesSection() {
  const values = Object.values(useSelector((state) => state.values.entities)).sort((a, b) => a.order - b.order);
  const isValuesModalOpen = useSelector((state) => state.company_state.isValuesModalOpen)
  const isValuesRecordModalOpen = useSelector((state) => state.company_state.isValuesRecordModalOpen);
  const dispatch = useDispatch(); 

  async function getValues() {
    dispatch(fetchAllValues());
  }

  useEffect(() => {
    if(!isValuesModalOpen && !isValuesRecordModalOpen) {
      getValues();
    }
  },[isValuesModalOpen]);

  function renderValues() {


      if (values && values.length===0) {

      return (
        <section className="mt-6 mb-6" style={{paddingLeft: "17%", paddingRight: "17%", backgroundColor: "White", paddingTop: "80px", paddingBottom: "80px", userSelect: "none"}} >
          <div className="columns is-multiline">
            <div className="column is-one-third" style={{fontFamily: "Gotham Extra", fontSize: "56px", lineHeight: "56px"}}>
              <div className="pr-1">
              OUR<br/>CORE<br/>VALUES
              </div>
            </div>
            <div className="column is-one-third pt-0 pr-0 pb-4 pl-4 is-flex" style={{fontFamily: "Gotham Extra", fontSize: "30px", lineHeight: "0.8em"}}>
              <div className="level" style={{backgroundColor: "transparent", minHeight: "150px",userSelect: "none"}}>
                <div className="level-item pt-6" style={{userSelect: "none", minHeight: "150px"}}>
                  <button className = "button p-4" style={{backgroundColor: "transparent", border: "solid 1px darkgrey", fontFamily: "Gotham Extra", fontSize: "20px"}} onClick={() => dispatch(openValuesModal())} >
                  Add Core Values
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
      }

      return (
      <Fragment>
      <section className="mt-6 mb-6" style={{paddingLeft: "17%", paddingRight: "17%", backgroundColor: "White", paddingTop: "80px", paddingBottom: "80px", userSelect: "none"}} onDoubleClick={() => dispatch(openValuesModal())}>
        <div className="columns is-multiline">
          <div className="column is-one-third" style={{fontFamily: "Gotham Extra", fontSize: "56px", lineHeight: "56px"}}>
            <div className="pr-1">
              OUR<br/>CORE<br/>VALUES
            </div>
          </div>

          {values && values.map((value)=> ( 
            <Fragment key={"Values_fragment_"+value.pk}>
              <div className="column is-one-third pt-0 pb-4 pl-4 pr-0 is-flex" style={{fontFamily: "Gotham Extra", fontSize: "18px", lineHeight: "0.8em"}}>
                <div className="card mt-0 pt-0 pb-5 is-shadowless" style={{backgroundColor: "rgb(238,238,238)", width: "100%", minHeight: "300px"}}>
                  <header className="card-header is-shadowless">
                    <p className="pl-5 pt-5" style={{fontWeight:'600'}}>
                      {value.value}
                    </p>
                  </header>
                  <div className="card-content" >
                    <div className="content ml-0" style={{backgroundColor: "rgb(238,238,238)"}}>
                        <p style={{fontFamily: "Gotham Extra", fontSize: "17.5px", lineHeight: "1.5em"}}>{value.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </section>
      </Fragment>
      )
  }


  return (
    renderValues()
  )
}

export default CompanyValuesSection;
