import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllCompanyMatrices = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/companymatrices`,
    authConfig()
  );
  return response.data;
};

export const fetchCompanymatrix = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/companymatrices/${id}`,
    authConfig()
  );
  return response.data;
};

export const createCompanymatrix = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/companymatrices`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateCompanymatrix = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/companymatrices/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteCompanymatrix = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/companymatrices/${id}`,
    authConfig()
  );
  return response.data;
};

const companymatricesAPI = {
  fetchAllCompanyMatrices,
  fetchCompanymatrix,
  createCompanymatrix,
  updateCompanymatrix,
  deleteCompanymatrix,
};
export default companymatricesAPI;
