import { React, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllTimelines } from "./../../../redux/toolkit/features/company/timelines";
import { openTimelineRecordModal, closeTimelineModal } from "../../../redux/toolkit/features/company_state";
import { openDeleteModal } from "./../../../redux/toolkit/features/modals/deleteConfirmModal";

function CompanyTimelineModal() {
    const timelines = Object.values(useSelector((state) => state.timelines.entities)).sort((a, b) => a.year - b.year);
    const isTimelineModalOpen = useSelector((state) => state.company_state.isTimelineModalOpen);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
    const textcolor = useSelector((state) => state.colors.textcolor);
    const dispatch = useDispatch(); 

    async function getTimelines() {
        dispatch(fetchAllTimelines());
    }

     let modalClass = "modal";
    if (isTimelineModalOpen) {
        modalClass += " is-active";
    }

    useEffect(() => {
            getTimelines();
      },[]);

    useEffect(() => {
        getTimelines();
    },[]);


    function renderRow(row) {
        return (
        <tr className="ml-4 pl-5" colSpan="2" style={{fontSize:"15px"}}>
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', fontWeight:"600"}} 
                onClick={() => {
                    dispatch(openTimelineRecordModal({timelinePK:row.pk}));
                    dispatch(closeTimelineModal());
            }}>
            {row.year}
            </td>
            <td  colSpan="2" className="mb-2 ml-5 pl-2" style={{borderTop:"solid 1px grey", borderBottom:"solid 1px grey"}} 
                onClick={() => {
                    dispatch(openTimelineRecordModal({timelinePK:row.pk}));
                    dispatch(closeTimelineModal());
            }}>
            {row.event}
            </td>                 
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} onClick={async () => {
                        dispatch(openDeleteModal({message: row.event,recordpk: row.pk, recordtype: 'timeline'}));
            }}>
            <button className="far fa-trash-alt" style={{color:"gray"}}
                ></button>
            </td>
        </tr>
        )
    }


    function renderForm() {
        return (
            <Fragment>
                <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
                    <tbody>
                        {timelines.map(row => (
                            <Fragment key={"Timeline_Modal_fragment_"+row.pk}>
                                {renderRow(row)}
                            </Fragment>
                        ))}
                    </tbody>
                </table>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>COMPANY TIMELINE</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeTimelineModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: '200px'}}>
                        {timelines.length !== 0 &&
                        renderForm()}
                        {timelines.length === 0 &&
                        <p className = "pt-5" style={{fontSize:"16.5px"}}>No Records Returned</p>}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <button
                            style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}}
                            className="button is-small"
                            key="new"
                            onClick={async () => {
                                dispatch(openTimelineRecordModal({timelinePK:null}));
                                dispatch(closeTimelineModal());
                            }}
                        >
                            Add New
                        </button>
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeTimelineModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default CompanyTimelineModal;
