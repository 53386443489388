import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllRolePerson = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/talent/roleperson`,
    authConfig()
  );
  return response.data;
};

export const fetchRolePerson = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/talent/roleperson/${id}`,
    authConfig()
  );
  return response.data;
};

export const createRolePerson = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/talent/roleperson`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateRolePerson = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/talent/roleperson/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteRolePerson = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/talent/roleperson/${id}`,
    authConfig()
  );
  return response.data;
};

const rolepersonAPI = {
  fetchAllRolePerson,
  fetchRolePerson,
  createRolePerson,
  updateRolePerson,
  deleteRolePerson,
};
export default rolepersonAPI;
