import api from "../../api/company/company";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const companyheroAdapter = createEntityAdapter({  
  selectId: (company) => company.pk});

const initialState = companyheroAdapter.getInitialState({status: "idle"});

export const fetchAllCompany = createAsyncThunk("company/fetch", () =>  api.fetchAllCompany());

export const updateCompany = createAsyncThunk("company/update", api.updateCompany);

export const createCompany = createAsyncThunk("company/create", api.createCompany);

export const deleteCompany = createAsyncThunk("company/delete", api.deleteCompany);

export const uploadImage = createAsyncThunk("company/image", api.uploadImage);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCompany.fulfilled, (state, { payload }) => {
        companyheroAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateCompany.fulfilled, companyheroAdapter.updateOne)
      .addCase(deleteCompany.fulfilled, companyheroAdapter.removeOne)
      .addCase(createCompany.fulfilled, companyheroAdapter.addOne);
  },
});

export default companySlice.reducer;

const selectState = (state) => state.company;

export const {
  selectAll: selectCompany,
} = companyheroAdapter.getSelectors(selectState);

export const selectCompanyStatus = createSelector(
  selectState,
  (state) => state.status
);
