import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllRoadmaplinks = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/linkedroadmaps`,
    authConfig()
  );
  return response.data.results;
};

export const fetchRoadmaplinks = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/linkedroadmaps/${id}`,
    authConfig()
  );
  return response.data;
};

export const createRoadmaplinks = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/linkedroadmaps`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateRoadmaplinks = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/linkedroadmaps/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteRoadmaplinks = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/linkedroadmaps/${id}`,
    authConfig()
  );
  return response.data;
};

const roadmaplinksAPI = {
  fetchAllRoadmaplinks,
  fetchRoadmaplinks,
  createRoadmaplinks,
  updateRoadmaplinks,
  deleteRoadmaplinks,
};
export default roadmaplinksAPI;
