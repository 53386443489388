import api from "../../api/sprints/sponsors";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const sponsorsAdapter = createEntityAdapter({
  selectId: (sponsor) => sponsor.pk,
});

const initialState = sponsorsAdapter.getInitialState({status: "idle"});

export const fetchAllSponsors = createAsyncThunk("sponsors/fetch", () =>
  api.fetchAllSponsors()
);

export const updateSponsors = createAsyncThunk("sponsors/update", api.updateSponsors);

export const createSponsors = createAsyncThunk("sponsors/create", api.createSponsors);

export const deleteSponsors = createAsyncThunk("sponsors/delete", api.deleteSponsors);

const sponsorsSlice = createSlice({
  name: "sponsors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSponsors.pending, (state) => {state.status = "loading";})
      .addCase(fetchAllSponsors.fulfilled, (state, { payload }) => {
        sponsorsAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateSponsors.fulfilled, sponsorsAdapter.updateOne)
      .addCase(deleteSponsors.fulfilled, sponsorsAdapter.removeOne)
      .addCase(createSponsors.fulfilled, sponsorsAdapter.addOne);
  },
});

export const {} = sponsorsSlice.actions;

export default sponsorsSlice.reducer;

const selectState = (state) => state.sponsors;

export const {
  selectAll: selectSponsors,
  selectById: selectSponsorById,
} = sponsorsAdapter.getSelectors(selectState);

export const selectSponsorsStatus = createSelector(
  selectState,
  (state) => state.status
);
