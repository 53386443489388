import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllInnovationlinks = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/linkedthemes`,
    authConfig()
  );
  return response.data.results;
};

export const fetchInnovationlinks = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/linkedthemes/${id}`,
    authConfig()
  );
  return response.data;
};

export const createInnovationlinks = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/linkedthemes`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateInnovationlinks = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/linkedthemes/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteInnovationlinks = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/linkedthemes/${id}`,
    authConfig()
  );
  return response.data;
};

const innovationlinksAPI = {
  fetchAllInnovationlinks,
  fetchInnovationlinks,
  createInnovationlinks,
  updateInnovationlinks,
  deleteInnovationlinks,
};
export default innovationlinksAPI;
