import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllPositionparent = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/positionparent`,
    authConfig()
  );
  return response.data.results;
};

export const fetchPositionparent = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/positionparent/${id}`,
    authConfig()
  );
  return response.data;
};

export const createPositionparent = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/positionparent`,
    data,
    authConfig()
  );
  return response.data;
};

export const updatePositionparent = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/positionparent/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deletePositionparent = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/positionparent/${id}`,
    authConfig()
  );
  return response.data;
};

const positionparentAPI = {
  fetchAllPositionparent,
  fetchPositionparent,
  createPositionparent,
  updatePositionparent,
  deletePositionparent,
};
export default positionparentAPI;
