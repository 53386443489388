import React, {Fragment, useState, useEffect} from 'react';
import 'bulma/css/bulma.css';
import { SketchPicker } from "react-color";

function ColorModal(props) {
    const {setShowModal, showModal, setBackground, background, text, setText, colortype} = props;
    const [sketchPickerColor, setSketchPickerColor] = useState({hex:'#dd8e00'});

    console.log("Show Modal", showModal)

    let modalClass = 'modal';
    if (showModal) {
        modalClass += ' is-active';
    }

    useEffect(() => {
        if(colortype==='background') {
        setSketchPickerColor(background?{hex:background}:{hex:'#dd8e00'})
        }
        if(colortype==='text') {
            setSketchPickerColor(text?{hex:text}:{hex:'#FFFFFF'})
        }
    }, [showModal]);

    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card m-0 p-0' key='modalCard' style={{width:"320px"}}>
                <header className="modal-card-head" key="modalHeader" style={{height:'35px', backgroundColor:background}}>
                        <p style={{fontSize:'16px', color: 'white'}} className="modal-card-title">COLOR PICKER</p>
                        <button className="delete" aria-label="close" onClick={() => setShowModal(false)}>
                            {" "}
                        </button>
                    </header>
                   <section className='modal-card-body m-0 pl-1 pr-1 pt-0 pb-0' style={{overflow: "hidden"}} key='modalBody'>
                   <SketchPicker
                   className="p-0 m-0"
                   disableAlpha={true}
                   width="100%"
                    onChange={(color) => {
                        setSketchPickerColor(color.hex);
                     }}
                     color={sketchPickerColor}/>

                   </section>
                    <footer className='modal-card-foot is-flex' style={{height: "50px"}}key='modalFooter'>
                        <nav className='level'>
                            <div className='level-right'>
                                <div className='level-item'>
                                    
                                    {colortype==='background' && 
                                    <Fragment> 
                                        <button
                                        className='button is-small'
                                        style={{backgroundColor: background, color: text}}
                                        key='save'
                                        onClick={()=> {
                                            setBackground(sketchPickerColor)
                                            setShowModal(false)
                                        }}
                                    >
                                        Update
                                    </button>
                                    </Fragment>}

                                    {colortype==='text' && 
                                    <Fragment> 
                                        <button
                                        className='button is-small'
                                        style={{backgroundColor: background, color: text}}
                                        key='save'
                                        onClick={()=> {
                                            setText(sketchPickerColor)
                                            setShowModal(false)
                                        }}
                                    >
                                        Update
                                    </button>
                                    </Fragment>}
                                </div>
                                <div className='level-item'>
                                    <button className='button is-small' key='cancel' onClick={() => setShowModal(false)}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </footer>
                </div>
            </div>

        </Fragment>
    );
}

export default ColorModal;
