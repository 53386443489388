import { React, Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { closePeopleModal, updatePersons, createPersons, selectPersonById } from "./../../../redux/toolkit/features/organization/persons";
import { closeTalentSelectPersonModal } from "./../../../redux/toolkit/features/organization_state";
import { createRolePerson } from "../../../redux/toolkit/features/roleperson";
import {filter} from "lodash"

function SelectTalentPersonModal() {
    const isTalentSelectPersonModalOpen = useSelector((state) => state.organization_state.isTalentSelectPersonModalOpen)
    const talentrolePK = useSelector((state) => state.organization_state.talentrolePK)
    const peoplerecordkey = useSelector((state) => state.persons.peoplepk)
    const persons = Object.values(useSelector((state) => state.persons.entities))
    const roleperson = Object.values(useSelector((state) => state.roleperson.entities));
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const [personvalue, setPersonValue] = useState(null);
    const dispatch = useDispatch(); 

    let modalClass = "modal";
    if (isTalentSelectPersonModalOpen) {
        modalClass += " is-active";
    }


    let rolepersonlist=filter(roleperson, {role:talentrolePK}).map(r => r.person)
    var personlist = filter( persons, (row) => !rolepersonlist.includes(row.pk))


    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                        <select className="select is-fullwidth" onChange={(event) => setPersonValue(event.target.value)}>
                            {personvalue === null && <option selected>{'<Select Employee>'}</option>}
                                {personlist &&
                            personlist.sort((a, b) => (a.firstname.localeCompare(b.firstname))).map((p) => (
                            <option key={p.pk} value={p.pk}>
                                {p.firstname} {p.secondname} 
                                </option>
                                ))}
                        </select>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" style={{width:'500px'}}key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"40px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>SELECT EMPLOYEE</p>

                        <button className="delete" aria-label="close" onClick={() => dispatch(closeTalentSelectPersonModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: '80px'}}>
                    {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"45px"}}>
                        <Fragment>
                        <button
                            style={{borderRadius:"4px", backgroundColor: backgroundcolor, color: textcolor}}
                            className="button is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(createRolePerson({person: personvalue, role: talentrolePK }))
                                .unwrap()
                                .then(() => {
                                    dispatch(closeTalentSelectPersonModal())
                                })
                            }}
                        >
                            Add
                        </button>
                        </Fragment>
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeTalentSelectPersonModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SelectTalentPersonModal;
