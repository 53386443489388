import {
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

const success_stateAdapter = createEntityAdapter();

const initialState = success_stateAdapter.getInitialState({
  isSuccessYearModalOpen: false,
  isSuccessModalOpen: false,
  isSuccessRecordModalOpen: false,
  successRecordModalPK: null,
  isSuccessBaseModalRoadmapsOpen: false,
  linkModaltype: null,
  successBaseId: null,
  isGanttBaselineModalOpen: false,
  ganttbaselineId: null,
  ganttbaselinesectionId: null,



  successDetailId: null,

  isBaselineModalOpen: false, baselinerowId: null,isGrid: true, successPK: null, isRoadmapSection: true, isInnovationSection: false, isActionsSection: false,
   isSuccessBaseModalOpen: false,   isSuccessBaseModalSprintsOpen: false, isSuccessBaseModalInnovationOpen: false,
  successBaseSprintSuccessId: null, successBaseRoadmapSuccessId: null, successBaseThemeSuccessId: null,
});

const success_stateSlice = createSlice({
  name: "success_state",
  initialState,
  reducers: {
    openSuccessYearModal(state) {
      state.isSuccessYearModalOpen = true;
    },
    closeSuccessYearModal(state) {
      state.isSuccessYearModalOpen = false;
    },
    openSuccessModal(state) {
      state.isSuccessModalOpen = true;
    },
    closeSuccessModal(state) {
      state.isSuccessModalOpen = false;
    },
    openSuccessRecordModal(state, action) {
      state.isSuccessRecordModalOpen = true;
      state.successRecordModalPK = action.payload.successRecordModalPK;
    },
    closeSuccessRecordModal(state) {
      state.isSuccessRecordModalOpen = false;
      state.successRecordModalPK = null;
    },
    openSuccessBaseModal(state, action) {
      state.isSuccessBaseModalOpen = true;
      state.linkModaltype = action.payload.linkModaltype;
      state.successBaseId = action.payload.successBaseId;
    },
    closeSuccessBaseModal(state) {
      state.isSuccessBaseModalOpen = false;
      state.linkModaltype = null;
      state.successBaseId = null;
    },
    openBaselineAlertModal(state, action) {
      state.isBaselineModalOpen = true;
      state.baselinerowId = action.payload.baselinerowId;
    },
    closeBaselineAlertModal(state) {
      state.isBaselineModalOpen = false;
      state.baselinerowId = null;
    },
    openGanttBaselineModal(state, action) {
      state.isGanttBaselineModalOpen = true;
      state.ganttbaselineId = action.payload.ganttbaselineId;
      state.ganttbaselinesectionId = action.payload.ganttbaselinesectionId;
    },
    closeGanttBaselineModal(state) {
      state.isGanttBaselineModalOpen = false;
      state.ganttbaselineId = null;
      state.ganttbaselinesectionId = null;
    },

    openSuccessBaseModalSprints(state, action) {
      state.isSuccessBaseModalSprintsOpen = true;
      state.successBaseSprintSuccessId = action.payload.successBaseSprintSuccessId;

    },
    closeSuccessBaseModalSprints(state) {
      state.isSuccessBaseModalSprintsOpen = false;
      state.successBaseSprintSuccessId = null;
    },
    openSuccessBaseModalRoadmaps(state, action) {
      state.isSuccessBaseModalRoadmapsOpen = true;
      state.successBaseRoadmapSuccessId = action.payload.successBaseRoadmapSuccessId;

    },
    closeSuccessBaseModalRoadmaps(state) {
      state.isSuccessBaseModalRoadmapsOpen = false;
      state.successBaseRoadmapSuccessId = null;
    },
    openSuccessBaseModalInnovation(state, action) {
      state.isSuccessBaseModalInnovationOpen = true;
      state.successBaseThemeSuccessId = action.payload.successBaseThemeSuccessId;

    },
    closeSuccessBaseModalInnovation(state) {
      state.isSuccessBaseModalInnovationOpen = false;
      state.successBaseThemeSuccessId = null;
    },
    openSuccessYearModal(state) {
      state.isSuccessYearModalOpen = true;
    },
    closeSuccessYearModal(state) {
      state.isSuccessYearModalOpen = false;
    },
    showGridPage(state) {
      state.isGrid = true;
      state.successPK = null;
    },
    showDetailPage(state, action) {
      state.isGrid = false;
      state.successPK = action.payload.successPK;
    },
    // setRoadmapsSection(state) {
    //   state.isRoadmapSection = true;
    //   state.isInnovationSection = false;
    //   state.isActionsSection = false;
    // },
    // setInnovationSection(state) {
    //   state.isRoadmapSection = false;
    //   state.isInnovationSection = true;
    //   state.isActionsSection = false;
    // },
    // setActionsSection(state) {
    //   state.isRoadmapSection = false;
    //   state.isInnovationSection = false;
    //   state.isActionsSection = true;
    // }




  },
});

export const { 
  openSuccessModal,
  closeSuccessModal,
  openSuccessRecordModal,
  closeSuccessRecordModal,
  openGanttBaselineModal,
  closeGanttBaselineModal,
  openBaselineAlertModal, closeBaselineAlertModal,openSuccessYearModal, closeSuccessYearModal, showGridPage, showDetailPage, setRoadmapsSection, setInnovationSection, setActionsSection, openSuccessBaseModal, closeSuccessBaseModal, 
  openSuccessBaseModalSprints, openSuccessBaseModalRoadmaps, openSuccessBaseModalInnovation, closeSuccessBaseModalInnovation, closeSuccessBaseModalSprints,closeSuccessBaseModalRoadmaps
} = success_stateSlice.actions;

export default success_stateSlice.reducer;



