import React, {Fragment, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {_} from 'lodash'
import {filter} from 'lodash'
import { Container } from "./charts/container";

import ConfirmDeleteBox from "./../../../components/common/ConfirmDeleteBox";
import { fetchAllCharts } from "../../../redux/toolkit/features/roadmapcharts";
import { closeGraphsModal, openGraphsEditModal } from "../../../redux/toolkit/features/roadmaps_state";


function SectionsGraphModal() {
    const tabs = ['Section', 'Table', 'Timeline', 'Charts']
    //const sectionpk = useSelector((state) => state.sections.sectionpk)
    const charts = (Object.values(useSelector((state) => state.roadmapcharts.entities)));
    const chartsstatus = useSelector((state) => state.roadmapcharts.status);
    const sectionPk = useSelector((state) => state.roadmaps_state.chartsectionpk);
    const showModalDelete = useSelector((state) => state.deleteConfirmModal.isOpen);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
    const textcolor = useSelector((state) => state.colors.textcolor);
    const showSectionsGraphModal = useSelector((state) => state.roadmaps_state.isGraphModalOpen);
    const showSectionsChartEditModal = useSelector((state) => state.roadmaps_state.isGraphEditModalOpen);
    const [filteredCharts, setFilteredCharts]= useState(filter(charts, {section:sectionPk}));
    const [showDeleteModal, setShowDeleteModal]= useState(false)
    const dispatch = useDispatch();

    let modalClass = 'modal';
    if (showSectionsGraphModal) {
        modalClass += ' is-active'
    }

    function refreshChartData() {
        dispatch(fetchAllCharts());
    }

    useEffect(() => {
        if (chartsstatus==="idle") {
            setFilteredCharts(filter(charts, {section:sectionPk}));
        }
    }, [chartsstatus]);

    useEffect(() => {
        refreshChartData()
        setFilteredCharts(filter(charts, {section:sectionPk}));
    }, [sectionPk]);

    function renderRows(){
          return (
            <Fragment>
                {filteredCharts.length !== 0 &&
                    <Container charts={filteredCharts}/>}
                {filteredCharts.length === 0 &&
                <p className = "pt-5" style={{fontSize:"16.5px"}}>No Records Returned</p>}
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card' key='modalCard'>
                    
                    <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className='modal-card-title' style={{fontSize:"17px", color: textcolor}}>CHARTS</p>
                        <button className='delete' aria-label='close' onClick={() => dispatch(closeGraphsModal())}>
                            {' '}
                        </button>
                    </header>
                    
                    <section className='modal-card-body' key='modalBody' style={{minHeight: '350px'}}>

                        {renderRows()}  

                    </section>
                    
                    <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>
                                
                                    <button
                                        style={{borderRadius:"4px", backgroundColor: backgroundcolor, color: textcolor}}
                                        className='button is-small's
                                        key='save'
                                        onClick={async () => {
                                            dispatch(openGraphsEditModal({chartpk:null, charteditsectionpk:sectionPk }));
                                            dispatch(closeGraphsModal());
                                        }}>
                                        Add Chart
                                    </button>

                        <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => dispatch(closeGraphsModal())}>
                            Cancel
                        </button>
                </footer>
                </div>
                </div>


        </Fragment>
    );
}

export {SectionsGraphModal};
