import { React, Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { closeCompetencyModal, selectCompetencyById, updateCompetencies, createCompetencies, fetchAllCompetencies  } from "./../../../redux/toolkit/features/competencies";

function CompetenciesModal() {
    const showModalCompetencies = useSelector((state) => state.competencies.isOpen)
    const competencyrecordkey = useSelector((state) => state.competencies.competencypk)
    const competencybyid = useSelector((state)=>selectCompetencyById(state, competencyrecordkey));  
    //const competencies = Object.values(useSelector((state) => state.competencies.entities));
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const [competency, setCompetency] = useState("");
    const [description, setDescription] = useState("");
    const dispatch = useDispatch(); 

    let modalClass = "modal";
    if (showModalCompetencies) {
        modalClass += " is-active";
    }

    let competencyvalue= (competencybyid?competencybyid.competency :"")
    let descriptionvalue= (competencybyid?competencybyid.description: "")   


      useEffect(() => {
        setCompetency(competencyvalue);
        setDescription(descriptionvalue);
      },[competencyrecordkey]);


    if (!competencybyid && competencyrecordkey!==null) {
        return null;
    }

    function renderForm() {
        return (
            <Fragment>
                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Competency</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <input
                                className="input"
                                value={competency}
                                onChange={(e) => setCompetency(e.target.value)}
                                />
                        </div>
                    </div>
                </div>

                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Description</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <textarea 
                                className="textarea"
                                rows="4"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        { competencyrecordkey &&
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>EDIT COMPETENCY</p>}
                        { !competencyrecordkey &&
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>NEW COMPETENCY</p>}

                        <button className="delete" aria-label="close" onClick={() => dispatch(closeCompetencyModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: '200px'}}>
                    {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    {competencyrecordkey === null &&
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(createCompetencies({competency: competency, description: description }))
                                .unwrap()
                                    dispatch(fetchAllCompetencies());
                                    dispatch(closeCompetencyModal())
                        }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}

                        {competencyrecordkey !== null &&
                        <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(updateCompetencies({id:competencyrecordkey,competency: competency, description: description }))
                                .unwrap()
                                    dispatch(fetchAllCompetencies());
                                    dispatch(closeCompetencyModal())
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeCompetencyModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default CompetenciesModal;
