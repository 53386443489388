import api from "../../api/company/timelines";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const timelinesAdapter = createEntityAdapter({
  selectId: (timelines) => timelines.pk,
  sortComparer: (a, b) => a.year - b.year
});

const initialState = timelinesAdapter.getInitialState({status: "idle"});

export const fetchAllTimelines = createAsyncThunk("timelines/fetch", () => api.fetchAllTimelines());

export const updateTimelines = createAsyncThunk("timelines/update", api.updateTimelines);

export const createTimelines = createAsyncThunk("timelines/create", api.createTimelines);

export const deleteTimelines = createAsyncThunk("timelines/delete", api.deleteTimelines);

const timelinesSlice = createSlice({
  name: "timelines",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTimelines.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllTimelines.fulfilled, (state, { payload }) => {
        timelinesAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateTimelines.fulfilled, timelinesAdapter.updateOne)
      .addCase(deleteTimelines.fulfilled, timelinesAdapter.removeOne)
      .addCase(createTimelines.fulfilled, timelinesAdapter.addOne);
  },
});

export const {} = timelinesSlice.actions;

export default timelinesSlice.reducer;

const selectState = (state) => state.timelines;

export const {
  selectAll: selectTimelines,
  selectById: selectTimelineById,
} = timelinesAdapter.getSelectors(selectState);

export const selectTimelinesStatus = createSelector(
  selectState,
  (state) => state.status
);
