import {React, useState, Fragment, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Table } from './Table';
import { RoadmapChart } from './Chart';
import { filter } from 'lodash'
import { openRoadmapSectionsDetailModal } from "../../redux/toolkit/features/roadmaps_state";
import { fetchAllChartRows } from "../../redux/toolkit/features/roadmapchartrows";
import { fetchAllGantt } from '../../redux/toolkit/features/roadmaps/gantt';
import { Gantt } from './Gantt';

function Section(props) {
    const {sectiondata, rows, cells} = props;
    const sectionPk = sectiondata.pk;
    const sectionrows = filter(rows, {section: sectionPk}) 
    const [isGraph, setisGraph] = useState(false);
    const showSectionsChartEditModal = useSelector((state) => state.roadmaps_state.isGraphEditModalOpen);
    const dispatch = useDispatch();


    useEffect(() => {
    }, [sectionrows]);


    useEffect(() => {
        dispatch(fetchAllChartRows());
    }, [showSectionsChartEditModal]);

 

    function TableBody() {

        return (
            
            <Table 
                key={'table_' + sectionPk} 
                sectionrows={sectionrows} 
                sectionPk={sectionPk} 
                rows={sectiondata.rows}
                setisGraph={setisGraph} 
                isGraph={isGraph}
                chartbutton={sectiondata.showcharts}
                cells={cells}

            />
        )
    }

    if (!sectiondata || !sectionPk) {
        return null;
    }


    return (
        <section className='mb-6' style={{backgroundColor: 'rgb(241,241,241)', borderRadius:"12px"}}>
            <div style={{fontFamily: 'Gotham Extra', fontWeight: 700, fontSize: '22px', lineHeight: '0.9em'}} onDoubleClick={() => dispatch(openRoadmapSectionsDetailModal({sectionpk:sectiondata.pk}))}>
                <div className='pt-5 pb-5 pl-5 pr-5'>
                    {sectiondata.name}<br/>
                </div>
                <div className='pl-5 pr-5' style={{backgroundColor: 'rgb(241,241,241)'}} onDoubleClick={() => dispatch(openRoadmapSectionsDetailModal({sectionpk:sectiondata.pk}))}>
                <span style={{fontFamily: 'Gotham Extra', fontSize: '15px', lineHeight: '25px'}}>
                    <p>{sectiondata.narrative}</p> 
                </span>
             </div>
             </div>

             {sectiondata.showtable &&
                <Fragment>
                <div className='pt-0 pl-5 pr-5 pb-5'>
                    {(sectiondata.rows?sectiondata.rows.length:0)!==0 &&
                        <div className="mb-6">{TableBody()}</div>
                    }
                </div>

                {isGraph &&
                    <div className='pt-0 pl-5 pr-5 pb-5'>
                        <RoadmapChart 
                            key={'chart_' + sectionPk} 
                            sectionPk={sectionPk} 
                            sectionrows={sectionrows} 
                            />
                    </div>
                }
            </Fragment>
            }

            {sectiondata.showgantt &&
                <Fragment>
                    <div className='pl-5 pr-5 pb-1'>
                    <div className="mb-6"><Gantt sectionPk={sectionPk} /></div>
                    </div>
                </Fragment>
            }
        </section>
    )
}

export { Section };
