import React, {Fragment, useEffect} from "react";
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import {filter} from "lodash";
import {  openRolesModal } from "./../../redux/toolkit/features/organization_state";
import { fetchAllRoleview } from "../../redux/toolkit/features/organization/roleview";


function OrgTableRow(props) {
    const {year} = props;
    const roleview = Object.values(useSelector((state) => state.roleview.entities));
    const dispatch = useDispatch();
    var newroles = filter(roleview, datarow =>  ((new Date(datarow.role_startdate)).getFullYear() === year || (new Date(datarow.role_enddate)).getFullYear() === year));
    let cellNumber = 0;

    useEffect(() => {
        dispatch(fetchAllRoleview)
    }, []);

    useEffect(() => {
    }, []);

    useEffect(() => {
        newroles = filter(roleview, datarow =>  ((new Date(datarow.role_startdate)).getFullYear() === year || (new Date(datarow.role_enddate)).getFullYear() === year));
    }, [roleview]);

    function renderCellValue(cell) {
        cellNumber++; 
        let enddate = new Date(cell.role_enddate)
        let totalcompensation = cell.compensationbase + cell.compensationsti + cell.compensationlti + cell.compensationbenefits
        if (!cell) {
            return <td key={cellNumber}> </td>;
        }
        let tag = null;
        if (cell.status === "Approved") {
            tag = <span className="is-pulled-right"><input type="checkbox" style={{backgroundColor: "lightgreen", color: 'white'}} checked /></span>;
        } else if (cell.status === "Pending") {
            tag = <span className="is-pulled-right"><input type="checkbox"/></span>;
        }

        if (new Date(cell.role_startdate).getFullYear() === year) {
            return (
                <table className = "table is-full-width ml-0 mb-0" style={{border: "none", backgroundColor:"transparent", cursor: "pointer"}} onClick={() => dispatch(openRolesModal({roleId:cell.role_id}))}>
                <tr key={year}> 
                <td style={{border: "none", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none"}} key={cellNumber}>
                    <a style={{color: "black", fontWeight:"600", fontSize: "13px"}}>{cell.role}</a> {tag}
                    <br />
                    <small>Start Date: <Moment format="MMMM DD, YYYY" tz="America/Los_Angeles">{new Date(cell.role_startdate)}</Moment></small>
                    <br />
                    <small>Total Compensation: <CurrencyFormat value={totalcompensation} displayType={'text'} thousandSeparator={true} prefix={'$'} /> {cell.compensation_currency} </small>
                </td>
                </tr>
                </table>
            );
        }

        if (new Date(enddate).getFullYear() === year) {
            return (
                <table className = "table is-full-width ml-0 mb-0" style={{border: "none", backgroundColor: "transparent", cursor: "pointer"}} onClick={() => dispatch(openRolesModal({roleId:cell.role_id}))}>
                <tr key={year}> 
                <td style={{border: "none", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none"}} key={cellNumber}>
                    <a style={{color: "black", fontWeight:"600", fontSize: "13px"}}>{cell.role}<span style={{color:'red'}}><sup> ●</sup></span></a> {tag}
                    <br />
                    <small>End Date: <Moment format="MMMM DD, YYYY" tz="America/Los_Angeles">{new Date(cell.role_enddate)}</Moment></small>
                    <br />
                    <small>Total Compensation: {"("}<CurrencyFormat value={totalcompensation} displayType={'text'} thousandSeparator={true} prefix={'$'} />{")"} {cell.compensation_currency} </small>
                </td>
                </tr>
                </table>
            );
        }
    }


    return (

        <Fragment>
            {newroles &&
                      newroles.map((row) => (
                    renderCellValue(row)
                      ))}
             </Fragment>
    );
}

export {OrgTableRow};
