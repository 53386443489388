import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class CompanyMatrixService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/companymatrices`;
    }

    async getMatrix() {
        const companymatrix = await this.getAll();
        return companymatrix;
    }
}

export const companymatrixService = new CompanyMatrixService();
