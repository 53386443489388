import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllCompetencyScores = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/talent/competencyscores`,
    authConfig()
  );
  return response.data;
};

export const fetchCompetencyScores = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/talent/competencyscores/${id}`,
    authConfig()
  );
  return response.data;
};

export const createCompetencyScores = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/talent/competencyscores`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateCompetencyScores = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/talent/competencyscores/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteCompetencyScores = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/talent/competencyscores/${id}`,
    authConfig()
  );
  return response.data;
};

const competencyscoresAPI = {
  fetchAllCompetencyScores,
  fetchCompetencyScores,
  createCompetencyScores,
  updateCompetencyScores,
  deleteCompetencyScores,
};
export default competencyscoresAPI;
