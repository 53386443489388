import React, {Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {SuccessRoadmapActions} from "./SuccessRoadmapActions";
import {SuccessThemeActions} from "./SuccessThemeActions";
import {SuccessSprintActions} from "./SuccessSprintActions";
import {filter} from "lodash"
import { selectSuccessById, fetchAllSuccess } from "./../../redux/toolkit/features/success/success";
import { openSuccessBaseModal } from "./../../redux/toolkit/features/success_state";

function SuccessLinks(props) {
  const {linkedrows, linkedthemes, linkedsprints } = props;
  const [selectedTab, setSelectedTab] = useState("Linked Roadmaps");
  const successPK = useSelector((state) => state.success_state.successPK)
  const detail = useSelector((state)=>selectSuccessById(state, successPK));
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllSuccess())
  }, [successPK]);

  if (!detail) {
    return null;
  }

      const linkedrowsfiltered = filter(linkedrows, {success: successPK})
      const linkedthemesfiltered = filter(linkedthemes, {success: successPK})
      const linkedsprintsfiltered = filter(linkedsprints, {success: successPK})


    function tabHeader() {
      return (
        <div key="TabHeader" className="tabs ml-0 pl-0" >
          <ul className="pl-0 ml-0" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}>
            <li 
              key="Linked Roadmaps" 
              className={selectedTab === "Linked Roadmaps" ? "is-active" : ""}
              onClick={() => setSelectedTab("Linked Roadmaps")}  
              >           
              <a className="ml-0 pl-0" style={{fontFamily: "Sans-serif", fontSize: "13px"}}>LINKED ROADMAPS ({linkedrowsfiltered.length})</a>
            </li>
            |
            <li
              key="TalentManagement" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
              className={selectedTab === 'Linked Innovation Themes' ? "is-active" : ""}
              onClick={() => setSelectedTab('Linked Innovation Themes')}  
              >       
              <a style={{fontFamily: "Sans-serif", fontSize: "13px"}}>LINKED INNOVATION THEMES  ({linkedthemesfiltered.length})</a>
            </li>
            |
            <li
              key="Metrics" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
              className={selectedTab === 'Linked Actions' ? "is-active" : ""}
              onClick={() => setSelectedTab('Linked Actions')}  
              >       
              <a style={{fontFamily: "Sans-serif", fontSize: "13px"}}>LINKED SPRINT ACTIONS  ({linkedsprintsfiltered.length})</a>
            </li>
          </ul>
          {selectedTab=== "Linked Roadmaps" && <button className="button is-small is-pulled-right mt-1 pt-0 pb-0" style={{borderColor: backgroundcolor, color:backgroundcolor, borderRadius:"5px"}} onClick={() => dispatch(openSuccessBaseModal({linkModaltype:'ROADMAP', successBaseId:successPK }))}>Edit Roadmap Links</button>}
          {selectedTab=== "Linked Innovation Themes" && <button className="button is-small is-pulled-right mt-1 pt-0 pb-0" style={{borderColor: backgroundcolor, color:backgroundcolor, borderRadius:"5px"}} onClick={() => dispatch(openSuccessBaseModal({linkModaltype:'INNOVATION', successBaseId:successPK}))}>Edit Innovation Links</button>}
          {selectedTab=== "Linked Actions" && <button className="button is-small is-pulled-right mt-1 pt-0 pb-0" style={{borderColor: backgroundcolor, color:backgroundcolor, borderRadius:"5px"}} onClick={() => dispatch(openSuccessBaseModal({linkModaltype:'SPRINT', successBaseId:successPK}))}>Edit Sprint Links</button>}
      </div>
      )
    }

    if(!successPK) {
      return null;
    }
   
    return (
        <Fragment>
        <section className="p-0 m-0" style={{ fontFamily: "Gotham Extra"}}>
            <div style={{backgroundColor:backgroundcolor, color: textcolor}}>
              <div className="p-3">
                <p style={{fontSize: "20px", fontWeight: "600", color: "white"}}>{detail.name} </p>
                <p style={{fontSize: "17px",color: "white"}}>{detail.description} </p>      
                </div>
                <div className="pl-3 pt-2 pb-5 " style={{ backgroundColor: "white"}}>
                {tabHeader()}
                </div>
            </div>
          </section>

            
            {selectedTab === "Linked Roadmaps" &&
            <SuccessRoadmapActions
                linkedrows = {linkedrows}
                success_id = {detail.pk}
            />}

            {selectedTab === "Linked Innovation Themes" &&
            <SuccessThemeActions 
                linkedthemes = {linkedthemes}
                success_id = {detail.pk}
            />}

            {selectedTab === "Linked Actions" &&
            <SuccessSprintActions
                linkedsprints = {linkedsprints}
                success_id = {detail.pk}
            />}

    </Fragment>
    )
}

export {SuccessLinks};