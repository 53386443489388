import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import {openCompanyHeroModal} from "./../../redux/toolkit/features/company_state"

function CompanyHeroSectionOnlyButton() {
    const dispatch = useDispatch();
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)

    
    return (
        <section className="hero is-large" style={{userSelect: "none", backgroundColor: backgroundcolor===null?"RGB(0,51,153)":backgroundcolor}}>
            <div className="hero-body">
                <div className="level" style={{backgroundColor: "transparent"}}>
                    <div className="level-item " style={{userSelect: "none"}}>
                        <button className="button p-4" style={{
                            backgroundColor: "transparent",
                            border: "solid 1px white",
                            color: "white",
                            fontFamily: "Gotham Extra",
                            fontSize: "20px"
                        }} onClick={() => dispatch(openCompanyHeroModal())}>
                            Add Company Hero
                        </button>
                    </div>
                </div>
            </div>
            <div className="hero-foot pb-4">
                <div className="box has-text-centered is-shadowless" style={{backgroundColor: "transparent"}}>
                    <i className="fas fa-chevron-circle-down" style={{color: "#ffffff", fontSize: "32px"}}/>
                </div>
            </div>
        </section>
    );
}

export default CompanyHeroSectionOnlyButton;
