import React, { useEffect, useState, Fragment } from "react";
import "../common/css/masterstyles.css";
import { useDispatch, useSelector } from "react-redux";
import { usersActions } from "../../redux/actions/users";
import { UserDetailForm } from "../users/UserDetailForm";
import { UserCreationForm } from "../users/UserCreationForm";
import ConfirmDeleteBox from "../common/ConfirmDeleteBox"
import { usersService } from "../../services/UsersService";
import { openDeleteModal, closeDeleteModal } from "./../../redux/toolkit/features/modals/deleteConfirmModal"
import { deleteUsers, fetchAllUsers } from "./../../redux/toolkit/features/tenants/users"

function UserManagement() {
  const tenant = Object.values(useSelector((state) => state.tenants.entities))
  //const listResponse = useSelector((state) => state.users.listResponse);
  const listResponse = Object.values(useSelector((state) =>  state.users.entities));
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const isDeleteModal = useSelector((state) => state.deleteConfirmModal.isOpen)
  const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);
  const recordkey = useSelector((state) => state.deleteConfirmModal.recordpk);   
  const textcolor = useSelector((state) => state.colors.textcolor)
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [creatingUser, setCreatingUser] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();



  useEffect(() => {
    refresh();
  }, []);


  function handleChange(event) {
    const filter = event.target.value;
    setFilterText(filter);
    refresh(filter, offset);
  }

  function refresh(text, offset) {
    dispatch(
      usersActions.getUserList({
        limit: limit,
        offset: offset,
        text: text,
      })
    );
    setCreatingUser(false);
  }

  function handleOnClickCreateUser(event) {
    setSelectedUser(null);
    setCreatingUser(true);
  }

  function onClickUser(user) {
    setCreatingUser(false);
    dispatch(usersActions.clearError());
    setSelectedUser(user);
  }

  async function onConfirmDelete(result) {
    if (result) {

        if (recordtype === 'user') {
            await dispatch(deleteUsers(recordkey))
                .unwrap()
                  dispatch(fetchAllUsers());
                  dispatch(closeDeleteModal());
        }
    }
  }


  var filteredUsers = listResponse.filter((user) => {

    if (user.first_name.toLowerCase().includes(filterText.toLowerCase()) || user.last_name.toLowerCase().includes(filterText.toLowerCase()) || user.email.toLowerCase().includes(filterText.toLowerCase())){
    return true;
    }
    return filteredUsers;
  })

  function renderUsers() {

    //return listResponse.map((user) => {
      return  filteredUsers.map((user) => {
      return (
        <a
          className="panel-block is-flex is-align-items-baseline"
          key={user.username}
        >
          <span className="panel-icon">
            <i className="fas fa-user" aria-hidden="true">
              {" "}
            </i>
          </span>
          <span className="is-flex-grow-1" onClick={() => onClickUser(user)}>
            {user.email}
            <br />
            <small>{user.first_name + " " + user.last_name}</small>
          </span>
          <span className="panel-icon" onClick={() => dispatch(openDeleteModal({message: 'User: ' + user.first_name + " " + user.last_name,recordpk: user.id, recordtype: 'user'}))}>
          <i className="fas fa-trash-alt" style={{cursor:'pointer'}}>
              {" "}
            </i>
          </span>
        </a>
      );
    });
  }



  return (
    <Fragment>
    <div className="columns is-left">
      <div className="column is-one-third ml-0">
        <div
          style={{
            display: "flex",
            overflowY: "auto",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <nav className="panel is-flex is-flex-grow-1 is-flex-direction-column">
            <div className="panel-block ml-0 mr-0 pl-0 pr-0 mt-0 pt-1">
            <button
                        className="button mr-2"
                        style={{backgroundColor:backgroundcolor, color: textcolor}}
                        onClick={handleOnClickCreateUser}
                      >
                        Create User
                      </button>
              <p className="control has-icons-left">
                <input
                  className="input"
                  type="text"
                  placeholder="Filter"
                  onChange={handleChange}
                  value={filterText}
                />
                <span className="icon is-left">
                  <i className="fas fa-search" aria-hidden="true">
                    {" "}
                  </i>
                </span>
              </p>
            </div>



            {/* <div className="panel-block is-flex">
              <div className="field is-grouped is-flex-grow-1">
                <nav className="level">
                  <div className="level-left">
                    <p className="level-item">
                      <p className="subtitle is-6" style={{fontWeight:500}}>Page {pageNumber}</p>
                    </p>
                    <p className="level-item">
                      {listResponse.previous !== null && (
                        <button
                          className="button is-link"
                          onClick={() => {
                            setOffset(offset - limit);
                            setPageNumber(pageNumber - 1);
                            refresh(filterText, offset - limit);
                          }}
                        >
                          &lt; Previous
                        </button>
                      )}
                    </p>
                    <p className="level-item">
                      {listResponse.next !== null && (
                        <button
                          className="button is-link"
                          onClick={() => {
                            setOffset(offset + limit);
                            setPageNumber(pageNumber + 1);
                            refresh(filterText, offset + limit);
                          }}
                        >
                          Next &gt;
                        </button>
                      )}
                    </p>
                  </div>
                 
                </nav>
              </div>
            </div> */}
            {renderUsers()}
          </nav>
        </div>
      </div>
      <div className="column">
        {creatingUser && <UserCreationForm onSave={refresh} />}
        {selectedUser && (
          <UserDetailForm 
          user={selectedUser} 
          tenant={tenant}
          onSave={refresh} />
        )}
      </div>
    </div>
    {isDeleteModal && <ConfirmDeleteBox onConfirmDelete={onConfirmDelete}/>}
    </Fragment>
  );
}

export { UserManagement };
