import api from "../../api/organization/businessunit";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const businessunitAdapter = createEntityAdapter({
  selectId: (person) => person.pk,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = businessunitAdapter.getInitialState({status: "idle"});

export const fetchAllBusinessunit = createAsyncThunk("businessunit/fetch", () => api.fetchAllBusinessunit());

export const updateBusinessunit = createAsyncThunk("businessunit/update", api.updateBusinessunit);

export const createBusinessunit = createAsyncThunk("businessunit/create", api.createBusinessunit);

export const deleteBusinessunit = createAsyncThunk("businessunit/delete", api.deleteBusinessunit);

const businessunitSlice = createSlice({
  name: "businessunit",
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBusinessunit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllBusinessunit.fulfilled, (state, { payload }) => {
        businessunitAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateBusinessunit.fulfilled, businessunitAdapter.updateOne)
      .addCase(deleteBusinessunit.fulfilled, businessunitAdapter.removeOne)
      .addCase(createBusinessunit.fulfilled, businessunitAdapter.addOne);
  },
});

export const { } = businessunitSlice.actions;

export default businessunitSlice.reducer;

const selectState = (state) => state.businessunit;

export const {
  selectAll: selectBusinessunit,
  selectById: selectBusinessunitById,
} = businessunitAdapter.getSelectors(selectState);

export const selectBusinessunitStatus = createSelector(
  selectState,
  (state) => state.status
);
