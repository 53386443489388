import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import {filter} from "lodash"
import { closeRolesLinkCompetencyModal } from "../../../redux/toolkit/features/organization_state";
import { fetchAllCompetencies } from "../../../redux/toolkit/features/competencies";
import { createRoleCompetencies, fetchAllRoleCompetencies } from "../../../redux/toolkit/features/rolecompetencies";


function PeopleModalAddCompetency() {
    const competencies = Object.values(useSelector((state) => state.competencies.entities)).sort((a, b) => (a.competency.localeCompare(b.competency)));
    const rolecompetencies = Object.values(useSelector((state) => state.rolecompetencies.entities));
    const isRoleLinkCompetencyModalOpen = useSelector((state) => state.organization_state.isRoleLinkCompetencyModalOpen)
    const rolePK = useSelector((state) => state.organization_state.competencyrolePK)
    const [competency, setCompetency] = useState(null)  
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch(); 

    let modalTitle=('ADD COMPETENCY')
    let modalClass = "modal";
    if (isRoleLinkCompetencyModalOpen) {
        modalClass += " is-active";
    }


    let rolecompetencylist=filter(rolecompetencies,{role_id: rolePK}).map(r => r.competency_id)
    var competencylist=filter( competencies, (row) => !rolecompetencylist.includes(row.pk));

    useEffect(() => {
        dispatch(fetchAllCompetencies());
        }, []);

    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                        <select className="select is-fullwidth" onChange={(event) => setCompetency(event.target.value)}>
                            {competency === null && <option selected>{'<Select Competency>'}</option>}
                            {competencylist.map((c) => (
                                <option key={c.pk} value={c.pk}>
                                {c.competency}
                                </option>
                                ))}
                        </select>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{modalTitle}</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeRolesLinkCompetencyModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: "50px"}}>             
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        {competency!==null &&
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(createRoleCompetencies({competency_id:competency ,role_id: rolePK }))
                                .unwrap()
                                .then(() => {
                                    dispatch(fetchAllRoleCompetencies())
                                    dispatch(closeRolesLinkCompetencyModal())
                                })
                            }}
                        >
                            Save Changes
                        </button>}
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} 
                            onClick={async () => {
                                dispatch(closeRolesLinkCompetencyModal());
                            }}
                        >
                            Cancel
                        </button>

                    </footer>
                </div>
            </div>

        </Fragment>
    );
}

export default PeopleModalAddCompetency;
