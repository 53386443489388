import React, { Fragment, useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrgTableRow } from "./OrgTableRow";
import { organizationActions } from "../../redux/actions/organization";
import CurrencyFormat from "react-currency-format";
import { positionModalActions } from "../../redux/actions/positionModal";
import { peopledatasets } from '../Organization';
import { fetchAllMetrics } from "./../../redux/toolkit/features/organization/metrics";
import {filter} from "lodash"
import { fetchAllCompensationindex } from "../../redux/toolkit/features/organization/compensationindex";
import { fetchAllRoleview } from "../../redux/toolkit/features/organization/roleview";



function OrgTable(props) {
  const {minYear, maxYear, personview,startmonth} = props;
  const roleview = Object.values(useSelector((state) => state.roleview.entities));
  const {defaulttextcolor, darkbackgroundcolor} = useContext(peopledatasets);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const metrics = Object.values(useSelector((state) => state.metrics.entities));
  const compensationindex = Object.values(useSelector((state) => state.compensationindex.entities));
  const [cstatus, setCStatus] = useState(false);
  const [hcstatus, setHCStatus] = useState(false);
  const [acstatus, setACStatus] = useState(false);
  var yearrows=[];
  var newrolefiltered=[];

 // const dispatch = useDispatch();
  let rowNumber = 0;
  const dispatch = useDispatch();  

  useEffect(() => {
    dispatch(fetchAllMetrics());
    dispatch(fetchAllCompensationindex());
    dispatch(fetchAllRoleview())
  }, []);

  useEffect(() => {
  }, [compensationindex]);


  function renderYearHeaders() {
    const headers = [];
    for (let year = minYear; year <= maxYear; year++
    ) {
      headers.push(
          <th className={"has-text-centered"} style={{color: defaulttextcolor}} key={year}>{year}</th>
      )
    }
    return headers
  }


  function headcountsummary() {
    const headcountrow = [];
    for (var year = minYear; year <= maxYear; year++) {
      let metricsyear = filter(metrics, {year:year})
      var totalcompensation = metricsyear[0]?metricsyear[0].totalcompensation:0
      var totalheadcount = metricsyear[0]?metricsyear[0].totalheadcount:0
      var avgcompensation = metricsyear[0]?metricsyear[0].avgcompensation:0
      var annualchangeroles = metricsyear[0]?metricsyear[0].annualchangeroles : 0
      var annualchangeindexing = metricsyear[0]?metricsyear[0].annualchangeindexing : 0
      let indexarray = filter(compensationindex, {year:year})
      var indexvalue = indexarray[0]?indexarray[0].indexvalue:""
      headcountrow.push(
        <td style={{fontSize:'smaller'}}>
          <Fragment>
          <span>
          {year===minYear && !hcstatus && <a onClick={()=> setHCStatus(true)}><i className="far fa-plus-square ml-0 mr-2 p-0" style={{fontSize:'smaller', pointer:'pointer', color: backgroundcolor}}/></a>}
          {year===minYear && hcstatus && <a onClick={()=> setHCStatus(false)}><i className="far fa-minus-square ml-0 mr-2 p-0" style={{fontSize:'smaller', pointer:'pointer', color: backgroundcolor}}/></a>} 
          {!hcstatus && <span style={{fontSize:'14px',cursor:'pointer'}} onClick={()=> setHCStatus(hcstatus?false:true)}>Headcount: {totalheadcount!==null?totalheadcount.toFixed(2):0}</span>}
          {hcstatus && <span style={{fontSize:'14px', fontWeight:'600', lineHeight:'34px',cursor:'pointer'}} onClick={()=> setHCStatus(hcstatus?false:true)}>Headcount: {totalheadcount!==null?totalheadcount.toFixed(2):0}</span>}
          <br/>
            {hcstatus && year!==minYear && 
              <Fragment>
                <span style={{fontSize:'12px'}}>
                AGR: {metricsyear[0]?metricsyear[0].headcountgrowth.toFixed(2)+'%':'N/A'}<br/>
                CAGR: {metricsyear[0]?metricsyear[0].headcountgrowthcagr.toFixed(2)+'%':'N/A'}<br/>
                </span>
              </Fragment>
            }
            </span>
          </Fragment>
        </td>
      )
    }

    return (
      <Fragment>
        <tr>
        {headcountrow}
        </tr>
      </Fragment>
    )
  }

  function compensationsummary() {
    const compensationrow = [];
    for (var year = minYear; year <= maxYear; year++) {
      let metricsyear = filter(metrics, {year:year})
      var totalcompensation = metricsyear[0]?metricsyear[0].totalcompensation:0
      var totalheadcount = metricsyear[0]?metricsyear[0].totalheadcount:0
      var avgcompensation = metricsyear[0]?metricsyear[0].avgcompensation:0
      var annualchangeroles = metricsyear[0]?metricsyear[0].annualchangeroles : 0
      var annualchangeindexing = metricsyear[0]?metricsyear[0].annualchangeindexing : 0
      let indexarray = filter(compensationindex, {year:year})
      var indexvalue = indexarray[0]?indexarray[0].indexvalue:""
      compensationrow.push(
        
        <td style={{fontSize:'smaller'}}>
        <Fragment>
          <span> 
          {year===minYear && !cstatus && <a onClick={()=> setCStatus(true)}><i className="far fa-plus-square ml-0 mr-2 p-0" style={{fontSize:'smaller', pointer:'pointer', color: backgroundcolor}}/></a>}
          {year===minYear &&  cstatus && <a onClick={()=> setCStatus(false)}><i className="far fa-minus-square ml-0 mr-2 p-0" style={{fontSize:'smaller', pointer:'pointer', color: backgroundcolor}}/></a>} 
          {!cstatus &&<span style={{fontSize:'14px',cursor:'pointer'}} onClick={()=> setCStatus(cstatus?false:true)}>Tot. Compensation: {totalcompensation!==null?'$'+Math.round(totalcompensation).toLocaleString():0}</span>}
          {cstatus &&<span style={{fontSize:'14px', fontWeight:'600', lineHeight:'34px',cursor:'pointer'}} onClick={()=> setCStatus(cstatus?false:true)}>Tot. Compensation: {totalcompensation!==null?'$'+Math.round(totalcompensation).toLocaleString():0}</span>}        
          <br/>
          {cstatus && year!==minYear && 
            <Fragment>
              <span style={{fontSize:'12px'}}>
                AGR: {metricsyear[0]?metricsyear[0].compensationgrowth.toFixed(2)+'%':'N/A'}<br/>
                CAGR: {metricsyear[0]?metricsyear[0].compensationgrowthcagr.toFixed(2)+'%':'N/A'}<br/>
              </span>
              <span style={{fontSize:'13px', fontWeight:'500', lineHeight:'34px'}}>Annual Change: ${Math.round(annualchangeindexing+annualchangeroles).toLocaleString()}</span><br/>
              <span style={{fontSize:'13px'}}>
                Hiring: ${Math.round(annualchangeroles).toLocaleString()} <br/>
                Indexing {indexvalue}%: ${annualchangeindexing!==null?Math.round(annualchangeindexing).toLocaleString():0} 
              </span>
              <br/>
            </Fragment>}
          </span>
        </Fragment>
        </td>
      )
    }

    return (
      <Fragment>
        <tr>
        {compensationrow}
        </tr>
      </Fragment>
    )
  }

  function avgcompensationsummary() {
    const avgcompensationrow = [];
    for (var year = minYear; year <= maxYear; year++) {
      let metricsyear = filter(metrics, {year:year})
      var totalcompensation = metricsyear[0]?metricsyear[0].totalcompensation:0
      var totalheadcount = metricsyear[0]?metricsyear[0].totalheadcount:0
      var avgcompensation = metricsyear[0]?metricsyear[0].avgcompensation:0
      let indexarray = filter(compensationindex, {year:year})
      var indexvalue = indexarray[0]?indexarray[0].indexvalue:""
      avgcompensationrow.push(
        <Fragment>
          <td style={{fontSize:'smaller'}}>
          {year===minYear && !acstatus && <a onClick={()=> setACStatus(true)}><i className="far fa-plus-square ml-0 mr-2 p-0" style={{fontSize:'smaller', pointer:'pointer', color: backgroundcolor}}/></a>}
          {year===minYear && acstatus && <a onClick={()=> setACStatus(false)}><i className="far fa-minus-square ml-0 mr-2 p-0" style={{fontSize:'smaller', pointer:'pointer', color: backgroundcolor}}/></a>} 
          {!acstatus &&<span style={{fontSize:'14px',cursor:'pointer'}} onClick={()=> setACStatus(acstatus?false:true)}>Avg. Compensation: {totalcompensation!==null?'$'+avgcompensation.toLocaleString():0}</span>}
          {acstatus &&<span style={{fontSize:'14px', fontWeight:'600', cursor:'pointer', lineHeight:'34px'}} onClick={()=> setACStatus(acstatus?false:true)}>Avg. Compensation: {totalcompensation!==null?'$'+avgcompensation.toLocaleString():0}</span>}    
          <br/> 
          {acstatus && year!==minYear && 
              <Fragment>
                <span style={{fontSize:'12px'}}>
                  AGR: {metricsyear[0]?metricsyear[0].avgcompensationgrowth!==null?metricsyear[0].avgcompensationgrowth.toFixed(2)+'%':'N/A':'N/A'}<br/>
                  CAGR: {metricsyear[0]?metricsyear[0].avgcompensationgrowthcagr!==null?metricsyear[0].avgcompensationgrowthcagr.toFixed(2)+'%':'N/A':'N/A'}<br/>
                </span>
              </Fragment>
            }
          </td>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <tr>
          {avgcompensationrow}
        </tr>
      </Fragment>
    )
  }



  function summaryrow() {
    return (
      <Fragment>
      {headcountsummary()}
      {compensationsummary()}
      {avgcompensationsummary()}
      </Fragment>
    )
  }


  function renderHeader() {
    return (
      <Fragment>
        <thead>
          <tr style={{backgroundColor: darkbackgroundcolor, color: defaulttextcolor, fontWeight: "400"}}>{renderYearHeaders()}</tr>
        </thead>
      </Fragment>

    );
  }

  function renderRow(year) {
    rowNumber++;
    return <Fragment>
      <td className="pl-0 ml-0"><OrgTableRow year={year} /></td></Fragment>
  }

  function renderRows() {
    const rows = [];
    //newrolefiltered=filter(roleview, role=> role.role_startdate !== null )
    for (let year = minYear; year <= maxYear; year++
      ) {
         rows.push(renderRow(year));
    }
    return (
      <Fragment>
      <tr>
      {rows}
      </tr>
      </Fragment>
    )
  }



  function renderBody() {
    return (
      <Fragment>
      <tbody>
        <th colSpan={maxYear-minYear+1} style={{textAlign:"center", backgroundColor:"rgb(238,238,238)"}}>Summary</th>
        {summaryrow()}
        <th colSpan={maxYear-minYear+1} style={{textAlign:"center", backgroundColor:"rgb(238,238,238)"}}>Hiring</th>
        {renderRows()}
      </tbody>
      </Fragment>
    );
  }

  return (
    <table
      className={"table is-bordered is-fullwidth mb-6"}
      style={{ tableLayout: "fixed", userSelect: "none"}}
    >
      {renderHeader()}
      {renderBody()}
    </table>
  );
}

export { OrgTable };
