import React, {Fragment, useEffect} from "react";
import {SprintList} from "./SuccessSprintList";
import {filter} from "lodash"

function SuccessSprintActions(props) {
    const { linkedsprints, success_id } = props; 
  
    if (!linkedsprints || !success_id) {
        return null;
    }

    const linkedsprintsfiltered = filter(linkedsprints, {success: success_id});

    if(linkedsprintsfiltered.length !== 0) {
        return (
            <Fragment>
                <div className="p-3">
                <SprintList
                linkedsprintsfiltered = {linkedsprintsfiltered}    
            />
                </div>
            </Fragment>

        )
    } else {
        return (
          
            <p className="pl-2"><b>No Linked Sprints Found</b></p>
        
          )
    }
}

export { SuccessSprintActions }