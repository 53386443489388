import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import "./common/css/masterstyles.css";
import { Theme } from "./innovation/Theme";
import IdeasModal from "./innovation/modals/IdeasModal";
import IdeaSprintModal from "./innovation/modals/IdeaSprint";
import ThemesModal from "./innovation/modals/ThemesModal";
import {fetchAllThemes, deleteThemes} from "./../redux/toolkit/features/innovation/themes";
import {openThemeModal, closeThemeModal} from "./../redux/toolkit/features/innovation_state";
import {fetchAllIdeas, deleteIdeas} from "./../redux/toolkit/features/innovation/ideas";
import ConfirmDeleteBox from "./common/ConfirmDeleteBox"
import {closeDeleteModal}  from "./../redux/toolkit/features/modals/deleteConfirmModal";
import Spinner from "./common/Spinner";
import SprintModal from "./sprints/modals/SprintModal";
import {setMenu} from "../redux/toolkit/features/menu";

function Innovation() {
  const dispatch = useDispatch();
  dispatch(setMenu('innovation'));
  const themes = Object.values(useSelector((state) => state.themes.entities));
  const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);
  const recordkey = useSelector((state) => state.deleteConfirmModal.recordpk);

  const isIdeasModalOpen = useSelector((state) => state.innovation_state.isIdeasModalOpen);
  const isIdeaSprintModalOpen = useSelector((state) => state.innovation_state.isIdeaSprintModalOpen);  
  const isThemeModalOpen = useSelector((state) => state.innovation_state.isThemeModalOpen);  
  const showModalDelete = useSelector((state) => state.deleteConfirmModal.isOpen);
  const isSprintModalOpen = useSelector((state) => state.sprints_state.isSprintModalOpen);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  
  async function getThemes() {
    setIsDataLoading(true);
      dispatch(fetchAllThemes())
    setIsDataLoading(false);
  }

  useEffect(() => {
      getThemes();
  }, []);

  async function onConfirmDelete(result) {
    if (result) {

      if (recordtype === 'ideas') {
        await dispatch(deleteIdeas(recordkey))
            .unwrap()
                dispatch(fetchAllIdeas());
                dispatch(closeDeleteModal());
      }

        if (recordtype === 'themes') {
          await dispatch(deleteThemes(recordkey))
          .unwrap()
              dispatch(fetchAllThemes());
              dispatch(closeThemeModal())
              dispatch(closeDeleteModal());
        }
       }

      }

  function renderinnovationpage() {
    return (
      <Fragment>
      <main className="has-navbar-fixed-top" style={{marginTop: "3.5em", userSelect: "none"}}>
        <section style={{paddingLeft: "12%", paddingRight: "12%", marginTop: "2em"}}>
          <div style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "32px",lineHeight: "26px",paddingBottom: "28px"}}>
          <div className="pr-1 pb-5">
              INNOVATION THEMES <button className="button is-small is-pulled-right" style={{borderColor: backgroundcolor, color: backgroundcolor, borderRadius: "5px"}} onClick={() => dispatch(openThemeModal({themePK:null}))}>Add Theme</button>
              </div>
            {themes && themes.length === 0 &&
            <p className="pt-6" style={{fontSize: "16.5px"}}>No Innovation Themes Returned</p>}
            </div>


          {themes && themes.length!==0 &&
            themes.map((theme) => (
                    <Theme
                      key={theme.pk}
                      themePK={theme.pk}
                      targetvalue={theme.targetvalue}
                      basevalue={theme.basevalue}
                      units={theme.units}
                      showtargets={theme.showtargets}
                      theme={theme}
                      description={theme.description}
                    />
            ))}
        </section>
      </main>

      {isIdeasModalOpen && <IdeasModal/>}
      {isIdeaSprintModalOpen && <IdeaSprintModal/>}
      {isThemeModalOpen && <ThemesModal/>}
      {showModalDelete && <ConfirmDeleteBox onConfirmDelete={onConfirmDelete}/>}
      {isSprintModalOpen && <SprintModal/>}

    </Fragment>

    )
  }

  return (
    (isDataLoading?<Spinner/>:renderinnovationpage())
  );
}

export { Innovation };
