import React, {Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {filter} from "lodash"
import { closeSuccessBaseModalInnovation, openSuccessBaseModal } from "./../../../redux/toolkit/features/success_state";
import { createInnovationlinks, fetchAllInnovationlinks } from "./../../../redux/toolkit/features/success/successinnovationlinks";

function SuccessModalLinksThemes() {
    const showSuccessLinksInnovationModal = useSelector((state) => state.success_state.isSuccessBaseModalInnovationOpen);
    const successId = useSelector((state) => state.success_state.successBaseThemeSuccessId);
    const themerecordId = useSelector((state) => state.success.successBaseThemeId);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const themes = Object.values(useSelector((state) => state.themes.entities));
    const linkedthemes = Object.values(useSelector((state) => state.innovationlinks.entities));
    const linkedthemesfiltered = (filter(linkedthemes, {success: successId})).map(r => r.theme)  
    const [themevalue, setThemeValue] = useState(null)
    const dispatch = useDispatch();
    

    //Exclude values from the Select lis that are already included
    let excludethemelist=(filter( linkedthemes, {success:successId})).map(r => r.theme_id)      
    let themelist = (filter( themes, (row) => !excludethemelist.includes(row.pk))).sort((a, b) => (a.theme.localeCompare(b.theme)))


    let modalClass = "modal";
    if (showSuccessLinksInnovationModal) {
        modalClass += " is-active";
    }

    useEffect(() => {
        if (showSuccessLinksInnovationModal){
        setThemeValue(null)
        }
    }, [showSuccessLinksInnovationModal]);

      
    function selectThemeLink() {
        return (
            <Fragment>
            <div className="field is-horizontal is-align-items-left">
                <label className="label field-label">Theme</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <div className="select is-flex">
                            <select className="select is-fullwidth" onChange={(event) => setThemeValue(event.target.value)}>
                            {themevalue === null && <option selected>{'<Select Theme>'}</option>}
                                {themelist &&
                                    themelist.map((p) => (
                                    <option key={p.pk} value={p.pk}>
                                        {p.theme} 
                                    </option>
                                ))}

                            </select>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
        )
    }

    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                    <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>INNOVATION THEME LINK</p>
                    <button className="delete" aria-label="close" 
                    onClick={() => {
                            dispatch(closeSuccessBaseModalInnovation());
                            dispatch(openSuccessBaseModal({linkModaltype:"INNOVATION", successBaseId: successId}));
                    }}
                    >
                        {" "}
                    </button>
                </header>
                <section className="modal-card-body" key="modalBody">
                {selectThemeLink()}
                </section>
                <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

                    {themevalue !==null && 
                    <button
                        style={{borderRadius:"4px"}}
                        className="button is-success is-small"
                        key="save"

                        onClick={async () => {
                            await dispatch(createInnovationlinks({theme_id: themevalue, success:successId}))
                            .unwrap()
                                dispatch(fetchAllInnovationlinks());
                                dispatch(closeSuccessBaseModalInnovation());
                                dispatch(openSuccessBaseModal({linkModaltype:"INNOVATION", successBaseId: successId}));
                        }}
                    >
                        Create Link
                    </button>
                    }

                    <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} 
                        onClick={() => {
                            dispatch(closeSuccessBaseModalInnovation());
                            dispatch(openSuccessBaseModal({linkModaltype:"INNOVATION", successBaseId: successId}));
                            }}
                        >
                        Cancel
                    </button>
                </footer>
            </div>
        </div>
    </Fragment>
    );
}

export default SuccessModalLinksThemes
