import api from "../../api/success/success";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const successAdapter = createEntityAdapter({
  selectId: (success) => success.pk,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = successAdapter.getInitialState({status: "idle"});

export const fetchAllSuccess = createAsyncThunk("success/fetch", () =>
  api.fetchAllSuccess()
);

export const updateSuccess = createAsyncThunk("success/update", api.updateSuccess);

export const createSuccess = createAsyncThunk("success/create", api.createSuccess);

export const deleteSuccess = createAsyncThunk("success/delete", api.deleteSuccess);


const successSlice = createSlice({
  name: "success",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSuccess.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllSuccess.fulfilled, (state, { payload }) => {
        successAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateSuccess.fulfilled, successAdapter.updateOne)
      .addCase(deleteSuccess.fulfilled, successAdapter.removeOne)
      .addCase(createSuccess.fulfilled, successAdapter.addOne);
  },
});

export const { } = successSlice.actions;

export default successSlice.reducer;

const selectState = (state) => state.success;

export const {
  selectAll: selectSuccess,
  selectById: selectSuccessById,
} = successAdapter.getSelectors(selectState);

export const selectSuccessStatus = createSelector(
  selectState,
  (state) => state.status
);

