import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import "bulma/css/bulma.css";
import { closeCompanyHeroModal } from "./../../../redux/toolkit/features/company_state"
import { updateCompany, fetchAllCompany, uploadImage } from "../../../redux/toolkit/features/company/company";

function CompanyHeroModal(props) {
    const [herotitle, setHeroTitle] = useState(props.company.herotitle);
    const [herosubtitle, setHeroSubtitle] = useState(props.company.herosubtitle);
    const isCompanyHeroModalOpen = useSelector((state) => state.company_state.isCompanyHeroModalOpen)
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch();

    async function onImageFileSelected(event) {
        const files = event.target.files[0];
        await dispatch(uploadImage({id:props.company.pk, file: files}))
        .unwrap()
        dispatch(fetchAllCompany());
    }

    let modalClass = "modal";
    if (isCompanyHeroModalOpen) {
        modalClass += " is-active";
    }

    function renderForm() {
        return (
            <Fragment>
                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Hero Title</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <input
                                className="input"
                                type="text"
                                placeholder="Theme Title"
                                value={herotitle}
                                onChange={(e) => setHeroTitle(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Hero Subtitle</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <textarea
                                className="textarea"
                                rows="2"
                                placeholder="Theme Description"
                                value={herosubtitle}
                                onChange={(e) => setHeroSubtitle(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Background Image</p>
                <div className="file" >
                    <label className="file-label">
                    <input 
                    style={{backgroundColor:'red !important'}}
                        className="file-input" 
                        type="file" 
                        name="image"
                        accept="image/gif, image/jpeg, image/png"
                        onChange={async (event) => onImageFileSelected(event)}
                        />
                        <span className="file-cta" style={{backgroundColor:'transparent'}}>
                            <span className="file-icon">
                                <i className="fas fa-upload" style={{color: (backgroundcolor)}}></i>
                            </span>
                            <span className="file-label"> Upload Image </span>
                        </span>
                    </label>
                </div>

        </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>COMPANY HERO</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeCompanyHeroModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(updateCompany({pk:props.company.pk,herotitle:herotitle, herosubtitle: herosubtitle}))
                                .unwrap()
                                  dispatch(closeCompanyHeroModal());
                                  dispatch(fetchAllCompany());
                            }}
                        >
                            Save Changes
                        </button>
                        <button className="button is-small" key="cancel"  style={{borderRadius:"4px"}} onClick={() => dispatch(closeCompanyHeroModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default CompanyHeroModal;
