import update from 'immutability-helper'
import React, { useCallback, useState, useEffect} from 'react'
import { useDispatch } from 'react-redux';
import {Card} from './Card.js'
import { orderProgressbaritem, fetchAllProgressbaritem } from '../../../../redux/toolkit/features/sprints/progressbaritem.js';

export const Container = (props) => {
    const [items, setItems] = useState(props.items);
    const dispatch = useDispatch();

    useEffect(() => {
        setItems(props.items);
    }, [props]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setItems((prevItems) => {
                const newItems = update(prevItems, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevItems[dragIndex]],
                    ],
                });
                const newItemsPks = newItems.map(item => item.pk);
                dispatch(orderProgressbaritem({'sorted_pks':newItemsPks, sprint:props.sprintPK}))
                dispatch(fetchAllProgressbaritem());
                return newItems;
            }
        )
    }, [])

    const renderItem = useCallback(
        (item, index) => {
            return (
                <Card
                    key={item.pk}
                    index={index}
                    id={item.pk}
                    text={item.item}
                    moveCard={moveCard}
                    rowPk={item.pk}
                />
            )
        },
        [],
    )
    if(!items){
        return <></>
    }
    return (
        <>
            <div className="m-0 p-0">{items.map((card, i) => renderItem(card, i))}</div>
        </>
    )
}