import React, {Fragment, useState} from "react";
import { useSelector, useDispatch } from 'react-redux'
import "bulma/css/bulma.css";
import {closeCompanyQuoteModal} from "./../../../redux/toolkit/features/company_state"
import { updateCompany, fetchAllCompany } from "../../../redux/toolkit/features/company/company";

function CompanyQuoteModal(props) {
    const { company } = props;
    const [quote, setQuote] = useState(company.quote);
    const isCompanyQuoteModalOpen = useSelector((state) => state.company_state.isCompanyQuoteModalOpen);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
    const textcolor = useSelector((state) => state.colors.textcolor);
    const dispatch = useDispatch();

     let modalClass = "modal";
    if (isCompanyQuoteModalOpen) {
        modalClass += " is-active";
    }

    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <textarea 
                                className="textarea"
                                rows="6"
                                placeholder="Quote"
                                value={quote}
                                onChange={(e) => setQuote(e.target.value)}
                                />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" style={{height:"50px", backgroundColor: backgroundcolor}} key="modalHeader">
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>COMPANY QUOTE</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeCompanyQuoteModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(updateCompany({pk:company.pk, quote:quote}))
                                .unwrap()
                                    dispatch(fetchAllCompany())
                                    dispatch(closeCompanyQuoteModal());
                            }}
                        >
                            Save Changes
                        </button>
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeCompanyQuoteModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default CompanyQuoteModal;
