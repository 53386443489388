import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllSprintinvestment = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/sprintinvestment`,
    authConfig()
  );
  return response.data.results;
};

export const fetchSprintinvestment = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/sprintinvestment/${id}`,
    authConfig()
  );
  return response.data;
};

export const createSprintinvestment = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/sprintinvestment`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateSprintinvestment = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/sprintinvestment/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteSprintinvestment = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/sprintinvestment/${id}`,
    authConfig()
  );
  return response.data;
};

const sprintinvestmentAPI = {
  fetchAllSprintinvestment,
  fetchSprintinvestment,
  createSprintinvestment,
  updateSprintinvestment,
  deleteSprintinvestment,
};
export default sprintinvestmentAPI;
