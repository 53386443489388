import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
//import "../common/css/dot.css";
import {filter} from "lodash"
import {talentcompetencyscoresService} from "../../services/TalentCompetencyScoresService";
import {talentrolepersonService} from "../../services/TalentRolePersonService";
import {fetchAllCompetencies} from "./../../redux/toolkit/features/competencies";
import { openTalentSelectPersonModal, openCompetencyScoresModal, openOverallScoresModal } from "../../redux/toolkit/features/organization_state";
import { openDeleteModal } from "../../redux/toolkit/features/modals/deleteConfirmModal";


function TalentPosition(props) {
    const {positionrow, persons, rolecompetencies, roleperson,competencies,competencyscores,overallscores} = props;
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
    const textcolor = useSelector((state) => state.colors.textcolor);
    const [filteredrows, setFilteredrows] = useState(null);
    const [filteredcompetencies, setFilteredcompetencies] = useState(null);
    const [isTalentVisible, setIsTalentVisible] = useState(false);
    const dispatch = useDispatch(); 

    useEffect(() => {
    },[isTalentVisible])

    let rolepersonfiltered=(filter(roleperson, {role: positionrow.pk})).map((r => r.person))
    let personfiltered=filter( persons, (row) => rolepersonfiltered.includes(row.pk))
    let rolecompetenciesfiltered=(filter(rolecompetencies, {role_id: positionrow.pk})).map((r => r.competency_id))
    let competenciesfiltered=filter( competencies, (row) => rolecompetenciesfiltered.includes(row.pk))


    function BuildCircles(score) {
        let arr=[]
        if (score !== null) {
            let fullcircle=Math.floor(score)
            let emptycircle=Math.floor(5.0-score)
            let partialcircle=5.0-fullcircle-emptycircle

            for (let i = 0; i < fullcircle; i++) {
                arr.push(<svg height="16" width="16"><circle r="7" cx="8" cy="8" fill={backgroundcolor} stroke={backgroundcolor} strokeWidth="1"/></svg>)
            }
            if (partialcircle>0) {
                arr.push(
                    <Fragment>
                        <svg height="16" width="8">
                        <circle r="7" cx="8" cy="8" fill={backgroundcolor} stroke={backgroundcolor} strokeWidth="1"/>
                        </svg>
                        <svg height="16" width="8">
                        <circle r="7" cx="0" cy="8" fill="white" stroke={backgroundcolor} strokeWidth="1" />
                        </svg>
                    </Fragment>
                )
            }
            for (let i = 0; i < Math.floor(5.0-score); i++) {
                arr.push(<svg height="16" width="16"><circle r="7" cx="8" cy="8" fill="white" stroke={backgroundcolor} strokeWidth="1"/></svg>)
            }
        }
        if (score === null) {
            for (let i = 0; i < 5; i++) {
                arr.push(<svg height="16" width="16"><circle r="7" cx="8" cy="8" fill="#ededed" stroke='#ededed' strokeWidth="1"/></svg>)
            }
        }
        return arr
    }

    function ReturnCompetencyScores(personID,competencyID) {
        const competencyscoresvalues=filter(competencyscores, {competency_id:competencyID, people_id: personID.personID, role_id: positionrow.pk})
        const competencyscorePK = competencyscoresvalues[0]?competencyscoresvalues[0].pk:null
        const competencyid = competencyscoresvalues[0]?competencyscoresvalues[0].competency_id:null
        const peopleid = competencyscoresvalues[0]?competencyscoresvalues[0].people_id:null
        const roleid = competencyscoresvalues[0]?competencyscoresvalues[0].role_id:null
        const competencyvalue=competencyscoresvalues[0]?competencyscoresvalues[0].score:null;
        const comment=competencyscoresvalues[0]?competencyscoresvalues[0].comment:"";

        return (
            <Fragment>
            <td style={{textAlign:'left', whiteSpace:'nowrap', cursor:'pointer'}} onClick={() => dispatch(openCompetencyScoresModal({competencyscorePK:competencyscorePK, competencyscorepeoplePK:personID.personID,competencyscorerolePK:positionrow.pk,competencyscorecompetencyPK:competencyID}))}>{BuildCircles(competencyvalue)}</td>
            <td style={{width:"100%", fontSize:'13px', color:backgroundcolor, cursor:'pointer'}} onClick={() => dispatch(openCompetencyScoresModal({competencyscorePK:competencyscorePK, competencyscorepeoplePK:personID.personID,competencyscorerolePK:positionrow.pk,competencyscorecompetencyPK:competencyID}))}>{comment}</td>

            </Fragment>       

        )
    }

    function ReturnCompetencies(competency, personID) {
        return (
            <Fragment>
                    <td style={{fontSize:'13px', textAlign:'left', whiteSpace:'nowrap', fontWeight:'400'}}>{competency.competency}</td>
                    {ReturnCompetencyScores(personID, competency.pk)}
            </Fragment>
        )
    }

    function GetCompetencies(personID) {

        return (
            <Fragment>
                {competenciesfiltered.map((row, index) => (
                <Fragment>
                <tr>
                    <td style={{border:'none'}}></td>
                    {index===(competenciesfiltered.length-1) && 
                    <td style={{fontSize:'14px', whiteSpace:'nowrap', fontWeight:'600'}}></td>}
                    {index!==(competenciesfiltered.length-1) && 
                    <td style={{fontSize:'14px', whiteSpace:'nowrap', fontWeight:'600', border:'none'}}></td>}
                        {ReturnCompetencies(row, personID)}
                    {index===(competenciesfiltered.length-1) && 
                    <td style={{textAlign:'right', width:'10px'}}></td>}
                    {index!==(competenciesfiltered.length-1) && 
                    <td style={{textAlign:'right', width:'10px',borderBottom:'none'}}></td>}
                </tr>
                </Fragment>
                ))}
            </Fragment>
        )
    }

    function getOverall(row) {
        const competencyoverall=filter(overallscores, {position_id: positionrow.pk, people_id:row.pk})
        const competencyoverallPK = competencyoverall[0]?competencyoverall[0].pk:null
        let score = competencyoverall[0]?competencyoverall[0].score:null
        const comment=competencyoverall[0]?competencyoverall[0].comment:"";
        return (
            <Fragment>
                <td style={{fontSize:'13px', textAlign:'left', whiteSpace:'nowrap', fontWeight:'400'}}>Overall Readiness</td>
                <td style={{cursor:'pointer'}} onClick={() => dispatch(openOverallScoresModal({overallscorePK:competencyoverallPK, overallscorepeoplePK:row.pk,overallscorerolePK:positionrow.pk}))}>{BuildCircles(score)}</td>
                <td style={{width:"100%", fontSize:'13px', color:backgroundcolor, cursor:'pointer'}} onClick={() => dispatch(openOverallScoresModal({overallscorePK:competencyoverallPK, overallscorepeoplePK:row.pk,overallscorerolePK:positionrow.pk}))}>{comment}</td>
            </Fragment>
        )
    }



    function GetCandidates(row, index) {
        let rolepersonfilter = filter(roleperson,{person: row.pk , role:positionrow.pk })
        let rolepersonPK = rolepersonfilter[0]?rolepersonfilter[0].pk:null
        return (
            <Fragment>
                <tr>
                    <td style={{borderBottom:'none'}}></td>
                    <td style={{fontSize:'14px', whiteSpace:'nowrap', fontWeight:'600', borderBottom:'none'}}>{row.firstname + ' ' + row.secondname}</td>
                    {getOverall(row)}
                    <td style={{textAlign:'right', width:'10px', borderBottom:'none', cursor:'pointer'}} onClick={() => dispatch(openDeleteModal({message: row.firstname + ' ' + row.secondname,recordpk: rolepersonPK, recordtype: 'roleperson'}))}><i className="fas fa-trash-alt" style={{color: 'rgb(150,150,150)', fontSize:'14.5px'}}></i></td>
                </tr>
                
                    <GetCompetencies personID={row.pk}/>



            </Fragment>
        )    
    }   

    return (
        <Fragment>
        {!isTalentVisible && 
            <Fragment>

                    <tr key={positionrow.title}>
                        <td className="pl-0" onClick={() => setIsTalentVisible(true)} style={{width:'10px', cursor:'pointer'}}><button className="far fa-plus-square" style={{fontSize:'smaller'}}/></td>
                        <td colSpan={6}>{positionrow.title} ({rolepersonfiltered.length})</td>
                    </tr>

            </Fragment>
        }

        {isTalentVisible && 
            <Fragment>

                    <tr key={positionrow.title}>
                    <td className="pl-0" style={{width:'10px', cursor:'pointer'}} onClick={() => setIsTalentVisible(false)} ><button className="far fa-minus-square" style={{fontSize:'smaller'}}/></td>
                    <td style={{fontWeight:'700'}} colSpan={4}>{positionrow.title} ({rolepersonfiltered.length})</td>
                    <td style={{textAlign:'right', cursor:'pointer'}} onClick={() => dispatch(openTalentSelectPersonModal({talentrolePK:positionrow.pk}))}><i className="fas fa-plus-circle" style={{color:backgroundcolor}}></i></td>
                    </tr>
                    {personfiltered.length===0 && <tr><td></td><td colSpan={8}><p >No Candidates Identified</p></td></tr>}
                    {personfiltered.length!==0 && personfiltered.map(row => (GetCandidates(row)))}
              </Fragment>
        }
        </Fragment>
        );
    }

export {TalentPosition};