import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import Moment from 'react-moment';
import 'moment-timezone';
import { openSprintCommentModal } from "../../redux/toolkit/features/sprints_state";

function Register(props) {
    const {comment, sprintPK} = props;
    const dispatch = useDispatch();

    return (
        <Fragment>
            <p className="ml-0" key={"Register_" + comment.pk} onClick={() => dispatch(openSprintCommentModal({commentPK:comment.pk, commentsprintPK:sprintPK}))}>
                <span className="pr-2"style={{ fontFamily: "Sans-serif", fontWeight: 600, fontSize:"12px" }}>{comment.username.toUpperCase()} </span>
                <span style={{ fontSize:"11px" }}>
                    <Moment format='DD MMMM YYYY' tz='America/Los_Angeles'>
                        {comment.date}
                    </Moment>
                    </span>
            </p>
            <p className="ml-0" style={{ fontWeight: 300, fontSize:"13px" }} onClick={() => dispatch(openSprintCommentModal({commentPK:comment.pk, commentsprintPK:sprintPK}))}>{comment.comment}</p>
            <br />
        </Fragment>
    );
}

export { Register };
