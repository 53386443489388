import api from "../api/roadmapgrowth";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const roadmapgrowthAdapter = createEntityAdapter({
  selectId: (growth) => growth.pk,
  //sortComparer: (a, b) => a.section.localeCompare(b.section),
});

const initialState = roadmapgrowthAdapter.getInitialState({
  status: "idle"
});

export const fetchAllGrowth = createAsyncThunk("growth/fetch", () =>
  api.fetchAllGrowth()
);

export const updateGrowth = createAsyncThunk("growth/update", api.updateGrowth);

export const createGrowth = createAsyncThunk("growth/create", api.createGrowth);

export const deleteGrowth = createAsyncThunk("growth/delete", api.deleteGrowth);

const roadmapgrowthSlice = createSlice({
  name: "roadmapgrowth",
  initialState,
  reducers: {
   },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGrowth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllGrowth.fulfilled, (state, { payload }) => {
        roadmapgrowthAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateGrowth.fulfilled, roadmapgrowthAdapter.updateOne)
      .addCase(deleteGrowth.fulfilled, roadmapgrowthAdapter.removeOne)
      .addCase(createGrowth.fulfilled, roadmapgrowthAdapter.addOne);
  },
});

export const {} = roadmapgrowthSlice.actions;

export default roadmapgrowthSlice.reducer;

const selectState = (state) => state.roadmapgrowth;

export const {
  selectAll: selectroadmapgrowth,
  selectById: selectGrowthById,
} = roadmapgrowthAdapter.getSelectors(selectState);

export const selectGrowthStatus = createSelector(
  selectState,
  (state) => state.status
);
