import api from "../../api/tenants/fxrow";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const fxrowAdapter = createEntityAdapter({
  selectId: (fxrow) => fxrow.pk,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = fxrowAdapter.getInitialState({status: "idle"});

export const fetchAllFXRow = createAsyncThunk("fxrow/fetch", () => api.fetchAllFXRow());

export const updateFXRow = createAsyncThunk("fxrow/update", api.updateFXRow);

export const createFXRow = createAsyncThunk("fxrow/create", api.createFXRow);

export const deleteFXRow = createAsyncThunk("fxrow/delete", api.deleteFXRow);

const fxrowSlice = createSlice({
  name: "fxrow",
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFXRow.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllFXRow.fulfilled, (state, { payload }) => {
        fxrowAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateFXRow.fulfilled, fxrowAdapter.updateOne)
      .addCase(deleteFXRow.fulfilled, fxrowAdapter.removeOne)
      .addCase(createFXRow.fulfilled, fxrowAdapter.addOne);
  },
});

export const { } = fxrowSlice.actions;

export default fxrowSlice.reducer;

const selectState = (state) => state.fxrow;

export const {
  selectAll: selectFXRow,
  selectById: selectFXRowById,
} = fxrowAdapter.getSelectors(selectState);

export const selectFXRowStatus = createSelector(
  selectState,
  (state) => state.status
);
