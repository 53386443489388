import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {Sprint} from "./../sprints/Sprint";
import {filter} from "lodash";
import {fetchAllSprints} from "./../../redux/toolkit/features/sprints/sprints";
import {fetchAllSponsors} from "./../../redux/toolkit/features/sprints/sponsors";
import {fetchAllComments} from "./../../redux/toolkit/features/sprints/comments";


function SprintList(props) {
    const {linkedsprintsfiltered} = props; 
    const sprints = Object.values(useSelector((state) => state.sprints.entities));
    const comments = Object.values(useSelector((state) => state.comments.entities));
    const dispatch = useDispatch();
 
    async function fetchSprints() {
        dispatch(fetchAllSprints());
        dispatch(fetchAllSponsors());
        dispatch(fetchAllComments());
    }

    useEffect(() => {
        fetchSprints();
    }, []);

    const rowIds = linkedsprintsfiltered.map(r => r.sprint_id);
    const filteredSprints=filter( sprints, (row) => rowIds.includes(row.pk));

    return (
        <Fragment>
                        <Sprint 
                            filteredSprints={filteredSprints} 
                            registers={comments}
                        />
        </Fragment>
        )
}

export { SprintList }

