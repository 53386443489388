import api from "../../api/organization/persons";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const personsAdapter = createEntityAdapter({
  selectId: (person) => person.pk,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = personsAdapter.getInitialState({
  status: "idle", isOpen: false, peoplepk: null
});

export const fetchAllPersons = createAsyncThunk("persons/fetch", () => api.fetchAllPersons());

export const updatePersons = createAsyncThunk("persons/update", api.updatePersons);

export const createPersons = createAsyncThunk("persons/create", api.createPersons);

export const deletePersons = createAsyncThunk("persons/delete", api.deletePersons);

const personsSlice = createSlice({
  name: "persons",
  initialState,
  reducers: {
    openPeopleModal(state, action) {
      state.isOpen = true;
      state.peoplepk = action.payload;
    },
    closePeopleModal(state) {
      state.isOpen = false;
      state.peoplepk = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPersons.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPersons.fulfilled, (state, { payload }) => {
        personsAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updatePersons.fulfilled, personsAdapter.updateOne)
      .addCase(deletePersons.fulfilled, personsAdapter.removeOne)
      .addCase(createPersons.fulfilled, personsAdapter.addOne);
  },
});

export const { openPeopleModal, closePeopleModal } = personsSlice.actions;

export default personsSlice.reducer;

const selectState = (state) => state.persons;

export const {
  selectAll: selectPersons,
  selectById: selectPersonById,
} = personsAdapter.getSelectors(selectState);

export const selectPersonsStatus = createSelector(
  selectState,
  (state) => state.status
);
