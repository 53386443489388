import React from "react";
import { useDispatch } from 'react-redux'
import {companyService} from "../../services/CompanyService";
import {openCompanyHeroModal} from "./../../redux/toolkit/features/company_state"

function CompanyHeroSectionWithData(props) {
    const {herotitle, herosubtitle, heroimage} = props;
    const dispatch = useDispatch();

    let imageURL = companyService.imageUrl({heroimage});
    let backgroundStyle = {backgroundColor: 'blue', userSelect: "none"};
    if (heroimage) {
        backgroundStyle = {
            backgroundImage: 'url(' + imageURL + ')',
            backgroundSize: "cover",
            userSelect: "none",
            backgroundPosition: "center"
        };
    }
    let fs=[];


    return (
        <section className="hero is-large" onDoubleClick={() => dispatch(openCompanyHeroModal())} style={backgroundStyle}>
            <div className="hero-body">
                <div className="" style={{marginLeft: "12%"}}>
                    <p className="title" style={{
                        fontWeight: "700",
                        fontFamily: "Gotham Extra",
                        fontSize: "72px",
                        lineHeight: "26px",
                        paddingBottom: "28px",
                        color: "white",
                        userSelect: "none"
                    }}>
                        {herotitle}
                    </p>
                    <p className="subtitle" style={{
                        fontWeight: "700",
                        fontFamily: "Gotham Extra",
                        fontSize: "50px",
                        lineHeight: "26px",
                        paddingTop: "12px",
                        paddingBottom: "28px",
                        color: "white"
                    }}>
                        {herosubtitle}
                    </p>
                </div>
            </div>
           
        </section>
    );
}

export default CompanyHeroSectionWithData;
