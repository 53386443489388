import React, { useEffect, Fragment, useState, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {filter} from "lodash"
import { roadmapdatasets } from '../Roadmaps';
import { celldatasets } from './Roadmap';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, StackingColumnSeries, Tooltip, Highlight, WaterfallSeries, DataLabel, Zoom, Crosshair, LineSeries } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
import { openGraphsModal } from "./../../redux/toolkit/features/roadmaps_state";
import { fetchAllCharts } from "../../redux/toolkit/features/roadmapcharts";
import { fetchAllChartRows } from "../../redux/toolkit/features/roadmapchartrows";



function RoadmapChart(props) {
    const palette = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"]
    const { sectionPk} = props;
    const { minYear, maxYear } = useContext(roadmapdatasets);
    const cellvalues = (useContext(celldatasets)).cells;
    const rows = (useContext(celldatasets)).rows;
    const charts = (Object.values(useSelector((state) => state.roadmapcharts.entities)));
    const chartrows = (Object.values(useSelector((state) => state.roadmapchartrows.entities)));
    //const showSectionsChartEditModal = useSelector((state) => state.sections.isGraphEditModalOpen);

    var chartlistarray = filter(charts, {section: sectionPk})
    var chartdata =[]
    const [chart_title, setTitle] = useState(chartlistarray[0]?chartlistarray[0].chart_title:null);
    const [chart_subtitle, setSubTitle] = useState(chartlistarray[0]?chartlistarray[0].chart_subtitle:null);
    const [chart_yaxis, setYaxis] = useState(chartlistarray[0]?chartlistarray[0].chart_yaxislabel:"");
    const [chartid, setChartid] = useState(null);
    const [chartstatus, setChartStatus] = useState(chartlistarray[0]?chartlistarray[0].chart_type:null);
    const cellstatus = useSelector((state) => state.roadmapcells.status);
    const dispatch = useDispatch();


    useEffect(() => {
        chartlistarray = filter(charts, {section: sectionPk})
    }, []);

    useEffect(() => {
        setChartStatus(chartlistarray[0]?chartlistarray[0].chart_type:null)
        setTitle(chartlistarray[0]?chartlistarray[0].chart_title:"")
        setYaxis(chartlistarray[0]?chartlistarray[0].chart_yaxis:"")
        setSubTitle(chartlistarray[0]?chartlistarray[0].chart_subtitle:"")
        chartdata = filter(chartlistarray, {pk: chartid})
    }, []);

    useEffect(() => {
        dispatch(fetchAllCharts());
        dispatch(fetchAllChartRows());
    }, []);

    function stackchart() {

        let chartdatarowsarray = filter(chartrows, {chart_id: chartid})
        let dataarray=[]

        for (const row of chartdatarowsarray) {
            var stackeddata=[]
            for (let year = minYear; year <= maxYear; year++) {
                let chartrow = filter(cellvalues, {row_id: row.row_id, year: year})
                var stackedobj={}
                stackedobj['x']=year;
                stackedobj['y']=chartrow[0].numeric_value;
                stackeddata.push(stackedobj)
            }
            dataarray.push(stackeddata)
        }

        const onChartLoad = (args) => {
            let chart = document.getElementById('charts');
            chart.setAttribute('title', '');
        };

        const axisLabelRender = (args) => {
            args.text = args.text.replace("0000000", "0M").replace("000000", "M");
        };

        return (
            <ChartComponent id={'stackcharts'+chartid} style={{ textAlign: "center" }} legendSettings={{ enableHighlight: true }} useGroupingSeparator={true} palettes={palette} primaryXAxis={{ majorGridLines: { width: 0 }, minorGridLines: { width: 0 }, majorTickLines: { width: 0 }, minorTickLines: { width: 0 }, interval: 1, lineStyle: { width: 0 }, labelIntersectAction: 'Rotate45', valueType: 'Category' }} primaryYAxis={{ title: 'Vehicles Production (In Millions)', lineStyle: { width: 0 }, majorTickLines: { width: 0 }, majorGridLines: { width: 1 }, minorGridLines: { width: 1 }, minorTickLines: { width: 0 }, labelFormat: '{value}' }} width={Browser.isDevice ? '100%' : '75%'} chartArea={{ border: { width: 0 } }} loaded={onChartLoad.bind(this)} tooltip={{ enable: true }} axisLabelRender={axisLabelRender.bind(this)}>
                <Inject services={[StackingColumnSeries, Category, Legend, Tooltip, Highlight]}/>
                <SeriesCollectionDirective>
                    {renderstackchart(dataarray, chartdatarowsarray)}
                    </SeriesCollectionDirective>
            </ChartComponent>
        )
    };
    
    function renderstackchart(dataarray, chartdatarowsarray) {
        const cells = [];
        for (let i = 0; i < chartdatarowsarray.length; i++) {
            var rowname = (filter(rows, {pk: chartdatarowsarray[i].row_id}))[0].name
            cells.push(<SeriesDirective dataSource={dataarray[i]} xName='x' yName='y' name={rowname} columnWidth={1} columnSpacing={0} opacity={0.85} border={ "none" } type='StackingColumn'/>);
        }
        return cells;
    }

    function waterfallchart() {

        let startvalue=0;
        let endvalue=0;
        let chartdatarowsarray = filter(chartrows, {chart_id: chartid});
        let waterfallarray=[];

        for (const row of chartdatarowsarray) {
            let startvaluearray = filter(cellvalues, {year: minYear,row_id:row.row_id})
            let endvaluearray = filter(cellvalues, {year: maxYear,row_id:row.row_id})

            if (startvaluearray.length !== 0) {
            startvalue = startvalue + startvaluearray[0].numeric_value 
            }
            if (endvaluearray.length !== 0) {
                endvalue = endvalue + endvaluearray[0].numeric_value 
            }
        }

        waterfallarray.push({x: minYear.toString(), y: startvalue, color:'CornflowerBlue'})
        for (const row of chartdatarowsarray) {
            let waterfallobj={};
            let cellvalue=[];
            let rowname = (filter(rows, {pk: row.row_id}))[0].name
            cellvalue = filter(cellvalues, {year: minYear,row_id:row.row_id})
            let baseyear=cellvalue[0].numeric_value    
            cellvalue = filter(cellvalues, {year:maxYear,row_id:row.row_id})
            let finalyear=cellvalue[0].numeric_value     
            waterfallobj['x']=rowname;
            waterfallobj['y']=finalyear-baseyear;
            waterfallarray.push(waterfallobj)
        }

        waterfallarray.push({x: maxYear.toString(), y: endvalue, color:'CornflowerBlue'})

        const primaryxAxis = { valueType: 'Category', majorGridLines: { width: 0 }, plotOffset: 20 };
        const primaryyAxis = { title: chart_yaxis };
        const marker = { dataLabel: { visible: true, font: { color: '#ffffff' } } };
        const connector = { color: '#000000', width: 1};
        const tooltip = { enable: true, shared: false };
        const legendSettings = { visible: false };
        const indexsum = chartdatarowsarray.length+1
        return (
            <ChartComponent id={'waterfallcharts'+chartid} primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} useGroupingSeparator={true} tooltip={tooltip} legendSettings={legendSettings} title={chart_title}>
                <Inject services={[WaterfallSeries, Category, Tooltip, Zoom, Crosshair, Legend, DataLabel]}/>
                <SeriesCollectionDirective>
                    <SeriesDirective dataSource={waterfallarray} xName='x' yName='y' name={chart_title} type='Waterfall' sumIndexes={[indexsum]} marker={marker} pointColorMapping="color" connector={connector} columnWidth={0.9} negativeFillColor='#e56590'>
                    </SeriesDirective>
                </SeriesCollectionDirective>
            </ChartComponent>)
    };

    function linechart() {

        let chartdatarowsarray = filter(chartrows, {chart_id: chartid});
        var linedata=[];
        for (const row of chartdatarowsarray) {
            let linearray=[];
            for (let year = minYear; year <= maxYear; year++) {
                let lineobj={};
                let cellvalue = filter(cellvalues, {year:year,row_id:row.row_id})
                lineobj['x']=year;
                if (cellvalue.length !== 0) {
                lineobj['y']=cellvalue[0].numeric_value;
                } else {
                    lineobj['y']=null;
                }
                linearray.push(lineobj);
            }
            linedata.push(linearray);
        }

        const primaryxAxis = { title:'Years', interval: 1};
        const primaryyAxis = { title: chart_yaxis};
        const marker = { visible: true, width: 10, opacity: 1, height: 10 };

        return (
            <Fragment>
            {chartdatarowsarray.length !== 0 &&
            <ChartComponent id={'linecharts'+chartid} primaryXAxis={primaryxAxis} useGroupingSeparator={true} primaryYAxis={primaryyAxis} palettes={palette} title={chart_title}>
                <Inject services={[LineSeries, Legend, Tooltip, DataLabel, Category]}/>
                <SeriesCollectionDirective>
                    {renderlinechart(chartdatarowsarray, marker, linedata)}
                </SeriesCollectionDirective>
            </ChartComponent>
            }

            {chartdatarowsarray.length === 0 &&
            <ChartComponent id={'linecharts'+sectionPk+'_'+chartid} primaryXAxis={primaryxAxis} useGroupingSeparator={true} primaryYAxis={primaryyAxis} palettes={palette} title={chart_title}>
            <Inject services={[LineSeries, Legend, Tooltip, DataLabel, Category]}/>
            </ChartComponent>
            }
            </Fragment>
        );
    };

    function renderlinechart(chartdatarowsarray, marker, linedata) {
           const cells = [];
        for (let i = 0; i < chartdatarowsarray.length; i++) {
            var rowname = (filter(rows, {pk: chartdatarowsarray[i].row_id}))[0].name
            cells.push(<SeriesDirective dataSource={linedata[i]} xName='x' yName='y' name={rowname} type='Line' width={4} opacity={0.6} marker={marker}/>);
        }
        return cells;
    }



    function displayChart(event) {
        setChartid(Number(event.target.value))
        chartdata = filter(chartlistarray, {pk: Number(event.target.value)})
        setTitle(chartdata[0].chart_title)
        setSubTitle(chartdata[0].chart_subtitle)
        setYaxis(chartdata[0].chart_yaxislabel)
        setChartStatus(chartdata[0].chart_type)
    }

    function chartdisplay() {
            if(chartlistarray.length!==0 && chartid===null) {
                setChartid(Number(chartlistarray[0].pk))
                chartdata = filter(chartlistarray, {pk: Number(chartlistarray[0].pk)})
                setTitle(chartdata[0].chart_title)
                setSubTitle(chartdata[0].chart_subtitle)
                setYaxis(chartdata[0].chart_yaxislabel)
                setChartStatus(chartdata[0].chart_type)
            }
            return (
            <Fragment>
                <div className="pl-4 pt-5">
                    <label style={{fontSize:"14px", fontWeight: 600}}>Displaying Chart: </label>
                    {chartlistarray !== undefined &&
                    <select 
                        className="mb-4 ml-2 pl-2 pr-3"  
                        onChange={(event) => displayChart(event)}
                    >
                        {chartlistarray && chartlistarray.map(chartheader => (
                            <option id={chartheader.pk} key={chartheader.pk} value={chartheader.pk}>{chartheader.chart_type + " Chart: " + chartheader.chart_title}</option>
                        ))}
                    </select>}
                </div>
                </Fragment>
            )
    }

    return (
        <Fragment>
            <div className="pb-3" style={{backgroundColor: "white", borderRadius:"10px"}} onDoubleClick={() => dispatch(openGraphsModal({chartsectionpk: sectionPk}))}>
                {chartdisplay()} 

                {chartlistarray.length!==0 && 
                <div className="ml-4 mr-4 mb-4" style={{border:"1px Gainsboro solid"}}>
                    {chartstatus==="Line" && linechart()}
                    {chartstatus==="Waterfall" && waterfallchart()}
                    {chartstatus==="Stackchart" && stackchart()}
                </div>
                }
            </div>
        </Fragment>
    )
}

export { RoadmapChart };
