import api from "../../api/sprints/sprints";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const sprintsAdapter = createEntityAdapter({
  selectId: (sprint) => sprint.pk,
  sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = sprintsAdapter.getInitialState({ status: "idle" });

export const fetchAllSprints = createAsyncThunk("sprints/fetch", () => api.fetchAllSprints());

export const updateSprints = createAsyncThunk("sprints/update", api.updateSprints);

export const createSprints = createAsyncThunk("sprints/create", api.createSprints);

export const deleteSprints = createAsyncThunk("sprints/delete", api.deleteSprints);


const sprintsSlice = createSlice({
  name: "sprints",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSprints.pending, (state) => {state.status = "loading";})
      .addCase(fetchAllSprints.fulfilled, (state, { payload }) => {
        sprintsAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateSprints.fulfilled, sprintsAdapter.updateOne)
      .addCase(deleteSprints.fulfilled, sprintsAdapter.removeOne)
      .addCase(createSprints.fulfilled, sprintsAdapter.addOne);
  },
});

export const {} = sprintsSlice.actions;

export default sprintsSlice.reducer;

const selectState = (state) => state.sprints;

export const {
  selectAll: selectSprints,
  selectById: selectSprintById,
} = sprintsAdapter.getSelectors(selectState);

export const selectSprintsStatus = createSelector(
  selectState,
  (state) => state.status
);

