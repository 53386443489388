import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {filter} from "lodash"
//import {selectCategoryById} from "../
import ConfirmDeleteBox from "./../common/ConfirmDeleteBox"
import {closeCategoriesModal} from "./../../redux/toolkit/features/stakeholder_state";
import {createCategory, updateCategory, fetchAllCategories} from "./../../redux/toolkit/features/stakeholders/categories"
import { openDeleteModal } from './../../redux/toolkit/features/modals/deleteConfirmModal';

function CategoryModal(props) {
  const {categories} = props;
  const categorymodalkey = useSelector((state) => state.stakeholder_state.categoryId);  
  const isCategoryModalOpen = useSelector((state) => state.stakeholder_state.isCategoriesModalOpen);  
  const dispatch = useDispatch(); 
  //const catgeoryPK = useSelector((state) => state.company_state.factPK);
 // const categorybyid = useSelector((state)=>selectCategoryById(state, factPK))  


  let category=filter(categories,{pk: categorymodalkey})
  var categorypk=category[0]?category[0].pk:null;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showDeleteModal, setShowDeleteModal]= useState(false)
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)

  const namevalue = category[0]?category[0].name:'';
  const descriptionvalue = category[0]?category[0].description:'';

  useEffect(() => {
    setName(namevalue);
    setDescription(descriptionvalue)
  }, [categories, namevalue, descriptionvalue]);

  let modalClass = "modal";
  if (isCategoryModalOpen) {
    modalClass += " is-active";
  }


  function deleteHandler(result){
    if (result) {
      // onCategoryDelete(categorymodalkey)
    }
    setShowDeleteModal(false)
  }

  function renderContent() {
    return (
      <Fragment>
        <p className="pb-2 pl-1" style={{fontSize:"12px", fontWeight: "500", userSelect:'none'}}>Category Name:</p>
        <input
          className="input"
          type="text"

          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <p className="pb-2 pl-1 pt-2" style={{fontSize:"12px", fontWeight: "500", userSelect:'none'}}>Category Trends:</p>

          <textarea
            className="textarea"
            type="text"
            rows={5}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
      </Fragment>
    );
  }

  return (
    <Fragment>
    <div className={modalClass} key="categoryModal">
      <div className="modal-background" key="modalBackground">
        {" "}
      </div>
      <div className="modal-card" key="modalCard">
        <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor, userSelect:'none'}}>
          <p className="modal-card-title" style={{fontSize:"17px", color: textcolor, userSelect:'none'}}>{categorymodalkey?"EDIT CATEGORY":"NEW CATEGORY"}</p>
          <button className="delete" aria-label="close" onClick={() => dispatch(closeCategoriesModal())}>
            {" "}
          </button>
        </header>
        <section className="modal-card-body" key="modalBody">
          {renderContent()}
        </section>
        <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

          {categorypk === null && 
          <button
            style={{borderRadius:"4px"}}
            className="button is-success is-small"
            key="save"
            onClick={async () => {
              await dispatch(createCategory({category:categorymodalkey, name:name, description:description}))
              .unwrap()
              dispatch(fetchAllCategories())
              dispatch(closeCategoriesModal())
          }}
          >
            Save changes
          </button>}

          {categorypk !== null && 
          <button
            style={{borderRadius:"4px"}}
            className="button is-success is-small"
            key="save"
            onClick={async () => {
              await dispatch(updateCategory({id:categorypk,category:categorymodalkey, name:name, description:description}))
              .unwrap()
              dispatch(fetchAllCategories())
              dispatch(closeCategoriesModal())
          }}
          >
            Save changes
          </button>}


          <button className="button is-small" style={{borderRadius:"4px"}} key="cancel" onClick={() => dispatch(closeCategoriesModal())}>
            Cancel
          </button>
          <span className="is-flex-grow-1" />
          {categorypk !== null &&
            <button
              style={{borderRadius:"4px"}}
              className="button is-danger is-small"
              key="delete"
              onClick={() => {
                dispatch(openDeleteModal({message: name, recordpk: categorypk, recordtype: 'category'}))
              }}
            >
              Delete
            </button>}
        </footer>
      </div>
    </div>
    <ConfirmDeleteBox 
      showModal={showDeleteModal}
      modaltext={name}
      deleteHandler={deleteHandler}
      />
    </Fragment>

  );
}

export default CategoryModal;
