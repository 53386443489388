import {
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

const organization_stateAdapter = createEntityAdapter();

const initialState = organization_stateAdapter.getInitialState({
  isProductivityModalOpen: false,
  isTalentSelectPersonModalOpen: false,
  talentrolePK: null,
  isRoleModalOpen: false,
  roleId: null,
  isRoleLinkPersonModalOpen: false,
  personrolePK: null,
  personpersonPK: null,
  isRoleLinkCompetencyModalOpen: false,
  competencyrolePK: null,
  isRoleLinkReportingModalOpen: false,
  reportingrolePK: null,
  rolerolePK:null,
  isCompetencyScoresModalOpen: false,
  competencyscorePK: null,
  competencyscorepeoplePK: null,
  competencyscorerolePK: null,
  competencyscorecompetencyPK: null,
  isOverallScoresModalOpen: false,
  overallscorePK: null,
  overallscorepeoplePK: null,
  overallscorerolePK: null,
  isProductivityLinksModalOpen: false,
});

const organization_stateSlice = createSlice({
  name: "organization_state",
  initialState,
  reducers: {
    openRolesModal(state, action) {
      state.isRoleModalOpen = true;
      state.roleId = action.payload.roleId;
    },
    closeRolesModal(state) {
      state.isRoleModalOpen = false;
      state.roleId = null;
    },
    openRolesLinkPersonModal(state, action) {
      state.isRoleLinkPersonModalOpen = true;
      state.personrolePK = action.payload.personrolePK;
      state.personpersonPK = action.payload.personpersonPK;
    },
    closeRolesLinkPersonModal(state) {
      state.isRoleLinkPersonModalOpen = false;
      state.personrolePK = null;
      state.personpersonPK = null;
    },
    openRolesLinkCompetencyModal(state, action) {
      state.isRoleLinkCompetencyModalOpen = true;
      state.competencyrolePK = action.payload.competencyrolePK;
    },
    closeRolesLinkCompetencyModal(state) {
      state.isRoleLinkCompetencyModalOpen = false;
      state.competencyrolePK = null;
    },
    openRolesLinkReportingModal(state, action) {
      state.isRoleLinkReportingModalOpen = true;
      state.reportingrolePK = action.payload.reportingrolePK;
      state.rolerolePK = action.payload.rolerolePK;
    },
    closeRolesLinkReportingModal(state) {
      state.isRoleLinkReportingModalOpen = false;
      state.reportingrolePK = null;
      state.rolerolePK = null;
    },
    openProductivityModal(state) {
      state.isProductivityModalOpen = true;
    },
    closeProductivityModal(state) {
      state.isProductivityModalOpen = false;
    },
    openProductivityLinksModal(state) {
      state.isProductivityLinksModalOpen = true;
    },
    closeProductivityLinksModal(state) {
      state.isProductivityLinksModalOpen = false;
    },
    openTalentSelectPersonModal(state, action) {
      state.isTalentSelectPersonModalOpen = true;
      state.talentrolePK = action.payload.talentrolePK;
    },
    closeTalentSelectPersonModal(state) {
      state.isTalentSelectPersonModalOpen = false;
      state.talentrolePK = null;
    },
    openCompetencyScoresModal(state, action) {
      state.isCompetencyScoresModalOpen = true;
      state.competencyscorePK = action.payload.competencyscorePK;
      state.competencyscorepeoplePK = action.payload.competencyscorepeoplePK;
      state.competencyscorerolePK = action.payload.competencyscorerolePK;
      state.competencyscorecompetencyPK = action.payload.competencyscorecompetencyPK;
    },
    closeCompetencyScoresModal(state) {
      state.isCompetencyScoresModalOpen = false;
      state.competencyscorePK = null;
      state.competencyscorepeoplePK = null;
      state.competencyscorerolePK = null;
      state.competencyscorecompetencyPK = null;
    },
    openOverallScoresModal(state, action) {
      state.isOverallScoresModalOpen = true;
      state.overallscorePK = action.payload.overallscorePK;
      state.overallscorepeoplePK = action.payload.overallscorepeoplePK;
      state.overallscorerolePK = action.payload.overallscorerolePK;
    },
    closeOverallScoresModal(state) {
      state.isOverallScoresModalOpen = false;
      state.overallscorePK = null;
      state.overallscorepeoplePK = null;
      state.overallscorerolePK = null;
    },
  },
});

export const { 
  openRolesModal,
  closeRolesModal,
  openRolesLinkPersonModal,
  closeRolesLinkPersonModal, 
  openRolesLinkCompetencyModal,
  closeRolesLinkCompetencyModal,
  openRolesLinkReportingModal,
  closeRolesLinkReportingModal,
  openProductivityModal,
  closeProductivityModal,
  openProductivityLinksModal,
  closeProductivityLinksModal,
  openTalentSelectPersonModal,
  closeTalentSelectPersonModal,
  openCompetencyScoresModal,
  closeCompetencyScoresModal,
  openOverallScoresModal,
  closeOverallScoresModal
} = organization_stateSlice.actions;

export default organization_stateSlice.reducer;



