import api from "../../api/sprints/sprintinvestment";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const sprintinvestmentAdapter = createEntityAdapter({
  selectId: (sprintinvestment) => sprintinvestment.pk,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = sprintinvestmentAdapter.getInitialState({ status: "idle" });

export const fetchAllSprintinvestment = createAsyncThunk("sprintinvestment/fetch", () => api.fetchAllSprintinvestment());

export const updateSprintinvestment = createAsyncThunk("sprintinvestment/update", api.updateSprintinvestment);

export const createSprintinvestment = createAsyncThunk("sprintinvestment/create", api.createSprintinvestment);

export const deleteSprintinvestment = createAsyncThunk("sprintinvestment/delete", api.deleteSprintinvestment);


const sprintinvestmentSlice = createSlice({
  name: "sprintinvestment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSprintinvestment.pending, (state) => {state.status = "loading";})
      .addCase(fetchAllSprintinvestment.fulfilled, (state, { payload }) => {
        sprintinvestmentAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateSprintinvestment.fulfilled, sprintinvestmentAdapter.updateOne)
      .addCase(deleteSprintinvestment.fulfilled, sprintinvestmentAdapter.removeOne)
      .addCase(createSprintinvestment.fulfilled, sprintinvestmentAdapter.addOne);
  },
});

export const {} = sprintinvestmentSlice.actions;

export default sprintinvestmentSlice.reducer;

const selectState = (state) => state.sprintinvestment;

export const {
  selectAll: selectSprintinvestment,
  selectById: selectSprintinvestmentById,
} = sprintinvestmentAdapter.getSelectors(selectState);

export const selectSprintinvestmentStatus = createSelector(
  selectState,
  (state) => state.status
);

