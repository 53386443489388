import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {filter} from "lodash"
import {closeMatrixModal} from "./../../redux/toolkit/features/stakeholder_state";
import {updateMatrix, createMatrix, fetchAllMatrices} from "./../../redux/toolkit/features/stakeholders/matrices";
import {updateCompanymatrix, createCompanymatrix, fetchAllCompanyMatrices} from "./../../redux/toolkit/features/stakeholders/companymatrix";


function MatrixModal(props) {
  const{ matrices, companymatrix} = props;
  const isMatrixModalOpen = useSelector((state) => state.stakeholder_state.isMatrixModalOpen)
  const isCompanyMatrixModalOpen = useSelector((state) => state.stakeholder_state.isCompanyMatrixModalOpen)
  const categorymodalkey = useSelector((state) => state.stakeholder_state.matrixcategoryId)
  const stakeholdermodalkey = useSelector((state) => state.stakeholder_state.matrixstakeholderId)
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const dispatch = useDispatch(); 
  var matrixpk=null;

  let matrix=[];
  if(!isCompanyMatrixModalOpen) {
    matrix=filter(matrices,{category: categorymodalkey, stakeholder:stakeholdermodalkey})
    matrixpk=matrix[0]?matrix[0].pk:null;
  } else {
    matrix=filter(companymatrix,{category: categorymodalkey})
    matrixpk=matrix[0]?matrix[0].pk:null;
  }

  console.log("Matrix,", matrix)

  const [importance, setImportance] = useState(0);
  const [description, setDescription] = useState("");
  const importancevalue = matrix[0]?matrix[0].importance:0;
  const descriptionvalue = matrix[0]?matrix[0].description:'';

  useEffect(() => {
    setImportance(importancevalue);
    setDescription(descriptionvalue)
  }, [matrices, importancevalue, descriptionvalue]);

 
  let modalClass = "modal";
  if (isMatrixModalOpen) {
    modalClass += " is-active";
  }


  function renderContent() {
    return (
      <Fragment>

        <p className="pb-2 pl-1" style={{fontSize:"12px", fontWeight: "500"}}>{isCompanyMatrixModalOpen?'Company Importance Score:': 'Stakeholder Importance Score:'}</p>
        <input 
          type="range" 
          min="1" 
          max="100" 
          value={importance} 
          className="slider p-0 m-0" 
          id="myRange" 
          style={{height: '15px', backgroundColor:backgroundcolor, width:'100%'}} 
          onChange={e => setImportance(e.target.value)}/>
          <div className="columns pt-0 pb-3 m-0" style={{fontSize:"11px", userSelect: 'none'}}>
            <div className="column is-one-half pt-0 pl-1 m-0">Not Important</div>
            <div className="column is-one-half has-text-right pt-0 m-0">Extremely Important</div>
          </div>

          <p className="pb-1 pl-1" style={{fontSize:"12px", fontWeight: "500"}}>{isCompanyMatrixModalOpen?'Comments:': 'Emerging Trends:'}</p>
          <textarea
            className="textarea"
            type="textarea"
            rows={5}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
      </Fragment>
    );
  }

  return (
    <div className={modalClass} key="matrixModal">
      <div className="modal-background" key="modalBackground">
        {" "}
      </div>
      <div className="modal-card" key="modalCard" style={{width:'500px'}}>
        <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
          <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{isCompanyMatrixModalOpen?'SET COMPANY IMPORTANCE':'SET STAKEHOLDER IMPORTANCE'}</p>
          <button className="delete" aria-label="close" onClick={() => dispatch(closeMatrixModal())}>
            {" "}
          </button>
        </header>
        <section className="modal-card-body" key="modalBody">
          {renderContent()}
        </section>
        <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

          {isCompanyMatrixModalOpen && matrixpk !== null && 
          <Fragment>
          <button
            className="button is-success is-small"
            style={{borderRadius:"4px"}}
            key="save"
            
            onClick={async () => {
              await dispatch(updateCompanymatrix({id:matrixpk,importance:importance, description:description, category:categorymodalkey }))
              .unwrap()
              dispatch(fetchAllCompanyMatrices())
              dispatch(closeMatrixModal());
          }}
          >
            Save changes
          </button>
          </Fragment>
          }

          {isCompanyMatrixModalOpen && matrixpk === null && 
          <Fragment>
          <button
            className="button is-success is-small"
            style={{borderRadius:"4px"}}
            key="save"
            
            onClick={async () => {
              await dispatch(createCompanymatrix({importance:importance, description:description, category:categorymodalkey }))
              .unwrap()
              dispatch(fetchAllCompanyMatrices())
              dispatch(closeMatrixModal());
          }}
          >
            Save changes
          </button>
          </Fragment>
          }

          {!isCompanyMatrixModalOpen && matrixpk !== null && 
          <Fragment>
          <button
            className="button is-success is-small"
            style={{borderRadius:"4px"}}
            key="save"
            
            onClick={async () => {
              await dispatch(updateMatrix({id:matrixpk,importance:importance, description:description, category:categorymodalkey, stakeholder:stakeholdermodalkey }))
              .unwrap()
              dispatch(fetchAllMatrices());
              dispatch(closeMatrixModal());
          }}
          >
            Save changes
          </button>
          </Fragment>
          }


          {!isCompanyMatrixModalOpen && matrixpk === null && 
          <Fragment>
          <button
            className="button is-success is-small"
            style={{borderRadius:"4px"}}
            key="save"
            
            onClick={async () => {
              await dispatch(createMatrix({importance:importance, description:description, category:categorymodalkey, stakeholder:stakeholdermodalkey }))
              .unwrap()
              dispatch(fetchAllMatrices());
              dispatch(closeMatrixModal());
          }}
          >
            Save changes
          </button>
          </Fragment>
          }



          <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeMatrixModal())}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}

export default MatrixModal;
