import React, { useEffect, useState } from "react";
import "../common/css/masterstyles.css";
import { useDispatch, useSelector } from "react-redux";
import { usersActions } from "../../redux/actions/users";
import { fetchCurrentUser } from "../../redux/toolkit/features/tenants/currentuser";
import { updateUser } from "../../redux/toolkit/features/tenants/currentuser";
import Moment from "react-moment";
import { fetchAllUsers, updateUsers } from "../../redux/toolkit/features/tenants/users";
import { fetchAllTenants } from "../../redux/toolkit/features/tenants";

function UserDetailForm(props) {
  const {user} = props;
  const currentTenant = Object.values(useSelector((state) =>  state.tenants.entities))[0];
  const currentUser = Object.values(useSelector((state) =>  state.currentuser.entities));
  //const currentUser = useSelector((state) => state.users.currentUser);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const [emaildomain, setEmaildomain] = useState("");
  const [emailname, setEmailname] = useState("")
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [role, setRole] = useState(0)
  const [isactive, setIsActive] = useState(true)

  console.log("USERS", user, currentUser)

  useEffect(() => {
    dispatch(fetchCurrentUser());
    dispatch(fetchAllTenants());
}, []);

  const userError = useSelector((state) => state.users.error);
  const [passwordData, setPasswordData] = useState({
    password: "",
    repeat: "",
  });

  const [passwordError, setPasswordError] = useState("");
  const [saved, setSaved] = useState(false);
  const dispatch = useDispatch();



  useEffect(() => {
     setEmaildomain(currentTenant? currentTenant.emaildomain:"")
   }, [currentTenant]);

   useEffect(() => {
    console.log("USERss", user)
    if (user) {
    setEmailname(user.email?(user.email).substring(0, user.email.indexOf("@")):user.email)
    setFirstname(user.first_name?user.first_name:"")
    setLastname(user.last_name?user.last_name:"")
    setRole(user.role?user.role:0)
    setIsActive(user.is_active?user.is_active:0)
    }
  }, [user]);


  const handleOnChangePassword = (event) => {
    const newPasswordData = {
      ...passwordData,
      [event.target.name]: event.target.value,
    };
    setPasswordData(newPasswordData);
  };

  const onSave = async () => {
    await dispatch(updateUsers({id:user.id,first_name:firstname, last_name:lastname,email:emailname+'@'+emaildomain,username:emailname+'@'+emaildomain, is_active:isactive, role:role }))
    .unwrap()
    dispatch(fetchAllUsers())
    setSaved(true);
  };

  const onChangePassword = () => {
    if (passwordData.password !== passwordData.repeat) {
      setPasswordError("Passwords must match.");
      return;
    }
    if (passwordData.password.trim() === "") {
      setPasswordError("Password can't be empty.");
      return;
    }
    setPasswordError("");
    dispatch(usersActions.changePassword(props.user.id, passwordData));
    setSaved(true);
  };

  // an admin user can change other users role.
  // an user can't change his/her own role
  const canChangePermissions = currentUser
    ? currentUser.role === 2 && props.user.id !== currentUser.id
    : false;

  // if (!user) {
  //   return null;

  // }

  return (
    <>
      <>
        <h3 className="title is-5">
          {props.user.email}<br/>
          <span className="subtitle is-7">
            Joined on{" "}
            <Moment format="MMMM DD, YYYY" tz="America/Los_Angeles">
              {user.date_joined}
            </Moment>
          </span>
        </h3>
        <div className="field">
          <label className="label">First Name</label>
          <div className="control">
            <input
              name={"first_name"}
              className="input"
              type="text"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              />
          </div>
        </div>

        <div className="field">
          <label className="label">Last Name</label>
          <div className="control">
            <input
              name={"last_name"}
              className="input"
              type="text"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              />
          </div>
        </div>

        <label className="label">Email</label>
        <div className="field has-addons">
          <div className="control is-expanded has-icons-left has-icons-right">
            <input
              name={"email"}
              className="input"
              type="email"
              value={emailname}
              onChange={(e) => setEmailname(e.target.value)}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope"> </i>
            </span>
          </div>
          <div className="control">
    <a className="button is-static">
      @{emaildomain}
    </a>
  </div>
        </div>


        {canChangePermissions && (
          <div className="field">
            <label className="label">Role</label>
            <div className="control">
              <div className="select">
                <select
                  value={role}
                  name={"role"}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value={0}>Read only</option>
                  <option value={1}>Read and write</option>
                  <option value={2}>Administration rights</option>
                </select>
              </div>
            </div>
          </div>
        )}

        {canChangePermissions && (
          <div className="field">
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={isactive}
                  name={"is_active"}
                  onChange={(e) => setIsActive(e.target.checked)}
                />{" "}
                Active
              </label>
            </div>
          </div>
        )}
        <div className="field is-grouped">
          <div className="control">
            <button className="button" style={{backgroundColor: backgroundcolor, color: textcolor}} onClick={onSave}>
              Save
            </button>
          </div>
        </div>
        {userError && <div className="notification is-danger">{userError}</div>}

        {saved && !userError && (
          <div className="notification is-success">
            <button className="delete" onClick={() => setSaved(false)}>
              {" "}
            </button>
            Data saved
          </div>
        )}
      </>
      <hr />
      <div>
        <h1 className="title">Change Password</h1>
        <div className="field">
          <label className="label">New Password</label>
          <p className="control has-icons-left">
            <input
              className="input"
              type="password"
              name={"password"}
              placeholder="New password"
              value={passwordData.password}
              onChange={handleOnChangePassword}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-lock"> </i>
            </span>
          </p>
        </div>
        <div className="field">
          <label className="label">Repeat New Password</label>
          <p className="control has-icons-left">
            <input
              className="input"
              type="password"
              name={"repeat"}
              placeholder="Repeat new password"
              value={passwordData.repeat}
              onChange={handleOnChangePassword}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-lock"> </i>
            </span>
          </p>
          {passwordError !== "" && (
            <p className="help is-danger">{passwordError}</p>
          )}
        </div>
        <div className="field is-grouped">
          <div className="control">
            <button className="button" style={{backgroundColor: backgroundcolor, color: textcolor}} onClick={onChangePassword}>
              Change Password
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export { UserDetailForm };
