import api from "../../api/company/values";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const valuesAdapter = createEntityAdapter({
  selectId: (value) => value.pk,
  sortComparer: (a, b) => a.value.localeCompare(b.value),
});

const initialState = valuesAdapter.getInitialState({status: "idle"});

export const fetchAllValues = createAsyncThunk("values/fetch", () => api.fetchAllValues());

export const updateValues = createAsyncThunk("values/update", api.updateValues);

export const createValues = createAsyncThunk("values/create", api.createValues);

export const deleteValues = createAsyncThunk("values/delete", api.deleteValues);

const valuesSlice = createSlice({
  name: "values",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllValues.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllValues.fulfilled, (state, { payload }) => {
        valuesAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateValues.fulfilled, valuesAdapter.updateOne)
      .addCase(deleteValues.fulfilled, valuesAdapter.removeOne)
      .addCase(createValues.fulfilled, valuesAdapter.addOne);
  },
});

export const {} = valuesSlice.actions;

export default valuesSlice.reducer;

const selectState = (state) => state.values;

export const {
  selectAll: selectValues,
  selectById: selectValueById,
} = valuesAdapter.getSelectors(selectState);

export const selectValuesStatus = createSelector(
  selectState,
  (state) => state.status
);
