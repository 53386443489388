import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchCurrentUser = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/users/current`,
    authConfig()
  );
  console.log("CurrentUSer API", response.data)
  return response.data;
};


const currentUserAPI = {
  fetchCurrentUser,
};
export default currentUserAPI;
