import { createSlice } from "@reduxjs/toolkit";

const initialState = { menu: 'company'};

const modalSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenu(state, action) {
      state.menu = action.payload;
    },
  },
});

export const {
  setMenu
} = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.menu;

