import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { closeBaselineAlertModal } from "./../../../redux/toolkit/features/success_state";
import { selectRowById, fetchAllRows, updateRows } from "./../../../redux/toolkit/features/roadmaprows";

function SuccessBaselineModal() {
    const showBaselineModal = useSelector((state) => state.success_state.isBaselineModalOpen)
    const rowId = useSelector((state) => state.success_state.baselinerowId)
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const [moderate, setModerate] = useState(null);
    const [severe, setSevere] = useState(null); 
    const [status, setStatus] = useState(null); 
    const [direction, setDirection] = useState("Less Than"); 
    const rowbyid = useSelector((state)=>selectRowById(state, rowId));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllRows())
        setModerate(Math.floor( rowbyid?rowbyid.baselinealertmoderate:100));
        setSevere(Math.floor( rowbyid?rowbyid.baselinealertsevere:90));
        setStatus(rowbyid?rowbyid.baselinestatus:true);
        setDirection(rowbyid?rowbyid.baselinealertdirection:"Less Than")
      }, [rowId,showBaselineModal ])    

    let modalClass = 'modal';
    if (showBaselineModal) {
        modalClass += ' is-active';
    }

    if(direction === null) {
        setDirection("Less Than")
    }

    function renderModal() {

        return (
            <Fragment>

                <div className="mb-3">
                <label className="checkbox">
                    <input type="checkbox" checked={status} onChange={(e) => setStatus(e.target.checked)}/>
                        {"  "}Show Baseline Alerts
                </label>
                </div>


                <p className="mb-2" style={{fontSize:'11px', fontWeight: '600'}}>MODERATE ALERT THRESHOLD</p>
                <div className="mb-4">
                    <div className="field is-horizontal is-align-items-center">        
                        <div className="field-body is-flex">
                            <select className="mr-2" defaultValue="Less Than" value={direction} onChange={(e) => setDirection(e.target.value)}>
                                <option value="Less Than">Less Than</option>
                                <option value="Greater Than">Greater Than</option>
                            </select>
                            <div className="field">
                                <p className="control has-icons-right">
                                {direction==='Greater Than' && 
                                    <input className="input" type="number" min="100" max="200" step="1" id="myPercent" value={moderate} onChange={(e) => setModerate(e.target.value)}/>}
                                {(direction==='Less Than' || direction===null) && 
                                    <input className="input" type="number" min="0" max="100" step="1" id="myPercent" value={moderate} onChange={(e) => setModerate(e.target.value)}/>}
                                    <span className="icon is-small is-right">
                                        <i className="fas fa-percent" style={{fontSize: '0.73em'}}></i>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>  
                </div>

                <p className="mb-2" style={{fontSize:'11px', fontWeight: '600'}}>SEVERE ALERT THRESHOLD</p>
                <div className="mb-4">
                    <div className="field is-horizontal is-align-items-center">        
                        <div className="field-body is-flex">
                            <select className="mr-2" defaultValue="Less Than" value={direction} onChange={(e) => setDirection(e.target.value)}>
                            <option value="Less Than">Less Than</option>
                            <option value="Greater Than">Greater Than</option>
                            </select>

                            <div className="field">
                                <p className="control has-icons-right">
                                    {direction==='Greater Than' && 
                                    <input className="input" type="number" min="100" max="200" step="1" id="myPercent" value={severe} onChange={(e) => setSevere(e.target.value)}/>}
                                    {(direction==='Less Than' || direction===null) && 
                                    <input className="input" type="number" min="0" max="100" step="1" id="myPercent" value={severe} onChange={(e) => setSevere(e.target.value)}/>}
                                    <span className="icon is-small is-right">
                                        <i className="fas fa-percent" style={{fontSize: '0.73em'}}></i>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>  
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card' style={{width:'360px'}} key='modalCard'>
                    <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className='modal-card-title' style={{fontSize:"17px", color: textcolor}}>SET BASELINE ALERT</p>
                        <button className='delete' aria-label='close' onClick={() => dispatch(closeBaselineAlertModal())}>
                            {' '}
                        </button>
                    </header>
                    <section className='modal-card-body' key='modalBody' style={{minHeight: '130px'}}>                  
                        {renderModal()}
                    </section>
                    <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>
                        <button
                            style={{borderRadius:"4px" }}
                            className="button is-small is-success"
                            key="new"
                            onClick={async () => {
                                dispatch(updateRows({id: rowbyid.pk,name: rowbyid.name, section:rowbyid.section, baselinealertmoderate: moderate, baselinealertsevere: severe, baselinealertdirection: direction, baselinestatus: status  }))
                                dispatch(fetchAllRows())
                                dispatch(closeBaselineAlertModal());
                            }}
                        >
                            Save
                        </button>
                        <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => dispatch(closeBaselineAlertModal())}>
                            Cancel
                        </button>

                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SuccessBaselineModal;
