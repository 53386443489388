import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllRows = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-rows`,
    authConfig()
  );
  return response.data.results;
};

export const fetchRows = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-rows/${id}`,
    authConfig()
  );
  return response.data;
};

export const createRows = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roadmap-rows`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateRows = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/roadmap-rows/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteRows = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/roadmap-rows/${id}`,
    authConfig()
  );
  return response.data;
};

export const orderRows = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roadmap-rows/sort-rows`,
    data,
    authConfig()
  );
  return response.data;
};


const roadmaprowsAPI = {
  fetchAllRows,
  fetchRows,
  createRows,
  updateRows,
  deleteRows,
  orderRows
};
export default roadmaprowsAPI;
