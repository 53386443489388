import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTenants, updateTenants } from "../../redux/toolkit/features/tenants";
import { fetchAllFX, deleteFX } from "../../redux/toolkit/features/tenants/fx";
import { fetchAllFXRow, updateFXRow, createFXRow } from "../../redux/toolkit/features/tenants/fxrow";
import {filter} from "lodash"
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';


function FXFormDetailCell(props) {
  const {rowPK, cellPK, editmode, fxvalue, pair, year} = props;
  const tenant = Object.values(useSelector((state) =>  state.tenants.entities))[0];
  const fxrow = Object.values(useSelector((state) =>  state.fxrow.entities));
  const [fxrate, setFXRate] = useState(null)
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const dispatch = useDispatch();

  console.log("Cell Data", pair, year,rowPK,cellPK )

  useEffect(() => {
    dispatch(fetchAllFX());
    dispatch(fetchAllFXRow());
    dispatch(fetchAllTenants());
  }, []);


  if (!tenant) {
    return null;
  }


  async function UpdateFXRates(e) {
    console.log("Cell Data", pair, year,rowPK,cellPK, e.target.value )
    if (e.keyCode === 13){
      if (cellPK!==null) {
        await dispatch(updateFXRow({id:cellPK,rate:e.target.value, pair:rowPK}))
        .unwrap()
        dispatch(fetchAllFXRow());
      }
    
      if (cellPK===null) {
        await dispatch(createFXRow({rate:e.target.value, pair:rowPK, year: year }))
        .unwrap()
        dispatch(fetchAllFXRow());
      }
    }
  }



  return (
    <Fragment>
          <td className="has-text-centered">
            <NumericTextBoxComponent format="n5" decimals={5} style={{border: "1px solid", borderColor:backgroundcolor}} validateDecimalOnType={true} showSpinButton={false} value={fxvalue} onKeyDown={(e) => UpdateFXRates(e)}/>
          </td>
      </Fragment>

      
  );

}

export { FXFormDetailCell };
