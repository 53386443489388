import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllCharts = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-chart`,
    authConfig()
  );
  return response.data.results;
};

export const fetchCharts = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-chart/${id}`,
    authConfig()
  );
  return response.data;
};

export const createCharts = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roadmap-chart`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateCharts = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/roadmap-chart/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteCharts = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/roadmap-chart/${id}`,
    authConfig()
  );
  return response.data;
};

const roadmapchartAPI = {
  fetchAllCharts,
  fetchCharts,
  createCharts,
  updateCharts,
  deleteCharts,
};
export default roadmapchartAPI;
