import api from "../../api/success/successroadmaplinks";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const roadmaplinksAdapter = createEntityAdapter({
  selectId: (roadmaplinks) => roadmaplinks.pk,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = roadmaplinksAdapter.getInitialState({
  status: "idle"
});

export const fetchAllRoadmaplinks = createAsyncThunk("roadmaplinks/fetch", () =>
  api.fetchAllRoadmaplinks()
);

export const updateRoadmaplinks = createAsyncThunk("roadmaplinks/update", api.updateRoadmaplinks);

export const createRoadmaplinks = createAsyncThunk("roadmaplinks/create", api.createRoadmaplinks);

export const deleteRoadmaplinks = createAsyncThunk("roadmaplinks/delete", api.deleteRoadmaplinks);


const roadmaplinksSlice = createSlice({
  name: "success",
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRoadmaplinks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRoadmaplinks.fulfilled, (state, { payload }) => {
        roadmaplinksAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateRoadmaplinks.fulfilled, roadmaplinksAdapter.updateOne)
      .addCase(deleteRoadmaplinks.fulfilled, roadmaplinksAdapter.removeOne)
      .addCase(createRoadmaplinks.fulfilled, roadmaplinksAdapter.addOne);
  },
});

export const {} = roadmaplinksSlice.actions;

export default roadmaplinksSlice.reducer;

const selectState = (state) => state.roadmaplinks;

export const {
  selectAll: selectRoadmaplinks,
  selectById: selectRoadmaplinkById,
} = roadmaplinksAdapter.getSelectors(selectState);

export const selectRoadmaplinksStatus = createSelector(
  selectState,
  (state) => state.status
);

