import api from "../../api/innovation/ideas";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const ideasAdapter = createEntityAdapter({
  selectId: (idea) => idea.pk,
  sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = ideasAdapter.getInitialState({status: "idle"});

export const fetchAllIdeas = createAsyncThunk("ideas/fetch", () =>
  api.fetchAllIdeas()
);

export const updateIdeas = createAsyncThunk("ideas/update", api.updateIdeas);

export const createIdeas = createAsyncThunk("ideas/create", api.createIdeas);

export const deleteIdeas = createAsyncThunk("ideas/delete", api.deleteIdeas);

const ideasSlice = createSlice({
  name: "ideas",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllIdeas.pending, (state) => {state.status = "loading"})
      .addCase(fetchAllIdeas.fulfilled, (state, { payload }) => {
        ideasAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateIdeas.fulfilled, ideasAdapter.updateOne)
      .addCase(deleteIdeas.fulfilled, ideasAdapter.removeOne)
      .addCase(createIdeas.fulfilled, ideasAdapter.addOne);
  },
});

export const {} = ideasSlice.actions;

export default ideasSlice.reducer;

const selectState = (state) => state.ideas;

export const {
  selectAll: selectIdeas,
  selectById: selectIdeaById,
} = ideasAdapter.getSelectors(selectState);

export const selectIdeasStatus = createSelector(
  selectState,
  (state) => state.status
);
