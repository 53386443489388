import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import "bulma/css/bulma.css";
import { closeCompanyPurposeModal } from "./../../../redux/toolkit/features/company_state"
import { updateCompany, fetchAllCompany } from "../../../redux/toolkit/features/company/company";


function CompanyPurposeModal(props) {
  const [purpose, setPurpose] = useState(props.company.purpose);
  const [statement, setStatement] = useState(props.company.statement);
  const isCompanyPurposeModalOpen = useSelector((state) => state.company_state.isCompanyPurposeModalOpen);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
  const textcolor = useSelector((state) => state.colors.textcolor);
  const dispatch = useDispatch();

  let modalClass = "modal";
  if (isCompanyPurposeModalOpen) {
    modalClass += " is-active";
  }

  function renderForm() {
    return (
        <Fragment>
          <p className="mb-2" style={{fontSize:"smaller", fontWeight:"600"}}>Company Story Title</p>
          <div className="field is-horizontal is-align-items-center">
            <div className="field-body is-flex">
              <div className="control is-flex-grow-1">
                <input
                    className="input"
                    type="text"
                    placeholder="Title"
                    value={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                />
              </div>
            </div>
          </div>

          <p className="mb-2" style={{fontSize:"smaller", fontWeight:"600"}}>Company Story</p>
          <div className="field is-horizontal is-align-items-center">
            <div className="field-body is-flex">
              <div className="control is-flex-grow-1">
              <textarea
                  className="textarea"
                  rows="11"
                  placeholder="Story"
                  value={statement}
                  onChange={(e) => setStatement(e.target.value)}
              />
              </div>
            </div>
          </div>
        </Fragment>
    );
  }

  return (
      <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
          <div className="modal-background" key="modalBackground">
            {" "}
          </div>
          <div className="modal-card" key="modalCard">
            <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
              <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>COMPANY STORY</p>
              <button className="delete" aria-label="close" onClick={() => dispatch(closeCompanyPurposeModal())}>
                {" "}
              </button>
            </header>
            <section className="modal-card-body" key="modalBody" style={{minHeight: '200px'}}>
              {renderForm()}
            </section>
            <footer className="modal-card-foot is-flex" key="modalFooter"  style={{height:"60px"}}>
              <button
                  style={{borderRadius:"4px"}}
                  className="button is-success is-small"
                  key="save"
                  onClick={async () => {
                    await dispatch(updateCompany({pk:props.company.pk, purpose:purpose, statement: statement}))
                    .unwrap()
                      dispatch(closeCompanyPurposeModal());
                      dispatch(fetchAllCompany());
                  }}
              >
                Save changes
              </button>
              <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeCompanyPurposeModal())}>
                Cancel
              </button>
            </footer>
          </div>
        </div>
      </Fragment>
  );
}

export default CompanyPurposeModal;
