import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {filter} from "lodash"
import { selectIdeaById } from "./../../redux/toolkit/features/innovation/ideas";
import { openIdeasModal, openIdeaSprintModal } from "./../../redux/toolkit/features/innovation_state";
import { openSprintModal } from "./../../redux/toolkit/features/sprints_state";
import { openDeleteModal } from "./../../redux/toolkit/features/modals/deleteConfirmModal";
import { fetchAllIdeas } from "./../../redux/toolkit/features/innovation/ideas";
import { fetchAllActionLink } from "./../../redux/toolkit/features/innovation/actionlink";
import { FormatString } from "../common/FormatCell"
import SprintModal from "./../../components/sprints/modals/SprintModal";

function IdeaRow(props) {
  const {themePK, index, decimals, Checkall, ideacheckedhistory} = props;
  const actionlink = Object.values(useSelector((state) => state.actionlink.entities));
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const idea = useSelector((state)=>selectIdeaById(state, index))  
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchAllActionLink());
  }, []);

  useEffect(() => {
    let status=(filter(ideacheckedhistory,{pk:index}))[0]?(filter(ideacheckedhistory,{pk:index}))[0].status:false
    if (status===true) {
      setIsChecked(true);
    } 
    if (status===false) {
      setIsChecked(false);
    }
  }, [ideacheckedhistory]);
   

  useEffect(() => {
    async function getIdeas() {
      dispatch(fetchAllIdeas())
      dispatch(fetchAllActionLink())
    }
    getIdeas();
  },[]);

  if (!idea || !index) {
    return null;
  }


  var actioncount = (filter(actionlink, {idea_id:idea.pk})).length
  var filteredsprints=(filter(actionlink, {idea_id:idea.pk}))
  var sprintPK = (filteredsprints[0]? filteredsprints[0].sprint_id: null)
  return (

      <Fragment>
        <tr key={'row_'+index}>
          <td><input type="checkbox" id="vehicle1" name="vehicle1" value={isChecked} checked={isChecked}  
            onChange={(e) => {
              Checkall(e.target.checked, idea.pk);
              setIsChecked(e.target.checked);
            }} />
          </td>
          {!isDetailVisible &&
          <td onClick={() => setIsDetailVisible(true)}><span style={{fontWeight:500}}><span className="pr-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}}><button className="far fa-plus-square" ></button></span>{idea.title}</span></td>
          }
          {isDetailVisible &&
          <td onClick={() => setIsDetailVisible(false)}><span style={{fontWeight:500}}><span className="pr-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}}><button className="far fa-minus-square"></button></span>{idea.title}</span><br/><p className="pl-5" style={{fontSize:'14.5px'}}>{idea.comment}</p></td>
          }
          <td style={{textAlign: "center"}}>{idea.impact!==null?FormatString(idea.impact, 'Number', decimals):'No Value'}</td>
          <td style={{textAlign: "center"}}>{idea.cost!==null?idea.cost.toLocaleString():'No Value'}</td>

          {actioncount!==0 && <td style={{textAlign: "center"}}><button className="button is-small" style={{color:backgroundcolor, borderColor: backgroundcolor, width: '70px'}} onClick={() => dispatch(openIdeaSprintModal({sprintPK:sprintPK}))}>Actioned</button></td>}
          {actioncount===0 && <td style={{textAlign: "center"}}><button className="button is-small" style={{width: '70px'}} onClick={() => dispatch(openSprintModal({sprintPK:null, ideaPK:idea.pk}))}  >Action</button></td>}
          
          <td onClick={() => dispatch(openIdeasModal({ideaPK:idea.pk, themeModalPK:themePK}))} className="pl-0 pr-0"><i className="far fa-edit mt-1" style={{color:"gray"}}/></td>
          <td onClick={() => dispatch(openDeleteModal({message: idea.title,recordpk: idea.pk, recordtype: 'ideas'}))}><i className="far fa-trash-alt mt-1" style={{color:"gray"}}/></td>
        </tr>


     <SprintModal/>
    </Fragment>
    )
  }
export { IdeaRow };

