import api from "../api/roadmapcharts";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const roadmapchartsAdapter = createEntityAdapter({
  selectId: (charts) => charts.pk,
  //sortComparer: (a, b) => a.section.localeCompare(b.section),
});

const initialState = roadmapchartsAdapter.getInitialState({
  status: "idle"
});

export const fetchAllCharts = createAsyncThunk("charts/fetch", () =>
  api.fetchAllCharts()
);

export const updateCharts = createAsyncThunk("charts/update", api.updateCharts);

export const createCharts = createAsyncThunk("charts/create", api.createCharts);

export const deleteCharts = createAsyncThunk("charts/delete", api.deleteCharts);

const roadmapchartsSlice = createSlice({
  name: "roadmapcharts",
  initialState,
  reducers: {
   },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCharts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCharts.fulfilled, (state, { payload }) => {
        roadmapchartsAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateCharts.fulfilled, roadmapchartsAdapter.updateOne)
      .addCase(deleteCharts.fulfilled, roadmapchartsAdapter.removeOne)
      .addCase(createCharts.fulfilled, roadmapchartsAdapter.addOne);
  },
});

export const { } = roadmapchartsSlice.actions;

export default roadmapchartsSlice.reducer;

const selectState = (state) => state.roadmapcharts;

export const {
  selectAll: selectroadmapcharts,
  selectById: selectChartById,
} = roadmapchartsAdapter.getSelectors(selectState);

export const selectChartsStatus = createSelector(
  selectState,
  (state) => state.status
);
