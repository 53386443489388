import api from "../../api/organization/competencyscores"
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const competencyscoresAdapter = createEntityAdapter({
  selectId: (competencyscore) => competencyscore.pk,
  //sortComparer: (a, b) => a.role.localeCompare(b.role),
});

const initialState = competencyscoresAdapter.getInitialState({
    status: "idle"
});

export const fetchAllCompetencyScores = createAsyncThunk("competencyscores/fetch", () =>
  api.fetchAllCompetencyScores()
);

export const updateCompetencyScores = createAsyncThunk("competencyscores/update", api.updateCompetencyScores);

export const createCompetencyScores = createAsyncThunk("competencyscores/create", api.createCompetencyScores);

export const deleteCompetencyScores = createAsyncThunk("competencyscores/delete", api.deleteCompetencyScores);


const competencyscoresSlice = createSlice({
  name: "competencyscores",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCompetencyScores.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCompetencyScores.fulfilled, (state, { payload }) => {
        competencyscoresAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateCompetencyScores.fulfilled, competencyscoresAdapter.updateOne)
      .addCase(deleteCompetencyScores.fulfilled, competencyscoresAdapter.removeOne)
      .addCase(createCompetencyScores.fulfilled, competencyscoresAdapter.addOne);
  },
});

export const { } = competencyscoresSlice.actions;

export default competencyscoresSlice.reducer;

const selectState = (state) => state.competencyscores;

export const {
  selectAll: selectCompetencyScores,
  selectById: selectCompetencyScoreById,
} = competencyscoresAdapter.getSelectors(selectState);

export const selectCompetencyScoresStatus = createSelector(
  selectState,
  (state) => state.status
);
