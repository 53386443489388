import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllIdeas = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/ideas`,
    authConfig()
  );
  return response.data;
};

export const fetchIdeas = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/ideas/${id}`,
    authConfig()
  );
  return response.data;
};

export const createIdeas = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/ideas`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateIdeas = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/ideas/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteIdeas = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/ideas/${id}`,
    authConfig()
  );
  return response.data;
};

const ideasAPI = {
  fetchAllIdeas,
  fetchIdeas,
  createIdeas,
  updateIdeas,
  deleteIdeas,
};
export default ideasAPI;
