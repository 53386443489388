import api from "../../api/tenants/currentuser";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const currentUserAdapter = createEntityAdapter({
  selectId: (currentUser) => currentUser.pk,
});

const initialState = currentUserAdapter.getInitialState({status: "idle"});

export const fetchCurrentUser = createAsyncThunk("currentuser/fetch", () => api.fetchCurrentUser());

const currentUserSlice = createSlice({
  name: "currentuser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCurrentUser.fulfilled, (state, { payload }) => {
        currentUserAdapter.setOne(state, payload);
        state.currentUser = payload;
        state.status = "idle";
      })
  },
});

export const {} = currentUserSlice.actions;

export default currentUserSlice.reducer;

const selectState = (state) => state.currentuser;

export const selectCurrentUserStatus = createSelector(
  selectState,
  (state) => state.status
);
