import React, {Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { openPeopleModal, fetchAllPersons} from "./../../redux/toolkit/features/organization/persons";
import { openDeleteModal } from "./../../redux/toolkit/features/modals/deleteConfirmModal";
import {filter} from "lodash"
import { fetchAllRoles } from "../../redux/toolkit/features/roles";
import { fetchAllPersonsview } from "../../redux/toolkit/features/organization/personview";
import { fetchAllBusinessunit } from "../../redux/toolkit/features/organization/businessunit";

function People() {
    const people = Object.values(useSelector((state) => state.personsview.entities));
    // const roles = Object.values(useSelector((state) => state.roles.entities));
    // const positionperson = Object.values(useSelector((state) => state.positionperson.entities));
    // const businessunit = Object.values(useSelector((state) => state.businessunit.entities));
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const [textfilter, setfilterquery] = useState('');
    var [filteredPeople] = [];
    const dispatch = useDispatch(); 
  
    useEffect(() => {
      // dispatch(fetchAllRoles());
      dispatch(fetchAllPersonsview());
      // dispatch(fetchAllPositionperson());
      // dispatch(fetchAllBusinessunit());
    },[]);


    filteredPeople = people.filter((person) => {
        let fullname= person.firstname!==null?person.firstname:"" + " " + person.secondname!==null?person.secondname:""
        if (fullname.toLowerCase().includes(textfilter.toLowerCase()) || (person.secondname!==null?person.secondname:"").toLowerCase().includes(textfilter.toLowerCase()) || (person.title!==null?person.title:"")?.toLowerCase().includes(textfilter.toLowerCase()) || (person.businessunit!==null?person.businessunit:"").toLowerCase().includes(textfilter.toLowerCase())){
          return true;
        }
        return filteredPeople;
      })   

    function handleChangeFilter(event) {
        setfilterquery(event.target.value);
    }

    function peoplelist(peoplerow){
      console.log("PR",peoplerow.pk)
      if (peoplerow.pk===null) {
        return null
      }
      return (
        <tr>
        <td className=" pl-0" style={{cursor:'pointer', whiteSpace:'nowrap', minWidth:'180px'}} onClick={() => dispatch(openPeopleModal(peoplerow.person_id))}>{peoplerow.firstname} {peoplerow.secondname}</td>
        <td className=" pl-0" style={{cursor:'pointer', textAlign:'center', whiteSpace:'nowrap', minWidth:'180px'}} onClick={() => dispatch(openPeopleModal(peoplerow.person_id))}>{peoplerow.title!==null?peoplerow.title:"-"}</td>
        <td className=" pl-0" style={{cursor:'pointer', textAlign:'center', whiteSpace:'nowrap', minWidth:'180px'}} onClick={() => dispatch(openPeopleModal(peoplerow.person_id))}>{peoplerow.businessunit!==null?peoplerow.businessunit:"-"}</td>
        <td className=" pl-0" style={{cursor:'pointer', width:'100%'}} onClick={() => dispatch(openPeopleModal(peoplerow.person_id))}></td>
        <td><a onClick={async () => {
                        dispatch(openDeleteModal({message: (peoplerow.firstname + " " + peoplerow.secondname),recordpk: peoplerow.pk, recordtype: 'people'}));
                        }}><i className="far fa-trash-alt mt-1" style={{color:"gray"}}/></a></td>
        </tr>
      )
    }

    if (!people) {
      return null
    }

    return (
        <Fragment>
          <section className="mb-6">
              <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "20px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        PEOPLE DIRECTORY  ({filteredPeople.length})<br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="level-right">
                            <div className="level-item pt-4 pr-0">
                                <div className="field has-addons">
                                    <div className="control is-rounded is-small" style={{width: "140px",userSelect: "none"}}>
                                        <span> <input  onChange={handleChangeFilter} className="input is-rounded is-small"  type="search" placeholder="Filter" style={{ color: "rgb(128,128,128)",userSelect: "none"}}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    
                    <hr className="pt-0 mt-0 pb-0 mb-0" style={{borderTop: "2px solid lightgrey"}} />
              <table className="table is-fullwidth">
                <tr>
                  <th className="pl-0" style={{whiteSpace:'nowrap'}}>Name</th>
                  <th className="pl-0" style={{whiteSpace:'nowrap', textAlign:'center'}}>Current Role</th>
                  <th className="pl-0" style={{whiteSpace:'nowrap', textAlign:'center'}}>Function</th>
                  <th className="pl-0"></th>
                  <th style={{width:"8px"}} ><a onClick={() => dispatch(openPeopleModal(null))}><button style={{color: backgroundcolor}} className="fas fa-plus-circle"></button></a></th>
                </tr>

              {filteredPeople.length!==0 && (filteredPeople.sort((a, b) => (a.firstname.localeCompare(b.firstname)))).map(row => (
                peoplelist(row)
              ))}

              {filteredPeople.length===0  && 
                <Fragment> 
                    <p className="pt-4" style={{fontWeight:'500'}}>No People Found</p>
                </Fragment>
              }
              </table>
                


            </section>
        </Fragment>
    )
}

export {People};