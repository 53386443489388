import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllCompetencies = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/talent/competencies`,
    authConfig()
  );
  return response.data;
};

export const fetchCompetencies = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/talent/competencies/${id}`,
    authConfig()
  );
  return response.data;
};

export const createCompetencies = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/talent/competencies`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateCompetencies = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/talent/competencies/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteCompetencies = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/talent/competencies/${id}`,
    authConfig()
  );
  return response.data;
};

const competenciesAPI = {
  fetchAllCompetencies,
  fetchCompetencies,
  createCompetencies,
  updateCompetencies,
  deleteCompetencies,
};
export default competenciesAPI;
