import React, { useEffect, Fragment, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import "./common/css/masterstyles.css";
import { Table } from "./investment/Table";
//import { roadmapsService } from "../services/RoadmapsService";
import { menuActions } from "../redux/actions/menu";
import { setMenu } from "../redux/toolkit/features/menu";
import {fetchAllSprints, deleteSprints} from "./../redux/toolkit/features/sprints/sprints";
import { fetchAllSprintinvestment } from "../../src/redux/toolkit/features/sprints/sprintinvestment";
import { fetchAllSprintinvestmentcell } from "../../src/redux/toolkit/features/sprints/sprintinvestmentcell";
import { SprintRows } from "./investment/SprintRows";
import {filter} from "lodash"

function Investments() {
  const sprints = Object.values(useSelector((state) => state.sprints.entities));
  const sprintinvestment = Object.values(useSelector((state) => state.sprintinvestment.entities));
  const sprintinvestmentcell = Object.values(useSelector((state) => state.sprintinvestmentcell.entities));
  const roadmap = Object.values(useSelector((state) => state.roadmap.entities));
  const [textfilter, setTextFilter] = useState("");
  const [minYear, setMinYear] = useState(null);
  const [maxYear, setMaxYear] = useState(null);
  const dispatch = useDispatch();
  dispatch(setMenu('investments'));

  var filteredsprints=sprints.filter(sprint => sprint.budget==='No');
  var orderedsprints = filteredsprints.sort((a, b) => {
    const orders = { 'Suspended': 0, 'Completed': 1, 'In Progress': 3, 'Not Started': 4 };
    return orders[a.action_status] - orders[b.action_status];
  });
  var investmentsprints = orderedsprints.filter((sprint) => {
    if (sprint.title.toLowerCase().includes(textfilter.toLowerCase()) || sprint.action_status.toLowerCase().includes(textfilter.toLowerCase())){
      return true;
    }
    return investmentsprints;
  })


  useEffect(() => {
    async function fetchData() {
      dispatch(fetchAllSprintinvestment());
        dispatch(fetchAllSprintinvestmentcell());
        dispatch(fetchAllSprints());
    }
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(menuActions.setActiveMenu("investments"));
  }, [dispatch]);

  useEffect(() => {
    setMinYear(roadmap[0]?roadmap[0].min_year:(new Date().getFullYear()))
    setMaxYear(roadmap[0]?roadmap[0].max_year:(new Date().getFullYear()+5))
  }, [roadmap]);


  function renderYearHeaders() {
    const headers = [];
    for (let year = minYear; year <= maxYear; year++) {
        headers.push(<th className={"has-text-centered"} key={year}>{year}</th>);
    }
    return headers;
  }

  function columntotals() {
    var filteredsprintinvestment=(filter( sprintinvestment, (row) => (investmentsprints.map(r => r.pk)).includes(row.sprint))).map(r => r.pk) 
    var celllist = (filter( sprintinvestmentcell, (row) => filteredsprintinvestment.includes(row.sprintinvestment)))
    const totalinputs=[]
    var totalrows=0
    for (let year = minYear; year <= maxYear; year++) {
      let celllistyear=filter(celllist,{year:year})
      let sumrows=0;
      celllistyear.map((sprint) => {
        sumrows=sumrows+sprint.value
      })
      totalinputs.push(<th className="has-text-centered">{sumrows.toLocaleString()}</th>)
      totalrows = totalrows + sumrows
    }
      totalinputs.push(<th className="has-text-centered">{totalrows.toLocaleString()}</th>)
    
    return (
      totalinputs
    )
  }

  function sprintInvestments() {

    return (
      <Fragment>
        <section>
          <nav className="level pb-0 mb-0 pt-5">
                    <div className="level-left">
                        <div className="level-item">
                            <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "22px", lineHeight: "22px", paddingTop: "35px"}}>
                                <div className="pr-1">
                                    SPRINT INVESTMENTS  ({investmentsprints.length})<br/><br/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="level-right">
                        <div className="level-item pt-4 pr-0">
                            <div className="field has-addons">
                                <div className="control is-rounded is-small" style={{width: "140px",userSelect: "none"}}>
                                    <span> <input className="input is-rounded is-small" type="search" placeholder="Filter" value={textfilter} onChange={(e) => setTextFilter(e.target.value)} style={{ color: "rgb(128,128,128)",userSelect: "none"}}/></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </nav>
                    
        <hr className="pt-0 mt-0 pb-0 mb-0" style={{borderTop: "2px solid lightgrey"}} />

      <table className="table is-fullwidth">
        <tbody>
          <tr>
          <th colSpan='2'>Sprint</th>
          <th>Status</th>
          {renderYearHeaders()}
          <th style={{textAlign:'center'}}>Row Total</th>
          </tr>
          {investmentsprints.map((sprint) => (
              <SprintRows 
                sprint={sprint}
                minYear={minYear}
                maxYear={maxYear}
                sprintinvestment={sprintinvestment}
                sprintinvestmentcell={sprintinvestmentcell}/>
          ))}
          <tr>
            <th colSpan='2'>Totals</th>
            <th></th>
            {columntotals()}
          </tr>

        </tbody>
      </table>
      </section>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>
      <section style={{paddingLeft: "12%", paddingRight: "12%", marginTop: "2em"}}>
          <div>
              <div className="pr-1" style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "32px",lineHeight: "26px",paddingBottom: "28px"}}>
              INVESTMENTS
              </div>
              {sprintInvestments()}
          {/* <Table
            investments={sprintinvestment}
            investmentcells={sprintinvestmentcell}
            minYear={minYear}
            maxYear={maxYear}
            toggleModal={toggleEditInvestmentModal}
            sprintinvestment={sprintinvestment}
            sprintinvestmentcell={sprintinvestmentcell}
           /> */}
          </div>
        </section>
      </main>


    </Fragment>
  );
}
export { Investments };
