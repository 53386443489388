import { React, Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Container } from "./CompanyValues/Container";
import { fetchAllValues } from "./../../../redux/toolkit/features/company/values";
import { closeValuesModal, openValuesRecordModal } from "../../../redux/toolkit/features/company_state";
import { closeDeleteModal }  from "./../../../redux/toolkit/features/modals/deleteConfirmModal";


function CompanyValuesModal() {
    const values = Object.values(useSelector((state) => state.values.entities)).sort((a, b) => a.order - b.order);
    const isValuesModalOpen = useSelector((state) => state.company_state.isValuesModalOpen)
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch(); 


    let modalClass = "modal";
    if (isValuesModalOpen) {
        modalClass += " is-active";
    }

    async function getValues() {
        dispatch(fetchAllValues());
    }

    useEffect(() => {
        getValues();
    },[]);


    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>COMPANY VALUES</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeValuesModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: '200px'}}>
                    {values.length !== 0 &&
                    <Container values={values} />}
                    {values.length === 0 && 
                    <p className = "pt-5" style={{fontSize:"16.5px"}}>No Records Returned</p>}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <button
                            style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}}
                            className="button is-small"
                            key="new"
                            onClick={async () => {
                                dispatch(openValuesRecordModal({valuePK:null}));
                                dispatch(closeValuesModal());
                            }}
                        >
                            Add New
                        </button>
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeValuesModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>

        </Fragment>
    );
}

export default CompanyValuesModal;
