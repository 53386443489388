import api from "../../api/success/successyear";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const successyearAdapter = createEntityAdapter({
  selectId: (successyear) => successyear.pk,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = successyearAdapter.getInitialState({status: "idle"});

export const fetchAllSuccessyear = createAsyncThunk("successyear/fetch", () =>
  api.fetchAllSuccessyear()
);

export const updateSuccessyear = createAsyncThunk("successyear/update", api.updateSuccessyear);

export const createSuccessyear = createAsyncThunk("successyear/create", api.createSuccessyear);

export const deleteSuccessyear = createAsyncThunk("successyear/delete", api.deleteSuccessyear);


const successyearSlice = createSlice({
  name: "successyear",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSuccessyear.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllSuccessyear.fulfilled, (state, { payload }) => {
        successyearAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateSuccessyear.fulfilled, successyearAdapter.updateOne)
      .addCase(deleteSuccessyear.fulfilled, successyearAdapter.removeOne)
      .addCase(createSuccessyear.fulfilled, successyearAdapter.addOne);
  },
});

export const { } = successyearSlice.actions;

export default successyearSlice.reducer;

const selectState = (state) => state.successyear;

export const {
  selectAll: selectSuccessyear,
  selectById: selectSuccessyearById,
} = successyearAdapter.getSelectors(selectState);

export const selectSuccessyearStatus = createSelector(
  selectState,
  (state) => state.status
);

