import api from "../../api/organization/compensationindex";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const compensationindexAdapter = createEntityAdapter({
  selectId: (person) => person.pk,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = compensationindexAdapter.getInitialState({
  status: "idle", isOpen: false, peoplepk: null
});

export const fetchAllCompensationindex = createAsyncThunk("compensationindex/fetch", () => api.fetchAllCompensationindex());

export const updateCompensationindex = createAsyncThunk("compensationindex/update", api.updateCompensationindex);

export const createCompensationindex = createAsyncThunk("compensationindex/create", api.createCompensationindex);

export const deleteCompensationindex = createAsyncThunk("compensationindex/delete", api.deleteCompensationindex);

const compensationindexSlice = createSlice({
  name: "compensationindex",
  initialState,
  reducers: {
    openPeopleModal(state, action) {
      state.isOpen = true;
      state.peoplepk = action.payload;
    },
    closePeopleModal(state) {
      state.isOpen = false;
      state.peoplepk = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCompensationindex.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCompensationindex.fulfilled, (state, { payload }) => {
        compensationindexAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateCompensationindex.fulfilled, compensationindexAdapter.updateOne)
      .addCase(deleteCompensationindex.fulfilled, compensationindexAdapter.removeOne)
      .addCase(createCompensationindex.fulfilled, compensationindexAdapter.addOne);
  },
});

export const { openPeopleModal, closePeopleModal } = compensationindexSlice.actions;

export default compensationindexSlice.reducer;

const selectState = (state) => state.compensationindex;

export const {
  selectAll: selectCompensationindex,
  selectById: selectCompensationindexById,
} = compensationindexAdapter.getSelectors(selectState);

export const selectCompensationindexStatus = createSelector(
  selectState,
  (state) => state.status
);
