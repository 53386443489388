import React, {Fragment} from 'react';
import BaseService from "./../../services/BaseService"
import successvision_workflow from "./images/successvision_workflow.png";
import innovationthemetargets from "./images/innovationthemetargets.png";
import innovationwaterfall from "./images/innovationwaterfall.png";
import innovationscatter from "./images/innovationscatter.png";


import "./help.css"

export function FXRates(){
return (
  <Fragment>
    <p>By default, USD (United States Dollar) is used as the base currency. To change the base currency, go to <i>Settings→FX</i>Rates and enter the three letter ISO 4217 code representing that currency.</p><br/>
    <p>Strat2gy provides multi-currency conversion to local currency for compensation data calculations. If you wish to specify compensation in multiple currencies, you will need to enter the FX Rate projections for each currency. To add a FX Rate, go to <i>Settings→FX</i> Rates and click on the plus icon in the top right corner. At the prompt enter the three-letter code for the currency you wish to add. </p><br/>
    <p>Forecast rates should be entered for all years. Where no FX Rate is available, the application will default to a parity exchange rate of 1.00, meaning any cross-currency calculations may not reflect the true values. </p>
  </Fragment>
  )
}

export function DateRanges(){
  return (
    <Fragment>
      <p><b>Financial Year Starting Month</b></p><br/>
      <p>For companies that may not run standard financial year calendars, Strat2gy provides the ability to customize the financial year start dates. For example, if your financial year runs January through December, the financial year starting month would be set to January. Alternatively, if the financial year runs July through June the following year, the starting month would be set to July. By default, Strat2gy uses a starting month of January. </p><br/>
      <p><b>Roadmaps Year Range</b></p><br/>
      <p>Strat2gy permits the strategy horizon to be set up to a maximum of seven years not including the current year. While some companies, especially those with investments in long-term infrastructure may do capital planning with horizons longer than this, it has been shown that it is the actions and outcome over the shorter timeframe that have the greatest impact on you longer term success. </p><br/>
      <p>Activities beyond a certain timeframe also see greater discount on present value and company valuations. With the discount cashflow model, something we can achieve in the next few years is going to result in a greater impact on your valuation than something that happens longer term. </p><br/>
      <p>For private or private equity backed companies, the roadmaps year range is often aligned to the investment horizon and exit/sale of the company.  This range is generally three to seven years. </p><br/> 
      <p>Public companies should be targeting a horizon of approximately five years. Public company investors have a shorter investment horizon than private equity and expect to see a return on their investment within several years. Public company strategy therefore needs to balance meeting these shorter term investor expectations with creating long-term value and growth. </p><br/>
      <p><b>People Year Range</b></p><br/>
      <p> This sets the range used in the People component of the Strat2gy application. The people year range has a maximum of four years not including the current year. </p>
    </Fragment>
    )
  }

  export function Values(){
    return (
      <Fragment>
        <p>The most successful companies follow what we refer to as a Values<sup>2</sup> approach to growth. These are companies that create financial value in a way that is true and consistent with their company’s values and beliefs. </p><br/>
        <p>Nobel prize wining economist Milton Friedman once famously espoused that the sole purpose of a business was to maximize profit for its owners and shareholders. Today, we would say that the purpose of a business is to maximize value for its owners and shareholders in a way that is consistent and true with its values and beliefs.</p><br/>
        <p>Defining your company values has become an important first step in the strategy process and it sets the expectations, rules, and bounds on how we are going to achieve strategic success.  Companies that follow a Values<sup>2</sup> approach to growth also tend to have a better environmental, social, and governance (ESG) record because these values and expectations of behaviours are clearly defined and integral to the business strategy and execution.  </p><br/>
        <p><b>Editing Company Values</b></p><br/>
        <p>The company values modal box can be accessed by double-clicking anywhere on the values section of the company page. Within this modal box, you can re-order the values list by dragging the value card up or down; delete a value by clicking on the trashcan icon at the right of the value card; or edit the value by clicking on the value card. To add a new value, click the Add New button on the bottom of the modal box.  </p>
        </Fragment>
      )
    }

  export function Timeline(){
    return (
      <Fragment>
        <p>In looking forward, it is equally important to understand the history of the organization and the foundations and pedigree on which you are building the future. As a result of business and staffing changes, over time some of this history can be lost. The history timeline allows you to share this history by defining key historical events core to the evolution and growth of the business into who you are today. </p><br/> 
        <p><b>Editing the History Timeline</b></p><br/>
        <p> To edit the company history, double-click anywhere within the history section of the company page. This will open the company timeline modal box where you can edit a timeline record by clicking on the timeline card; or delete a timeline record by clicking on the trashcan icon. To enter a new timeline record, click on the Add New button and enter the year and event details. </p><br/>
        <p>Records are ordered automatically based on the year. </p> 
      </Fragment>
    )
  }

  export function GeneralSettings(){
    return (
      <Fragment>
        <p>Under the general settings tab, you can customize the application branding to your own company specific settings. </p><br/>
        <p>The company name will be the company name used across the application and in any reports generated by Strat2gy. The company name should be set to the generic company name that is generally used internally by the company on its reports and presentation. </p><br/>
        <p>The email domain is used by the application for user management. Usernames are based on a users email address, and the company domain setting provides consistency with these user settings. It is also part of the security features of Strat2gy to restrict the ability to create users with email domains outside of your company’s domain. </p><br/>
        <p> The logo on the menu bar can be company branded by uploading your company logo. Logo backgrounds should be transparent or white. </p><br/>
        <p> The application color scheme can also be customized and set to the company's brand colors. You can set both the background color and text color. Note the text color is only used when the background color is displayed.  </p><br/>
      </Fragment>
    )
  }

  export function ApplicationSetup(){
    return (
      <Fragment>
        <p>Strat2gy has been designed to be fully customizable in both the look of the application and its functionality. </p><br/>
        <p>The first step in using Strat2gy is to configure these application settings through the settings page, accessible through the settings option on the menu bar. </p><br/>
        <p>Note that the application settings are only accessible to users with administrator rights.  </p><br/>
      </Fragment>
    )
  }

  export function Company(){
    return (
      <Fragment>
        <p>The most powerful strategies are built around a story. It inspires, motivates, and aligns the company around its strategic journey, and acts as the glue that brings the various parts of a strategy together. </p><br/> 
        <p>The company page is effectively the introduction and first chapter of this strategic growth story. It allows you to tell the story of who you are, where you are going, why and how you are going to be successful, and the values on which the company and strategy is being built.  </p><br/>
        <p>The company page along with the success vision form the top level of the cascading strategy model used by Strat2gy. A strong growth story and success vision, supported by clear roadmaps and value focussed activity builds a strategic framework strong on clarity, alignment, focus, and accountability. </p><br/>
      </Fragment>
    )
  }

  export function Facts(){
    return (
      <Fragment>
        <p>What are the key facts that describe your company status today? Facts are a succinct and convenient way of helping tell the story of who you are, and they also act as a reminder of where you are along your strategic growth journey.  Facts often become talking points and help develop consistency of messaging when describing the company to others. </p><br/>
        <p><b>Editing Company Facts</b></p><br/>
        <p>The company facts modal box can be accessed by double-clicking anywhere on the facts section of the company page. Within this modal box, you can re-order the facts list by dragging the fact card up or down; delete a fact by clicking on the trashcan icon at the right of the fact card; or edit the fact by clicking on the fact card. To add a new fact, click the Add New button on the bottom of the modal box.  </p>
      </Fragment>
    )
  }

  export function IntroducingStrat2gy(){
    return (
      <Fragment>
        <p>Strat2gy is a Strategic Growth Management Platform designed to help companies’ grow faster. Based on years of research and the experiences of hundreds of companies, Strat2gy helps companies successfully navigate their strategic growth journey and to realize their full strategic potential. </p><br/>
        <p><b>A Single Strategic View</b></p><br/>
        <p>Strat2gy provides a single location for viewing and managing your company’s growth strategy. Traditional strategic management systems suffer various limitations including information segmentation (strategy is spread across multiple corporate systems, presentations, spreadsheets etc.) and functional segmentation (interdependent business functions across the business operating independently). Given the traditional complexity and challenges of creating a single strategic organizational view, this process gets only done periodically, if at all. </p><br/>
        <p>Strat2gy’s integrated design and cascading top-down approach allows you to see across the organization and ensures the business stays focussed and aligned to the strategy. </p><br/>
        <p><b>A True Agile Strategy Platform</b></p><br/>
        <p>A traditional strategy has a life of less than eighteen months before obsolescence and divergence is observable. A key reason is that most strategies are point in time and after the initial fanfare of rolling out a new plan, little is done going forward to maintain the plan and the strategic momentum. In the absence of a strategy, the annual budget processes and departmental and personal goal setting replace the strategy process. </p><br/>
        <p> Strat2gy allows companies to quickly and easily update your strategy ensuring that it remains current and relevant. Strat2gy’s alerts bring to your attention areas of divergence so that this can be proactively managed and addressed. </p> <br/>
      </Fragment>
    )
  }

export function SuccessVisions(){
  return (
    <Fragment>
      <p> Success Visions provide the answers to the question ‘what is success?’ and ‘what will this company look like when successful?’ The first question defines success, and the second grounds success by identifying key indicators and outcomes. </p><br/>
      <p>Companies that exhibit a shared vision of success tend to excel compared to those that simply utilize generic goal-based approaches. The discussion of what is success is a powerful process, that builds a psychological and emotional attachment and commitment to the shared vision because success is personal for both the individual and team. </p><br/>
      <p>For most companies, success can be defined with between six to ten success visions. Over time, they will mature and potentially the timeframe will change, but the definition of success generally stays consistent, reflecting the strong resilience and persistence of success visons over other visioning and goal setting processes. Success visions may cover general areas such as:</p><br/>
        <ul className="helplist">
        <li>Valuation and share price</li>
        <li>Revenues</li>
        <li>Market Ranking/Peer Group Performance</li>
        <li>Business Functions/ Business Lines</li>
        <li>Business Reputation</li>
        <li>People</li>
        <li>Financial/ Balance Sheet Health</li> 
        <li>Market and Geographic Coverage</li>
        <li>Acquisitions</li>
        </ul>

      <br/>
      <p><b>The Role of Success Visions in the Strategy Process</b></p><br/>
      <p>Once you have defined the success visions, companies can explore and build the roadmaps to achieve this strategic success. Initially it is quite common for there to be gaps between the success visions and roadmap outcomes but by following an agile strategy approach, over time you begin to create alignment between the success visions and the roadmaps. </p> 
      <div className="container mt-2">
        <div class="columns is-centered">
          <div class="column has-text-centered">
          <img
            alt="Company Logo"
            src={successvision_workflow}
            style={{height:'320px' }}
          />
        </div>
        </div>
      </div>
    </Fragment>
  )
}

export function SuccessVisionTimeframe(){
  return (
    <Fragment>
  <p> The target year for the success visions is set by clicking on the year in the heading at the top of the page and selecting the target year from the pulldown list. Like roadmaps, success visions can be set up to seven years into the future, however most companies will take a shorter horizon, generally three to five years. </p><br/>
  <p> The target year does not need to correspond the year range used for roadmaps. There are many reasons why a company may elect to set a longer planning horizon but set the year for the success vision to a shorter timeframe. </p>

    </Fragment>
  )
}

export function ChoosingSuccessVisions(){
  return (
    <Fragment>
<p> Success can be generally defined with between six to nine success visions.   </p>
<p> Success visions may cover the following areas:</p>
<ul className="helplist">
<li>Valuation and share price</li>
<li>Revenues</li>
<li>Market positioning market keader, per comparison</li>
<li>Business Functions/ Busines Lines</li>
<li>Business Reputation</li>
<li>People</li>
<li>Financial Health</li> 
<li>Geographic Coverage</li>
<li>Acquisitions etc.</li>
</ul>
    </Fragment>
  )
}

export function Roadmaps(){
  return (
    <Fragment>
      <p>Roadmaps play an important role in bridging the strategy and strategic execution of the strategy. They also allow companies to evaluate different strategic paths, better understand risks and strategic sensitivities, and to be able to track strategic progress against baseline outcomes. </p>
      </Fragment>
  )
}

export function Sprints(){
  return (
    <Fragment>
      <p>Sprints are a very powerful tool for validating and executing on strategy.  Unfortunately, their value and impact are often diminished when companies look at sprints as tasks rather than value driven.</p><br/>  
      <p>Sprints as a value driven activity should focus on three things:</p><br/>
      <p>1.	What do we need to do now to compress or maintain timelines.</p>
      <p>2.	What can we do to increase future cashflow, by either increasing revenue or managing costs.</p>
      <p>3.	What can we do to reduce strategic and execution risk.</p><br/>
      <p>If you are familiar with discount cashflow (DCF) valuation models, you will recognize these three areas as drivers of valuation. By being focussed and disciplined on where you focus your efforts, rather than being measured on how many tasks you undertake, companies and executives can be much more impactful in moving the company and its valuation forward. </p><br/>
      <p>An equally important aspect of sprints is that they help you rapidly learn and adapt, and this is closely aligned to the third DCF factor, reducing risk. A large component of strategy is based on assumptions and what you think will happen in the future. As you undertake a sprint, you often identify things that are different to your understanding or the base assumptions on which your strategy is built. Having the opportunity to ask the question ‘what have we learnt and how does this impact our strategy going forward’ helps to both validate, refine, and mature your strategy. </p>
      </Fragment>
  )
}



export function SprintReview(){
  return (
    <Fragment>
      <p>The date for the next strategic review is set by clicking on the date on the sprint header and choosing a date from the date select tool.  Setting a review date ensures that strategic reviews continue to be regularly undertaken and that these meetings can be scheduled and planned well in advance rather than as an afterthought. Based on the feedback and experience of companies undertaking regular reviews, it seems that the optimal number of strategy reviews each year is three, with the third being a pre-cursor to the annual operating plan. This then permits the strategy to provide the guidance into the annual process and ensure that annual budgets and plans stay aligned with the strategy. </p><br/>
      <p>These review sessions provide a feedback loop to review and incorporate YTD performance and learnings that that help refine and mature strategic directions and roadmaps. </p>
    </Fragment>
  )
}

export function Innovation(){
  return (
    <Fragment>
      <p>Innovation is a critical but often overlooked component of a growth strategy. When we build roadmaps, it is common for there to be gaps between where the current roadmap takes us and where we want to be in the future. Examples may include:</p><br/>
      <ul className="helplist">
      <li>Revenue shortfalls: Where do we find additional sources of revenue. What potential new products or services could we introduce? </li>
      <li>Costs: How do we reduce costs and improve efficiencies? How do we offset the impact of inflationary pressures on the business? </li>
      <li>People: How do we improve our people engagement, attract and retain the best talent? </li> 
      </ul><br/>
      <p>Similarly, as you execute on your plan, there will be unforeseen issues and challenges that may arise. Alternatively, there could be unforeseen opportunities that the company may wish to take advantage of. </p><br/>
      <p>When we don’t have a formal process to identify and address these gaps, they tend to remain unresolved and don’t get the attention they need given their impact on our strategic outcomes. What successful growth companies do well is to identify and quantify these gaps, and then have a focussed approach to how they solve and address them. </p><br/>
      <p>All these examples fall under the umbrella of innovation, as it is about finding new solutions to these challenges and opportunities. </p>
    </Fragment>
  )
}

export function InnovationThemes(){
  return (
    <Fragment>
      <p>Innovation themes focus on a specific area of strategy that is critical to your strategic success but still requires ideas or answers to how you can achieve that outcome.</p><br/>
      <p>When creating a theme, you should describe why this theme is important to strategic success and the outcome that you are looking to achieve. Where possible, you should quantity this objective by clicking on the ‘Show Theme Measures’ checkbox and entering the values.</p><br/>

      <div className="container mt-2">
        <div class="columns is-centered">
          <div class="column has-text-centered">
          <img
            alt="Theme Measures"
            src={innovationthemetargets}
            style={{height:'80px' }}
          />
        </div>
        </div>
      </div>

      <p>By entering these values, you have now defined clearly the strategic gap associated with that theme and allows you to see the impact of the various ideas in targeting the themes objectives and outcomes. </p>

    </Fragment>
  )
}

export function Ideas(){
  return (
    <Fragment>
      <p>While it would be ideal to find one great idea or solution that fully addresses the innovation theme objectives, this is rarely the case. It is usually the collective sum of various ideas that contribute to addressing strategic gaps and why ideation becomes an important part of strategy execution. </p><br/>
      <p>Team members should be encouraged to bring forward and enter ideas that they think can contribute to the outcome, along with an estimate of cost and its impact on the target outcome.  </p><br/>
      <p>From these various ideas, you can prioritise based on a variety of considerations, including magnitude of impact, impact/cost ratios, and feasibility/likelihood of success. In evaluating ideas, companies can action as a sprint task where ideas warrant further evaluation or feasibility analysis. </p><br/>  
    </Fragment>
  )
}


  export function InnovationCharts(){
    return (
      <Fragment>
        <p>Innovation charts provide a tool for visualizing the themes ideas.</p><br/>
        <p>The waterfall chart displays all selected ideas and shows their collective impact on addresing the gap between the current base number and target number that is associated with the theme.</p><br/>   
          
        <div className="container mt-2">
          <div class="columns is-centered">
            <div class="column has-text-centered">
            <img
              alt="Innovation Waterfall Chart"
              src={innovationwaterfall}
              style={{height:'300px' }}
            />
          </div>
          </div>
        </div>

        <p> The scatter chart displays all selected ideas and charts them based on their impact and cost. The scatter chatter allows you to see those ideas with the greatest impact and also the best cost to impact ratios.</p><br/>

        <div className="container mt-2">
          <div class="columns is-centered">
            <div class="column has-text-centered">
            <img
              alt="Innovation Scatter Chart"
              src={innovationscatter}
              style={{height:'300px' }}
            />
          </div>
          </div>
        </div>
      </Fragment>
    )
  }



  export function Investments(){
    return (
      <Fragment>
        <p>Investments allows you to view and track strategic growth commitments that are unbudgeted and not included in the future year roadmaps. By being able to track and identify these items, it provides you visibility into the total cost of commitments and make decisions on future funding options, either finding savings elsewhere in the budgets or roadmaps to offset, deciding not to continue to invest in the particular growth initiative or action, or to decide that the initiative is self-funding due to the revenue or cost savings it will generate. </p>
      </Fragment>
    )
  }

  export function Reports(){
    return (
      <Fragment>
        <p>Strat2gy allows reports to be generated in both Excel and PowerPoint formats. Reports can be access through the reports section of the menu bar.</p>
      </Fragment>
    )
  }

  export function ExportExcel(){
    return (
      <Fragment>
        <p>The Export to Excel menu generates and downloads an Excel file containing the success vision, baseline data, and roadmap tables. Roadmap tables are grouped by category and each category is displayed on a separate sheet. </p>
      </Fragment>
    )
  }

  export function ExportPPT(){
    return (
      <Fragment>
        <p>The Export to PowerPoint menu generates and downloads an PowerPoint file containing the following information: </p><br/>
          <ul className="helplist">
            <li>Company facts</li>
            <li>Company timelines</li>
            <li>Company values</li>
            <li>Success visions</li>
            <li>Roadmaps</li>
          </ul>
      </Fragment>
    )
  }

  export function Formulas(){
    return (
      <Fragment>
        <p>Roadmap tables support the use of user-defined formulas to build custom models specific to your company. To access the roadmap formulas, from the roadmaps page press CTRL+SHIFT+F to show and hide the formula detail. Formulas can only be accessed and edited by those with admin privileges. </p><br/>
        <p><b>Adding a Formula</b></p><br/>
        <p>Formulas support addition, subtraction, multiplication and division. To identify a row, the row identifier is included in curly brackets {'{}'}. For example, to add row five and six, the formula would be {'{5} + {6}'}. Parentheses or round brackets can also be used for formula grouping. {'({5}+{6})*{7}'} would firstly calculate the sum of rows five and six, then multiply the sum by the value of row seven.</p><br/>
        <p><b>Formula Errors</b></p><br/>
        <p>Should the formula be invalid an error message will be displayed on the row corresponding to the formula. Common reasons for errors include circular references or incorrect formatting of the formula. It is always recommended that when editing formulas the calculation be manually checked to ensure that the calculated values match. </p>
      </Fragment>
    )
  }

  export function Stakeholders(){
    return (
      <Fragment>
        <p>Stakeholders are people, groups, or organizations internal or external to the company who have a vested interest in its success. More than just being able to identify who the stakeholders, it is important to understand their interests and drivers, and to have strategies to engage and mange them. The stakeholder page assists in being able to map and analyze stakeholder interests and identify where there mat be misalignment between stakeholders and the company. </p>
      </Fragment>
    )
  }
  
  export function People(){
    return (
      <Fragment>
        <p>While a people strategy is critical to a company’s long-term success, it is often overlooked or treated separately in the strategy process. Strat2gy’s people modules ensure that people are core and fully integrated into the strategy process and decision making.</p><br/>
        <p>Strat2gy provides four core capabilities with people planning:</p><br/> 
        <ul className="helplist">
        <li>Organizational Design: Strat2gy allows organizations to map out their organizational structure forward over a number of years, allowing companies to plan in advance how the organizational will grow and evolve in line with the strategy. </li>
        <li>Core Metrics: Based on your organizational designs, Strat2gy provides key insights and core metrics around annual total headcount and compensation changes, including sources of changes including wage indexing, new hiring and FX impacts.  </li>
        <li>Productivity Analysis: When linked to roadmaps, you can directly see the productivity changes between the planned organizational structures and the company’s forward growth and performance. Access in real-time to these productivity numbers helps ensure that organizational growth is aligned to company growth.  </li>
        <li>Talent Development: The talent model allows you to identify key/critical future roles and identify and map potential pipeline candidates to these roles.  </li>
        </ul>
      </Fragment>
    )
  }

  export function RoadmapSections(){
    return (
      <Fragment>
        <p>A roadmap section maps a component of your strategy, whether it be financials, products, production, people etc. Sections contain a narrative where you tell the story of how this will be achieved, along with options of including a table and/or gantt charts to outline the supporting detail. </p><br/>
        <p>Sections are assigned to a roadmap category, allowing you to view all roadmaps that relate to the specific category. </p><br/>
        <p>A section table consists of user defined rows and columns for each year of the strategic plan. Similarly, a gannt chart allows you to create a timeline of activities that support the strategic objectives. </p><br/>
      </Fragment>
    )
  }


  export function RoadmapRows(){
    return (
      <Fragment>
        <p>A table consists of user defined rows. Rows can be defined by type (number, text, percentage) and by level, allowing sub-rows to be indented. To add a new row, open the section edit box, and from the Table Rows tab click on the Add Row button. To edit a row, simply click on the row name in the table to edit.</p><br/>
        <p>For each numeric rows, you have the option of displaying the annual and compounding growth rates for the row by clicking on the % button on the table header. </p><br/>
      </Fragment>
    )
  }


  export function RoadmapGantt(){
    return (
      <Fragment>
        <p>To add a gannt chart to the section, click on the checkbox in the Gannt Chart tab of the sections edit box. Task duration is set by either setting the start and end date of the start date and duration in days.</p><b/>
        <p>Task dependencies can also be set, meaning a task will start on the completion of the dependant task. </p><b/>
      </Fragment>
    )
  }


  export function Baselines(){
    return (
      <Fragment>
        <p>Roadmap baselines set the targets for what we will need to achieve in order to meet the success vision outcome. For roadmap tables, baselines can be set for a particular row and for gantts they can be assigned to tasks. </p><br/>
        <p>Roadmaps allow us to identify gaps or variances between our roadmaps and the success visons. It’s normal early in a strategic process to have gaps between baselines and roadmaps, and this forms part of the strategic process to identify way in which these gaps can be addressed. </p><br/>
        <p>For baselines associated with table rows, alert thresholds can be set that bring to your attention variances that exceed these thresholds.</p><br/>
      </Fragment>
    )
  }

  export function Linkages(){
    return (
      <Fragment>
        <p>By clicking on a success vision, you can view and edit linkages to roadmaps, innovation themes, and sprints. These linkages provide a view of the key items associated to that success vision, and bring focus to how we intend to deliver on the success vision objectives.</p><br/>
        <p style={{fontWeight:'500'}}>Roadmap Linkages</p><br/>
        <p>Roadmap linkages allow you to associate a roadmap row or gannt chart to a success vision. For roadmap rows, baselines can be set that allow you to track the roadmap progress against the baseline.</p><br/>
        <p style={{fontWeight:'500'}}>Innovation Theme Linkages</p><br/>
        <p>Innovation theme linkages allow you to associate an innovation theme to the success vision. </p><br/>
        <p style={{fontWeight:'500'}}>Sprint Linkages</p><br/>
        <p>Sprint linkages allow you to associate sprint items related to the success vision.</p>

      </Fragment>
    )
  }

  export function RoadmapCharts(){
    return (
      <Fragment>
        <p>To assist in viewing and interpreting roadmaps, charts can be added to each roadmap section and associated with user-defined roadmap rows. </p><br/>
        <p>To add charts to a roadmap section, click on the Include Graphs checkbox on the Table Rows tab of the section. To add or edit charts, double click on the chart area of the roadmap chart.  </p><br/>
      </Fragment>
    )
  }

 