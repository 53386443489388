import { React, Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { selectOverallScoreById, updateOverallscores, createOverallscores, fetchAllOverallscores  } from "./../../../redux/toolkit/features/organization/overallscores";
import { closeOverallScoresModal } from "./../../../redux/toolkit/features/organization_state";

function TalentScoresOverallModal() {
    const isOverallScoresModalOpen = useSelector((state) => state.organization_state.isOverallScoresModalOpen);
    const overallscorePK = useSelector((state) => state.organization_state.overallscorePK);
    const overallscorebyid = useSelector((state)=>selectOverallScoreById(state, overallscorePK));  
    const rolePK = useSelector((state) => state.organization_state.overallscorerolePK);
    const personPK = useSelector((state) => state.organization_state.overallscorepeoplePK);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
    const textcolor = useSelector((state) => state.colors.textcolor);
    const [score, setScore] = useState(0);
    const [comment, setComment] = useState("");
    const dispatch = useDispatch(); 


    let modalClass = "modal";
    if (isOverallScoresModalOpen) {
        modalClass += " is-active";
    }

      useEffect(() => {
        setScore(overallscorebyid?overallscorebyid.score.toFixed(1):5.0);
        setComment(overallscorebyid?overallscorebyid.comment:"");
      },[overallscorePK]);

    var options=[]
    for (let i = 0; i < 5; i += 0.10) {
        options.push(<option key={((Math.round(i*10))/10).toFixed(1)} value={((Math.round(i*10))/10).toFixed(1)}>{((Math.round(i*10))/10).toFixed(1)}</option>)
    }


    function renderForm() {
        return (
            <Fragment>
                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Overall Rating (0-5)</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                    <div className="select">
                            <select
                                style={{width:"180px"}}
                                value={score}
                                onChange={(e) => setScore(e.target.value)}
                                >
                                {options}
                            </select>

                        </div>
                    </div>
                </div>

                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Comment</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <textarea 
                                className="textarea"
                                rows="4"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>UPDATE OVERALL RATING</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeOverallScoresModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: '200px'}}>
                    {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    {overallscorePK === null &&
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(createOverallscores({score: score, comment: comment, position_id: rolePK,people_id:personPK  }))
                                .unwrap()
                                    dispatch(fetchAllOverallscores());
                                    dispatch(closeOverallScoresModal())
                        }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}

                        {overallscorePK !== null &&
                        <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(updateOverallscores({id:overallscorePK, score: score, comment: comment, position_id: rolePK,people_id:personPK }))
                                .unwrap()
                                    dispatch(fetchAllOverallscores());
                                    dispatch(closeOverallScoresModal())
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeOverallScoresModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export { TalentScoresOverallModal }
