import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class PersonService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/personview`;
    }

    async getPersons() {
        console.log("Get personview service")
        const persons = await this.getAll();
        console.log("Get personview service1", persons)
        return persons;
    }
}

export const personService = new PersonService();
