import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {fetchAllFacts} from "./../../redux/toolkit/features/company/facts";
import {openFactsModal} from "./../../redux/toolkit/features/company_state";


function CompanyFactsSection() {
  const facts = Object.values(useSelector((state) => state.facts.entities)).sort((a, b) => a.order - b.order);
  const dispatch = useDispatch(); 

  async function getFacts() {
    dispatch(fetchAllFacts());
  }

  useEffect(() => {
      getFacts();
  },[]);

  function renderFacts() {
    if (facts && facts.length===0) {
      return (
        <section className="mt-6 mb-6" style={{paddingLeft: "17%", paddingRight: "17%", backgroundColor: "White", paddingTop: "80px", paddingBottom: "80px", userSelect: "none"}} >
          <div className="columns is-multiline">
            <div className="column is-one-third" style={{fontFamily: "Gotham Extra", fontSize: "56px", lineHeight: "56px"}}>
              <div className="pr-1">
              KEY<br/>COMPANY<br/>FACTS
              </div>
            </div>
            <div className="column is-one-third pt-0 pr-0 pb-4 pl-4 is-flex" style={{fontFamily: "Gotham Extra", fontSize: "30px", lineHeight: "0.8em"}}>
              <div className="level" style={{backgroundColor: "transparent", minHeight: "150px",userSelect: "none"}}>
                <div className="level-item pt-6" style={{userSelect: "none", minHeight: "150px"}}>
                  <button className = "button p-4" style={{backgroundColor: "transparent", border: "solid 1px darkgrey", fontFamily: "Gotham Extra", fontSize: "20px"}} onClick={() => dispatch(openFactsModal())} >
                  Add Company Facts
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
      } else {
        return (
          <section className="mt-6 mb-6" style={{paddingLeft: "17%", paddingRight: "17%", backgroundColor: "White", paddingTop: "80px", paddingBottom: "80px", userSelect: "none"}} onDoubleClick={() => dispatch(openFactsModal())}>
          <div className="columns is-multiline">
            <div className="column is-one-third" style={{fontFamily: "Gotham Extra", fontSize: "56px", lineHeight: "56px"}}>
              <div className="pr-1">
              KEY<br/>COMPANY<br/>FACTS
              </div>
            </div>
  
            {facts && facts.map((fact)=> ( 
              <Fragment key={"Facts_fragment_"+fact.pk}>
                <div className="column is-one-third pt-0 pr-0 pb-4 pl-4 is-flex" key={"Facts_column_"+fact.pk} style={{fontFamily: "Gotham Extra", fontSize: "30px", lineHeight: "0.8em"}}>
                  <div className="card mt-0 pt-0 pb-5 is-shadowless" style={{backgroundColor: "rgb(238,238,238)", width: "100%", minHeight: "300px"}}>
                    <div className="card-content" >
                      <div className="content ml-0" style={{backgroundColor: "rgb(238,238,238)"}}>
                          <p key={"Facts_fact_"+fact.pk} style={{fontFamily: "Gotham Extra", fontSize: "18.5px", lineHeight: "1.5em"}}>{fact.fact}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </section>
        )
      }}


  return <Fragment>{renderFacts()}</Fragment>;
}

export default CompanyFactsSection;
