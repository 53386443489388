import React, {Fragment, useState, useEffect} from "react";
import { useDispatch } from 'react-redux'
import { SprintInvestmentCell } from "./SprintInvestmentCell";
import { updateSprintinvestment, fetchAllSprintinvestment } from "../../../../redux/toolkit/features/sprints/sprintinvestment";
import { openDeleteModal } from "../../../../redux/toolkit/features/modals/deleteConfirmModal";
import './input.css';

function SprintInvestmentRow(props) {
    const {row, cells, minYear, maxYear} = props;
    const [rowtitle, setRowTitle] = useState(null)
    const dispatch = useDispatch();

    useEffect(() => {
        setRowTitle(row.item);
    },[])

    async function commitSprintInvestment(value) {
        await dispatch(updateSprintinvestment({id: row.pk, item: value , sprint: row.sprint}))
        .unwrap()
            dispatch(fetchAllSprintinvestment());
    }

    function rendercells() {
        const rowinputs = [];
        for (let year = minYear; year <= maxYear; year++) {
            rowinputs.push(<SprintInvestmentCell investmentrowPK={row.pk} year={year} cells={cells}/>) 
        }
        return ( rowinputs )
    }   

    return (
        <Fragment>
            <tr>
                <td ><input className="e-input" style={{border:'none'}} value={rowtitle} onChange={(e) => setRowTitle(e.target.value)} onKeyDown={(e) => e.key === 'Enter' ? commitSprintInvestment(e.target.value):null}/></td>
                {rendercells()}
                <td className="ml-1 mr-0 pl-1 pr-0" style={{width:"7px", textAlign:'right'}} onClick={() => dispatch(openDeleteModal({message: rowtitle,recordpk:row.pk, recordtype: 'sprintinvestment'}))}><button className="far fa-trash-alt mt-1 ml-0 pl-0" style={{color:"gray"}}></button></td>
            </tr>
        </Fragment>
    );
}

export {SprintInvestmentRow}
