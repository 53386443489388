import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllCompany = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/company`,
    authConfig()
  );
  return response.data.results;
};

export const fetchCompany = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/company/${id}`,
    authConfig()
  );
  return response.data;
};

export const createCompany = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/company`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateCompany = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/company/${data.pk}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteCompany = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/company/${id}`,
    authConfig()
  );
  return response.data;
};

export const uploadImage = async (data) => {
  const url = `${apiUtils.getBaseURL()}/company/` + (data.id) + "/upload-image";
  const formData = new FormData();
  formData.append("file", data.file);
  const config = authConfig();
  config.headers["content-type"] = "multipart/form-data";
  const response = await axios.put(url, formData, config);
  return response.data;
};

const companyAPI = {
  fetchAllCompany,
  fetchCompany,
  createCompany,
  updateCompany,
  deleteCompany,
  uploadImage,
};
export default companyAPI;
