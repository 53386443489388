import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllFXRow = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/fxrow`,
    authConfig()
  );
  return response.data.results;
};

export const fetchFXRow = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/fxrow/${id}`,
    authConfig()
  );
  return response.data;
};

export const createFXRow = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/fxrow`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateFXRow = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/fxrow/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteFXRow = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/fxrow/${id}`,
    authConfig()
  );
  return response.data;
};

const fxrowAPI = {
  fetchAllFXRow,
  fetchFXRow,
  createFXRow,
  updateFXRow,
  deleteFXRow,
};
export default fxrowAPI;
