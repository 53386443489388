import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import "bulma/css/bulma.css";
import {filter} from "lodash"
import SprintModalButtons from "./SprintModalButtons"
import { closeSprintModal }  from "./../../../redux/toolkit/features/sprints_state";
import { updateSprints, createSprints, selectSprintById, fetchAllSprints }  from "./../../../redux/toolkit/features/sprints/sprints";
import { createActionLink }  from "./../../../redux/toolkit/features/innovation/actionlink";
import { fetchAllSponsors } from "../../../redux/toolkit/features/sprints/sponsors";
import { openDeleteModal } from '../../../redux/toolkit/features/modals/deleteConfirmModal';
import {fetchAllProgressbaritem } from '../../../redux/toolkit/features/sprints/progressbaritem';
import {Container} from "./Progress/Container"

function SprintModal() {
    const sponsors = Object.values(useSelector((state) => state.sponsors.entities));
    const comments = Object.values(useSelector((state) => state.comments.entities));
    const progressbaritem = (Object.values(useSelector((state) => state.progressbaritem.entities))).sort((a, b) => a.order - b.order);
    const isSprintModalOpen = useSelector((state) => state.sprints_state.isSprintModalOpen)
    const ideaPK = useSelector((state) => state.sprints_state.ideaPK)
    const isSprintSponsorModalOpen = useSelector((state) => state.sprints_state.isSprintSponsorModalOpen)
    const sprintPK = useSelector((state) => state.sprints_state.sprintPK)
    const sprintbyID = useSelector((state) => selectSprintById(state,sprintPK));
    const [title, SetTitle] = useState('');
    const [description, SetDescription] = useState('');
    const [actionstatus, SetActionStatus] = useState('');
    const [budget, SetBudget] = useState('No');
    const [sprintlane, SetSprintlane] = useState(false);
    const tabs = ['Sprint', 'Sponsors', 'Progress Bar', 'Comments']
    const tabsshort = ['Sprint']
    const [activetab, setActivetab] = useState(tabs[0]);

    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch();
    var progressbaritemarray = filter(progressbaritem, {sprint: sprintPK})

    let sprinttitlevalue = "Edit Sprint"
    if (sprintPK === null) {
        sprinttitlevalue = "Add Sprint"
    }
    const sprinttitle = (sprinttitlevalue)
   

    useEffect(() => {
        SetTitle(sprintbyID?sprintbyID.title:"")
        SetDescription(sprintbyID?sprintbyID.description:"")
        SetActionStatus(sprintbyID?sprintbyID.action_status:"In Progress")
        SetBudget(sprintbyID?sprintbyID.budget:"Yes")
        SetSprintlane(sprintbyID?sprintbyID.sprintlane:false)
      }, [sprintPK])

  

      useEffect(() => {
            dispatch(fetchAllSponsors())
            dispatch(fetchAllProgressbaritem())
      }, [])


     let modalClass = "modal";
    if (isSprintModalOpen) {
        modalClass += " is-active";
    }


    function formtabs() {
        return (
            <div className="tabs is-boxed">
                <ul>
                {sprintPK=== undefined &&
                    <Fragment>
                    {tabsshort.map(tab => (
                        <li className ={activetab === tab ? "is-active" : ""}
                            key={"tab_"+tab}
                            onClick={() => setActivetab(tab)}
                        >
                        <a>{tab}</a>
                        </li>
                    ))}
                    </Fragment>
                    }
                    {sprintPK!== undefined &&
                    <Fragment>
                    {tabs.map(tab => (
                        <li className ={activetab === tab ? "is-active" : ""}
                            key={"tab_"+tab}
                            onClick={() => setActivetab(tab)}
                        >
                        <a>{tab}</a>
                        </li>
                    ))}
                    </Fragment>
                    }
                </ul>
            </div>
        )
    }

    function renderSponsors() {
        
        if (activetab !== 'Sponsors') {
            return null;
        }  
        let sponsorsnames=filter( sponsors, {sprint_id:sprintPK})

        if ((sponsorsnames).length !== 0) {
            return (
                <Fragment>
                    <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
                        <tbody>
                            {sponsorsnames.map(row => (
                                <Fragment>
                                    <tr className="ml-4 pl-5" colSpan="2">
                                        <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}} >{row.firstname + ' ' + row.secondname}</td>         
                                        <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} 
                                                    onClick={() => dispatch(openDeleteModal({message: row.fullname, recordpk: row.pk, recordtype: 'sponsors'}))}
                                        >
                                        <button className="far fa-trash-alt" style={{color:"gray"}}
                                            ></button>
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                </Fragment>
                )
            } else {
                return (<p className="pl-4" style={{weight:"700"}}>No Sprint Owners Assigned</p>);
            }
    }


        // function renderWorkers() {
        
        //     if (activetab !== 'Team') {
        //         return null;
        //     }  

        //     let workersnames=filter( workersview.results, {sprint_id:sprintPK})

        //     if ((workersnames).length !== 0) {
        //         return (
        //             <Fragment>
        //                 <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
        //                     <tbody>
        //                         {workersnames.map(row => (
        //                             <Fragment>
        //                                 <tr className="ml-4 pl-5" colSpan="2">
        //                                     <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}} >{row.firstname + ' ' + row.secondname}</td>         
        //                                     <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} onClick={async () => {
        //                                             toggleConfirmDeleteModal(true, row.fullname, sprintPK, 'sprintworkers',row.pk ) 
        //                                     }}>
        //                                     <button className="far fa-trash-alt" style={{color:"gray"}}
        //                                         ></button>
        //                                     </td>
        //                                 </tr>
        //                             </Fragment>
        //                         ))}
        //                     </tbody>
        //                 </table>
        //             </Fragment>
        //             )
        //         } else {
        //             return (<p className="pl-4" style={{weight:"700"}}>No Team Members Assigned</p>);
        //         }
        //     }

            function renderComments() {
        
                if (activetab !== 'Comments') {
                    return null;
                }  

                let commentsfiltered = filter(comments, {sprint: sprintPK})
                if ((commentsfiltered).length !== 0) {
                    return (
                        <Fragment>
                            <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
                                <tbody>
                                    {commentsfiltered.map(row => (
                                    <Fragment>
                                        <tr className="ml-4 pl-5" colSpan="2">
                                            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}} >{row.comment}</td>         
                                            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} 
                                                    onClick={() => dispatch(openDeleteModal({message: row.comment, recordpk: row.pk, recordtype: 'comments'}))}
                                            >
                                            <button className="far fa-trash-alt" style={{color:"gray"}}
                                                ></button>
                                            </td>
                                        </tr>
                                    </Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </Fragment>
                        )
                } else {
                    return (<p className="pl-4" style={{weight:"700"}}>No Comments</p>);
                }
            }

    function renderSprintlane() {
        if (activetab !== 'Progress Bar') {
            return null;
        }  
        return (
            <Fragment>
            <label className="checkbox pl-4">
                <input className="mr-3" type="checkbox" checked={sprintlane} onChange={(e) => SetSprintlane(e.target.checked)}/>
                     Show Progress
                </label>
                <hr/>
                {progressbaritemarray.length !== 0 &&
                        <Container items={progressbaritemarray.sort((a, b) => a.order > b.order)} sprintPK={sprintPK}/>}
                        {progressbaritemarray.length === 0 &&
                        <p className = "pt-5" style={{fontSize:"16.5px"}}>No Records Returned</p>}
                </Fragment>
        )
    }

    function renderForm() {
        
        if (activetab !== 'Sprint') {
            return null;
        }  

        return (
            <Fragment>
                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Sprint Title</p>
                <div className="field is-horizontal is-align-items-center">
                        <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                        <input 
                            type="text" 
                            className="input"
                            value={title}
                            onChange={(e) => SetTitle(e.target.value)}
                        />
                        </div>
                    </div>
                </div>

                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Description</p>
                <div className="field is-horizontal is-align-items-center">         
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                        <textarea 
                            className="textarea"
                            rows="5" 
                            value={description}
                            onChange={(e) => SetDescription(e.target.value)}
                        />
                        </div>
                    </div>
                </div>

                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Status</p>
                <div className="field is-horizontal is-align-items-center">    
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">    
                            <div className="select is-flex">
                                <div className="select is-fullwidth">
                                    <select defaultValue={"In Progress"} value={actionstatus} onChange={(e) => SetActionStatus(e.target.value)}>
                                        <option value={'In Progress'} selected >In Progress</option>
                                        <option value={'Not Started'}>Not Started</option>
                                        <option  value={'Completed'}>Completed</option>
                                        <option  value={'Suspended'}>Suspended</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Budget</p>
                <div className="field is-horizontal is-align-items-center">    
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">    
                            <div className="select is-flex">
                                <div className="select is-fullwidth">
                                    <select value={budget} onChange={(e) => SetBudget(e.target.value)}>
                                        <option value={'Yes'}>Yes</option>
                                        <option value={'No'}>No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title"style={{fontSize:"17px", color: textcolor}}>{sprinttitle}</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeSprintModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{height: '520px'}}>
                        {formtabs()}
                        {renderForm()}
                        {renderSponsors()}
                        {renderSprintlane()}
                        {renderComments()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <nav className='level'>
                                <div className='level-left'>
                                    <div className='level-item'>
                                    {sprintPK!== null && 
                                        <button
                                            style={{borderRadius:"4px"}}
                                            className="button is-success is-small"
                                            key="save"
                                            onClick={async () => {
                                                await dispatch(updateSprints({id: sprintPK, title: title, description: description, action_status:actionstatus, budget: budget, sprintlane: sprintlane, progress:0}))
                                                .unwrap()
                                                .then(() => {
                                                    dispatch(fetchAllSprints())
                                                    dispatch(closeSprintModal())
                                                })
                                            }}
                                        >
                                            Save changes
                                        </button>
                                    }

                                    {sprintPK=== null && 
                                        <button
                                            style={{borderRadius:"4px"}}
                                            className="button is-success is-small"
                                            key="save"
                                            onClick={async () => {
                                                var sprintrecord = await dispatch(createSprints({title: title, description: description, action_status:actionstatus, budget: budget, sprintlane: sprintlane}))
                                                .unwrap()
                                                {ideaPK!== null && dispatch(createActionLink({idea_id: ideaPK, sprint_id: sprintrecord.pk}))}
                                                dispatch(fetchAllSprints())
                                                dispatch(closeSprintModal())
                                            }}
                                        >
                                            Save changes
                                        </button>
                                    }

                                    </div>
                                    <div className='level-item'>
                                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeSprintModal())}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                {sprintPK &&
                                <div className='level-right'>
                                    <div className='level-item'>
                                    <SprintModalButtons
                                        activetab={activetab}
                                        sprintPK={sprintPK}
                                    />
                                    </div>
                                </div>
                                }
                            </nav>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SprintModal;
