import api from "../../api/organization/positionperson";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const positionpersonAdapter = createEntityAdapter({
  selectId: (positionperson) => positionperson.pk,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = positionpersonAdapter.getInitialState({
  status: "idle", isOpen: false, peoplepk: null
});

export const fetchAllPositionperson = createAsyncThunk("positionperson/fetch", () => api.fetchAllPositionperson());

export const updatePositionperson = createAsyncThunk("positionperson/update", api.updatePositionperson);

export const createPositionperson = createAsyncThunk("positionperson/create", api.createPositionperson);

export const deletePositionperson = createAsyncThunk("positionperson/delete", api.deletePositionperson);

const positionpersonSlice = createSlice({
  name: "positionperson",
  initialState,
  reducers: {
    openPeopleModal(state, action) {
      state.isOpen = true;
      state.peoplepk = action.payload;
    },
    closePeopleModal(state) {
      state.isOpen = false;
      state.peoplepk = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPositionperson.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPositionperson.fulfilled, (state, { payload }) => {
        positionpersonAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updatePositionperson.fulfilled, positionpersonAdapter.updateOne)
      .addCase(deletePositionperson.fulfilled, positionpersonAdapter.removeOne)
      .addCase(createPositionperson.fulfilled, positionpersonAdapter.addOne);
  },
});

export const { openPeopleModal, closePeopleModal } = positionpersonSlice.actions;

export default positionpersonSlice.reducer;

const selectState = (state) => state.positionperson;

export const {
  selectAll: selectPositionperson,
  selectById: selectPositionpersonById,
} = positionpersonAdapter.getSelectors(selectState);

export const selectPositionpersonStatus = createSelector(
  selectState,
  (state) => state.status
);
