import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { selectThemeById, updateThemes, createThemes, fetchAllThemes } from "./../../../redux/toolkit/features/innovation/themes";
import { closeThemeModal } from "./../../../redux/toolkit/features/innovation_state";
import { openDeleteModal } from "./../../../redux/toolkit/features/modals/deleteConfirmModal";

function ThemesModal() {
  const isThemeModalOpen = useSelector((state) => state.innovation_state.isThemeModalOpen)  
  const themePK = useSelector((state) => state.innovation_state.themePK)
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [basevalue, setBaseValue] = useState(0);
  const [targetvalue, setTargetValue] = useState(0);
  const [decimals, setDecimals] = useState(0);
  const [units, setUnits] = useState("");
  const [showtargets, setShowTargets] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const themebyid = useSelector((state)=>selectThemeById(state, themePK))  
  const dispatch = useDispatch(); 

  useEffect(() => {
      setName(themebyid?themebyid.theme:'');
      setDescription(themebyid?themebyid.description:'');
      setShowTargets(themebyid?themebyid.showtargets:false);
      setTargetValue(themebyid?themebyid.targetvalue:null);    
      setBaseValue(themebyid?themebyid.basevalue:null); 
      setUnits(themebyid?themebyid.units:''); 
      setDecimals(themebyid?themebyid.decimals:0)
      setModalTitle(themebyid?"EDIT INNOVATION THEME":"NEW INNOVATION THEME");
  }, [themebyid, themePK])


  let modalClass = "modal";
  if (isThemeModalOpen) {
    modalClass += " is-active";
  }

  function renderContent() {
    return (
      <Fragment>
        <p className="pb-1 pl-1" style={{fontSize:"12px", fontWeight: "500"}}>Innovation Theme Title</p>
        <input
            className="input"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
        />

        <p className="pb-1 pl-1 pt-2" style={{fontSize:"12px", fontWeight: "500"}}>Innovation Theme Outcomes</p>
        <textarea
          className="textarea"
          rows="5"
          type="text"
          value={description}
          onChange={e => setDescription(e.target.value)}
        />

        <label className="checkbox pt-4" style={{fontSize:'12px', fontWeight:"500"}}>
          <input className="mr-2" type="checkbox" value={showtargets} checked={showtargets} onChange={e => setShowTargets(e.target.checked)}/>
          Show Theme Measures
        </label>

        {showtargets &&
        <Fragment>
          <div className="columns pt-2">
            <div className="column is-one-quarter">
              <p className="pl-1 pb-1" style={{fontSize:"12px", fontWeight: "500"}}>Starting Base</p>
              <input
                className="input"
                type="number"
                value={basevalue}
                onChange={e => setBaseValue(e.target.value)}
              />
            </div>
            <div className="column is-one-quarter">
            <p className="pl-1 pb-1" style={{fontSize:"12px", fontWeight: "500"}}>Target</p>
            <input
              className="input"
              type="number"
              value={targetvalue}
              onChange={e => setTargetValue(e.target.value)}
            />
            </div>
            <div className="column is-one-quarter">
            <p className="pl-1 pb-1" style={{fontSize:"12px", fontWeight: "500"}}>Units</p>
            <input
              className="input"
              type="text"
              value={units}
              onChange={e => setUnits(e.target.value)}
            />
            </div>
            <div className="column is-one-quarter">
            <p className="pl-1 pb-1" style={{fontSize:"12px", fontWeight: "500"}}>Decimal Places</p>
              <div className="select is-fullwidth">
                <select
                  defaultValue={0}
                  value={decimals}
                  onChange={e => setDecimals(e.target.value)}
                >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                </select>
              </div>
            </div>
          </div>
        </Fragment>
        }
            
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={modalClass} key="categoryModal">
        <div className="modal-background" key="modalBackground">
          {" "}
        </div>
        <div className="modal-card" key="modalCard">
          <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
            <p className="modal-card-title" style={{fontSize:"16px", color: textcolor}}>{modalTitle}</p>
            <button className="delete" aria-label="close" onClick={() => dispatch(closeThemeModal())}>
              {" "}
            </button>
          </header>
          <section className="modal-card-body" key="modalBody">
            {renderContent()}
          </section>
          <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

          {themePK === null &&
            <Fragment>
            <button
              style={{borderRadius:"4px"}}
              className="button is-success is-small"
              key="save"
              onClick={async () => {
                await dispatch(createThemes({theme: name, description: description, basevalue: basevalue, targetvalue: targetvalue, showtargets: showtargets, units: units, decimals: decimals}))
                .unwrap()
                    dispatch(fetchAllThemes());
                    dispatch(closeThemeModal());
            }}
            >
              Save Changes
            </button>
            </Fragment>
          }

          {themePK !== null &&
            <Fragment>
            <button
              style={{borderRadius:"4px"}}
              className="button is-success is-small"
              key="save"
              onClick={async () => {
                await dispatch(updateThemes({id: themePK, theme: name, description: description, basevalue: basevalue, targetvalue: targetvalue, showtargets: showtargets, units: units, decimals: decimals}))
                .unwrap()
                    dispatch(fetchAllThemes());
                    dispatch(closeThemeModal());
            }}
            >
              Save Changes
            </button>
            </Fragment>
          }

            <button className="button is-small" style={{borderRadius:"4px"}} key="cancel" onClick={() => dispatch(closeThemeModal())}>
              Cancel
            </button>
            <span className="is-flex-grow-1" />
            {themePK !==null && 
              <button
                style={{borderRadius:"4px"}}
                className="button is-danger is-small"
                key="delete"
                onClick={() => dispatch(openDeleteModal({message: name,recordpk: themePK, recordtype: 'themes'}))}
              >
                Delete
              </button>
          }
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default ThemesModal;
