import React, { useEffect, useState, Fragment} from "react";
import { useSelector, useDispatch } from "react-redux";
import { UserManagement } from "./settings/UserManagement";
import { fetchAllUsers } from "../redux/toolkit/features/tenants/users";


function Users() {
  const listResponse = Object.values(useSelector((state) =>  state.users.entities));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllUsers());
}, []);


  // if (!isAdmin) {
  //   return null;
  // }


  return (

  <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>
    <section style={{paddingLeft: "10%", paddingRight: "10%", marginTop: "2em"}}>
            <nav className="level pb-0 mb-0 pt-5">
                      <div className="level-left">
                          <div className="level-item">
                              <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "32px", lineHeight: "22px", paddingTop: "35px"}}>
                                  <div className="pr-1">
                                      USERS ({listResponse.length})<br/><br/>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </nav>
                  
                  <hr className="pt-0 mt-0 pb-0 mb-5" style={{borderTop: "2px solid lightgrey"}} />

        <UserManagement/>

      </section>
    </main>
  );
}

export { Users };
