import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllSprintinvestmentcell = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/sprintinvestmentcell`,
    authConfig()
  );
  return response.data.results;
};

export const fetchSprintinvestmentcell = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/sprintinvestmentcell/${id}`,
    authConfig()
  );
  return response.data;
};

export const createSprintinvestmentcell = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/sprintinvestmentcell`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateSprintinvestmentcell = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/sprintinvestmentcell/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteSprintinvestmentcell = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/sprintinvestmentcell/${id}`,
    authConfig()
  );
  return response.data;
};

const sprintinvestmentcellAPI = {
  fetchAllSprintinvestmentcell,
  fetchSprintinvestmentcell,
  createSprintinvestmentcell,
  updateSprintinvestmentcell,
  deleteSprintinvestmentcell,
};
export default sprintinvestmentcellAPI;
