import api from "../api/rolecompetencies";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const rolecompetenciesAdapter = createEntityAdapter({
  selectId: (rolecompetency) => rolecompetency.pk,
  //sortComparer: (a, b) => a.competency_id.localeCompare(b.competency_id),
});

const initialState = rolecompetenciesAdapter.getInitialState({
  status: "idle"
});

export const fetchAllRoleCompetencies = createAsyncThunk("rolecompetencies/fetch", () =>
  api.fetchAllRoleCompetencies()
);

export const updateRoleCompetencies = createAsyncThunk("rolecompetencies/update", api.updateRoleCompetencies);

export const createRoleCompetencies = createAsyncThunk("rolecompetencies/create", api.createRoleCompetencies);

export const deleteRoleCompetencies = createAsyncThunk("rolecompetencies/delete", api.deleteRoleCompetencies);


const rolecompetenciesSlice = createSlice({
  name: "rolecompetencies",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRoleCompetencies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRoleCompetencies.fulfilled, (state, { payload }) => {
        rolecompetenciesAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateRoleCompetencies.fulfilled, rolecompetenciesAdapter.updateOne)
      .addCase(deleteRoleCompetencies.fulfilled, rolecompetenciesAdapter.removeOne)
      .addCase(createRoleCompetencies.fulfilled, rolecompetenciesAdapter.addOne);
  },
});

export const { } = rolecompetenciesSlice.actions;

export default rolecompetenciesSlice.reducer;

const selectState = (state) => state.rolecompetencies;

export const {
  selectAll: selectRoleCompetencies,
  selectById: selectRoleCompetencyById,
} = rolecompetenciesAdapter.getSelectors(selectState);

export const selectRoleCompetenciesStatus = createSelector(
  selectState,
  (state) => state.status
);
