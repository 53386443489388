import api from "../../api/organization/localcurrency";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const localcurrencyAdapter = createEntityAdapter({
  selectId: (localcurrency) => localcurrency.pk,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = localcurrencyAdapter.getInitialState({status: "idle"});

export const fetchAllLocalcurrency = createAsyncThunk("localcurrency/fetch", () => api.fetchAllLocalcurrency());

export const updateLocalcurrency = createAsyncThunk("localcurrency/update", api.updateLocalcurrency);

export const createLocalcurrency = createAsyncThunk("localcurrency/create", api.createLocalcurrency);

export const deleteLocalcurrency = createAsyncThunk("localcurrency/delete", api.deleteLocalcurrency);

const localcurrencySlice = createSlice({
  name: "localcurrency",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLocalcurrency.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllLocalcurrency.fulfilled, (state, { payload }) => {
        localcurrencyAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateLocalcurrency.fulfilled, localcurrencyAdapter.updateOne)
      .addCase(deleteLocalcurrency.fulfilled, localcurrencyAdapter.removeOne)
      .addCase(createLocalcurrency.fulfilled, localcurrencyAdapter.addOne);
  },
});

export const { openPeopleModal, closePeopleModal } = localcurrencySlice.actions;

export default localcurrencySlice.reducer;

const selectState = (state) => state.localcurrency;

export const {
  selectAll: selectLocalcurrency,
  selectById: selectLocalcurrencyById,
} = localcurrencyAdapter.getSelectors(selectState);

export const selectLocalcurrencyStatus = createSelector(
  selectState,
  (state) => state.status
);
