import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllMetrics = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/talent/metrics`,
    authConfig()
  );
  return response.data.results;
};

export const fetchMetrics = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/talent/metrics/${id}`,
    authConfig()
  );
  return response.data;
};

export const createMetrics = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/talent/metrics`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateMetrics = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/talent/metrics/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteMetrics = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/talent/metrics/${id}`,
    authConfig()
  );
  return response.data;
};

const metricsAPI = {
  fetchAllMetrics,
  fetchMetrics,
  createMetrics,
  updateMetrics,
  deleteMetrics,
};
export default metricsAPI;
