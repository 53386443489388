import React, {Fragment, useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import 'bulma/css/bulma.css';
import { closeFXModal, createFX, fetchAllFX } from '../../../redux/toolkit/features/tenants/fx';

function FXNewPair(props) {
    const {basecurrency} = props;
    const isFXModalOpen = useSelector((state) =>  state.fx.isFXModalOpen);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const [currency, setCurrency] = useState("");
    const dispatch = useDispatch();

    console.log("Status,", isFXModalOpen, currency)

    let modalClass = 'modal';
    if (isFXModalOpen) {
        modalClass += ' is-active';
    }

    useEffect(() => {
    }, [isFXModalOpen]);

    async function AddFXRow() {
        await dispatch(createFX({pair:currency+basecurrency}))
        .unwrap()
        dispatch(fetchAllFX());
      }


    function EditPair() {
    return(
        <Fragment>
            <div className="columns is-centered mt-1 mb-1">
                <div className="column is-half">
                    <div className="field has-addons">
                        <p className="control" style={{width:'60px'}}>
                            <input className="input" type="text" placeholder="Text" maxLength={3} value={currency} onChange={(e)=> setCurrency((e.target.value).toUpperCase())} style={{textTransform: 'uppercase'}}/>
                        </p>
                        <p className="control p-0 mr-1">
                            <button className="button" style={{backgroundColor:'rgb(245, 245,245)',pointerEvents:'none'}}>
                            <span style={{fontWeight:'400'}}>{basecurrency}</span>
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
    }


    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card m-0 p-0' key='modalCard' style={{width:"320px"}}>
                <header className="modal-card-head" key="modalHeader" style={{height:'35px', backgroundColor:backgroundcolor}}>
                        <p style={{fontSize:'16px', color: 'white'}} className="modal-card-title">ENTER CURRENCY PAIR</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeFXModal(false))}>
                            {" "}
                        </button>
                    </header>
                   <section className='modal-card-body m-0 pl-1 pr-1 pt-0 pb-0' style={{overflow: "hidden", height: '68px'}} key='modalBody'>
                        {EditPair()}

                   </section>
                    <footer className='modal-card-foot is-flex' style={{height: "50px"}}key='modalFooter'>
                        <nav className='level'>
                            <div className='level-right'>
                                <div className='level-item'>
                                {(currency.length===3 && currency!==basecurrency) && 
                                <button className='button is-small is-success' key='cancel' 
                                    onClick={() => {
                                        AddFXRow()
                                        dispatch(closeFXModal(false))}
                                }>
                                        Save
                                </button>}
                                {(currency.length!==3 || currency===basecurrency) && 
                                <button className='button is-small' style={{pointerEvents:'none'}} key='cancel' onClick={() => dispatch(closeFXModal(false))}>
                                        Save
                                </button>}
                                 
                                 
                                </div>
                                <div className='level-item'>
                                    <button className='button is-small' key='cancel' onClick={() => dispatch(closeFXModal(false))}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </footer>
                </div>
            </div>

        </Fragment>

    );
}

export default FXNewPair;
