import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux'
import { closeDeleteModal }  from "./../redux/toolkit/features/modals/deleteConfirmModal";
import { setMenu } from "../redux/toolkit/features/menu";
import Spinner from "./common/Spinner";
import { deleteFacts, fetchAllFacts } from "../redux/toolkit/features/company/facts";
import { deleteTimelines, fetchAllTimelines } from "../redux/toolkit/features/company/timelines";
import { deleteValues, fetchAllValues } from "../redux/toolkit/features/company/values";
import { fetchAllCompany } from "../redux/toolkit/features/company/company";

//Import CSS
import "./common/css/main.css";
import "./common/css/masterstyles.css";
import "bulma-extensions/bulma-timeline/dist/css/bulma-timeline.min.css";

// Import Company Page Sections
import CompanyHeroSection from "./company/CompanyHeroSection";
import CompanyPurposeSection from "./company/CompanyPurposeSection";
import CompanyValuesSection from "./company/CompanyValuesSection";
import CompanyFactsSection from "./company/CompanyFactsSection";
import CompanyTimelineSection from "./company/CompanyTimelineSection";
import CompanyQuoteSection from "./company/CompanyQuoteSection";

// Import Modals
import CompanyHeroModal from "./company/modals/CompanyHeroModal";
import CompanyPurposeModal from "./company/modals/CompanyPurposeModal";
import CompanyQuoteModal from "./company/modals/CompanyQuoteModal";
import CompanyFactsModal from "./company/modals/CompanyFactsModal";
import CompanyFactsRecordModal from "./company/modals/CompanyFactsRecordModal";
import CompanyValuesModal from "./company/modals/CompanyValuesModal";
import CompanyValuesRecordModal from "./company/modals/CompanyValuesRecordModal";
import CompanyTimelineModal from "./company/modals/CompanyTimelineModal";
import CompanyTimelineRecordModal from "./company/modals/CompanyTimelineRecordModal";
import ConfirmDeleteBox from "./common/ConfirmDeleteBox";


function Company() {
    const company = Object.values(useSelector((state) => state.company.entities))[0];
 
    const isFactsModalOpen = useSelector((state) => state.company_state.isFactsModalOpen);
    const isFactsRecordModalOpen = useSelector((state) => state.company_state.isFactsRecordModalOpen);
    const isTimelineModalOpen = useSelector((state) => state.company_state.isTimelineModalOpen);
    const isTimelineRecordModalOpen = useSelector((state) => state.company_state.isTimelineRecordModalOpen);
    const isValuesModalOpen = useSelector((state) => state.company_state.isValuesModalOpen);
    const isValuesRecordModalOpen = useSelector((state) => state.company_state.isValuesRecordModalOpen);
    const isCompanyPurposeModalOpen = useSelector((state) => state.company_state.isCompanyPurposeModalOpen);
    const isCompanyHeroModalOpen = useSelector((state) => state.company_state.isCompanyHeroModalOpen);

    const dispatch = useDispatch(); 
    dispatch(setMenu('company'));
    const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);
    const recordkey = useSelector((state) => state.deleteConfirmModal.recordpk);    
    const [isDataLoading, setIsDataLoading] = useState(false);
    
     async function fetchCompany() {
        dispatch(fetchAllCompany());
    }

    useEffect(() => {
        async function fetchData() {
            setIsDataLoading(true);
                await fetchCompany();

            setIsDataLoading(false);
        }
        fetchData();
    },[]);

    async function onConfirmDelete(result) {
        if (result) {

            if (recordtype === 'facts') {
                await dispatch(deleteFacts(recordkey))
                    .unwrap()
                        dispatch(fetchAllFacts());
                        dispatch(closeDeleteModal());
            }

            if (recordtype === 'values') {
                await dispatch(deleteValues(recordkey))
                    .unwrap()
                        dispatch(fetchAllValues());
                        dispatch(closeDeleteModal());
            }

            if (recordtype === 'timeline') {
                await dispatch(deleteTimelines(recordkey))
                    .unwrap()
                        dispatch(fetchAllTimelines());
                        dispatch(closeDeleteModal());
            }

        }

    }

    if (!company) {
        return <Spinner/>;
    }

    function rendercompanypage() {
        return (
            <main className="has-navbar-fixed-top">

                <CompanyHeroSection
                    herotitle={company.herotitle}
                    herosubtitle={company.herosubtitle}
                    heroimage={company.heroimage}
                />
                {isCompanyHeroModalOpen && <CompanyHeroModal company={company}/>}
                
                {isCompanyPurposeModalOpen && <CompanyPurposeModal company={company}/>}
                <CompanyPurposeSection
                    purpose={company.purpose}
                    statement={company.statement}
                />
         
                <CompanyValuesSection/>
                {isValuesModalOpen && <CompanyValuesModal/>}      
                {isValuesRecordModalOpen && <CompanyValuesRecordModal/>}

                <CompanyQuoteSection company={company}/>
                <CompanyQuoteModal company={company}/>      

                <CompanyTimelineSection/>
                {isTimelineModalOpen && <CompanyTimelineModal/>}      
                {isTimelineRecordModalOpen && <CompanyTimelineRecordModal/>}  

                <CompanyFactsSection/>
                {isFactsModalOpen && <CompanyFactsModal/>}      
                {isFactsRecordModalOpen && <CompanyFactsRecordModal/>}

                <ConfirmDeleteBox onConfirmDelete={onConfirmDelete}/>

            </main>
        )
    }

    return (
        (isDataLoading?<Spinner/>:rendercompanypage())
    )
}

export { Company };
