import api from "../../api/organization/productivity";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const productivityAdapter = createEntityAdapter({
  selectId: (productivity) => productivity.pk,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = productivityAdapter.getInitialState({status: "idle"});

export const fetchAllProductivity = createAsyncThunk("productivity/fetch", () => api.fetchAllProductivity());

export const updateProductivity = createAsyncThunk("productivity/update", api.updateProductivity);

export const createProductivity = createAsyncThunk("productivity/create", api.createProductivity);

export const deleteProductivity = createAsyncThunk("productivity/delete", api.deleteProductivity);

const productivitySlice = createSlice({
  name: "productivity",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProductivity.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllProductivity.fulfilled, (state, { payload }) => {
        productivityAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateProductivity.fulfilled, productivityAdapter.updateOne)
      .addCase(deleteProductivity.fulfilled, productivityAdapter.removeOne)
      .addCase(createProductivity.fulfilled, productivityAdapter.addOne);
  },
});

export const { openPeopleModal, closePeopleModal } = productivitySlice.actions;

export default productivitySlice.reducer;

const selectState = (state) => state.productivity;

export const {
  selectAll: selectProductivity,
  selectById: selectProductivityById,
} = productivityAdapter.getSelectors(selectState);

export const selectProductivityStatus = createSelector(
  selectState,
  (state) => state.status
);
