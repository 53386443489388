import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllRoles = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/talent/position`,
    authConfig()
  );
  return response.data.results;
};

export const fetchRoles = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/talent/position/${id}`,
    authConfig()
  );
  return response.data;
};

export const createRoles = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/talent/position`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateRoles = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/talent/position/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteRoles = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/talent/position/${id}`,
    authConfig()
  );
  return response.data;
};

const rolesAPI = {
  fetchAllRoles,
  fetchRoles,
  createRoles,
  updateRoles,
  deleteRoles,
};
export default rolesAPI;
