import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllGantt = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-gantt`,
    authConfig()
  );
  return response.data.results;
};

export const fetchGantt = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-gantt/${id}`,
    authConfig()
  );
  return response.data;
};

export const createGantt = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roadmap-gantt`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateGantt = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/roadmap-gantt/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteGantt = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/roadmap-gantt/${id}`,
    authConfig()
  );
  return response.data;
};

export const orderGantt = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roadmap-gantt/sort-gantt`,
    data,
    authConfig()
  );
  return response.data;
};

const ganttAPI = {
  fetchAllGantt,
  fetchGantt,
  createGantt,
  updateGantt,
  deleteGantt,
  orderGantt,
};
export default ganttAPI;
