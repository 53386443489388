import React, {Fragment, useState, useContext, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import { peopledatasets } from '../../Organization';
import {filter} from "lodash"
//import SelectPeopleModal from "./PeopleModalSelectPeople";
import { createRoles, updateRoles, selectRoleById, fetchAllRoles } from "./../../../redux/toolkit/features/roles";
import { closeRolesModal, openRolesLinkPersonModal, openRolesLinkCompetencyModal, openRolesLinkReportingModal } from "../../../redux/toolkit/features/organization_state";
import { openDeleteModal } from "../../../redux/toolkit/features/modals/deleteConfirmModal";
import { fetchAllPositionparent } from "../../../redux/toolkit/features/organization/positionparent";
import { fetchAllPositionperson } from "../../../redux/toolkit/features/organization/positionperson";
import { fetchAllRoleview } from "../../../redux/toolkit/features/organization/roleview";
import { fetchAllMetrics } from "../../../redux/toolkit/features/organization/metrics";
import { fetchAllTenants } from "../../../redux/toolkit/features/tenants";
import { fetchAllFX } from "../../../redux/toolkit/features/tenants/fx";

function PeopleModal() {
    const isRoleModalOpen = useSelector((state) => state.organization_state.isRoleModalOpen)
    const isRoleLinkReportingModalOpen = useSelector((state) => state.organization_state.isRoleLinkReportingModalOpen)
    const roleid = useSelector((state) => state.organization_state.roleId)
    const rolebyid = useSelector((state)=>selectRoleById(state, roleid));  
    const positionparent = Object.values(useSelector((state) => state.positionparent.entities));
    const positionperson = Object.values(useSelector((state) => state.positionperson.entities));
    const tenant = Object.values(useSelector((state) => state.tenants.entities))[0];
    const fx = Object.values(useSelector((state) => state.fx.entities));

    const {roleview, personview, competencies, rolecompetencies} = useContext(peopledatasets);
    const tabs = ['Role', 'Manager', 'People', 'Compensation', 'Competencies'];
    const tabsshort = ['Role', 'Compensation'];
    const [activetab, setActivetab] = useState(tabs[0]);
    const [role, setRole] = useState(null);  
    const [rolestartdate, setRoleStartDate] = useState("");    
    const [roleenddate, setRoleEndDate] = useState("");    
    const [rolesuccession, setRoleSuccession] = useState(false);    
    const [compensationcurrency, setCompensationCurrency] = useState('CAD');    
    const [compensationbase, setCompensationBase] = useState(null);    
    const [compensationSTI, setCompensationSTI] = useState(null);    
    const [compensationLTI, setCompensationLTI] = useState(null);    
    const [compensationbenefits, setCompensationBenefits] = useState(null);     
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)

    const dispatch = useDispatch(); 

    let rolecompensationbasevalue= (rolebyid?rolebyid.compensationbase: 0)   
    let rolecompensationstivalue= (rolebyid?rolebyid.compensationsti:0)
    let rolecompensationltivalue= (rolebyid?rolebyid.compensationlti: 0)   
    let rolecompensationbenefitsvalue= (rolebyid?rolebyid.compensationbenefits :0)
    let rolecompensationcurrencyvalue= (rolebyid?rolebyid.compensation_currency: "CAD")   

    useEffect(() => {
      dispatch(fetchAllPositionparent());
      setRole(rolebyid?rolebyid.title :"");
      setRoleStartDate(rolebyid?rolebyid.startdate: null)
      setRoleEndDate(rolebyid?rolebyid.enddate :null)
      setRoleSuccession(rolebyid?rolebyid.issuccession :null)
      setCompensationBase(rolecompensationbasevalue)
      setCompensationSTI(rolecompensationstivalue)
      setCompensationLTI(rolecompensationltivalue)
      setCompensationBenefits(rolecompensationbenefitsvalue)
      setCompensationCurrency(rolebyid?rolebyid.compensation_currency :'CAD')
      }, [roleid, rolebyid]);

      useEffect(() => {
            dispatch(fetchAllTenants());
            dispatch(fetchAllFX());
            dispatch(fetchAllRoles());
      }, []);

      useEffect(() => {
        if (!isRoleLinkReportingModalOpen) {
            dispatch(fetchAllRoleview());
            dispatch(fetchAllRoles());
            dispatch(fetchAllPositionparent());
            dispatch(fetchAllPositionperson());
        }
      }, [isRoleLinkReportingModalOpen]);

      useEffect(() => {
        if (!isRoleModalOpen) {
        setRoleEndDate("");
        setRoleStartDate("");
        }
      }, [isRoleModalOpen]);

    let modalTitle=(!roleid?"ADD ROLE":"EDIT ROLE")
    let modalClass = "modal";
    if (isRoleModalOpen) {
        modalClass += " is-active";
    }

    function formtabs() {
                return (
                    <div className="tabs is-boxed">
                        <ul>
                            {roleid !== null &&
                            <Fragment>
                            {tabs.map(tab => (
                                <li className ={activetab === tab ? "is-active" : ""}
                                    key={"tab_"+tab}
                                    onClick={() => setActivetab(tab)}
                                    style={{color:"grey"}}
                                >
                                <a>{tab}</a>
                                </li>
                            ))}
                            </Fragment>
                            }
                            {roleid === null &&
                            <Fragment>
                            {tabsshort.map(tab => (
                                <li className ={activetab === tab ? "is-active" : ""}
                                    key={"tab_"+tab}
                                    onClick={() => setActivetab(tab)}
                                    style={{color:"grey"}}
                                >
                                <a>{tab}</a>
                                </li>
                            ))}
                            </Fragment>
                            }
                        </ul>
                    </div>
                )
            }
    

    function renderrole() {

        return(
            <Fragment>
            <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Role Title</p>
            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            className="input"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            />
                    </div>
                </div>
            </div>

            <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Start Date</p>
            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            type="date" 
                            className="input"
                            value={rolestartdate}
                            onChange={(e) => setRoleStartDate(e.target.value)}
                            />
                    </div>
                </div>
            </div>

            <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>End Date</p>
            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            type="date" 
                            className="input"
                            value={roleenddate}
                            onChange={(e) => setRoleEndDate(e.target.value)}
                            />
                    </div>
                </div>
            </div>

            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                    <input className="ml-1 mt-2" type="checkbox" checked={rolesuccession} onChange={(e) => setRoleSuccession(e.target.checked)} />
                    <label className="checkbox pl-2" style={{fontWeight:400}}>Include in Talent Planning Pipeline</label>
                    </div>
                </div>
            </div>
            </Fragment>
        )
    }

    function rendercompensation() {
        let optionlist=[]
        optionlist.push(<option value={tenant.basecurrency!==null?tenant.basecurrency:'USD'}>{tenant.basecurrency!==null?tenant.basecurrency:'USD'}</option>)
        fx.sort((a,b)=> a.pair.localeCompare(b.pair)).map((fxpair) => optionlist.push(<option value={(fxpair.pair).substring(0,3)}>{(fxpair.pair).substring(0,3)}</option>))

        return (
            <Fragment>

            <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Total Compensation</p>
            <div className="field has-addons">
                <div className="control is-expanded">
                    <input 
                        //style={{borderWidth:0, boxShadow: "none", pointerEvents: "none"}}
                        style={{pointerEvents: "none"}}
                        readOnly
                        className="input"
                        value={Number(compensationbase) + Number(compensationSTI) + Number(compensationLTI) + Number(compensationbenefits)}
                    />
                </div>
                <div className="control">
                <span className="select">
                    <select 
                        style={{width:'100px'}}
                        value={compensationcurrency}
                        onChange={(e) => setCompensationCurrency(e.target.value)}
                        >
                        {optionlist}
                    </select>
                </span>
                </div>
            </div>



            <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Base Salary</p>
            <div className="field has-addons">
                    <div className="control is-expanded">
                        <input 
                            type="number"
                            className="input"
                            value={compensationbase}
                            onChange={(e) => setCompensationBase(e.target.value)}
                            />
                    </div>
                    <div className="control">
                        <input 
                            style={{width:'100px' ,pointerEvents: "none"}}
                            type="text"
                            className="input"
                            readOnly
                            value={compensationcurrency}
                            />
                    </div>
                </div>



            <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Short-Term Incentive / Cash Bonus</p>
            <div className="field has-addons">
                    <div className="control is-expanded">
                        <input 
                            type="number"
                            className="input"
                            value={compensationSTI}
                            onChange={(e) => setCompensationSTI(e.target.value)}
                            />
                    </div>
                    <div className="control">
                        <input 
                            style={{width:'100px' ,pointerEvents: "none"}}
                            type="text"
                            className="input"
                            readOnly
                            value={compensationcurrency}
                            />
                    </div>
                </div>


            <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Long-Term Incentive / Stock & Options</p>
            <div className="field has-addons">
                    <div className="control is-expanded">
                        <input 
                            type="number"
                            className="input"
                            value={compensationLTI}
                            onChange={(e) => setCompensationLTI(e.target.value)}
                            />
                    </div>
                    <div className="control">
                        <input 
                            style={{width:'100px' ,pointerEvents: "none"}}
                            type="text"
                            className="input"
                            readOnly
                            value={compensationcurrency}
                            />
                    </div>
                </div>

            <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Benefits</p>
            <div className="field has-addons">
                    <div className="control is-expanded">
                        <input 
                            type="number"
                            className="input"
                            value={compensationbenefits}
                            onChange={(e) => setCompensationBenefits(e.target.value)}
                            />
                    </div>
                    <div className="control">
                        <input 
                            style={{width:'100px' ,pointerEvents: "none"}}
                            type="text"
                            className="input"
                            readOnly
                            value={compensationcurrency}
                            />
                    </div>
                </div>
            </Fragment>
        )
    }

    function renderreporting() {
        let reportingrow=filter(roleview, o => (o.role_id === roleid && o.parent_id !== null)) 
        if (reportingrow.length !== 0) {
            return (
                <Fragment>
                <table className='table is-fullwidth' style={{borderCollapse: 'separate', borderSpacing: '0 10px'}}>
                    <tbody>
                    {reportingrow.map((row, index) => (
                         renderreportingcards(row)
                     ))}
                </tbody>
                </table>
                </Fragment>
            )
        } else {
            return (
                <p className="pl-4" style={{fontWeight:'500', fontSize:'15px'}}>No Role Identified</p>
            )
        }
    }

    function renderreportingcards(row) {
        let tenure=null
        if (row.reporting_startdate !== null || row.reporting_enddate!== null) {
            tenure=' ['
            if (row.reporting_startdate !== null) {
                tenure=tenure + ' Starts: ' +  (row.reporting_startdate).toString()
            }
            if (row.reporting_enddate !== null) {
                tenure=tenure + ' Ends: ' +  (row[0].reporting_enddate).toString()
            }
            tenure=tenure +' ]'
        }
    return(
        <Fragment>
        <tr className="ml-4 pl-5" colSpan="2" style={{fontSize:"15px"}}>
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', fontWeight:"400"}} onClick={() => dispatch(openRolesLinkReportingModal({reportingrolePK:row.parent_id, rolerolePK:row.role_id}))}><p>{row.parent}<span className="pl-2">{tenure}</span></p></td>     
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} 
                    onClick={() => dispatch(openDeleteModal({message: row.parent,recordpk: (filter(positionparent,{parent:row.parent_id, position:row.role_id}))[0].pk, recordtype: 'rolereporting'}))}
            >
            <button className="far fa-trash-alt" style={{color:"gray"}}
                ></button>
            </td>
        </tr>
    </Fragment>
    )

    }

    function reportingtenure(rolenumber, rolerow) {
        let tenure=null
        // if (rolerow[rolenumber].startdate !== null || rolerow[rolenumber].enddate!== null) {
        //     tenure=' ['
        //     if (rolerow[rolenumber].startdate !== null) {
        //         tenure=tenure + ' Starts: ' +  (rolerow[rolenumber].startdate).toString()
        //     }
        //     if (rolerow[rolenumber].enddate !== null) {
        //         tenure=tenure + ' Ends: ' +  (rolerow[rolenumber].enddate).toString()
        //     }
        //     tenure=tenure +' ]'
        // }
        return(tenure)
    }

    function renderpeople() {
        let peoplerow=filter(personview,{position_id: roleid})
    
        if (peoplerow.length !== 0) {
            return (
                <Fragment>
                <table className='table is-fullwidth' style={{borderCollapse: 'separate', borderSpacing: '0 10px'}}>
                    <tbody>
                    {peoplerow.map(row => (
                    renderpeoplecards(row)
                ))}
                </tbody>
                </table>
                </Fragment>
            )
        } else {
            return (
                <p className="pl-4" style={{fontWeight:'500', fontSize:'15px'}}>No Employee Assigned</p>
            )
        }
    }

    function renderpeoplecards(row) {
        let tenure=null
        if (row.startdate !== null || row.enddate!== null) {
            tenure=' ['
            if (row.startdate !== null) {
                tenure=tenure + ' Starts: ' +  (row.startdate).toString()
            }
            if (row.enddate !== null) {
                tenure=tenure + ' Ends: ' +  (row[0].enddate).toString()
            }
            tenure=tenure +' ]'
        }

        return(
            <Fragment>
            <tr className="ml-4 pl-5" colSpan="2" style={{fontSize:"15px"}}>
                <td style={{cursor:'pointer', borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', fontWeight:"400"}} onClick={() => dispatch(openRolesLinkPersonModal({personrolePK:roleid, personpersonPK:row.person_id}))}><p>{row.firstname + ' ' + row.secondname}<span className="pl-2" style={{fontWeight:400}}>{tenure}</span></p></td>     
                <td style={{cursor:'pointer',borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} 
                        onClick={() => dispatch(openDeleteModal({message: row.parent,recordpk: (filter(positionperson,{person:row.person_id, position:row.position_id}))[0].pk, recordtype: 'positionperson'}))}
                >
                <button className="far fa-trash-alt" style={{color:"gray"}}
                    ></button>
                </td>
            </tr>
        </Fragment>
        )
    }


    function rendercompetencies() {
        let rolecompetencyrow=filter(rolecompetencies,{role_id: roleid})
        let competency_ids = rolecompetencyrow.map(r => r.competency_id)
        let competenciesrow=filter( competencies, (row) => competency_ids.includes(row.pk))
        if (competenciesrow.length !== 0) {
            return (
                <Fragment>
                    <table className='table is-fullwidth' style={{borderCollapse: 'separate', borderSpacing: '0 10px'}}>
                        <tbody>
                        {competenciesrow && competenciesrow.map(row => (
                            <Fragment>
                                <tr className="ml-4 pl-5" colSpan="2" style={{fontSize:"15px"}}>
                                    <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', fontWeight:"400"}} >{row.competency}</td>     
                                    <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} 
                                        onClick={() => dispatch(openDeleteModal({message: row.competency,recordpk: (filter(rolecompetencyrow,{competency_id:row.pk}))[0].pk, recordtype: 'rolecompetency'}))}
                                    >
                                    <button className="far fa-trash-alt" style={{color:"gray"}}
                                        ></button>
                                    </td>
                                </tr>
                            </Fragment>
                        ))}
                        </tbody>
                    </table>
                </Fragment>
            )
        } else {
            return (
                <p className="pl-4" style={{fontWeight:'500', fontSize:'15px'}}>No Competencies Assigned</p>
            )
        }
    }

    function rendercompetenciescards() {
        
    }



    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{modalTitle}</p>
                        <button className="delete" aria-label="close" onClick={() => { dispatch(closeRolesModal()); setActivetab(tabs[0]); }}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: "450px"}}>
                        {formtabs()}
                        {activetab==='Role' && renderrole()}
                        {activetab==='Compensation' && rendercompensation()}
                        {activetab==='Manager' && renderreporting()}
                        {activetab==='Competencies' && rendercompetencies()}
                        {activetab==='People' && renderpeople()}                        

                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <nav className='level'>
                            <div className='level-left'>
                                <div className='level-item'>
                                    {roleid === null && role!=="" &&
                                    <Fragment>
                                    <button
                                        style={{borderRadius:"4px"}}
                                        className="button is-success is-small"
                                        key="save"
                                        onClick={async () => {
                                            await dispatch(createRoles({title:role, startdate:rolestartdate, enddate:roleenddate,compensationbase:compensationbase,compensationsti:compensationSTI,compensationlti:compensationLTI, compensationbenefits:compensationbenefits,compensation_currency:compensationcurrency}))
                                            .unwrap()
                                                dispatch(fetchAllRoleview())
                                                dispatch(fetchAllRoles())
                                                dispatch(closeRolesModal());
                                                dispatch(fetchAllMetrics());
                                        }}
                                        >
                                        Save Changes
                                    </button>
                                    </Fragment>
                                    }

                                    {roleid !== null && 
                                    <Fragment>
                                    <button
                                        style={{borderRadius:"4px"}}
                                        className="button is-success is-small"
                                        key="save"
                                        onClick={async () => {
                                            await dispatch(updateRoles({id: roleid, title:role, startdate:rolestartdate, enddate:roleenddate,issuccession:rolesuccession, compensationbase:compensationbase,compensationsti:compensationSTI,compensationlti:compensationLTI, compensationbenefits:compensationbenefits,compensation_currency:compensationcurrency}))
                                            .unwrap()
                                                dispatch(fetchAllRoleview())
                                                dispatch(fetchAllRoles())
                                                dispatch(closeRolesModal());
                                                dispatch(fetchAllMetrics());
                                        }}
                                        >
                                        Save Changes
                                    </button>
                                    </Fragment>
                                    }

                                    <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} 
                                        onClick={async () => {
                                        dispatch(closeRolesModal());
                                        setActivetab(tabs[0]);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            <div className='level-right'>
                                <div className='level-item'>
                                    {activetab==="Manager" && <button className="button is-pulled-right is-small" key="newreport" onClick={() => dispatch(openRolesLinkReportingModal({rolerolePK:roleid}))} style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} >
                                        Add Reporting
                                    </button>}
                                    {activetab==="Competencies" && <button className="button is-pulled-right is-small" key="newcompetency" style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} onClick={() => dispatch(openRolesLinkCompetencyModal({competencyrolePK:roleid}))}>
                                        Assign Competency
                                    </button>}
                                    {activetab==="People" && <button className="button is-pulled-right is-small" key="newemployee" onClick={() => dispatch(openRolesLinkPersonModal({personrolePK:roleid}))} style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} >
                                        Assign Employee
                                    </button>}
                                </div>
                            </div>
                        </nav>
                    </footer>
                </div>
            </div>


        {/* <SelectPeopleModal 
            //isSelectPeopleModal={isSelectPeopleModal}
            toggleSelectPeopleModal={toggleSelectPeopleModal}
        /> */}

        </Fragment>


    );
}

export default PeopleModal;
