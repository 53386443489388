import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllGrowth = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-cellgrowth`,
    authConfig()
  );
  return response.data.results;
};

export const fetchGrowth = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-cellgrowth/${id}`,
    authConfig()
  );
  return response.data;
};

export const createGrowth = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roadmap-cellgrowth`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateGrowth = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/roadmap-cellgrowth/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteGrowth = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/roadmap-cellgrowth/${id}`,
    authConfig()
  );
  return response.data;
};

const roadmapgrowthAPI = {
  fetchAllGrowth,
  fetchGrowth,
  createGrowth,
  updateGrowth,
  deleteGrowth,
};
export default roadmapgrowthAPI;
