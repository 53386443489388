import React, {Fragment, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllSprints } from "./../../../redux/toolkit/features/sprints/sprints";
import { fetchAllSprintlinks } from "./../../../redux/toolkit/features/success/successsprintlinks";
import {filter} from 'lodash'
import { openDeleteModal } from "./../../../redux/toolkit/features/modals/deleteConfirmModal";



function RenderSprint() {
    const linkedsprints = Object.values(useSelector((state) =>  state.sprintlinks.entities));
    const sprints = Object.values(useSelector((state) =>  state.sprints.entities));
    const successId = useSelector((state) => state.success_state.successBaseId)
    const dispatch = useDispatch();

    async function fetchSprintlinksdata() {
        dispatch(fetchAllSprintlinks());
        dispatch(fetchAllSprints());
    }

    useEffect(() => {
        fetchSprintlinksdata();
      }, []);


    var filteredlinkedsprints = filter(linkedsprints, {success:successId})

    function renderRow(row) {
        let sprinttitle = filter(sprints, {pk:row.sprint_id})
        return (
        <tr className="ml-4 pl-5" colSpan="2" style={{fontSize:"15px"}}>
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', fontWeight:"400"}}>
            {sprinttitle[0]?sprinttitle[0].title:""}
            </td>
            <td  colSpan="2" className="mb-2 ml-5 pl-2" style={{borderTop:"solid 1px grey", borderBottom:"solid 1px grey"}}>
            {row.event}
            </td>                 
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}}
                onClick={async () => {dispatch(openDeleteModal({message: "Sprint",recordpk: row.pk, recordtype: 'sprint'}))
            }}>
            <a><button className="far fa-trash-alt" style={{color:"gray"}}/></a>
            </td>
        </tr>
        )
    }


    if (filteredlinkedsprints.length !== 0) {
        return (
            <Fragment>
            <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
                <tbody>
                    {filteredlinkedsprints.map(row => (
                        <Fragment key={"Timeline_Modal_fragment_"+row.pk}>
                            {renderRow(row)}
                        </Fragment>
                    ))}
                </tbody>
            </table>
        </Fragment>
        );
    } else {
        return (
            <p style={{fontWeight:'400', fontSize:'0.95em'}}>No Linked Sprints Found</p>
        )
    }


}

export {RenderSprint};
