import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllCagr = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-cellcagr`,
    authConfig()
  );
  return response.data.results;
};

export const fetchCagr = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-cellcagr/${id}`,
    authConfig()
  );
  return response.data;
};

export const createCagr = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roadmap-cellcagr`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateCagr = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/roadmap-cellcagr/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteCagr = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/roadmap-cellcagr/${id}`,
    authConfig()
  );
  return response.data;
};

const roadmapcagrAPI = {
  fetchAllCagr,
  fetchCagr,
  createCagr,
  updateCagr,
  deleteCagr,
};
export default roadmapcagrAPI;
