import React, {Fragment, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import "bulma/css/bulma.css";
import {filter} from "lodash"
import { SprintInvestmentRow } from "./Investments/SprintInvestmentRow"
import { closeSprintInvestmentModal } from "../../../redux/toolkit/features/sprints_state";
import { createSprintinvestment, fetchAllSprintinvestment } from "../../../redux/toolkit/features/sprints/sprintinvestment";
import { createSprintinvestmentcell, fetchAllSprintinvestmentcell } from "../../../redux/toolkit/features/sprints/sprintinvestmentcell";
import { openDeleteModal } from "../../../redux/toolkit/features/modals/deleteConfirmModal";
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';


function SprintInvestmentModal(props) {
    const {minYear, maxYear} = props;
    const sprintinvestment = Object.values(useSelector((state) => state.sprintinvestment.entities));
    const sprintinvestmentcell = Object.values(useSelector((state) => state.sprintinvestmentcell.entities));
    const isSprintInvestmentModalOpen = useSelector((state) => state.sprints_state.isSprintInvestmentModalOpen);
    const sprintInvestmentPK = useSelector((state) => state.sprints_state.sprintInvestmentPK);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch();


    let modalClass = "modal";
    if (isSprintInvestmentModalOpen) {
        modalClass += " is-active";
    }

    useEffect(() => {
        dispatch(fetchAllSprintinvestment());
        dispatch(fetchAllSprintinvestmentcell());
    },[])

    async function commitSprintInvestment(item) {
        await dispatch(createSprintinvestment({item: item , sprint: sprintInvestmentPK}))
        .unwrap()
            dispatch(fetchAllSprintinvestment());
    }

    async function commitSprintInvestmentcell(item) {
        await dispatch(createSprintinvestment({item: item , sprint: sprintInvestmentPK}))
        .unwrap()
            dispatch(fetchAllSprintinvestmentcell());
    }


    function renderTotals(sprint) {
        let sprintarray = filter(sprintinvestment, {sprint:sprintInvestmentPK})
        if (sprintarray.length > 0) {
            const totalinputs = [];
            for (let year = minYear; year <= maxYear; year++) {
                var yeartotal=0
                sprintarray.map((sprint) => {
                    let rowvalue = filter(sprintinvestmentcell, {sprintinvestment:sprint.pk, year:year})
                    yeartotal=yeartotal+(rowvalue[0]?rowvalue[0].value:0)
            })
            totalinputs.push(<td className="has-text-centered"><NumericTextBoxComponent showSpinButton={false} decimals={0} format='n0'  style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontWeight:'600'}} value={yeartotal}/></td>);
        }
        return(
            <tr>
                <td style={{fontWeight:'500', fontSize:'0.9em'}}>Totals</td>
                {totalinputs}
                <td></td>
            </tr>
        )
        }
    }

    function renderHeader() {
        return (
            <Fragment>
                <tr>
                    <th key={'Tableheader'}>Item</th>
                    {renderYearHeaders()}
                </tr>
            </Fragment>
        )
    }

    function renderYearHeaders() {
        const headers = [];
        for (let year = minYear; year <= maxYear; year++) {
            if (year <  new Date().getFullYear()) {
            headers.push(<th className="has-text-centered" key={year}>{year} Actual</th>);
            } else {
                headers.push(<th className={'has-text-centered'} key={year}>{year}</th>);
            }
        }
        headers.push(<th onClick={() => commitSprintInvestment(null)} className={'has-text-centered ml-1 mr-0 pl-1 pr-0'}><button className="fas fa-plus-circle" style={{color:backgroundcolor}}></button></th>);
       
       return headers;
    }

    function renderForm() {
        let sprintarray = filter(sprintinvestment, {sprint:sprintInvestmentPK})
        return (
            <table className="table is-fullwidth">
                <tbody>
                    {renderHeader()}
                    {sprintarray && sprintarray.length > 0 && sprintarray.map((sprint) => (
                        <SprintInvestmentRow row={sprint} cells={sprintinvestmentcell} minYear={minYear} maxYear={maxYear}/>
                      ))}
                    {renderTotals()}
                </tbody>
            </table>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard" style={{minWidth:"80%"}}>
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title"style={{fontSize:"17px", color: textcolor}}>REQUIRED FUNDING</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeSprintInvestmentModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{width:"100%"}}>
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px", justifyContent:'right'}}>
                            <button className="button is-small" key="cancel" onClick={() => dispatch(closeSprintInvestmentModal())}>
                            Close
                            </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SprintInvestmentModal;
