import React, { useState, useEffect, Fragment } from "react";
import "../common/css/masterstyles.css";
import { useDispatch, useSelector } from "react-redux";
import { tenantsActions } from "../../redux/actions/tenants";
import { tenantsService } from "../../services/TenantsService";
import BaseService from "../../services/BaseService";
import { fetchAllTenants, updateTenants } from "../../redux/toolkit/features/tenants";
import { fetchAllFX, createFX, deleteFX, openFXModal } from "../../redux/toolkit/features/tenants/fx";
import { fetchAllFXRow } from "../../redux/toolkit/features/tenants/fxrow";
import { FXFormDetail } from "./FXFormDetail";
import {filter} from "lodash"
import FXNewPair from "./modals/FXNewPair"


function FXForm() {
  const tenant = Object.values(useSelector((state) =>  state.tenants.entities))[0];
  const fx = Object.values(useSelector((state) =>  state.fx.entities));
  const fxrow = Object.values(useSelector((state) =>  state.fxrow.entities));
  const [base, setBase] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [background, setBackground] = useState("");
  const [text, setText] = useState("");
  const tenantError = useSelector((state) => state.tenants.error);
  const [nameError, setNameError] = useState("");
  const [saved, setSaved] = useState(false);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false)
  const [colortype, setColortype] = useState(background)

  useEffect(() => {
    dispatch(fetchAllFX());
    dispatch(fetchAllFXRow());
    dispatch(fetchAllTenants());
    setBase(tenant?tenant.basecurrency:'Currency Not Set')
  }, []);


  if (!tenant) {
    return null;
  }


  async function UpdateBaseCurrency(e) {
    if (e.keyCode === 13 && e.target.value!==tenant.basecurrency){
      if (e.target.value.length===3) {
        await dispatch(updateTenants({id:tenant.pk,basecurrency:e.target.value.toUpperCase()}))
        .unwrap()
        dispatch(fetchAllTenants())
      }
    }
  }

  function TableHeaders() {
    let yearrows=[];
    for (let year = new Date().getFullYear(); year <= 2028; year++) {
        yearrows.push(<th className="has-text-centered">{year}</th>)     
    }

    return (
      <tr>
        <th className='pl-0 ml-0'>Currency Pair</th>
      {yearrows}
      <td></td>
      <th style={{width:"18px", color: backgroundcolor}} ><a onClick={()=> dispatch(openFXModal({fxPK:null}))} style={{color: backgroundcolor}} ><button className="fas fa-plus-circle"></button></a></th>
 
      </tr>
    )
  }

  function TableRows() {  
    return(
      <Fragment>
      {fx && fx.map(f => (
        <FXFormDetail f={f}/>
      ))
      }
      </Fragment>
    )
  }


  function FXTable() {
    return (
      <Fragment>
      <hr className="pt-0 mt-0 pb-0 mb-0" style={{borderTop: "2px solid lightgrey"}} />
      <table className="table is-fullwidth ml-0 pl-0">
        <tbody ml-0 pl-0>
          {TableHeaders()}
          {fx.length===0 && <tr><td colSpan={6}>No Records Returned</td></tr>}
          {fx.length!==0 && TableRows()}
        </tbody>
      </table>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <nav className="level mb-3 p-0" style={{height:'20px'}}>
        <div className="level-left m-0 p-0">
          <div className="level-item m-0 p-0">
          <p style={{fontSize:"16px", fontWeight:'600',fontFamily: 'Arial, Helvetica, san-serif'}}>Base Currency:</p>
          </div>
          <div className="level-item ml-2 pb-1">
          <input className="mt-1" style={{textTransform:'uppercase', fontSize:'15px', fontWeight:'600', border:"none", cursor:'pointer', width:'43px', fontFamily: 'Arial, Helvetica, san-serif'}} onKeyDown={(e) => UpdateBaseCurrency(e)} maxLength={3} onChange={(e)=>setBase(e.target.value)} value={base}/>
          </div>
        </div>
      </nav>
      {FXTable()}
      <FXNewPair basecurrency={tenant.basecurrency}/>
      </Fragment>
      
  );

}

export { FXForm };
