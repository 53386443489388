import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, message: null, recordpk: null, recordtype: "" };

const modalSlice = createSlice({
  name: "deleteConfirmModal",
  initialState,
  reducers: {
    openDeleteModal: (state, action) => {
        state.isOpen = true;
        state.message = action.payload.message;
        state.recordpk = action.payload.recordpk;
        state.recordtype = action.payload.recordtype;
    },
    transitionDeleteModal(state) {
      state.isOpen = false;
      state.message = null;
      state.recordpk = null;
    },

    closeDeleteModal(state) {
      state.isOpen = false;
      state.message = null;
      state.recordpk = null;
      state.recordtype = null;
    },
  },
});

export const { openDeleteModal, transitionDeleteModal, closeDeleteModal } = modalSlice.actions;

export default modalSlice.reducer;

const selectState = (state) => state.deleteConfirmModal;

export const selectDeleteModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectDeleteModalMessage = createSelector(
  selectState,
  (state) => state.message
);
