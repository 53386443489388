import api from "../api/roadmapsection";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const sectionsAdapter = createEntityAdapter({
  selectId: (section) => section.pk,
  //sortComparer: (a, b) => a.section.localeCompare(b.section),
});

const initialState = sectionsAdapter.getInitialState({
  status: "idle", isCategory: true, category: null, isOpen: false, sectionpk: null, isDetailModalOpen: null, isEditRowsModalOpen: false, editrowpk: null, isGraphModalOpen: false, chartsectionpk: null,
  isGraphEditModalOpen: false, chartpk: null, charteditsectionpk: null
});

export const fetchAllSections = createAsyncThunk("sections/fetch", () =>
  api.fetchAllSections()
);

export const updateSections = createAsyncThunk("sections/update", api.updateSections);

export const createSections = createAsyncThunk("sections/create", api.createSections);

export const deleteSections = createAsyncThunk("sections/delete", api.deleteSections);

export const orderSections = createAsyncThunk("sections/order", api.orderSections);

const roadmapsectionSlice = createSlice({
  name: "sections",
  initialState,
  reducers: {
    setCategory(state, action) {
      state.isCategory = true;
      state.category = action.payload.category;
    },
    openRoadmapSectionsModal(state) {
      state.isOpen = true;
    },
    closeRoadmapSectionsModal(state) {
      state.isOpen = false;
    },
    openRoadmapSectionsDetailModal(state, action) {
      state.isDetailModalOpen = true;
      state.sectionpk = action.payload.sectionpk;
    },
    closeRoadmapSectionsDetailModal(state) {
      state.isDetailModalOpen = false;
      state.sectionpk = null;
    },
    openEditRowsModal(state, action) {
      state.isEditRowsModalOpen = true;
      state.editrowpk = action.payload.editrowpk;
    },
    closeEditRowsModal(state) {
      state.isEditRowsModalOpen = false;
      state.editrowpk = null;
    },
    openGraphsModal(state, action) {
      state.isGraphModalOpen = true;
      state.chartsectionpk = action.payload.chartsectionpk;
    },
    closeGraphsModal(state) {
      state.isGraphModalOpen = false;
      state.chartsectionpk = null;
    },
    openGraphsEditModal(state, action) {
      state.isGraphEditModalOpen = true;
      state.chartpk = action.payload.chartpk;
      state.charteditsectionpk = action.payload.charteditsectionpk;
    },
    closeGraphsEditModal(state) {
      state.isGraphEditModalOpen = false;
      state.chartpk = null;
      state.charteditsectionpk = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSections.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllSections.fulfilled, (state, { payload }) => {
        sectionsAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateSections.fulfilled, sectionsAdapter.updateOne)
      .addCase(deleteSections.fulfilled, sectionsAdapter.removeOne)
      .addCase(createSections.fulfilled, sectionsAdapter.addOne);
  },
});

export const { setCategory, openRoadmapSectionsModal, closeRoadmapSectionsModal, openRoadmapSectionsDetailModal, closeRoadmapSectionsDetailModal, openEditRowsModal, closeEditRowsModal, openGraphsModal, closeGraphsModal,
  openGraphsEditModal, closeGraphsEditModal } = roadmapsectionSlice.actions;

export default roadmapsectionSlice.reducer;

const selectState = (state) => state.sections;

export const {
  selectAll: selectSections,
  selectById: selectSectionById,
} = sectionsAdapter.getSelectors(selectState);

export const selectSectionsStatus = createSelector(
  selectState,
  (state) => state.status
);
