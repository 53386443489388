import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { SprintDetail } from "./SprintDetail";

function Sprint(props) {
  const {filteredSprints} = props; 
  const sprintActionStatus = useSelector((state) => state.sprints.sprintAction)
  
  useEffect(() => {
  }, [sprintActionStatus]);


  return (
    <Fragment>
                  {filteredSprints && filteredSprints.length !== 0 && 
                      filteredSprints.map((sprint) => (
                              <SprintDetail 
                                sprintPK={sprint.pk}
                              />
                      ))}
                {filteredSprints.length === 0 && 
                <p style={{fontSize:"16.5px"}}>No Sprints Returned</p>
                }
                

    </Fragment>
  );
}

export { Sprint };
