import { React, Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { closePeopleModal, updatePersons, createPersons, selectPersonById, fetchAllPersons } from "./../../../redux/toolkit/features/organization/persons";
import { fetchAllPersonsview } from "./../../../redux/toolkit/features/organization/personview";

function PeoplesModal() {
    const showModalPeople = useSelector((state) => state.persons.isOpen)
    const peoplerecordkey = useSelector((state) => state.persons.peoplepk)
    const peoplebyid = useSelector((state)=>selectPersonById(state, peoplerecordkey));  
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const [firstname, setFirstname] = useState(null);
    const [secondname, setSecondname] = useState(null);
    const dispatch = useDispatch(); 

    let modalClass = "modal";
    if (showModalPeople) {
        modalClass += " is-active";
    }

    useEffect(() => {
        dispatch(fetchAllPersons());
        setFirstname(peoplebyid?peoplebyid.firstname :"");
        setSecondname(peoplebyid?peoplebyid.secondname: "");
      },[peoplebyid])


    if (!peoplebyid && peoplerecordkey!==null) {
        return null;
    }

    function renderForm() {
        return (
            <Fragment>
                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>First Name</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <input 
                                className="input"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                />
                        </div>
                    </div>
                </div>

                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Second Name</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <input 
                                className="input"
                                value={secondname}
                                onChange={(e) => setSecondname(e.target.value)}
                                />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        { peoplerecordkey &&
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>EDIT EMPLOYEE</p>}
                        { !peoplerecordkey &&
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>NEW EMPLOYEE</p>}

                        <button className="delete" aria-label="close" onClick={() => dispatch(closePeopleModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: '150px'}}>
                    {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    {peoplerecordkey === null &&
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(createPersons({firstname: firstname, secondname: secondname }))
                                .unwrap()
                                .then(() => {
                                    dispatch(fetchAllPersonsview());
                                    dispatch(fetchAllPersons());
                                    dispatch(closePeopleModal())
                                })
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}

                        {peoplerecordkey !== null &&
                        <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(updatePersons({id: peoplerecordkey, firstname: firstname, secondname: secondname }))
                                .unwrap()
                                .then(() => {
                                    dispatch(fetchAllPersonsview());
                                    dispatch(fetchAllPersons());
                                    dispatch(closePeopleModal())
                                })
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closePeopleModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default PeoplesModal;
