import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class BaselineDeltaService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/baseline-delta`;
    }

    async getCells() {
        const baselinedelta = await this.getAll();
        return baselinedelta.results;
    }
}

export const baselineDeltaService = new BaselineDeltaService();
