import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllPersonsview = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/personview`,
    authConfig()
  );
  return response.data.results;
};

export const fetchPersonsview = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/personview/${id}`,
    authConfig()
  );
  return response.data;
};

export const createPersonsview = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/personview`,
    data,
    authConfig()
  );
  return response.data;
};

export const updatePersonsview = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/personview/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deletePersonsview = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/personview/${id}`,
    authConfig()
  );
  return response.data;
};

const personsviewAPI = {
  fetchAllPersonsview,
  fetchPersonsview,
  createPersonsview,
  updatePersonsview,
  deletePersonsview,
};
export default personsviewAPI;
