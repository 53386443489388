import api from "../../api/sprints/sprintinvestmentcell";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const sprintinvestmentcellAdapter = createEntityAdapter({
  selectId: (sprintinvestmentcell) => sprintinvestmentcell.pk,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = sprintinvestmentcellAdapter.getInitialState({ status: "idle" });

export const fetchAllSprintinvestmentcell = createAsyncThunk("sprintinvestmentcell/fetch", () => api.fetchAllSprintinvestmentcell());

export const updateSprintinvestmentcell = createAsyncThunk("sprintinvestmentcell/update", api.updateSprintinvestmentcell);

export const createSprintinvestmentcell = createAsyncThunk("sprintinvestmentcell/create", api.createSprintinvestmentcell);

export const deleteSprintinvestmentcell = createAsyncThunk("sprintinvestmentcell/delete", api.deleteSprintinvestmentcell);


const sprintinvestmentcellSlice = createSlice({
  name: "sprintinvestmentcell",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSprintinvestmentcell.pending, (state) => {state.status = "loading";})
      .addCase(fetchAllSprintinvestmentcell.fulfilled, (state, { payload }) => {
        sprintinvestmentcellAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateSprintinvestmentcell.fulfilled, sprintinvestmentcellAdapter.updateOne)
      .addCase(deleteSprintinvestmentcell.fulfilled, sprintinvestmentcellAdapter.removeOne)
      .addCase(createSprintinvestmentcell.fulfilled, sprintinvestmentcellAdapter.addOne);
  },
});

export const {} = sprintinvestmentcellSlice.actions;

export default sprintinvestmentcellSlice.reducer;

const selectState = (state) => state.sprintinvestmentcell;

export const {
  selectAll: selectSprintinvestmentcell,
  selectById: selectSprintinvestmentcellById,
} = sprintinvestmentcellAdapter.getSelectors(selectState);

export const selectSprintinvestmentcellStatus = createSelector(
  selectState,
  (state) => state.status
);

