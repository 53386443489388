import api from "../../api/sprints/progressbar";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const progressbarAdapter = createEntityAdapter({
  selectId: (progressbar) => progressbar.pk,
});

const initialState = progressbarAdapter.getInitialState({status: "idle"});

export const fetchAllProgressbar = createAsyncThunk("progressbar/fetch", () => api.fetchAllProgressbar());

export const updateProgressbar = createAsyncThunk("progressbar/update", api.updateProgressbar);

export const createProgressbar = createAsyncThunk("progressbar/create", api.createProgressbar);

export const deleteProgressbar = createAsyncThunk("progressbar/delete", api.deleteProgressbar);

const progressbarSlice = createSlice({
  name: "progressbar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProgressbar.pending, (state) => {state.status = "loading";})
      .addCase(fetchAllProgressbar.fulfilled, (state, { payload }) => {
        progressbarAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateProgressbar.fulfilled, progressbarAdapter.updateOne)
      .addCase(deleteProgressbar.fulfilled, progressbarAdapter.removeOne)
      .addCase(createProgressbar.fulfilled, progressbarAdapter.addOne);
  },
});

export const {} = progressbarSlice.actions;

export default progressbarSlice.reducer;

const selectState = (state) => state.progressbar;

export const {
  selectAll: selectProgressbar,
  selectById: selectProgressbarById,
} = progressbarAdapter.getSelectors(selectState);

export const selectProgressbarStatus = createSelector(
  selectState,
  (state) => state.status
);
