import React, {Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { closeGanttModal } from "../../../redux/toolkit/features/roadmaps_state";
import { selectGanttById, fetchAllGantt, createGantt, updateGantt } from "../../../redux/toolkit/features/roadmaps/gantt";
import { openDeleteModal } from "../../../redux/toolkit/features/modals/deleteConfirmModal";
import { MultiSelect } from "react-multi-select-component";
import {filter} from 'lodash'


function SectionsModalEditGantt() {
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
    const textcolor = useSelector((state) => state.colors.textcolor);
    const ganttpk = useSelector((state) => state.roadmaps_state.ganttrowpk);
    const sectionpk = useSelector((state) => state.roadmaps_state.ganttsectionpk);
    const isGanttModalOpen = useSelector((state) => state.roadmaps_state.isGanttModalOpen);
    const gantt = Object.values(useSelector((state) => state.gantt.entities)).sort((a, b) => a.order - b.order);
    const ganttbyid = useSelector((state)=>selectGanttById(state, ganttpk));
    const [activity, setActivity] = useState("");
    const [startdate, setStartdate] = useState(null);
    const [enddate, setEnddate] = useState(null);
    const [duration, setDuration] = useState(0);
    const [percent, setPercent] = useState(0);    
    const [selected, setSelected] = useState([]) 
    const [dependencies, setDependencies] = useState('')
    const dispatch = useDispatch();


    let ganttrecord=[ganttbyid?ganttbyid.pk:null]
    let sectiongantt=filter(gantt,{section:sectionpk})
    var dependencieslist=filter(sectiongantt, function(gantt) {return !ganttrecord.includes(gantt.pk)});
    var selectlist= []
    dependencieslist.map(list => selectlist.push({label:list.taskname,value:list.pk}))

  
    console.log("GBI", ganttbyid)

    useEffect(() => {
        setActivity(ganttbyid?ganttbyid.taskname:"")
        setStartdate(ganttbyid?ganttbyid.startdate:null)
        setEnddate(ganttbyid?ganttbyid.enddate:null)
        setDuration(ganttbyid?ganttbyid.duration:0)
        setPercent(ganttbyid?ganttbyid.percentcomplete:0)
        setDependencies(ganttbyid?ganttbyid.dependencies:'')
    }, [ganttpk]);

    useEffect(() => {
        if (ganttbyid) {
        let dependciesstripped = dependencies
        let dependenciesbuild=[]
        if (dependciesstripped!==null) {
            dependciesstripped = dependciesstripped.replaceAll('FS','')
            dependenciesbuild= dependciesstripped.split(',');
        }
    
        let dependenciesbuildarray = []
        dependenciesbuild.map(list => {
            let label = filter(gantt, {pk:Number(list)})
            dependenciesbuildarray.push({label:label[0]?label[0].taskname:'',value:Number(list)})
            setSelected(dependenciesbuildarray)
        })
    }
    }, [dependencies]);


    useEffect(() => {
        dispatch(fetchAllGantt())
    }, []);

    function Dependencies(args) {
        setSelected(args)
        let dependenciesarray = args.map((s) => {return s.value+'FS'}).join(',')
        setDependencies(dependenciesarray)
    }

    
    let modalClass = 'modal';
    if (isGanttModalOpen) {
        modalClass += ' is-active'
    }

    function modalform() {


        return (
            <Fragment>
            <p className="mb-1" style={{fontSize: "smaller"}}>Activity Name</p>
            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input
                            className="input"
                            type="text"
                            value={activity}
                            onChange={async (event) => {setActivity(event.target.value)}}
                        />
                    </div>
                </div>
            </div>

            <p className="mb-1" style={{fontSize: "smaller"}}>Start Date</p>
            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input
                            className="input"
                            type="date"
                            value={startdate}
                            onChange={async (event) => {setStartdate(event.target.value)}}
                        />
                    </div>
                </div>
            </div>

            <p className="mb-1" style={{fontSize: "smaller"}}>End Date</p>
            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input
                            className="input"
                            type="date"
                            value={enddate}
                            onChange={async (event) => {setEnddate(event.target.value)}}
                        />
                    </div>
                </div>
            </div>


            <p className="mb-1" style={{fontSize: "smaller"}}>Days Duration</p>
            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input
                            className="input"
                            type="number"
                            value={duration}
                            onChange={async (event) => {setDuration(event.target.value)}}
                        />
                    </div>
                </div>
            </div>     

            <p className="mb-1" style={{fontSize: "smaller"}}>Dependencies</p>
            <MultiSelect 
                options={selectlist}         
                value={selected}
                onChange={Dependencies.bind()}
                labelledBy="Taskname"
                hasSelectAll={false}
            />

            <p className="mb-1" style={{fontSize: "smaller"}}>Percent Complete</p>
            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input
                            className="input"
                            type="number"
                            min="0" max="100"
                            value={percent}
                            onChange={async (event) => {setPercent(event.target.value)}}
                        />
                    </div>
                </div>
            </div>     



        </Fragment>
        )
    }

    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none",zIndex:'100'}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
            <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
            <p className="modal-card-title" style={{fontSize:"16px", color: textcolor}}>GANTT DETAILS</p>
                    <button className="delete" aria-label="close" onClick={() => dispatch(closeGanttModal())}>
                        {" "}
                    </button>
                </header>
                <section className="modal-card-body" key="modalBody" style={{minHeight: "330px"}}>
                  {modalform()}
                </section>
                <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                <nav className="level">
                    <div className="level-left">
                        <div className="level-item">
                            {ganttpk === null &&
                                <button
                                    style={{borderRadius:"4px"}}
                                    className="button is-success is-small"
                                    key="save"
                                    onClick={async () => {
                                        await dispatch(createGantt({taskname: activity,startdate:startdate, enddate: enddate, duration:duration, section:sectionpk, percentcomplete:percent, dependencies: dependencies}))
                                        .unwrap()
                                            dispatch(fetchAllGantt());
                                            dispatch(closeGanttModal());
                                    }}
                                >
                                    Save Changes
                                </button>
                            }

                            {ganttpk !== null &&
                                <button
                                    style={{borderRadius:"4px"}}
                                    className="button is-success is-small"
                                    key="save"
                                    onClick={async () => {
                                        await dispatch(updateGantt({id: ganttpk, taskname: activity,startdate:startdate, enddate: enddate, duration:duration, section:sectionpk, percentcomplete: percent, dependencies: dependencies}))
                                        .unwrap()
                                             dispatch(fetchAllGantt());
                                            dispatch(closeGanttModal());

                                    }}
                                >
                                    Save Changes
                                </button>
                            }

                            <button className="button is-small" key="cancel" 
                                onClick={() => {
                                    dispatch(closeGanttModal())
                                }}>
                                Cancel
                            </button>

                        </div>
                    </div>

                    {ganttpk!==null &&
                    <div className="level-right">
                        <div className="level-item">
                            <button className="button is-small is-danger" key="cancel" style={{borderRadius:"4px"}} 
                                onClick={async () => {
                                    dispatch(openDeleteModal({message: activity,recordpk: ganttpk, recordtype: 'gantt'}))
                                    dispatch(closeGanttModal());
                        }}>
                                Delete
                            </button>
                        </div>
                    </div>}
                </nav>


                </footer>
            </div>
        </div>

    </Fragment>
    );
}

export {SectionsModalEditGantt}
