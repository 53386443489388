import React from 'react';
//import '../common/css/masterstyles.css';
import Header from './Header';
import Row from './Row';

function Matrix(props) {
  const { stakeholders, categories, matrices, companymatrix } = props;

  return (
    <table className='table is-fullwidth is-hoverable is-bordered'>
      <Header 
        stakeholders={stakeholders} 
      />


      {categories.map((row) => (
        <Row
          category={row}
          stakeholders={stakeholders}
          matrices={matrices}
          companymatrix={companymatrix}
        />
      ))}
    </table>
  );
}

export default Matrix;
