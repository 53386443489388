import api from "../../api/sprints/comments";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const commentsAdapter = createEntityAdapter({
  selectId: (comments) => comments.pk,
});

const initialState = commentsAdapter.getInitialState({status: "idle"});

export const fetchAllComments = createAsyncThunk("comments/fetch", () => api.fetchAllComments());

export const updateComments = createAsyncThunk("comments/update", api.updateComments);

export const createComments = createAsyncThunk("comments/create", api.createComments);

export const deleteComments = createAsyncThunk("comments/delete", api.deleteComments);

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllComments.pending, (state) => {state.status = "loading";})
      .addCase(fetchAllComments.fulfilled, (state, { payload }) => {
        commentsAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateComments.fulfilled, commentsAdapter.updateOne)
      .addCase(deleteComments.fulfilled, commentsAdapter.removeOne)
      .addCase(createComments.fulfilled, commentsAdapter.addOne);
  },
});

export const {} = commentsSlice.actions;

export default commentsSlice.reducer;

const selectState = (state) => state.comments;

export const {
  selectAll: selectComments,
  selectById: selectCommentById,
} = commentsAdapter.getSelectors(selectState);

export const selectCommentsStatus = createSelector(
  selectState,
  (state) => state.status
);
