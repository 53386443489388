import api from "../../api/innovation/themes";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const themesAdapter = createEntityAdapter({
  selectId: (theme) => theme.pk,
  sortComparer: (a, b) => a.theme.localeCompare(b.theme),
});

const initialState = themesAdapter.getInitialState({status: "idle"});

export const fetchAllThemes = createAsyncThunk("themes/fetch", () => api.fetchAllThemes());

export const updateThemes = createAsyncThunk("themes/update", api.updateThemes);

export const createThemes = createAsyncThunk("themes/create", api.createThemes);

export const deleteThemes = createAsyncThunk("themes/delete", api.deleteThemes);

const themesSlice = createSlice({
  name: "themes",
  initialState,
  reducers: {
    openThemeModal(state, action) {
      state.isModalOpen = true;
      state.themeModalpk = action.payload;
    },
    closeThemeModal(state) {
      state.isModalOpen = false;
      state.themeModalpk = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllThemes.pending, (state) => {state.status = "loading"})
      .addCase(fetchAllThemes.fulfilled, (state, { payload }) => {
        themesAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateThemes.fulfilled, themesAdapter.updateOne)
      .addCase(deleteThemes.fulfilled, themesAdapter.removeOne)
      .addCase(createThemes.fulfilled, themesAdapter.addOne);
  },
});

export const {} = themesSlice.actions;

export default themesSlice.reducer;

const selectState = (state) => state.themes;

export const {
  selectAll: selectThemes,
  selectById: selectThemeById,
} = themesAdapter.getSelectors(selectState);

export const selectThemesStatus = createSelector(
  selectState,
  (state) => state.status
);
