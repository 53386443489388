import api from "../../api/sprints/progressbaritem";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const progressbaritemAdapter = createEntityAdapter({
  selectId: (progressbaritem) => progressbaritem.pk,
  sortComparer: (a, b) => a.order - b.order,
});

const initialState = progressbaritemAdapter.getInitialState({status: "idle"});

export const fetchAllProgressbaritem = createAsyncThunk("progressbaritem/fetch", () => api.fetchAllProgressbaritem());

export const updateProgressbaritem = createAsyncThunk("progressbaritem/update", api.updateProgressbaritem);

export const createProgressbaritem = createAsyncThunk("progressbaritem/create", api.createProgressbaritem);

export const deleteProgressbaritem = createAsyncThunk("progressbaritem/delete", api.deleteProgressbaritem);

export const orderProgressbaritem = createAsyncThunk("progressbaritem/order", api.orderProgressbaritem);

const progressbaritemSlice = createSlice({
  name: "progressbaritem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProgressbaritem.pending, (state) => {state.status = "loading";})
      .addCase(fetchAllProgressbaritem.fulfilled, (state, { payload }) => {
        progressbaritemAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateProgressbaritem.fulfilled, progressbaritemAdapter.updateOne)
      .addCase(deleteProgressbaritem.fulfilled, progressbaritemAdapter.removeOne)
      .addCase(createProgressbaritem.fulfilled, progressbaritemAdapter.addOne);
  },
});

export const {} = progressbaritemSlice.actions;

export default progressbaritemSlice.reducer;

const selectState = (state) => state.progressbaritem;

export const {
  selectAll: selectProgressbaritem,
  selectById: selectProgressbaritemById,
} = progressbaritemAdapter.getSelectors(selectState);

export const selectProgressbaritemStatus = createSelector(
  selectState,
  (state) => state.status
);
