import React, {Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { closeSprintCommentModal } from "../../../redux/toolkit/features/sprints_state";
import { createComments, updateComments, selectCommentById } from "../../../redux/toolkit/features/sprints/comments";
import { usersService } from "../../../services/UsersService";


function SprintCommentModal(props) {
    const {sprintPK} = props;
    const isSprintCommentModalOpen = useSelector((state) => state.sprints_state.isSprintCommentModalOpen)
    const commentPK = useSelector((state) => state.sprints_state.commentPK)
    const commentsprintPK = useSelector((state) => state.sprints_state.commentsprintPK)
    const commentById = useSelector((state)=>selectCommentById(state, commentPK));
    const [commentvalue,setCommentvalue] = useState("");
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch();

    const [currentUser, setCurrentUser] = useState(null);


    async function fetchCurrentUser() {
      const currentUser = await usersService.getCurrentUser();
      setCurrentUser(currentUser);  
    }
  
    useEffect(() => {
      fetchCurrentUser();
    }, []);
  

    useEffect(() => {
        setCommentvalue(commentById?commentById.comment:"")
      }, [commentPK]);


      if (!currentUser) {
        return null;
    }


    const currentDate = new Date()
    var username = currentUser.first_name + ' ' + currentUser.last_name

    let modalClass = "modal";
    if (isSprintCommentModalOpen) {
        modalClass += " is-active";
    }



    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">

                        <textarea 
                        className="textarea" 
                        rows="7"
                        value={commentvalue}
                        onChange = {(e) => setCommentvalue(e.target.value)}
                        />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

//     const currentDate = new Date()
//     const datelog = moment(currentDate, 'MM/DD/YYYY')
//     if (key) {
//       await registersService.patch(key, {comment: sprintcommentvalue, date: currentDate});
//   } else {
//       let username = currentUser.first_name + ' ' + currentUser.last_name
//       await registersService.post({username: username, comment: sprintcommentvalue,date: currentDate,sprint: sprintid});
//   }
//     await fetchSprints();
//   }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none", zIndex:100}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" style={{width:"500px"}} key="modalCard">
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                <p className="modal-card-title"style={{fontSize:"17px", color: textcolor}}>{commentPK!== null?'EDIT COMMENT':'ADD NEW COMMENT'}</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeSprintCommentModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        {commentPK === null &&
                            <button
                                className="button is-success is-small"
                                style={{borderRadius:"4px"}}
                                key="save"
                                onClick={async () => {
                                    dispatch(createComments({username:username, date: new Date(), comment:commentvalue, sprint:commentsprintPK }))
                                    dispatch(closeSprintCommentModal());
                                }}
                            >
                                Save changes
                            </button>
                        }

                        {commentPK !== null &&
                            <button
                                className="button is-success is-small"
                                style={{borderRadius:"4px"}}
                                key="save"
                                onClick={async () => {
                                    dispatch(updateComments({id:commentPK, username:username, date: new Date(), comment:commentvalue, sprint:commentsprintPK }))
                                    dispatch(closeSprintCommentModal());
                                }}
                            >
                                Save changes
                            </button>
                        }

                        <button className="button is-small" style={{borderRadius:"4px"}} key="cancel" onClick={() => dispatch(closeSprintCommentModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SprintCommentModal;
