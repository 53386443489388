import {
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

const company_stateAdapter = createEntityAdapter();

const initialState = company_stateAdapter.getInitialState({
  isCompanyHeroModalOpen:false,
  isCompanyPurposeModalOpen:false,
  isCompanyQuoteModalOpen:false,
  isTimelineModalOpen:false,
  isTimelineRecordModalOpen:false,
  timelinePK:null,
  isFactsModalOpen:false,
  isFactsRecordModalOpen:false,
  factPK:null,
  isValuesModalOpen:false,
  isValuesRecordModalOpen:false,
  valuePK:null
});

const company_stateSlice = createSlice({
  name: "company_state",
  initialState,
  reducers: {
    openCompanyHeroModal(state) {
      state.isCompanyHeroModalOpen = true;
    },
    closeCompanyHeroModal(state) {
      state.isCompanyHeroModalOpen = false;
    },
    openCompanyPurposeModal(state) {
      state.isCompanyPurposeModalOpen = true;
    },
    closeCompanyPurposeModal(state) {
      state.isCompanyPurposeModalOpen = false;
    },
    openCompanyQuoteModal(state) {
      state.isCompanyQuoteModalOpen = true;
    },
    closeCompanyQuoteModal(state) {
      state.isCompanyQuoteModalOpen = false;
    },
    openTimelineModal(state) {
      state.isTimelineModalOpen = true;
    },
    closeTimelineModal(state) {
      state.isTimelineModalOpen = false;
    },
    openTimelineRecordModal(state, action) {
      state.isTimelineRecordModalOpen = true;
      state.timelinePK = action.payload.timelinePK;
    },
    closeTimelineRecordModal(state) {
      state.isTimelineRecordModalOpen = false;
      state.timelinePK = null;
    },
    openFactsModal(state) {
      state.isFactsModalOpen = true;
    },
    closeFactsModal(state) {
      state.isFactsModalOpen = false;
    },
    openFactsRecordModal(state,action) {
      state.isFactsRecordModalOpen = true;
      state.factPK = action.payload.factPK;
    },
    closeFactsRecordModal(state) {
      state.isFactsRecordModalOpen = false;
      state.factPK = null;
    },
    openValuesModal(state) {
      state.isValuesModalOpen = true;
    },
    closeValuesModal(state) {
      state.isValuesModalOpen = false;
    },
    openValuesRecordModal(state,action) {
      state.isValuesRecordModalOpen = true;
      state.valuePK = action.payload.valuePK;
    },
    closeValuesRecordModal(state) {
      state.isValuesRecordModalOpen = false;
      state.valuePK = null;
    },
  },
});

export const { 
  openCompanyHeroModal,
  closeCompanyHeroModal,
  openCompanyPurposeModal,
  closeCompanyPurposeModal,
  openCompanyQuoteModal,
  closeCompanyQuoteModal,
  openTimelineModal,
  closeTimelineModal,
  openTimelineRecordModal,
  closeTimelineRecordModal,
  openFactsModal,
  closeFactsModal,
  openFactsRecordModal,
  closeFactsRecordModal,
  openValuesModal,
  closeValuesModal,
  openValuesRecordModal,
  closeValuesRecordModal
} = company_stateSlice.actions;

export default company_stateSlice.reducer;



