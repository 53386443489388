import React, {Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllInnovationlinks } from "./../../../redux/toolkit/features/success/successinnovationlinks";
import { fetchAllThemes } from "./../../../redux/toolkit/features/innovation/themes";
import {filter} from 'lodash'
import { openDeleteModal } from "./../../../redux/toolkit/features/modals/deleteConfirmModal";


function RenderInnovation() {
    const linkedthemes = Object.values(useSelector((state) =>  state.innovationlinks.entities));
    const themes = Object.values(useSelector((state) =>  state.themes.entities))
    const successId = useSelector((state) => state.success_state.successBaseId)
    const dispatch = useDispatch();
   
    async function fetchRoadmaplinksdata() {
        dispatch(fetchAllInnovationlinks());
        dispatch(fetchAllThemes());
    }

    useEffect(() => {
        fetchRoadmaplinksdata();
      }, []);

    var filteredlinkedthemes = filter(linkedthemes, {success:successId})

    function renderRow(row) {
        let themename = filter(themes, {pk:row.theme_id})

        return (
        <tr className="ml-4 pl-5" colSpan="2" style={{fontSize:"15px"}}>
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}}>
                {themename[0]?themename[0].theme:""}
            </td>        
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}}
                onClick={async () => {dispatch(openDeleteModal({message: "Innovation Theme",recordpk: row.pk, recordtype: 'theme'}))
            }}>
            <a><button className="far fa-trash-alt" style={{color:"gray"}}/></a>
            </td>
        </tr>
        )
    }

    if (filteredlinkedthemes.length !== 0) {
        return (
            <Fragment>
            <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
                <tbody>
                    {filteredlinkedthemes.map(row => (
                        <Fragment key={"Timeline_Modal_fragment_"+row.pk}>
                            {renderRow(row)}
                        </Fragment>
                    ))}
                </tbody>
            </table>
        </Fragment>
        );
    } else {
        return (
            <p style={{fontWeight:'400', fontSize:'0.95em'}}>No Linked Innovation Themes Found</p>
        )
    }
}

export {RenderInnovation};
