import api from "../../api/organization/overallscores";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const overallscoresAdapter = createEntityAdapter({
  selectId: (score) => score.pk,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = overallscoresAdapter.getInitialState({
  status: "idle", isOpen: false, peoplepk: null
});

export const fetchAllOverallscores = createAsyncThunk("overallscores/fetch", () => api.fetchAllOverallscores());

export const updateOverallscores = createAsyncThunk("overallscores/update", api.updateOverallscores);

export const createOverallscores = createAsyncThunk("overallscores/create", api.createOverallscores);

export const deleteOverallscores = createAsyncThunk("overallscores/delete", api.deleteOverallscores);

const overallscoresSlice = createSlice({
  name: "overallscores",
  initialState,
  reducers: {
    openPeopleModal(state, action) {
      state.isOpen = true;
      state.peoplepk = action.payload;
    },
    closePeopleModal(state) {
      state.isOpen = false;
      state.peoplepk = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOverallscores.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllOverallscores.fulfilled, (state, { payload }) => {
        overallscoresAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateOverallscores.fulfilled, overallscoresAdapter.updateOne)
      .addCase(deleteOverallscores.fulfilled, overallscoresAdapter.removeOne)
      .addCase(createOverallscores.fulfilled, overallscoresAdapter.addOne);
  },
});

export const { openPeopleModal, closePeopleModal } = overallscoresSlice.actions;

export default overallscoresSlice.reducer;

const selectState = (state) => state.overallscores;

export const {
  selectAll: selectOverallscores,
  selectById: selectOverallScoreById,
} = overallscoresAdapter.getSelectors(selectState);

export const selectOverallscoresStatus = createSelector(
  selectState,
  (state) => state.status
);
