import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllFacts, updateFacts, createFacts, selectFactById } from "./../../../redux/toolkit/features/company/facts";
import { openFactsModal, closeFactsRecordModal} from "./../../../redux/toolkit/features/company_state";

function CompanyFactsRecordModal() {
    const factPK = useSelector((state) => state.company_state.factPK);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const isFactsRecordModalOpen = useSelector((state) => state.company_state.isFactsRecordModalOpen);
    const factbyid = useSelector((state)=>selectFactById(state, factPK))  
    const dispatch = useDispatch();
    const [fact, setFact] = useState("");

      useEffect(() => {
        if (factbyid) {
        setFact(factbyid.fact)
        } else {
        setFact("")   
        }
      }, [factbyid])

     let modalClass = "modal";
      if (isFactsRecordModalOpen) {
          modalClass += " is-active";
      }

    var modaltitle='';
    var placeholdertext='';
    if (factPK===null) {
        modaltitle="NEW COMPANY FACT"
        placeholdertext="Company Fact"
    } else {
        modaltitle="COMPANY FACT"
    }

    if (!factbyid && factPK!==null) {
        return null;
    }
 
    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            {fact !== undefined &&
                            <textarea 
                                className="textarea"
                                rows="6"
                                placeholder={placeholdertext}
                                value={fact}
                                onChange={(e) => setFact(e.target.value)}
                                />}
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{modaltitle}</p>
                        <button className="delete" aria-label="close" onClick={() => {
                                dispatch(closeFactsRecordModal());
                                dispatch(openFactsModal());
                        }}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    {factPK === null &&
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"

                            onClick={async () => {
                                await dispatch(createFacts({fact: fact}))
                                .unwrap()
                                    dispatch(fetchAllFacts());
                                    setFact("")
                                    dispatch(closeFactsRecordModal());
                                    dispatch(openFactsModal());
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}

                        {factPK !== null &&
                        <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(updateFacts({id:factPK, fact: fact}))
                                .unwrap()
                                    dispatch(fetchAllFacts());
                                    setFact("")
                                    dispatch(closeFactsRecordModal());
                                    dispatch(openFactsModal());
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} 
                            onClick={() => {
                                dispatch(closeFactsRecordModal());
                                dispatch(openFactsModal());
                                }}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default CompanyFactsRecordModal;
