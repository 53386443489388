import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";
import {authConfig} from "./helpers";
import axios from "axios";

class FactsService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/facts`;
    }

    async getFacts() {
        const facts = await this.getAll();
        return facts.results;
    }

    async sortFacts(factPkList){
        const url = this.listUrl() + "/sort-facts";
        const config = authConfig();
        const response = await axios.post(url, {"sorted_pks": factPkList}, config);
        return response.data;
    }
}

export const factsService = new FactsService();
