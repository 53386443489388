import React, { useState, useEffect } from "react";
import "./common/css/masterstyles.css";
import { useDispatch, useSelector } from 'react-redux';
import { loginActions } from "../redux/actions/login";
import logolight from "./login/strat2gylogin.png"
import logodark from "./login/strat2gylogin_dark.png"


function Login(props) {
    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const { username, password } = inputs;
    const loggingIn = useSelector(state => state.login.loggingIn);
    const loginError = useSelector(state => state.login.loginError);
    const loginErrorResult = useSelector(state => state.login.error);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loginActions.logout());
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (username && password) {
            dispatch(loginActions.login(username, password));
        }
    }

    function clearError() {
        dispatch(loginActions.clearError())
    }

    function renderLoginError() {
        if (!loginError) {
            return null;
        }
        return (
            <div className="notification is-warning">
                <button className="delete" onClick={clearError}> </button>
                {loginErrorResult}
            </div>
        );
    }

    return (
        <section className="m-0 p-0">
                    <div className="columns is-vcentered is-centered m-0 p-0">
                        <div className="column is-half">
                            <form className="container mb-6 is-fullwidth has-text-centered" style={{width:'400px'}}>
                            <img 
                                alt="Strat2gy Logo"
                                src={logodark}
                                style={{ height: "40px", userSelect:'none', pointerEvents: 'none'}}
                            />
                            <p className="mb-5" style={{fontWeight:'600', color:'rgb(33, 60, 143)', fontSize:'1.5em', userSelect:'none', pointerEvents: 'none' }}>Login to Strat2gy</p>
                                <div className="field">
                                    <div className="control has-icons-left">
                                        <input type="text" name="username" onChange={handleChange}
                                            value={username} placeholder="Email Address"
                                            className="input" required />
                                        <span className="icon is-small is-left">
                                            <i className="fa fa-user"> </i>
                                        </span>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control has-icons-left">
                                        <input type="password" name="password" onChange={handleChange}
                                            value={password} placeholder="Password" className="input"
                                            required />
                                        <span className="icon is-small is-left">
                                            <i className="fa fa-lock"> </i>
                                        </span>
                                    </div>
                                </div>
                                <div className="field">
                                    <button className="button is-success is-fullwidth" style={{backgroundColor:'rgb(33, 60, 143)'}} onClick={handleSubmit} disabled={loggingIn}>
                                        Login
                                    </button>
                                </div>
                                {renderLoginError()}
                            </form>
                            </div>


                        <div className="column is-half m-0 p-0" style={{backgroundColor:'rgb(33, 60, 143)', height:'100vh'}}>
                        <nav class="level p-0 m-0" style={{height:'100%'}}>
                        <div class="level-item has-text-centered p-0 m-0">
                            <img className="mb-6 ml-0 mr-0 mt-0 p-0"
                                alt="Strat2gy Logo"
                                src={logolight}
                                style={{height: "140px"}}
                            />
                            </div>
                            </nav>
                        </div>
                    </div>

        </section>
    );
}

export { Login };
