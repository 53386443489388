import api from "../../api/organization/roleview";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const roleviewAdapter = createEntityAdapter({
  selectId: (role) => role.pk,
  sortComparer: (a, b) => a.role.localeCompare(b.role),
});

const initialState = roleviewAdapter.getInitialState({
  status: "idle"
});

export const fetchAllRoleview = createAsyncThunk("roleview/fetch", () => api.fetchAllRoleview());

export const updateRoleview = createAsyncThunk("roleview/update", api.updateRoleview);

export const createRoleview = createAsyncThunk("roleview/create", api.createRoleview);

export const deleteRoleview = createAsyncThunk("roleview/delete", api.deleteRoleview);

const roleviewSlice = createSlice({
  name: "roleview",
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRoleview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRoleview.fulfilled, (state, { payload }) => {
        roleviewAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateRoleview.fulfilled, roleviewAdapter.updateOne)
      .addCase(deleteRoleview.fulfilled, roleviewAdapter.removeOne)
      .addCase(createRoleview.fulfilled, roleviewAdapter.addOne);
  },
});

export const { } = roleviewSlice.actions;

export default roleviewSlice.reducer;

const selectState = (state) => state.roleview;

export const {
  selectAll: selectRoleview,
  selectById: selectRoleviewById,
} = roleviewAdapter.getSelectors(selectState);

export const selectRoleviewStatus = createSelector(
  selectState,
  (state) => state.status
);
