import React, { Fragment, useEffect, useState } from "react";
import {filter} from "lodash"


function SprintRows(props) {
    const {sprint, sprintinvestment, sprintinvestmentcell, minYear, maxYear} = props;
    const [expanded, setExpanded] = useState(false);
   

    function renderValues(sprintPK) {
        let sprintarray = filter(sprintinvestment, {sprint:sprintPK})
        const totalinputs = [];
        let rowtotal= 0;
        for (let year = minYear; year <= maxYear; year++) {
            var yeartotal=0
            sprintarray.map((sprint) => {
                let rowvalue = filter(sprintinvestmentcell, {sprintinvestment:sprint.pk, year:year})
                yeartotal=yeartotal+(rowvalue[0]?rowvalue[0].value:0)
                rowtotal=rowtotal + (rowvalue[0]?rowvalue[0].value:0)
            })
            {!expanded && totalinputs.push(<td className="has-text-centered">{yeartotal.toLocaleString()}</td>)}
            {expanded && totalinputs.push(<td style={{borderBottom:'1px solid grey'}}className="has-text-centered">{yeartotal.toLocaleString()}</td>)}
        }
            {!expanded && totalinputs.push(<td className="has-text-centered">{rowtotal.toLocaleString()}</td>)}
            {expanded && totalinputs.push(<td style={{borderBottom:'1px solid grey'}}className="has-text-centered">{rowtotal.toLocaleString()}</td>)}
        return (
          totalinputs
        )
      }

      function showdetails(sprintPK) {
        let sprintarray = filter(sprintinvestment, {sprint:sprintPK})
        const detailrows = [];
        if (sprintarray.length > 0) {
            sprintarray.map((sprint) => {
                detailrows.push(<tr><td style={{border:'none'}}></td><td style={{fontStyle:"italic", fontSize:'0.8em'}}>{sprint.item}</td><td></td>{showdetailsrows(sprint.pk)}</tr>)
            })
        }
        return(detailrows)
      }

      function showdetailsrows(sprintPK) {
        const detailcells = [];
        let rowtotal= 0;
        for (let year = minYear; year <= maxYear; year++) {
            let cell = (filter(sprintinvestmentcell, {sprintinvestment:sprintPK,year:year}))
            detailcells.push(<td className="has-text-centered" style={{fontStyle:"italic", fontSize:'0.8em'}}>{cell[0]?cell[0].value.toLocaleString():0}</td>)
            rowtotal=rowtotal + (cell[0]?cell[0].value:0)
          }
            detailcells.push(<td className="has-text-centered" style={{fontStyle:"italic", fontSize:'0.8em'}}>{rowtotal.toLocaleString()}</td>)
          return detailcells
        }

    return (

        <Fragment>
            <tr>
              {!expanded && <Fragment>
              <td className="mr-0 pr-0" style={{width:'10px'}} onClick={() => setExpanded(true)}><p className="far fa-plus-square" style={{fontSize:"smaller"}}></p></td>
              <td>{sprint.title}</td>
              <td>{sprint.action_status}</td>
              {renderValues(sprint.pk)}
              </Fragment>
              }
              {expanded && <Fragment>

                <td className="mr-0 pr-0" style={{width:'10px',borderBottom:'1px solid grey'}} onClick={() => setExpanded(false)}><p className="far fa-minus-square" style={{fontSize:"smaller"}}></p></td>
                <td style={{borderBottom:'1px solid grey'}}>{sprint.title}</td>
                <td style={{borderBottom:'1px solid grey'}}>{sprint.action_status}</td>
                {renderValues(sprint.pk)}
                </Fragment>
             }
            </tr>
            {expanded && showdetails(sprint.pk)}
        </Fragment>


    )
}

export { SprintRows };
