import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { openProgressModal, openSprintCommentModal } from '../../../redux/toolkit/features/sprints_state';
import { openSprintAddSponsorModal } from '../../../redux/toolkit/features/sprints_state';
import { openDeleteModal } from '../../../redux/toolkit/features/modals/deleteConfirmModal';

function SprintModalButtons(props) {
    const {activetab, sprintPK, toggleAddPersonModal} = props;
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch();

    function renderButtons() {

        if (activetab === 'Comments') {
            return (
                <button className='button is-small' key='comment' style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} onClick={() => dispatch(openSprintCommentModal({commentsprintPK:sprintPK,commentPK:null }))}>
                    Add Comment
                </button>
                )
            }

        if (activetab === 'Sponsors') {
            return (
            <button className='button is-small' key='addsponsor' style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} onClick={() => dispatch(openSprintAddSponsorModal({sponsorsprintPK:sprintPK}))}>
                Add Sponsor
            </button>
            )
        }

        // if (activetab === 'Team') {
        //     return (
        //     <button className='button is-small' key='linktheme' style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} onClick={() => toggleAddPersonModal(true, sprintPK, "Workers")}>
        //         Add Team Member
        //     </button>
        //     )
        // }

        if (activetab === 'Sprint') {
            return (
            <button className='button is-small is-danger' key='delete' style={{borderRadius:"4px"}} onClick={() => dispatch(openDeleteModal({message: 'Sprint Action', recordpk: sprintPK, recordtype: 'sprints'}))}>
                Delete Sprint
            </button>
            )
        }

        if (activetab === 'Progress Bar') {
            return (
            <button className='button is-danger is-small' key='delete' style={{borderRadius:"4px", backgroundColor: backgroundcolor, color: textcolor}} onClick={() => dispatch(openProgressModal({progresssprintPK:sprintPK, progressPK:null}))}>
                Add Item
            </button>
            )
        }
    }

    return renderButtons()
}


export default SprintModalButtons;
