import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class CellValuesService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/roadmap-cellvalues`;
    }

    async getCells() {
        const cellvalues = await this.getAll();
        return cellvalues;
    }
}

export const cellValuesService = new CellValuesService();
