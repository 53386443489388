import { React, Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { closeCompetencyModal, selectCompetencyById, updateCompetencies, createCompetencies  } from "./../../../redux/toolkit/features/competencies";
import { fetchAllProductivity, createProductivity, updateProductivity } from "../../../redux/toolkit/features/organization/productivity";
import { fetchAllRows } from "../../../redux/toolkit/features/roadmaprows";
import { fetchAllSections } from "../../../redux/toolkit/features/roadmapsection";
import { closeProductivityModal, openProductivityLinksModal } from "../../../redux/toolkit/features/organization_state";
import { openDeleteModal } from "../../../redux/toolkit/features/modals/deleteConfirmModal";
import {filter} from "lodash"

function MetricsModal() {
    const isProductivityModalOpen = useSelector((state) => state.organization_state.isProductivityModalOpen)
    const productivity = Object.values(useSelector((state) => state.productivity.entities));
    const rows = Object.values(useSelector((state) => state.roadmaprows.entities));
    const productivityPK = useState(null);
    const sections = Object.values(useSelector((state) => state.sections.entities));
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch(); 

    let modalClass = "modal";
    if (isProductivityModalOpen) {
        modalClass += " is-active";
    }

    useEffect(() => {
        dispatch(fetchAllRows());
        dispatch(fetchAllSections());
        dispatch(fetchAllProductivity());
      },[]);

    var rowsfiltered=filter( rows, (row) => (productivity.map((r => r.row))).includes(row.pk))


    function renderForm() {
        return (
            <Fragment>
                <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
                    <tbody>
                        {rowsfiltered.map(row => (
                            <Fragment key={"Metric_Modal_Fragment_"+row.pk}>
                                {renderRow(row)}
                            </Fragment>
                        ))}
                    </tbody>
                </table>
            </Fragment>
        );
    }

    function renderRow(row) {
        let sectionname = filter(sections, {pk:row.section})
        let productivityrow = (filter(productivity, {row:row.pk}))
        let productivityID = productivityrow[0]?productivityrow[0].pk:null;
        return (
        <tr className="ml-4 pl-5" colSpan="2" style={{fontSize:"15px"}}>
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}}>
            <span style={{fontSize: '13px'}}>Section: {sectionname[0]?sectionname[0].name:""} <br/> Row: {row?row.name:""}</span>
            </td>        
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} onClick={async () => {
                        dispatch(openDeleteModal({message: (row?row.name:""),recordpk: productivityID, recordtype: 'productivity'}));
            }}>
            <a><button className="far fa-trash-alt" style={{color:"gray"}}/></a>
            </td>
        </tr>

        )
    }



    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>PRODUCTIVITY REFERENCE ROWS</p>

                        <button className="delete" aria-label="close" onClick={() => dispatch(closeProductivityModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: '200px'}}>
                    {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(openProductivityLinksModal())
                                dispatch(closeProductivityModal())
                            }}
                            //     dispatch(closeProductivityModal())
                            //     .unwrap()
                            //     .then(() => {
                            //         dispatch(closeProductivityModal())
                            //     })
                            // }}
                        >
                            Link Roadmap
                        </button>
                        </Fragment>


                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeProductivityModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default MetricsModal;
