import React from 'react';
import { render } from "react-dom";
import { App } from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";


import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9Td0NjW3pfc3RTQ2Fd');



render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
);
