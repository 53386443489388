import {
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

const innovation_stateAdapter = createEntityAdapter();

const initialState = innovation_stateAdapter.getInitialState({
  isThemeModalOpen: false, 
  themePK: null,
  isIdeasModalOpen: false,
  ideaPK: null,
  themeModalPK: null,
  isIdeaSprintModalOpen: false,
  sprintPK: null,
});

const innovation_stateSlice = createSlice({
  name: "innovation_state",
  initialState,
  reducers: {
    openThemeModal(state, action) {
      state.isThemeModalOpen = true;
      state.themePK = action.payload.themePK;
    },
    closeThemeModal(state) {
      state.isThemeModalOpen = false;
      state.themePK = null;
    },
    openIdeasModal(state, action) {
      state.isIdeasModalOpen = true;
      state.ideaPK = action.payload.ideaPK;
      state.themeModalPK = action.payload.themeModalPK;
    },
    closeIdeasModal(state) {
      state.isIdeasModalOpen = false;
      state.ideaPK = null;
      state.themeModalPK = null;
    },
    openIdeaSprintModal(state, action) {
      state.isIdeaSprintModalOpen = true;
      state.sprintPK = action.payload.sprintPK;
    },
    closeIdeaSprintModal(state) {
      state.isIdeaSprintModalOpen = false;
      state.sprintPK = null;
    },
  },
});

export const { 
  openThemeModal,
  closeThemeModal,
  openIdeasModal,
  closeIdeasModal,
  openIdeaSprintModal,
  closeIdeaSprintModal,
} = innovation_stateSlice.actions;

export default innovation_stateSlice.reducer;



