import api from "../api/roleperson";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const rolepersonAdapter = createEntityAdapter({
  selectId: (roleperson) => roleperson.pk,
  //sortComparer: (a, b) => a.role.localeCompare(b.role),
});

const initialState = rolepersonAdapter.getInitialState({
    status: "idle"
});

export const fetchAllRolePerson = createAsyncThunk("roleperson/fetch", () =>
  api.fetchAllRolePerson()
);

export const updateRolePerson = createAsyncThunk("roleperson/update", api.updateRolePerson);

export const createRolePerson = createAsyncThunk("roleperson/create", api.createRolePerson);

export const deleteRolePerson = createAsyncThunk("roleperson/delete", api.deleteRolePerson);


const rolepersonSlice = createSlice({
  name: "roleperson",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRolePerson.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRolePerson.fulfilled, (state, { payload }) => {
        rolepersonAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateRolePerson.fulfilled, rolepersonAdapter.updateOne)
      .addCase(deleteRolePerson.fulfilled, rolepersonAdapter.removeOne)
      .addCase(createRolePerson.fulfilled, rolepersonAdapter.addOne);
  },
});

export const { } = rolepersonSlice.actions;

export default rolepersonSlice.reducer;

const selectState = (state) => state.roleperson;

export const {
  selectAll: selectRolePerson,
  selectById: selectRolePersonById,
} = rolepersonAdapter.getSelectors(selectState);

export const selectRolePersonStatus = createSelector(
  selectState,
  (state) => state.status
);
