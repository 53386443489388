import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class RowIndentService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/roadmap-indent`;
    }

    async getRowIndent() {
        const indent = await this.getAll();
        return indent;
    }
}


export const rowindentService = new RowIndentService();
