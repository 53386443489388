import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllLocalcurrency = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/localcurrency`,
    authConfig()
  );
  return response.data.results;
};

export const fetchLocalcurrency = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/localcurrency/${id}`,
    authConfig()
  );
  return response.data;
};

export const createLocalcurrency = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/localcurrency`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateLocalcurrency = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/localcurrency/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteLocalcurrency = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/localcurrency/${id}`,
    authConfig()
  );
  return response.data;
};

const localcurrencyAPI = {
  fetchAllLocalcurrency,
  fetchLocalcurrency,
  createLocalcurrency,
  updateLocalcurrency,
  deleteLocalcurrency,
};
export default localcurrencyAPI;
