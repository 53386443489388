import React, { useState, useEffect, Fragment } from "react";
import "../common/css/masterstyles.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTenants, updateTenants } from "../../redux/toolkit/features/tenants";
import { fetchAllRoadmap, updateRoadmap, createRoadmap } from "../../redux/toolkit/features/roadmaps/roadmap";
import { createOrganization, fetchAllOrganization, updateOrganization } from "../../redux/toolkit/features/organization/organization";

function RangeForm() {
  var currentyear=new Date().getFullYear()
  const tenant = Object.values(useSelector((state) =>  state.tenants.entities))[0];
  const roadmap = Object.values(useSelector((state) =>  state.roadmap.entities))[0];
  const organization = Object.values(useSelector((state) =>  state.organization.entities))[0];
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false)
  const [startmonth, setStartmonth] = useState(1)
  const [roadmapstartyear, setRoadstartyear] = useState(null)
  const [roadmapendyear, setRoadmapendyear] = useState(null)
  const [prioryear, setPrioryear] = useState(null)
  const [orgstartyear, setOrgStartyear] = useState(null) 
  const [orgendyear, setOrgendyear] = useState(currentyear+3) 

 
  useEffect(() => {
    setStartmonth(tenant?tenant.financialyearstart:1)
  }, [tenant]);

  useEffect(() => {
    setRoadstartyear(roadmap?roadmap.min_year:currentyear)
    setRoadmapendyear(roadmap?roadmap.max_year:currentyear+4)
    setPrioryear(roadmap?roadmap.prior_year:false)
  }, [roadmap]);

  useEffect(() => {
    setOrgendyear(organization?organization.end_year:currentyear+4)
  }, [organization]);



  useEffect(() => {
    dispatch(fetchAllRoadmap());
    dispatch(fetchAllTenants());
    dispatch(fetchAllOrganization());
  }, []);

  async function CommitTenantChanges() {
    if (startmonth===null) {
      setRoadmapendyear(1);
    }
    await dispatch(updateTenants({id:tenant.pk,financialyearstart:startmonth}))
    .unwrap()
    dispatch(fetchAllTenants());
  }

  async function CommitRoadmapChanges() {
    if (roadmapendyear===null) {
      setRoadmapendyear(currentyear+4)
    }
    if ((roadmap.length)===0) {
      await dispatch(createRoadmap({min_year:currentyear,max_year: roadmapendyear, prior_year:prioryear}))
      .unwrap()
      dispatch(fetchAllRoadmap());
    } else {
      await dispatch(updateRoadmap({id:roadmap.pk, min_year:currentyear,max_year: roadmapendyear, prior_year:prioryear}))
      .unwrap()
      dispatch(fetchAllRoadmap());
    }
  }

  async function CommitOrganizationChanges() {
    if (orgendyear===null) {
      setOrgendyear(currentyear+4)
    }
    if (organization.length===0) {
      await dispatch(createOrganization({tenant_id: tenant.pk, start_year:currentyear,end_year: orgendyear}))
      .unwrap()
      dispatch(fetchAllOrganization());
    } else {
      await dispatch(updateOrganization({id:organization.pk,tenant_id: tenant.pk, start_year:currentyear,end_year: orgendyear}))
      .unwrap()
      dispatch(fetchAllOrganization());
    }
  }

  if (!tenant) {
    return null;
  }

  return (
    <Fragment>
      <p style={{fontSize:"17px", fontWeight:"600"}}>Financial Year Starting Month:</p>
      <div className="select p-0 mt-3 mb-3" style={{fontSize:"15px"}}>
      <select style={{width:'190px'}} value={startmonth} onChange={(e)=>setStartmonth(e.target.value)}>
        <option value={1}>January</option>
        <option value={2}>February</option>
        <option value={3}>March</option>
        <option value={4}>April</option>
        <option value={5}>May</option>
        <option value={6}>June</option>
        <option value={7}>July</option>
        <option value={8}>August</option>
        <option value={9}>September</option>
        <option value={10}>October</option>
        <option value={11}>November</option>
        <option value={12}>December</option>
      </select>
        </div>

      <p style={{fontSize:"17px", fontWeight:"600"}}>Roadmaps Year Range:</p>
        <div className="select p-0 mt-3 mb-3" style={{fontSize:"15px"}}>
        <select style={{width:'190px'}} value={roadmapendyear} onChange={(e)=>setRoadmapendyear(e.target.value)}>
        <option value={currentyear+3}>{currentyear+3}</option>
        <option value={currentyear+4}>{currentyear+4}</option>
        <option value={currentyear+5}>{currentyear+5}</option>
        <option value={currentyear+6}>{currentyear+6}</option>
        <option value={currentyear+7}>{currentyear+7}</option>
      </select>
        </div>
        <br/>
      <label className="checkbox"><input type="checkbox" value={prioryear} checked={prioryear} onChange={(e)=> {setPrioryear(e.target.checked)}}
        /> Show Prior Year Actuals</label>
      <br/>
      <br/>

      <p style={{fontSize:"17px", fontWeight:"600"}}>People Year Range:</p>
      <div className="select p-0 mt-3 mb-3" style={{fontSize:"15px"}}>
        <select style={{width:'190px'}} value={orgendyear} onChange={(e)=> setOrgendyear(e.target.value)}>
        <option value={currentyear+2}>{currentyear+2}</option>
        <option value={currentyear+3}>{currentyear+3}</option>
        <option value={currentyear+4}>{currentyear+4}</option>
      </select>
        </div>
        <br/>
        <br/>

      <button className="button is-success" 
        onClick={() => {
          CommitTenantChanges()
          CommitRoadmapChanges()
          CommitOrganizationChanges()
          }
        }>
        Save
      </button>
    </Fragment>
  );

}

export { RangeForm };
