import React, {Fragment, useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {filter} from "lodash"
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { fetchAllFX } from "./../../redux/toolkit/features/tenants/fx";
import { fetchAllCompensationindex } from "../../redux/toolkit/features/organization/compensationindex";
import { fetchAllRoles, updateRoles} from "./../../redux/toolkit/features/roles";
import { fetchAllLocalcurrency } from "./../../redux/toolkit/features/organization/localcurrency";

function CompensationDetail(props) {
    const {row, currency,  compensationindex, editmode, basecurrency} = props;
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const fx = Object.values(useSelector((state) => state.fx.entities));
    const [base, setBase] = useState(row.compensationbase!==null?row.compensationbase: 0 );
    const [sti, setSTI] = useState(row.compensationsti!==null?row.compensationsti: 0 );
    const [lti, setLTI] = useState(row.compensationlti!==null?row.compensationlti: 0 );
    const [benefits, setBenefits] = useState(row.compensationbenefits!==null?row.compensationbenefits: 0 );
    const [compcurrency, setCompcurrency] = useState(row.compensation_currency!==null?row.compensation_currency: null );
    const dispatch = useDispatch(); 

    useEffect(() => {
      dispatch(fetchAllFX());
      setBase(row.compensationbase!==null?row.compensationbase: 0)
      setSTI(row.compensationsti!==null?row.compensationsti: 0 )
      setLTI(row.compensationlti!==null?row.compensationlti: 0 )
      setBenefits(row.compensationbenefits!==null?row.compensationbenefits: 0)
      setCompcurrency(row.compensation_currency!==null?row.compensation_currency: null)
    },[row]);

    function FXOptions() {
      let fxlist=[];
      fx.sort((a, b)=> a.pair.localeCompare(b.pair)).map((fxrow) =>
       fxlist.push(<option value={fxrow.pair.substring(0, 3)}>{fxrow.pair.substring(0, 3)}</option>)
      )
      return(
        fxlist
      )
    }


    async function updateCompensation(e) {
      if (e.keyCode === 13 || e.keyCode === 9){
        await dispatch(updateRoles({id:row.pk, compensationbase:base, compensationsti:sti, compensationlti: lti, compensationbenefits: benefits, compensation_currency:compcurrency}))
        .unwrap()
        dispatch(fetchAllRoles())
        dispatch(fetchAllLocalcurrency())
      }
    } 

    async function updateCurrency(e) {
      console.log("Update Comp", e.target.value, row.pk)
        setCompcurrency(e.target.value)
        await dispatch(updateRoles({id:row.pk, compensation_currency:e.target.value}))
        .unwrap()
        dispatch(fetchAllRoles())
        dispatch(fetchAllLocalcurrency())
    } 


    function renderRow() {
      return (
        <Fragment>
        <tr>
        <td className="pl-0">{row.title}</td>
        {!editmode && currency==='local' && <td className="has-text-centered" >{row.compensation_currency}</td>}
        {!editmode && currency==='standard' && <td className="has-text-centered" >{basecurrency}</td>}
        {editmode && <td className="has-text-centered" ><div className='select is-small'><select style={{border:"1px solid", borderColor: backgroundcolor, width:'88px'}} value={compcurrency} 
          onChange={(e)=>updateCurrency(e) }>
          {compcurrency===null &&<option value={null}>{'<NONE>'}</option>}
          <option value={basecurrency}>{basecurrency}</option>{FXOptions()}</select></div></td>}
        {!editmode &&<td className="has-text-centered" >{base.toLocaleString()}</td>}
        {editmode &&<td className="has-text-centered" ><NumericTextBoxComponent format="n0" decimals={0} style={{border:"1px solid", borderColor: backgroundcolor, textAlign:'center', width:'90px'}} showSpinButton={false} value={base} onChange={(e) => setBase(e.target.value)} onKeyDown={(e) => updateCompensation(e)}/></td>}
        {!editmode &&<td className="has-text-centered" >{sti.toLocaleString()}</td>}
        {editmode &&<td className="has-text-centered" ><NumericTextBoxComponent format="n0" decimals={0} style={{border:"1px solid", borderColor: backgroundcolor, textAlign:'center', width:'90px'}} showSpinButton={false} value={sti} onChange={(e) => setSTI(e.target.value)} onKeyDown={(e) => updateCompensation(e)}/></td>}
        {!editmode &&<td className="has-text-centered" >{lti.toLocaleString()}</td>}
        {editmode &&<td className="has-text-centered" ><NumericTextBoxComponent format="n0" decimals={0} style={{border:"1px solid", borderColor: backgroundcolor, textAlign:'center', width:'90px'}} showSpinButton={false} value={lti} onChange={(e) => setLTI(e.target.value)} onKeyDown={(e) => updateCompensation(e)}/></td>}
        {!editmode &&<td className="has-text-centered" >{benefits.toLocaleString()}</td>}
        {editmode &&<td className="has-text-centered" ><NumericTextBoxComponent format="n0" decimals={0} style={{border:"1px solid", borderColor: backgroundcolor, textAlign:'center', width:'90px'}} showSpinButton={false} value={benefits} onChange={(e) => setBenefits(e.target.value)} onKeyDown={(e) => updateCompensation(e)}/></td>}
        {renderYears()}
        </tr>
        </Fragment>
      )
    }

    function renderYears() {
      let yearrows=[];
      let compoundrate=1.00;
      for (let year = new Date().getFullYear(); year <= 2028; year++) {
        let indexarray=filter(compensationindex, {year, year})
        let indexrate=1.00+((indexarray[0]?indexarray[0].indexvalue:0)/100)
        compoundrate=compoundrate*indexrate;
          yearrows.push(<td className="has-text-centered">{Math.round((row.compensationbase+row.compensationsti+row.compensationlti+row.compensationbenefits)*compoundrate).toLocaleString()}</td>);  
        }     
      return (
        yearrows
      )
    }

    if (!row) {
      return (null);
    }

    return (
        <Fragment>
        {renderRow()}
        </Fragment>
    )
}

export {CompensationDetail};