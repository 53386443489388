import React, {Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { RenderSprint } from "./../../success/modals/RenderSprint";
import { RenderRoadmaps } from "./../../success/modals/RenderRoadmaps";
import { RenderInnovation } from "./../../success/modals/RenderInnovation";
import { closeSuccessBaseModal, openSuccessBaseModalRoadmaps, openSuccessBaseModalInnovation, openSuccessBaseModalSprints } from "./../../../redux/toolkit/features/success_state";

function SuccessBaseModal(props) {
    const {linkedrows, linkedsprints, linkedthemes} = props;
    const isSuccessBaseModalOpen = useSelector((state) => state.success_state.isSuccessBaseModalOpen)
    const linkModaltype = useSelector((state) => state.success_state.linkModaltype)
    const successId = useSelector((state) => state.success_state.successBaseId)
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch();

    let modalClass = 'modal';
    let modalTitle = (linkModaltype + ' LINKS')
    if (isSuccessBaseModalOpen) {
        modalClass += ' is-active';
    }

    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card' key='modalCard'>
                    <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className='modal-card-title' style={{fontSize:"17px", color: textcolor}}>{modalTitle}</p>
                        <button className='delete' aria-label='close' onClick={() => dispatch(closeSuccessBaseModal())}>
                            {' '}
                        </button>
                    </header>
                    <section className='modal-card-body' key='modalBody' style={{minHeight: '330px'}}>
                    
                    {linkModaltype==="ROADMAP" && 
                    <RenderRoadmaps
                        linkedrows={linkedrows}
                        successId={successId}
                    />}

                    {linkModaltype==="INNOVATION" && 
                    <RenderInnovation
                        linkedthemes={linkedthemes}
                        successId={successId}
                    />}

                    {linkModaltype==="SPRINT" && 
                    <RenderSprint
                    linkedsprints={linkedsprints}
                        successId={successId}
                    />}

                    </section>
                    <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>

                        {linkModaltype==="ROADMAP" && 
                            <button
                                style={{color: textcolor, backgroundColor: backgroundcolor, borderRadius:"4px"}}
                                className="button is-small"
                                key="new"
                                onClick={async () => {
                                    dispatch(openSuccessBaseModalRoadmaps({successBaseRoadmapSuccessId:successId}));
                                    dispatch(closeSuccessBaseModal());
                                }}
                            >
                                Add New Link
                            </button>
                        }

                        {linkModaltype==="INNOVATION" && 
                            <button
                                style={{color: textcolor, backgroundColor: backgroundcolor, borderRadius:"4px"}}
                                className="button is-small"
                                key="new"
                                onClick={async () => {
                                    dispatch(openSuccessBaseModalInnovation({successBaseThemeSuccessId:successId}));
                                    dispatch(closeSuccessBaseModal());
                                }}
                            >
                                Add New Link
                            </button>
                        }

                        {linkModaltype==="SPRINT" && 
                            <button
                                style={{color: textcolor, backgroundColor: backgroundcolor, borderRadius:"4px"}}
                                className="button is-small"
                                key="new"
                                onClick={async () => {
                                    dispatch(openSuccessBaseModalSprints({successBaseSprintSuccessId:successId}));
                                    dispatch(closeSuccessBaseModal())
                                }}
                            >
                                Add New Link
                            </button>
                        }


                        <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => dispatch(closeSuccessBaseModal())}>
                            Cancel
                        </button>

                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SuccessBaseModal;
