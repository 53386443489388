import React, { Fragment, useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Register } from "./Registers";
import { openSprintCommentModal } from "../../redux/toolkit/features/sprints_state";
import { fetchAllComments } from "../../redux/toolkit/features/sprints/comments";
import { filter } from "lodash"

function RenderComments(props) {
    const {sprintPK} = props;
    const comments = Object.values(useSelector((state) => state.comments.entities));
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const [commentsVisible, setcommentsVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchAllComments());
    },[]);

    function hideCommentsSection() {
        setcommentsVisible(false);
      }
      
    function showCommentsSection() {
      setcommentsVisible(true);
    }

    const commentlist = filter(comments, {sprint: sprintPK})

    if(commentsVisible) {
    return (
      <Fragment>
        <nav className="level mb-4" style={{height: "15px"}}>
          <div className="level-left">
            <div className="level-item">
              <p className="p-0 pb-0" style={{fontFamily: "Sans-serif", fontSize: "12px"}} onClick={() => hideCommentsSection()}><b>▼ COMMENTS ({commentlist.length})</b></p>
              <div className="level-item">
              <a className="pb-3 pl-4" title="Add New Comment" onClick={() => dispatch(openSprintCommentModal({commentPK:null, commentsprintPK:sprintPK}))}><i className="fas fa-comment-medical" style={{color:backgroundcolor, fontSize:"18px"}}/></a>
            </div>
            </div>
          </div>
        </nav>

      <hr className="pt-0 mt-1 mb-4" style={{borderTop: "1px solid gray"}}/>
      {commentlist && commentlist.length!==0 && 
        commentlist.map((comment) => (
          <div key={comment.pk}>
            <Register comment={comment} sprintPK={sprintPK}/>
          </div>
        ))}
      {commentlist.length===0 &&
      <p className="mb-4" style={{fontSize:"13px"}}>No Comments</p>}

      </Fragment>
    )
  } else {
    return(
      <Fragment>
        <nav className="level mb-4" style={{height: "15px"}}>
          <div className="level-left">
            <div className="level-item">
              <p className="pl-0 pb-0" style={{fontFamily: "Sans-serif", fontSize: "12px", fontWeight:"500"}} onClick={() => showCommentsSection()}><b>► COMMENTS ({commentlist.length})</b></p>
            </div>
          </div>
        </nav>

    </Fragment>
    )
  }
}

export { RenderComments };
