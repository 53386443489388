import update from 'immutability-helper'
import React, { useCallback, useState, useEffect} from 'react'
// import { useSelector } from 'react-redux'
import {Card} from './card.js'
import { useSelector } from 'react-redux'
//import {sectionsService} from "../../../../services/SectionsService";


export const Container = (props) => {
        const [charts, setCharts] = useState([]);

    useEffect(() => {
        setCharts(props.charts);
    }, [props ]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCharts((prevCharts) => {
                const newCharts = update(prevCharts, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevCharts[dragIndex]],
                    ],
                });
                // const newSectionsPks = newSections.map(section => section.pk);
                // sectionsService.sortSections(newSectionsPks)//.then(() => getFactsOnMainPage());
                // return newSections;
            }
        )
    }, [])

    const renderChart = useCallback(
        (chart, index) => {
            return (
                <Card
                    key={chart.pk}
                    index={index}
                    id={chart.pk}
                    text={chart.chart_title}
                    moveCard={moveCard}
                    rowPk={chart.pk}
                />
            )
        },
        [],
    )

    return (
        <>
            <div className="m-0 p-0">{charts.map((card, i) => renderChart(card, i))}</div>
        </>
    )
}