import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {fetchAllIdeas, updateIdeas,createIdeas, selectIdeaById} from "./../../../redux/toolkit/features/innovation/ideas";
import {closeIdeaSprintModal} from "./../../../redux/toolkit/features/innovation_state";
import { SprintDetail } from "./../../sprints/SprintDetail";

function IdeaSprint() {
  const isIdeaSprintModalOpen = useSelector((state) => state.innovation_state.isIdeaSprintModalOpen)  
  const sprintPK = useSelector((state) => state.innovation_state.sprintPK)
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)

  const dispatch = useDispatch(); 

  let modalClass = "modal";
  if (isIdeaSprintModalOpen) {
    modalClass += " is-active";
  }

  return (
    <Fragment>
      <div className={modalClass} key="categoryModal">
        <div className="modal-background" key="modalBackground">
          {" "}
        </div>
        <div className="modal-card" key="modalCard" style={{minWidth:'70%'}}>
          <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
            <p className="modal-card-title" style={{fontSize:"16px", color: textcolor}}>LINKED SPRINT ACTION</p>
            <button className="delete" aria-label="close" onClick={() => dispatch(closeIdeaSprintModal())}>
              {" "}
            </button>
          </header>
          <section className="modal-card-body" key="modalBody">
          <SprintDetail 
            sprintPK={sprintPK}
          />
          </section>
        </div>
      </div>
    </Fragment>
  );
}

export default IdeaSprint;
