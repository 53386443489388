import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllRoleCompetencies = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/talent/rolecompetencies`,
    authConfig()
  );
  return response.data;
};

export const fetchRoleCompetencies = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/talent/rolecompetencies/${id}`,
    authConfig()
  );
  return response.data;
};

export const createRoleCompetencies = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/talent/rolecompetencies`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateRoleCompetencies = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/talent/rolecompetencies/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteRoleCompetencies = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/talent/rolecompetencies/${id}`,
    authConfig()
  );
  return response.data;
};

const rolecompetenciesAPI = {
  fetchAllRoleCompetencies,
  fetchRoleCompetencies,
  createRoleCompetencies,
  updateRoleCompetencies,
  deleteRoleCompetencies,
};
export default rolecompetenciesAPI;
