import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {filter} from "lodash"
import { showDetailPage} from '../../redux/toolkit/features/success_state';
import { fetchAllBaselinedelta } from "../../redux/toolkit/features/success/baselinedelta";

function SuccessGrid(props) {
    const { success, linkedrows } = props; 

    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const baselinedelta = Object.values(useSelector((state) => state.baselinedelta.entities));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllBaselinedelta());
      }, []);

    function renderGrid(successlist) {
        var alertstatus = 'None'
        var baselinerows = filter( linkedrows, {success: successlist.pk})
        var baselinerowslist = baselinerows.map(r => r.row_id)     
        let alertlist=filter( baselinedelta, (row) => baselinerowslist.includes(row.row_id))
        if (filter(alertlist,{alertstatus:'Moderate'}).length !==0) {
            alertstatus='Moderate'
        }
        if (filter(alertlist,{alertstatus:'Severe'}).length !==0) {
            alertstatus='Severe'
        }
        return (
            <Fragment>

                {alertstatus === "Moderate" &&
                <div className="card mt-0 pt-0 pb-2 is-shadowless" style={{minHeight: "250px", backgroundColor: backgroundcolor, width: "100%", borderLeft: "8px gold solid", cursor: "pointer"}}>
                {renderCard(successlist)}
                </div>}

                {(alertstatus === "Severe") &&
                <div className="card mt-0 pt-0 pb-2 is-shadowless" style={{minHeight: "250px", backgroundColor: backgroundcolor, width: "100%", borderLeft: "8px red solid", cursor: "pointer"}}>
                {renderCard(successlist)}
                </div>}

                {(alertstatus==="None") &&
                <div className="card mt-0 pt-0 pb-2 is-shadowless" style={{minHeight: "250px", backgroundColor: backgroundcolor, width: "100%", cursor: "pointer"}}>
                {renderCard(successlist)}
                </div>}

            </Fragment>
        )
    }

    function renderCard(successlist) {
        return (
                <Fragment>
                <header className="card-header is-shadowless">
                    <p className="pl-5 pt-5 pb-0" style={{color:textcolor, fontSize: "22px"}}>
                        {successlist.name}
                    </p>
                </header>
                <div className="card-content pt-0" style={{color:textcolor}}>
                        <div className="content pl-0 ml-0" style={{backgroundColor: "transparent"}}><span style={{width:"100%",fontFamily: "Gotham Extra",fontSize: "16.5px",lineHeight: ".8em"}}>
                        {successlist.description || ""}
                        </span>
                    </div>
                </div>
            </Fragment>
        )
    }

    if (backgroundcolor===null) {
        return null;
    }
    return (
        <Fragment>
            <div className="columns is-multiline">
                {success && success.map(successlist => (
                    <div className="column is-one-third pt-0 pr-0 is-flex" style={{fontFamily: "Gotham Extra", fontSize: "26px",lineHeight: "1em"}} key={successlist.pk} onClick={() => dispatch(showDetailPage({successPK:successlist.pk}))}>
                        {renderGrid(successlist)}
                    </div>
                ))}
            </div>
        </Fragment>
       )
    }

    export { SuccessGrid };