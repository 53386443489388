import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import { closeSprintAddSponsorModal } from "../../../redux/toolkit/features/sprints_state";
import { fetchAllPersons } from "../../../redux/toolkit/features/organization/persons";
import { fetchAllSponsors, createSponsors } from "../../../redux/toolkit/features/sprints/sponsors";
import { filter } from "lodash"

function SprintAddPersonModal() {
    const isSprintSponsorModalOpen = useSelector((state) => state.sprints_state.isSprintSponsorModalOpen)
    const sprintPK = useSelector((state) => state.sprints_state.sponsorsprintPK)
    const persons = Object.values(useSelector((state) => state.persons.entities));
    const sponsors = Object.values(useSelector((state) => state.sponsors.entities));
    const [selectlist,setSelectList] = useState([]);
    const [personrowid, setPersonRowid] = useState(null);    
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch();

    let modalClass = "modal";
    if (isSprintSponsorModalOpen) {
        modalClass += " is-active";
    }

    useEffect(() => {
        async function fetchData() {
            dispatch(fetchAllPersons())
            dispatch(fetchAllSponsors())
        }
        fetchData();
        let sponsorslist=(filter( sponsors, {sprint_id:sprintPK})).map(r => r.person_id)      
        setSelectList(filter( persons, (row) => !sponsorslist.includes(row.pk)));
    }, [sprintPK]);

    function renderForm() {
        return (
            <Fragment>
                    <div className="select is-fullwidth">
                            <select onChange = {(e) => setPersonRowid(e.target.value)}>
                            
                                <option disabled selected hidden>&lt;Select Sponsor&gt;</option>
                                {selectlist.sort((a, b) => a.firstname.localeCompare(b.firstname)).map(row => (
                                    <option value={row.pk}>{row.firstname} {row.secondname}</option>
                                ))}
                            
                            </select>
                    </div>

            </Fragment>
        );
    }
 
    if (!persons || ! sponsors) {
        return null;
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none", zIndex:100}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                
                <div className="modal-card" key="modalCard" style={{width:"500px"}}>
                <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>Sponsor Name</p>

                        <button className="delete" aria-label="close" onClick={() => dispatch(closeSprintAddSponsorModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{height:"180px"}}>
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

                    {personrowid !== null &&
                        <button style={{backgroundColor: backgroundcolor, color: textcolor}}
                            className="button is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(createSponsors({person_id:personrowid, sprint:sprintPK}))
                                .unwrap()
                                .then(() => {
                                    dispatch(closeSprintAddSponsorModal());
                                })
                            }}
                        >
                            Add
                        </button>}                        
                        
                        <button className="button is-small" style={{borderRadius:"4px"}} key="cancel" onClick={() => dispatch(closeSprintAddSponsorModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SprintAddPersonModal;