import api from "../../api/roadmaps/gantt";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const ganttAdapter = createEntityAdapter({
  selectId: (gantt) => gantt.pk,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = ganttAdapter.getInitialState({status: "idle"});

export const fetchAllGantt = createAsyncThunk("gantt/fetch", () =>
  api.fetchAllGantt()
);

export const updateGantt = createAsyncThunk("gantt/update", api.updateGantt);

export const createGantt = createAsyncThunk("gantt/create", api.createGantt);

export const deleteGantt = createAsyncThunk("gantt/delete", api.deleteGantt);

export const orderGantt = createAsyncThunk("rows/sort", api.orderGantt);


const ganttSlice = createSlice({
  name: "gantt",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGantt.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllGantt.fulfilled, (state, { payload }) => {
        ganttAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateGantt.fulfilled, ganttAdapter.updateOne)
      .addCase(deleteGantt.fulfilled, ganttAdapter.removeOne)
      .addCase(createGantt.fulfilled, ganttAdapter.addOne);
  },
});

export const { } = ganttSlice.actions;

export default ganttSlice.reducer;

const selectState = (state) => state.gantt;

export const {
  selectAll: selectGantt,
  selectById: selectGanttById,
} = ganttAdapter.getSelectors(selectState);

export const selectGanttStatus = createSelector(
  selectState,
  (state) => state.status
);

