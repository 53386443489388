import React, {Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {filter} from "lodash"
import { closeSuccessBaseModalSprints, openSuccessBaseModal } from "./../../../redux/toolkit/features/success_state";
import { createSprintlinks, fetchAllSprintlinks } from "./../../../redux/toolkit/features/success/successsprintlinks";



function SuccessModalLinksSprints() {
    const showSuccessLinksSprintsModal = useSelector((state) => state.success_state.isSuccessBaseModalSprintsOpen);
    const successId = useSelector((state) => state.success_state.successBaseSprintSuccessId);
    const sprintrecordId = useSelector((state) => state.success.successBaseSprintId);
    const linkedsprints = Object.values(useSelector((state) => state.sprintlinks.entities));
    const [sprintvalue, setSprintValue] = useState(null)
    const sprints = Object.values(useSelector((state) => state.sprints.entities));
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    let modalClass = "modal";
    const dispatch = useDispatch();
    
    if (showSuccessLinksSprintsModal) {
        modalClass += " is-active";
    }

    //Exclude values from the Select lis that are already included
    let excludesprintlist=(filter( linkedsprints, {success:successId})).map(r => r.sprint_id)      
    let sprintslist = (filter( sprints, (row) => !excludesprintlist.includes(row.pk))).sort((a, b) => (a.title.localeCompare(b.title)))

    useEffect(() => {
        if (showSuccessLinksSprintsModal){
            setSprintValue(null)
        }
    }, [showSuccessLinksSprintsModal]);
      
    function selectSprintLink() {
        return (
            <Fragment>
            <div className="field is-horizontal is-align-items-left">
            <label className="label field-label pt-2">Sprint</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    <div className="select is-flex">
                        <select className="select is-fullwidth" onChange={(event) => setSprintValue(event.target.value)}>
                        {sprintvalue === null && <option selected>{'<Select Sprint>'}</option>}
                            {sprintslist &&
                        sprintslist.map((p) => (
                        <option key={p.pk} value={p.pk}>
                            {p.title} 
                            </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
        </Fragment>
        )
    }

    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                    <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>ADD SPRINT LINK</p>
                    <button className="delete" aria-label="close" 
                    onClick={() => {
                        dispatch(closeSuccessBaseModalSprints());
                        dispatch(openSuccessBaseModal({linkModaltype:"SPRINT", successBaseId: successId}));
                    }}
                    >
                        {" "}
                    </button>
                </header>
                <section className="modal-card-body" key="modalBody">
                {selectSprintLink()}
                </section>
                <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

                {sprintvalue !==null && 
                    <button
                        style={{borderRadius:"4px"}}
                        className="button is-success is-small"
                        key="save"

                        onClick={async () => {
                            await dispatch(createSprintlinks({sprint_id: sprintvalue, success:successId}))
                            .unwrap()
                                dispatch(fetchAllSprintlinks());
                                dispatch(closeSuccessBaseModalSprints());
                                dispatch(openSuccessBaseModal({linkModaltype:"SPRINT", successBaseId: successId}));
                        }}
                    >
                        Create Link
                    </button>
                }

                    <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} 
                        onClick={() => {
                            dispatch(closeSuccessBaseModalSprints());
                            dispatch(openSuccessBaseModal({linkModaltype:"SPRINT", successBaseId: successId}));
                            }}
                        >
                        Cancel
                    </button>
                </footer>
            </div>
        </div>
    </Fragment>

    );
}

export default SuccessModalLinksSprints
