import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RenderComments } from "./Comments";
import { SprintLane } from "./SprintLane";
import { Stepper } from "./Stepper";
import { openSprintInvestmentModal, openSprintModal }  from "./../../redux/toolkit/features/sprints_state";
import { updateSprints, fetchAllSprints, selectSprintById}  from "./../../redux/toolkit/features/sprints/sprints";
import { fetchAllSponsors}  from "./../../redux/toolkit/features/sprints/sponsors";
import {filter} from "lodash"
import { sprintdatasets } from '../Sprints';

function SprintDetail(props) {
  const {sprintPK} = props; 
  const sprintbyid = useSelector((state)=>selectSprintById(state, sprintPK));  
  const sponsors = Object.values(useSelector((state) => state.sponsors.entities));
  const roadmap = Object.values(useSelector((state) => state.roadmap.entities));
  const sprintinvestment = Object.values(useSelector((state) => state.sprintinvestment.entities));
  const sprintinvestmentcell = Object.values(useSelector((state) => state.sprintinvestmentcell.entities));
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });
  const [status,setStatus] = useState("");
  const [budgetted,setBudget] = useState("");
  const [minYear, setMinYear] = useState(null);
  const [maxYear, setMaxYear] = useState(null);
  const [editSprintlane, setEditSprintlane] = useState(false);
  var budget = 0
  const dispatch = useDispatch();


  async function fetchAllSprintData() {
    dispatch(fetchAllSprints());
    if (sprintbyid) {
      setStatus(sprintbyid.action_status);
      setBudget(sprintbyid.budget);
    }
  }

  useEffect(() => {
    dispatch(fetchAllSprints);
  },[editSprintlane]);

  useEffect(() => {
      setMinYear(roadmap[0]?roadmap[0].min_year:new Date().getFullYear())
      setMaxYear(roadmap[0]?roadmap[0].max_year:new Date().getFullYear()+5)
  }, [roadmap]);


  useEffect(() => {
    if (sprintbyid) {
    setStatus(sprintbyid.action_status);
    }
  },[sprintPK]);

  useEffect(() => {
    dispatch(fetchAllSponsors());
    dispatch(fetchAllSprints());
  },[sprintPK]);

  async function onChangeStatus(e, key) {
    setStatus(e.target.value);
    await dispatch(updateSprints({id: key, action_status: e.target.value}))
    .unwrap()
      dispatch(fetchAllSprints());
  }

  async function onChangeBudget(e, key) {
    setBudget(e.target.value)
    await dispatch(updateSprints({id: key, budget: e.target.value}))
      .unwrap()
      dispatch(fetchAllSprints());
  }

  if (budgetted === "No") {
    let budgetarray= filter(sprintinvestment,{sprint:sprintPK})
    if (budgetarray.length > 0) {
      budgetarray.map((sprint) => {
        for (let year = minYear; year <= maxYear; year++) {
              let cellvalue = filter(sprintinvestmentcell, {sprintinvestment:sprint.pk, year:year})
              budget=budget+(cellvalue[0]?cellvalue[0].value:0)
        }
      })
    }
  }


  if (!sprintPK || !sprintbyid) {
    return null;
  }


  let sponsorsnames=filter(sponsors, {sprint_id:sprintPK})

  return (
    <Fragment>
    <div className="mb-4 pl-5 pr-5" style={{border: "1px solid rgb(238,238,238)", borderRadius: "12px", backgroundColor: "rgb(248,248,248)"}} >
  
    <table className="table is-fullwidth pt-0 mt-0" style={{backgroundColor:"transparent", borderSpacing: "0 7px", borderCollapse: "separate"}}>
    <tbody>
    <tr  key={"sprint_titke" + sprintPK} onDoubleClick={() => dispatch(openSprintModal({sprintPK:sprintPK}))}>
      <td style={{borderBottom:"solid 2px rgb(180,180,180)", borderColor: backgroundcolor, width:"10%"}}><span style={{fontSize: "16px", fontWeight: "600"}}>Sprint:</span></td>
      <td style={{borderBottom:"solid 2px rgb(180,180,180)", borderColor: backgroundcolor}}><span style={{fontWeight:"500"}}>{sprintbyid.title}</span></td>
    </tr>
    <tr key={"sprint_objective" + sprintPK} onDoubleClick={() => dispatch(openSprintModal({sprintPK:sprintPK}))}>
      <td className="pr-3"><p style={{fontSize: "14px", fontWeight: "500"}}>Objective/s:</p></td>
      <td><span style={{fontWeight:"400"}}>{sprintbyid.description}</span></td>
    </tr>
          <tr onDoubleClick={() => dispatch(openSprintModal({sprintPK:sprintPK}))}>
            <td className="pr-4"><p style={{fontSize: "14px", fontWeight: "500"}}>Sprint Owner/s:</p></td>
              <td>            
                {sponsorsnames && (sponsorsnames).length !== 0 && 
                sponsorsnames.map((sponsor) => sponsor.fullname ).join(", ")}
                
                {sponsorsnames && (sponsorsnames).length === 0 && 
                "None Assigned"}
              </td>
          </tr>
          <tr>
            <td><p style={{fontSize: "14px", fontWeight: "500"}}>Status:</p></td>
            <td>
            <div className="select is-small pt-0 pb-0 mt-0 mb-0" style={{backgroundColor:"transparent"}}>
                <select key={"sprint_select" + sprintPK} className="pt-0 pb-0 mt-0 mb-0"style={{backgroundColor:"transparent"}} 
                onChange={(e) => onChangeStatus(e,sprintPK)} 
                value={status}>
                  <option value="Not Started">Not Started</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Suspended">Suspended</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td><p style={{fontSize: "14px", fontWeight: "500"}}>Budgeted:</p></td>
            <td>        
              <div className="select is-small pt-0 pb-0 mt-0 mb-0" style={{backgroundColor:"transparent"}}>
                <select key={"budget_select" + sprintPK} className="pt-0 pb-0 mt-0 mb-0"style={{backgroundColor:"transparent"}} 
                onChange={(e) => onChangeBudget(e,sprintPK)} 
                value={budgetted}>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </td>
          </tr>
          {budgetted === "No" && 
          <tr>
            <td ><p style={{fontSize: "14px", fontWeight: "500"}}>Investment:</p></td>
            <td>{budget.toLocaleString()}<span style={{fontSize:"smaller", color: backgroundcolor}}> <a style={{color: backgroundcolor}} onClick={() => dispatch(openSprintInvestmentModal({sprintInvestmentPK:sprintPK}))}><u className="ml-2" style={{fontSize:'smaller'}}>DETAIL</u></a> </span></td>
          </tr>}
          {sprintbyid.sprintlane &&
          // <tr>
          //   <td ><p style={{fontSize: "14px", fontWeight: "500"}}>Sprint Lane:</p></td>
          //   <td className="mr-0 pr-0" onDoubleClick={() => setEditSprintlane(true)}><SprintLane sprintPK = {sprintPK} setEditSprintlane={setEditSprintlane} editSprintlane={editSprintlane}/></td>
          // </tr>
          <tr>
            <td ><p style={{fontSize: "14px", fontWeight: "500"}}>Stages:</p></td>
            <td className="mr-0 pr-0"><Stepper sprintPK = {sprintPK}/></td>
          </tr>

          }
        </tbody>
      </table>
      <br/>
      <RenderComments
        sprintPK={sprintPK}
        registers={sprintbyid.registers}
      />
      </div>
      <br/>
      </Fragment>
    )
  }

export { SprintDetail };
