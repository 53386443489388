import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import {filter} from "lodash"
import { closeRolesLinkPersonModal } from "../../../redux/toolkit/features/organization_state";
import { createPositionperson, updatePositionperson, fetchAllPositionperson } from "../../../redux/toolkit/features/organization/positionperson";
import { fetchAllPersons } from "../../../redux/toolkit/features/organization/persons";
import { fetchAllPersonsview } from "../../../redux/toolkit/features/organization/personview";


function PeopleModalAddPeople() {
    const persons = Object.values(useSelector((state) => state.persons.entities)).sort((a, b) => (a.firstname.localeCompare(b.firstname)));
    const personview = Object.values(useSelector((state) => state.personsview.entities));
    const isRoleLinkPersonModalOpen = useSelector((state) => state.organization_state.isRoleLinkPersonModalOpen)
    const rolePK = useSelector((state) => state.organization_state.personrolePK)
    const personPK = useSelector((state) => state.organization_state.personpersonPK)
    const [employee, setEmployee] = useState(null)  
    const [employeestartdate, setEmployeeStartDate] = useState(null)  
    const [employeeenddate, setEmployeeEndDate] = useState(null)  
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch(); 

    var persondetail = filter(personview,{position_id:rolePK,person_id: personPK})
    let modalTitle=('EMPLOYEE')
    let modalClass = "modal";
    if (isRoleLinkPersonModalOpen) {
        modalClass += " is-active";
    }

    //var personlist = filter( persons, (row) => ![personPK].includes(row.pk))

    useEffect(() => {
        dispatch(fetchAllPersons());
        dispatch(fetchAllPersonsview());
        }, []);

    useEffect(() => {
        setEmployee(persondetail[0]?persondetail[0].person_id:null)
        setEmployeeStartDate(persondetail[0]?persondetail[0].startdate:null)         
        setEmployeeEndDate(persondetail[0]?persondetail[0].enddate:null)    
             
    }, [isRoleLinkPersonModalOpen]);

    
    console.log("Employee Datae", (employeestartdate===""))

    function renderForm() {
        return (
            <Fragment>
                <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Employee</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                        <span className="select is-fullwidth">

                        {personPK && <select  onChange={(event) => setEmployee(event.target.value)} value={employee} disabled>
                            {employee === null && <option selected>{'<Select Manager>'}</option>}
                            {persons.map((c) => (
                                <option key={c.pk} value={c.pk}>
                                {c.firstname} {c.secondname}
                                </option>
                                ))}
                        </select>}

                        {!personPK && <select  onChange={(event) => setEmployee(event.target.value)} value={employee}>
                            {persons === null && <option selected>{'<Select Manager>'}</option>}
                            {persons.map((c) => (
                                <option key={c.pk} value={c.pk}>
                                {c.firstname} {c.secondname}
                                </option>
                                ))}
                        </select>}

                        </span>
                        </div>
                    </div>
                </div>

            <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Reporting Start Date</p>
            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            type="date" 
                            className="input"
                            value={employeestartdate}
                            onChange={(e) => setEmployeeStartDate(e.target.value===""?null:e.target.value)}
                            />
                    </div>
                </div>
            </div>

            <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Reporting End Date</p>
            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            type="date" 
                            className="input"
                            value={employeeenddate}
                            onChange={(e) => setEmployeeEndDate(e.target.value===""?null:e.target.value)}
                            />
                    </div>
                </div>
            </div>

            </Fragment>
        );
    }
    return (

        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{modalTitle}</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeRolesLinkPersonModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: "50px"}}>             
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        {employee!==null && (persondetail[0]?persondetail[0].positionperson_id:null)===null && 
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(createPositionperson({person: employee ,position: rolePK, startdate:employeestartdate ,enddate:employeeenddate}))
                                .unwrap()
                                    dispatch(fetchAllPersonsview());
                                    dispatch(fetchAllPositionperson());
                                    dispatch(closeRolesLinkPersonModal());
                            }}
                        >
                            Save Changes
                        </button>}

                        {employee!==null && (persondetail[0]?persondetail[0].positionperson_id:null)!==null && 
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(updatePositionperson({id: (persondetail[0].positionperson_id), person: employee ,position: rolePK, startdate:employeestartdate ,enddate:employeeenddate}))
                                .unwrap()
                                    dispatch(fetchAllPersonsview());
                                    dispatch(fetchAllPositionperson());
                                    dispatch(closeRolesLinkPersonModal());
                            }}
                        >
                            Save Changes
                        </button>}

                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} 
                            onClick={async () => {
                                dispatch(closeRolesLinkPersonModal())
                            }}
                        >
                            Cancel
                        </button>

                    </footer>
                </div>
            </div>

        </Fragment>
    );
}

export default PeopleModalAddPeople;
