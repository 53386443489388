import api from "../../api/tenants/fx";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const fxAdapter = createEntityAdapter({
  selectId: (fx) => fx.pk,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = fxAdapter.getInitialState({status: "idle",isFXModalOpen: false,fxPK:null  });

export const fetchAllFX = createAsyncThunk("fx/fetch", () => api.fetchAllFX());

export const updateFX = createAsyncThunk("fx/update", api.updateFX);

export const createFX = createAsyncThunk("fx/create", api.createFX);

export const deleteFX = createAsyncThunk("fx/delete", api.deleteFX);

const fxSlice = createSlice({
  name: "fx",
  initialState,
  reducers: {
    openFXModal(state, action) {
      state.isFXModalOpen = true;
      state.fxPK = action.payload.fxPK;
    },
    closeFXModal(state) {
      state.isFXModalOpen = false;
      state.fxPK = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFX.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllFX.fulfilled, (state, { payload }) => {
        fxAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateFX.fulfilled, fxAdapter.updateOne)
      .addCase(deleteFX.fulfilled, fxAdapter.removeOne)
      .addCase(createFX.fulfilled, fxAdapter.addOne);
  },
});

export const {openFXModal,closeFXModal} = fxSlice.actions;

export default fxSlice.reducer;

const selectState = (state) => state.fx;

export const {
  selectAll: selectFX,
  selectById: selectFXById,
} = fxAdapter.getSelectors(selectState);

export const selectFXStatus = createSelector(
  selectState,
  (state) => state.status
);
