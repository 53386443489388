import React, { Fragment, useState, useEffect } from "react";
import "../common/css/masterstyles.css";
import { useSelector, useDispatch } from 'react-redux'
import { Idea } from "./Idea";
import {ThemeChart} from "./ThemeChart";
import {openThemeModal} from "./../../redux/toolkit/features/innovation_state";
import {filter} from "lodash"


function Theme(props) {
  const {themePK, theme} = props;
  const ideas = Object.values(useSelector((state) => state.ideas.entities));
  const [isAllRowSelected, setIsAllRowSelected] = useState(false);
  const dispatch = useDispatch(); 
  var checkboxstatus=[];
  var ideasfilteredlist=[];
  var ideacheckedstatus=[];
  var ideaslist=filter(ideas, {theme: themePK})
  const [ideacheckedhistory, setIdeacheckedhistory] = useState([])
  const [cost, setCost] = useState(0)
  const [impact, setImpact] = useState(0)
  const [filteredideas, setFilteredideas] = useState([])
  
  function setGroupSelect(event) {
    setIsAllRowSelected(event);
  }


  function Checkall(event, type) {
    var costbase=0;
    var impactbase=0
    ideacheckedstatus=[]

    if (type==='group') {
      //setIsAllRowSelected(event);
      ideaslist.map((idea, index) => (
        ideacheckedstatus.push({pk:idea.pk,status: event,index:index})
        ))
      let ideaselectlist=filter(ideacheckedstatus,{status:true}).map(i => i.pk)
    
      ideasfilteredlist= ideas.filter((idea) => ideaselectlist.includes(idea.pk))
      ideasfilteredlist.map((idea) => {
        costbase = costbase + idea.cost;
        impactbase = impactbase + idea.impact;
      })
    }

    if (type!=='group') {
      setIsAllRowSelected(false);
      ideaslist.map((idea, index) => (
        idea.pk===type?ideacheckedstatus.push({pk:idea.pk,status: event, index:index}):ideacheckedstatus.push({pk:idea.pk,status: ideacheckedhistory[index]?ideacheckedhistory[index].status:false ,index:index})
      ))
      }

      let ideaselectlist=filter(ideacheckedstatus,{status:true}).map(i => i.pk)   
      ideasfilteredlist= ideas.filter((idea) => ideaselectlist.includes(idea.pk))
      ideasfilteredlist.map((idea) => {
        costbase = costbase + idea.cost;
        impactbase = impactbase + idea.impact;
      })
    setIdeacheckedhistory(ideacheckedstatus);
    setCost(costbase)
    setImpact(impactbase)
    setFilteredideas(ideasfilteredlist)
    }



// useEffect(() => {
// }, [isThemeModalOpen]);

  function charts(checkedarray) {

    let ideasarray=[];
    if (checkedarray) {
    for (let i = 0; i < checkedarray.length; i++) {
      if (checkedarray[i]) {
        ideasarray.push(i)
      }
    }
  }
    return (
      <Fragment>
      <div className="mt-5 ml-5 mr-5" style={{backgroundColor:'white'}}>
        <ThemeChart
          basevalue={theme.basevalue}
          targetvalue={theme.targetvalue}
          units={theme.units}
          theme={theme.theme}
          themePK={themePK}
          ideasarray={ideasarray}
          ideasfiltered={filteredideas}
          decimals={theme.decimals}
        />
      </div>

    </Fragment>
    )
  }

  if (!theme ) {
    return null;
  }

  return (
    <Fragment>
      <section className='mb-6 pb-6' style={{backgroundColor: 'rgb(240,240,240)', border:'1px rgb(235,235,235) solid', borderRadius: '10px'}}>
        <div style={{fontFamily: 'Gotham Extra', fontWeight: 700, fontSize: '22px', lineHeight: '0.9em'}}>
          <div className='pt-5 pb-4 pl-5 pr-4' onDoubleClick={() => dispatch(openThemeModal({themePK: themePK}))}>
            {theme.theme}
            <br/>
          </div>
          <div className='pl-5 pr-4' style={{backgroundColor: 'rgb(240,240,240)'}} onDoubleClick={() => dispatch(openThemeModal({themePK: themePK}))}>
            <span style={{fontFamily: 'Gotham Extra', fontSize: '15px', lineHeight: '25px'}}>
            {theme.description}
            </span>
          </div>
        </div>

        <Idea
          themePK={themePK}
          units={theme.units}
          isAllRowSelected={isAllRowSelected}
          setGroupSelect={setGroupSelect}
          themeModalKey={themePK}
          checkboxstatus={checkboxstatus}
          charts={charts}
          decimals={theme.decimals}
          Checkall={Checkall}
          ideaslist={ideaslist}
          cost={cost}
          impact={impact}
          ideacheckedhistory={ideacheckedhistory}
        />

        {theme.showtargets &&
          charts()
        }
      </section>
    </Fragment>
  );
}

export { Theme };
