import "regenerator-runtime/runtime.js";
import axios from "axios";
import { authConfig } from "./helpers";
import BaseService from "./BaseService";

class OrganizationService extends BaseService {
  listUrl() {
    return `${this.baseUrl}/organization`;
  }

  async getYears() {
    const years = await this.getAll();
    return years.results;
  }

  // async getOrgChartData(year) {
  //   const url = this.listUrl() + "chart";
  //   const config = authConfig();
  //   config.params = { startyear: year };
  //   const response = await axios.get(url, config);
  //   return response.data;
  // }
}

export const organizationService = new OrganizationService();
