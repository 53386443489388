import api from "../api/roadmapchartrows";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const roadmapchartrowsAdapter = createEntityAdapter({
  selectId: (chartrows) => chartrows.pk,
  //sortComparer: (a, b) => a.section.localeCompare(b.section),
});

const initialState = roadmapchartrowsAdapter.getInitialState({
  status: "idle"
});

export const fetchAllChartRows = createAsyncThunk("chartrows/fetch", () =>
  api.fetchAllChartRows()
);

export const updateChartRows = createAsyncThunk("chartrows/update", api.updateChartRows);

export const createChartRows = createAsyncThunk("chartrows/create", api.createChartRows);

export const deleteChartRows = createAsyncThunk("chartrows/delete", api.deleteChartRows);

const roadmapchartrowsSlice = createSlice({
  name: "roadmapchartrows",
  initialState,
  reducers: {
   },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllChartRows.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllChartRows.fulfilled, (state, { payload }) => {
        roadmapchartrowsAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateChartRows.fulfilled, roadmapchartrowsAdapter.updateOne)
      .addCase(deleteChartRows.fulfilled, roadmapchartrowsAdapter.removeOne)
      .addCase(createChartRows.fulfilled, roadmapchartrowsAdapter.addOne);
  },
});

export const { } = roadmapchartrowsSlice.actions;

export default roadmapchartrowsSlice.reducer;

const selectState = (state) => state.roadmapchartrows;

export const {
  selectAll: selectroadmapchartrows,
  selectById: selectchartrowById,
} = roadmapchartrowsAdapter.getSelectors(selectState);

export const selectChartRowsStatus = createSelector(
  selectState,
  (state) => state.status
);
