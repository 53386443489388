import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import {filter} from "lodash"
import { closeRolesLinkReportingModal } from "../../../redux/toolkit/features/organization_state";
import { fetchAllRoles } from "../../../redux/toolkit/features/roles";
import { createPositionparent, fetchAllPositionparent, updatePositionparent } from "../../../redux/toolkit/features/organization/positionparent";
import { fetchAllRoleview } from "../../../redux/toolkit/features/organization/roleview";


function PeopleModalAddReporting() {
    const roles = Object.values(useSelector((state) => state.roles.entities)).sort((a, b) => (a.title.localeCompare(b.title)));
    const roleview = Object.values(useSelector((state) => state.roleview.entities));
    const isRoleLinkReportingModalOpen = useSelector((state) => state.organization_state.isRoleLinkReportingModalOpen)
    const rolePK = useSelector((state) => state.organization_state.reportingrolePK)
    const positionPK = useSelector((state) => state.organization_state.rolerolePK)
    const [reportingrole, setReportingRole] = useState(null)  
    const [reportingstartdate, setReportingStartDate] = useState(null)  
    const [reportingenddate, setReportingEndDate] = useState(null)  
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch(); 

    let modalTitle=('ROLE REPORTS TO')
    let modalClass = "modal";
    if (isRoleLinkReportingModalOpen) {
        modalClass += " is-active";
    }
    var roledetail = filter(roleview,{role_id:positionPK,parent_id: rolePK})
    var rolelist = filter( roles, (row) => ![rolePK].includes(row.pk))

    console.log("Parent",reportingrole,"Position", positionPK, reportingstartdate, reportingenddate)

    useEffect(() => {
        dispatch(fetchAllRoles());
        dispatch(fetchAllRoleview());
        }, []);

    useEffect(() => {
        setReportingRole(roledetail[0]?roledetail[0].parent_id:null)
        setReportingStartDate(roledetail[0]?roledetail[0].reporting_startdate:null)         
        setReportingEndDate(roledetail[0]?roledetail[0].reporting_enddate:null)    
                
    }, [isRoleLinkReportingModalOpen]);



    function renderForm() {
        return (
            <Fragment>
                <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Reports To</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">


                        {rolePK &&
                        <select className="select is-fullwidth" value={reportingrole} onChange={(event) => setReportingRole(event.target.value)} disabled>
                            {reportingrole === null && <option selected>{'<Select Manager>'}</option>}
                            {roles.map((c) => (
                                <option key={c.pk} value={c.pk}>
                                {c.title}
                                </option>
                                ))}
                        </select>}

                        {!rolePK &&
                        <select className="select is-fullwidth" value={reportingrole} onChange={(event) => setReportingRole(event.target.value)}>
                            {reportingrole === null && <option selected>{'<Select Manager>'}</option>}
                            {roles.map((c) => (
                                <option key={c.pk} value={c.pk}>
                                {c.title}
                                </option>
                                ))}
                        </select>}


                        </div>
                    </div>
                </div>

            <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Reporting Start Date</p>
            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            type="date" 
                            className="input"
                            value={reportingstartdate}
                            onChange={(e) => setReportingStartDate(e.target.value===""?null:e.target.value)}
                            />
                    </div>
                </div>
            </div>

            <p className="pl-1 pb-1" style={{fontSize:'smaller', fontWeight:600}}>Reporting End Date</p>
            <div className="field is-horizontal is-align-items-center">
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            type="date" 
                            className="input"
                            value={reportingenddate}
                            onChange={(e) => setReportingEndDate(e.target.value===""?null:e.target.value)}
                            />
                    </div>
                </div>
            </div>

            </Fragment>
        );
    }

    return (

        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{modalTitle}</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeRolesLinkReportingModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: "50px"}}>             
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

                        {reportingrole!==null && (roledetail[0]?roledetail[0].positionparent_id:null)===null && 
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(createPositionparent({parent: reportingrole,position: positionPK, startdate:reportingstartdate ,enddate:reportingenddate}))
                                .unwrap()
                                    dispatch(fetchAllPositionparent());
                                    dispatch(fetchAllRoleview());
                                    dispatch(closeRolesLinkReportingModal());
                            }}
                        >
                            Save Changes
                        </button>}

                        {reportingrole!==null && (roledetail[0]?roledetail[0].positionparent_id:null)!==null && 
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(updatePositionparent({id: (roledetail[0].positionparent_id),parent: reportingrole,position: positionPK, startdate:reportingstartdate ,enddate:reportingenddate}))
                                .unwrap()
                                    dispatch(fetchAllPositionparent());
                                    dispatch(fetchAllRoleview());
                                    dispatch(closeRolesLinkReportingModal());
                            }}
                        >
                            Save Changes
                        </button>}

                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} 
                            onClick={async () => {
                                dispatch(closeRolesLinkReportingModal());
                            }}
                        >
                            Cancel
                        </button>

                    </footer>
                </div>
            </div>

        </Fragment>
    );
}

export default PeopleModalAddReporting;
