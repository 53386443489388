import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";
import {authConfig} from "./helpers";
import axios from "axios";

class CompanyService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/company`;
    }

    async getCurrentCompany() {
        const companies = await this.getAll();
        let company;
        if (companies.count === 0) {
            company = await companyService.post({ statement: "", quote: "", purpose: ""})
        } else {
            company = companies.results[0];
        }
        return company;
    }

    async uploadImage(id, file) {
        const url = this.detailUrl(id) + "/upload-image";
        const formData = new FormData();
        formData.append("file", file);
        const config = authConfig();
        config.headers["content-type"] = "multipart/form-data";
        const response = await axios.put(url, formData, config);
        return response.data;
    }

    imageUrl(company) {
        if (!company.heroimage) {
            return "";
        }
        return company.heroimage.startsWith("http")
            ? company.heroimage
            : BaseService.baseServer + company.heroimage;
    }
}

export const companyService = new CompanyService();
