import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StepperComponent, StepsDirective, StepDirective } from '@syncfusion/ej2-react-navigations';
import "./stepper.css"
import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import { fetchAllProgressbaritem, updateProgressbaritem } from "../../redux/toolkit/features/sprints/progressbaritem";
import {filter} from "lodash"

function Stepper(props) {
    const {sprintPK} = props;
    const progressbaritem = (Object.values(useSelector((state) => state.progressbaritem.entities))).sort((a, b) => a.order - b.order);
    const dispatch = useDispatch();
    var progressitems=filter(progressbaritem, {sprint:sprintPK})

    useEffect(() => {
      dispatch(fetchAllProgressbaritem());
    }, [])

    async function renderposition(pos) {
      progressitems.map((item,index) => {
        if(index > pos) {
          dispatch(updateProgressbaritem({id:item.pk,status:null}))
        }
        if(index <= pos) {
          dispatch(updateProgressbaritem({id:item.pk,status:true}))
        } 
        if(pos===0 && index===0) {
          if (item.status===true) {
            dispatch(updateProgressbaritem({id:item.pk,status:null}))
          } else {
            dispatch(updateProgressbaritem({id:item.pk,status:true}))
          }
        } 
        dispatch(fetchAllProgressbaritem());
    })
    }

    function renderDirective() {
      let directivearray=[];
      if (progressitems.length!==0) {
        progressitems.map(item => {
          directivearray.push(<StepDirective iconCss={'sf-icon-chevron'} label={item.item} isValid={item.status!==null?item.status:null}/>)
      })
      }
      return (
        directivearray
      )
    }

    return (
      <Fragment>
      {progressitems.length!== 0 && 
        <div className="stepper-custom-style" style={{justifyContent:'left'}}> 
          <StepperComponent id={'stepper'+sprintPK}
          stepChanged={(args) => {
            renderposition(args.activeStep)
            }}>
                <StepsDirective>
                  {renderDirective()}
                  </StepsDirective>
          </StepperComponent>
        </div>
        }

        {progressitems.length=== 0 && 
        <p>No Stepper Items Available</p>
        }
    </Fragment>                       
        );
}

export { Stepper };
