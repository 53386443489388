import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllComments = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/registers`,
    authConfig()
  );
  return response.data.results;
};

export const fetchComments = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/registers/${id}`,
    authConfig()
  );
  return response.data;
};

export const createComments = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/registers`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateComments = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/registers/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteComments = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/registers/${id}`,
    authConfig()
  );
  return response.data;
};

const commentsAPI = {
  fetchAllComments,
  fetchComments,
  createComments,
  updateComments,
  deleteComments,
};
export default commentsAPI;
