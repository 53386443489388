import api from "../api/roadmapcells";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const cellsAdapter = createEntityAdapter({
  selectId: (cell) => cell.pk,
  //sortComparer: (a, b) => a.row_id.localeCompare(b.row_id),
});

const initialState = cellsAdapter.getInitialState({
  status: "idle",
});

export const fetchAllCells = createAsyncThunk("cells/fetch", () =>
  api.fetchAllCells()
);

export const updateCells = createAsyncThunk("cells/update", api.updateCells);

export const createCells = createAsyncThunk("cells/create", api.createCells);

export const deleteCells = createAsyncThunk("cells/delete", api.deleteCells);

const roadmapcellSlice = createSlice({
  name: "roadmapcells",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCells.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCells.fulfilled, (state, { payload }) => {
        cellsAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateCells.fulfilled, cellsAdapter.updateOne)
      .addCase(deleteCells.fulfilled, cellsAdapter.removeOne)
      .addCase(createCells.fulfilled, cellsAdapter.addOne);
  },
});

export default roadmapcellSlice.reducer;

const selectState = (state) => state.roadmapcells;

export const {
  selectAll: selectCells,
  selectById: selectCellById,
} = cellsAdapter.getSelectors(selectState);

export const selectCellStatus = createSelector(
  selectState,
  (state) => state.status
);
