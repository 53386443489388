import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllSprintCycle = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/sprintcycle`,
    authConfig()
  );
  return response.data.results;
};

export const fetchSprintCycle = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/sprintcycle/${id}`,
    authConfig()
  );
  return response.data;
};

export const createSprintCycle = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/sprintcycle`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateSprintCycle = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/sprintcycle/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteSprintCycle = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/sprintcycle/${id}`,
    authConfig()
  );
  return response.data;
};

const sprintcycleAPI = {
  fetchAllSprintCycle,
  fetchSprintCycle,
  createSprintCycle,
  updateSprintCycle,
  deleteSprintCycle,
};
export default sprintcycleAPI;
