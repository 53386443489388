import api from "../../api/stakeholders/companymatrix";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const companymatricesAdapter = createEntityAdapter({
  selectId: (companymatrix) => companymatrix.pk,
});

const initialState = companymatricesAdapter.getInitialState({
  status: "idle",
});

export const fetchAllCompanyMatrices = createAsyncThunk("companymatrices/fetch", () =>
  api.fetchAllCompanyMatrices()
);

export const updateCompanymatrix = createAsyncThunk(
  "companymatrices/update",
  api.updateCompanymatrix
);

export const createCompanymatrix = createAsyncThunk(
  "companymatrices/create",
  api.createCompanymatrix
);

export const deleteCompanymatrix = createAsyncThunk(
  "companymatrices/delete",
  api.deleteCompanymatrix
);

const companymatricesSlice = createSlice({
  name: "companymatrices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCompanyMatrices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCompanyMatrices.fulfilled, (state, { payload }) => {
        companymatricesAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateCompanymatrix.fulfilled, companymatricesAdapter.updateOne)
      .addCase(deleteCompanymatrix.fulfilled, companymatricesAdapter.removeOne)
      .addCase(createCompanymatrix.fulfilled, companymatricesAdapter.addOne);
  },
});

export default companymatricesSlice.reducer;

const selectState = (state) => state.companymatrices;

export const {
  selectAll: selectMatrices,
  selectById: selectCompanymatrixById,
} = companymatricesAdapter.getSelectors(selectState);

export const selectMatricesStatus = createSelector(
  selectState,
  (state) => state.status
);
