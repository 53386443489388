import api from "../../api/innovation/actionlink";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const actionlinkAdapter = createEntityAdapter({
  selectId: (idea) => idea.pk,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = actionlinkAdapter.getInitialState({status: "idle"});

export const fetchAllActionLink = createAsyncThunk("actionlink/fetch", () =>
  api.fetchAllActionLink()
);

export const updateActionLink = createAsyncThunk("actionlink/update", api.updateActionLink);

export const createActionLink = createAsyncThunk("actionlink/create", api.createActionLink);

export const deleteActionLink = createAsyncThunk("actionlink/delete", api.deleteActionLink);

const actionlinkSlice = createSlice({
  name: "actionlink",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllActionLink.pending, (state) => {state.status = "loading"})
      .addCase(fetchAllActionLink.fulfilled, (state, { payload }) => {
        actionlinkAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateActionLink.fulfilled, actionlinkAdapter.updateOne)
      .addCase(deleteActionLink.fulfilled, actionlinkAdapter.removeOne)
      .addCase(createActionLink.fulfilled, actionlinkAdapter.addOne);
  },
});

export const {} = actionlinkSlice.actions;

export default actionlinkSlice.reducer;

const selectState = (state) => state.actionlink;

export const {
  selectAll: selectActionLink,
  selectById: selectActionLinkById,
} = actionlinkAdapter.getSelectors(selectState);

export const selectActionLinkStatus = createSelector(
  selectState,
  (state) => state.status
);
