import {
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

const sprints_stateAdapter = createEntityAdapter();

const initialState = sprints_stateAdapter.getInitialState({

  isSprintModalOpen: false, 
  sprintPK: null,
  ideaPK: null,
  isSprintDateModalOpen: false,
  isSprintCommentModalOpen: false,
  isSprintSponsorModalOpen: false,
  commentPK: null,
  commentsprintPK: null,
  isActiveSprint: true,
  isCompletedSprint: false,
  isDiscontinuedSprint: false,
  isMyTaskSprint: false,
  isSprintInvestmentModalOpen: false,
  sprintInvestmentPK: null,
  isProgressModalOpen: false,
  progresssprintPK: null,
  progressPK:null
});

const sprints_stateSlice = createSlice({
  name: "sprints_state",
  initialState,
  reducers: {
    openSprintModal(state, action) {
      state.isSprintModalOpen = true;
      state.sprintPK = action.payload.sprintPK;
      state.ideaPK = action.payload.ideaPK;
    },
    closeSprintModal(state) {
      state.isSprintModalOpen = false;
      state.sprintPK = null;
      state.ideaPK = null;
    },
    openSprintDateModal(state) {
      state.isSprintDateModalOpen = true;
    },
    closeSprintDateModal(state) {
      state.isSprintDateModalOpen = false;
    },
    openSprintCommentModal(state,action) {
      state.isSprintCommentModalOpen = true;
      state.commentPK = action.payload.commentPK;
      state.commentsprintPK = action.payload.commentsprintPK;
    },
    closeSprintCommentModal(state) {
      state.isSprintCommentModalOpen = false;
      state.commentPK = null;
      state.commentsprintPK = null;
    },
    openSprintAddSponsorModal(state,action) {
      state.isSprintSponsorModalOpen = true;
      state.sponsorsprintPK = action.payload.sponsorsprintPK;
    },
    closeSprintAddSponsorModal(state) {
      state.isSprintSponsorModalOpen = false;
      state.sponsorsprintPK = null;
    },
    checkActiveSprint(state) {
      state.isActiveSprint = true;
    },
    uncheckActiveSprint(state) {
      state.isActiveSprint = false;
    },
    checkCompletedSprint(state) {
      state.isCompletedSprint = true;
    },
    uncheckCompletedSprint(state) {
      state.isCompletedSprint = false;
    },
    checkDiscontinuedSprint(state) {
      state.isDiscontinuedSprint = true;
    },
    uncheckDiscontinuedSprint(state) {
      state.isDiscontinuedSprint = false;
    },
    checkMyTaskSprint(state) {
      state.isMyTaskSprint = true;
    },
    uncheckMyTaskSprint(state) {
      state.isMyTaskSprint = false;
    },
    openSprintInvestmentModal(state, action) {
      state.isSprintInvestmentModalOpen = true;
      state.sprintInvestmentPK = action.payload.sprintInvestmentPK;
    },
    closeSprintInvestmentModal(state) {
      state.isSprintInvestmentModalOpen = false;
      state.sprintInvestmentPK = null;
    },
    openProgressModal(state, action) {
      state.isProgressModalOpen = true;
      state.progresssprintPK = action.payload.progresssprintPK;
      state.progressPK = action.payload.progressPK;
    },
    closeProgressModal(state) {
      state.isProgressModalOpen = false;
      state.progresssprintPK = null;
      state.progressPK = null;
    },
  },
});

export const { 

  openSprintModal,
  closeSprintModal,
  openSprintDateModal,
  closeSprintDateModal,
  openSprintCommentModal,
  closeSprintCommentModal,
  openSprintAddSponsorModal,
  closeSprintAddSponsorModal,
  checkActiveSprint,
  uncheckActiveSprint,
  checkCompletedSprint,
  uncheckCompletedSprint,
  checkDiscontinuedSprint,
  uncheckDiscontinuedSprint,
  checkMyTaskSprint,
  uncheckMyTaskSprint,
  openSprintInvestmentModal,
  closeSprintInvestmentModal,
  openProgressModal,
  closeProgressModal


} = sprints_stateSlice.actions;

export default sprints_stateSlice.reducer;



