import api from "../../toolkit/api/tenants";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const tenantsAdapter = createEntityAdapter({
  selectId: (tenant) => tenant.pk,
  //sortComparer: (a, b) => a.fact.localeCompare(b.fact),
});

const initialState = tenantsAdapter.getInitialState({status: "idle", isHelpModalOpen: false});

export const fetchAllTenants = createAsyncThunk("tenants/fetch", () => api.fetchAllTenants());

export const updateTenants = createAsyncThunk("tenants/update", api.updateTenants);

export const createTenants = createAsyncThunk("tenants/create", api.createTenants);

export const deleteTenants = createAsyncThunk("tenants/delete", api.deleteTenants);

export const uploadLogo = createAsyncThunk("tenants/image", api.uploadLogo);

export const logoUrl = createAsyncThunk("tenants/logo", api.logoUrl);

const tenantsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    openHelpModal(state) {
      state.isHelpModalOpen = true;
    },
    closeHelpModal(state) {
      state.isHelpModalOpen = false;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTenants.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllTenants.fulfilled, (state, { payload }) => {
        tenantsAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateTenants.fulfilled, tenantsAdapter.updateOne)
      .addCase(deleteTenants.fulfilled, tenantsAdapter.removeOne)
      .addCase(createTenants.fulfilled, tenantsAdapter.addOne);
  },
});

export const {openHelpModal, closeHelpModal} = tenantsSlice.actions;

export default tenantsSlice.reducer;

const selectState = (state) => state.tenants;

export const {
  selectAll: selectTenants,
  selectById: selectTenantById,
} = tenantsAdapter.getSelectors(selectState);

export const selectTenantsStatus = createSelector(
  selectState,
  (state) => state.status
);
