import React, {Fragment } from "react";
import { useDispatch } from "react-redux";
import "../common/css/dot.css";
import { openRolesModal } from "./../../redux/toolkit/features/organization_state";
import { openDeleteModal } from "../../redux/toolkit/features/modals/deleteConfirmModal";


function RolesDetail(props) {
    const {row, personview} = props;
    const dispatch = useDispatch();
    var incumbent = personview.filter(person => person.position_id===row.pk && (person.startdate === null || new Date(person.startdate) <= new Date()) && (person.enddate === null || new Date(person.enddate) > new Date()))

    return (
        <Fragment>
             <td className="pl-0" style={{cursor:'pointer'}} onClick={() => dispatch(openRolesModal({roleId:row.pk}))}>{row.title}</td>
             <td style={{textAlign:'center', cursor:'pointer'}} onClick={() => dispatch(openRolesModal({roleId:row.pk}))}>{incumbent[0]?incumbent[0].firstname + ' '+ incumbent[0].secondname:'Open Position'}</td>
             <td style={{textAlign:'center', cursor:'pointer'}} onClick={() => dispatch(openRolesModal({roleId:row.pk}))}>-</td>             
             <td style={{textAlign:'center', cursor:'pointer'}} onClick={() => dispatch(openRolesModal({roleId:row.pk}))}>{row.startdate!==null?row.startdate:'-'}</td>
             <td style={{textAlign:'center', cursor:'pointer'}} onClick={() => dispatch(openRolesModal({roleId:row.pk}))}>{row.enddate!==null?row.enddate:'-'}</td>
             <td style={{textAlign:'center'}}>{(row.compensationbase?row.compensationbase:0 + row.compensationsti?row.compensationsti:0+row.compensationlti?row.compensationlti:0+row.compensationbenefits?row.compensationbenefits:0).toLocaleString()+" " + row.compensation_currency}</td>
             <td style={{textAlign:'center'}}><i id="target" className="fas fa-list"/></td>
             {row.issuccession && <td style={{textAlign:'center', cursor:'pointer'}} onClick={() => dispatch(openRolesModal({roleId:row.pk}))}><i style={{fontSize:'smaller', color:'rgb(100,100,100)'}} className="fas fa-check"/></td>}             
             {!row.issuccession && <td style={{textAlign:'center', cursor:'pointer'}} onClick={() => dispatch(openRolesModal({roleId:row.pk}))}></td>}     
             <td style={{width:'8px', textAlign:'center'}} className="mr-0 ml-0 pl-0 pr-0"><a className="ml-0 mr-0 pl-0 pr-0" onClick={() => dispatch(openDeleteModal({message: row.title,recordpk: row.pk, recordtype: 'role'}))}><i className="far fa-trash-alt mt-1 ml-0 mr-0 pl-0 pr-0" style={{color:'rgb(120,120,120)'}} title="Delete Role"/></a></td>
      
        </Fragment>
    )
}

export {RolesDetail};