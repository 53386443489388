import React, {Fragment, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { closeSuccessYearModal} from '../../../redux/toolkit/features/success_state';
import { updateSuccessyear, createSuccessyear, fetchAllSuccessyear } from '../../../redux/toolkit/features/success/successyear';


function SuccessYearModal(props) {
    const {successyear} = props;
    const isSuccessYearModalOpen = useSelector((state) => state.success_state.isSuccessYearModalOpen)
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch();

    let modalClass = 'modal';
    if (isSuccessYearModalOpen) {
        modalClass += ' is-active';
    }
   
    const currentyear = (new Date()).getFullYear();
    const years = Array.from(new Array(8),(val, index) => currentyear + index);
    const [year, setYear] = useState(null);

    useEffect(() => {
        setYear(successyear[0]?.year || currentyear)
    }, [successyear]);

    function renderForm() { 

        return (

        <Fragment>
            <div className="field is-horizontal is-align-items-center">
                    <div className="field-body">
                        <div className="control">
                            <div className="select">
                                <select
                                    style={{width:"250px"}}
                                    className="select"
                                    value={year}
                                    onChange={(e)=> setYear(e.target.value)}
                                >
                                    {years &&
                                    years.map((p, index) => (
                                        <option key={"YearList_"+index} value={p}>
                                            {p}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                    </div>
                </div>     
            </Fragment>
        )
    }

   


    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card' key='modalCard' style={{width: "300px"}}>
                    <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className='modal-card-title' style={{fontSize:"17px", color: textcolor}}>SET SUCCESS YEAR</p>
                        <button className='delete' aria-label='close' onClick={() => dispatch(closeSuccessYearModal())}>
                            {' '}
                        </button>
                    </header>
                    <section className='modal-card-body' key='modalBody' style={{minHeight: '50px'}}>
                      
                    {renderForm()}

                    </section>
                    <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>

                        {successyear[0] &&
                            <button
                                style={{borderRadius:"4px"}}
                                className="button is-success is-small"
                                key="new"
                                onClick={async () => {
                                    await dispatch(updateSuccessyear({id:successyear[0].pk,year:year}))
                                    .unwrap()
                                        dispatch(fetchAllSuccessyear());
                                        dispatch(closeSuccessYearModal());
                                }}
                            >
                                Save
                            </button>}

                            {successyear[0]===undefined &&
                            <button
                                style={{borderRadius:"4px"}}
                                className="button is-success is-small"
                                key="new"
                                onClick={async () => {
                                    await dispatch(createSuccessyear({year:year}))
                                    .unwrap()
                                        dispatch(fetchAllSuccessyear());
                                        dispatch(closeSuccessYearModal());
                                }}
                            >
                                Save
                            </button>}


                            <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => dispatch(closeSuccessYearModal())}>
                                Cancel
                            </button>

                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SuccessYearModal;
