import api from "../api/competencies";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const competenciesAdapter = createEntityAdapter({
  selectId: (competency) => competency.pk,
  sortComparer: (a, b) => a.competency.localeCompare(b.competency),
});

const initialState = competenciesAdapter.getInitialState({
  status: "idle", isOpen: false, competencypk: null
});

export const fetchAllCompetencies = createAsyncThunk("competencies/fetch", () =>
  api.fetchAllCompetencies()
);

export const updateCompetencies = createAsyncThunk("competencies/update", api.updateCompetencies);

export const createCompetencies = createAsyncThunk("competencies/create", api.createCompetencies);

export const deleteCompetencies = createAsyncThunk("competencies/delete", api.deleteCompetencies);


const competenciesSlice = createSlice({
  name: "competencies",
  initialState,
  reducers: {
    openCompetencyModal(state, action) {
      state.isOpen = true;
      state.competencypk = action.payload;
    },
    closeCompetencyModal(state) {
      state.isOpen = false;
      state.competencypk = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCompetencies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCompetencies.fulfilled, (state, { payload }) => {
        competenciesAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateCompetencies.fulfilled, competenciesAdapter.updateOne)
      .addCase(deleteCompetencies.fulfilled, competenciesAdapter.removeOne)
      .addCase(createCompetencies.fulfilled, competenciesAdapter.addOne);
  },
});

export const { openCompetencyModal, closeCompetencyModal } = competenciesSlice.actions;

export default competenciesSlice.reducer;

const selectState = (state) => state.competencies;

export const {
  selectAll: selectCompetencies,
  selectById: selectCompetencyById,
} = competenciesAdapter.getSelectors(selectState);

export const selectCompetenciesStatus = createSelector(
  selectState,
  (state) => state.status
);
