import api from "../../api/success/baseline";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const baselineAdapter = createEntityAdapter({
  selectId: (baseline) => baseline.pk,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = baselineAdapter.getInitialState({status: "idle"});

export const fetchAllBaseline = createAsyncThunk("baseline/fetch", () =>
  api.fetchAllBaseline()
);

export const updateBaseline = createAsyncThunk("baseline/update", api.updateBaseline);

export const createBaseline = createAsyncThunk("baseline/create", api.createBaseline);

export const deleteBaseline = createAsyncThunk("baseline/delete", api.deleteBaseline);


const baselineSlice = createSlice({
  name: "baseline",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBaseline.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllBaseline.fulfilled, (state, { payload }) => {
        baselineAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateBaseline.fulfilled, baselineAdapter.updateOne)
      .addCase(deleteBaseline.fulfilled, baselineAdapter.removeOne)
      .addCase(createBaseline.fulfilled, baselineAdapter.addOne);
  },
});

export const { } = baselineSlice.actions;

export default baselineSlice.reducer;

const selectState = (state) => state.baseline;

export const {
  selectAll: selectBaseline,
  selectById: selectBaselineById,
} = baselineAdapter.getSelectors(selectState);

export const selectbaselineStatus = createSelector(
  selectState,
  (state) => state.status
);

