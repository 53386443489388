import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "moment-timezone";
import { IdeaRow } from "./IdeaRow";
import {openIdeasModal} from "./../../redux/toolkit/features/innovation_state";
import {fetchAllIdeas} from "./../../redux/toolkit/features/innovation/ideas";
import {closeDeleteModal}  from "./../../redux/toolkit/features/modals/deleteConfirmModal";
import { FormatString } from "../common/FormatCell"

function Idea(props) {
  const {themePK, checkboxstatus, charts, setIdeaRowID, decimals, Checkall, ideaslist, cost, impact, isAllRowSelected, setGroupSelect,  ideacheckedhistory} = props;
  const ideas = Object.values(useSelector((state) => state.ideas.entities));
  const isIdeasModalOpen = useSelector((state) => state.innovation_state.isIdeasModalOpen)  
  const isDeleteModalOpen = useSelector((state) => state.deleteConfirmModal.isOpen);
  const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);
  let RowsSelected = [];
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const [ideaarray, setIdeaArray] = useState([])
  const dispatch = useDispatch();
  
  async function getIdeas() {
    dispatch(fetchAllIdeas())
  }

  useEffect(() => {
  },[cost, impact]);


  useEffect(() => {
    if(!isIdeasModalOpen) {
      getIdeas();
    }
  },[isIdeasModalOpen]);

  useEffect(() => {
    if (!isDeleteModalOpen && recordtype==="ideas") {
      getIdeas();
      dispatch(closeDeleteModal());
    }
  },[isDeleteModalOpen]);


  if (!ideas) {
    return null;
  }

  return (
    <Fragment>

      <div className='pl-5 pr-5 pt-5'>
        <table className="table is-fullwidth">
          <tbody>
            <tr style={{backgroundColor: backgroundcolor, color: textcolor }}>
              <th style={{width:"10px",color: textcolor}}>
                <input type="checkbox" id="checkall" name="checkall" checked={isAllRowSelected}  
                  onChange={(e) => {
                    Checkall(e.target.checked, 'group')
                    setGroupSelect(e.target.checked)
                  }}/>
              </th>
              <th style={{color: textcolor}}>Idea</th>
              <th style={{textAlign: "center", width:"100px", color: textcolor}}>{'Impact '}</th>
              <th style={{textAlign: "center", width:"100px", color: textcolor}}>Cost</th>
              <th style={{textAlign: "center", width:"140px", color: textcolor}}>Action Status</th>
              <th style={{width:"8px", color: textcolor}}></th>
              <th style={{width:"8px", color: textcolor}} ><a style={{color: textcolor}} onClick={() => dispatch(openIdeasModal({ideaPK:null,themeModalPK:themePK}))}><button className="fas fa-plus-circle"></button></a></th>
            </tr>
            {ideas &&
            ideaslist.map((idea) => (
            <Fragment>
              <IdeaRow 
                index={idea.pk} 
                RowsSelected={RowsSelected} 
                themeModalKey={themePK}
                isAllRowSelected={isAllRowSelected}
                checkboxstatus={checkboxstatus}
                setIdeaRowID={setIdeaRowID}
                themePK={themePK}
                decimals={decimals}
                Checkall={Checkall}
                ideacheckedhistory={ideacheckedhistory}
              />
            </Fragment>
            ))}
            {ideas.length!==0 &&
            <tr>
              <td colSpan={2} style={{textAlign: "right", fontWeight:600}}>Selected Totals:</td>
              <td style={{textAlign: "center", fontWeight:600}}>{FormatString(impact, 'Number', decimals)}</td>
              <td style={{textAlign: "center", fontWeight:600}}>{cost.toLocaleString()}</td>
              <td colSpan={2}></td>
            </tr>}
            {ideas.length===0 &&
            <tr>
              <td colSpan={6} style={{textAlign: "center", fontWeight:400}}>Click <a style={{color: backgroundcolor}} onClick={() => dispatch(openIdeasModal({ideaPK:null,themeModalPK:themePK}))}><i className="fas fa-plus-circle"/></a> to Add New Idea </td>
            </tr>}

          </tbody>
        </table>
      </div>

    </Fragment>
  );
}

export { Idea };
