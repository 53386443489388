import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllTimelines = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/timeline`,
    authConfig()
  );
  return response.data.results;
};

export const fetchTimelines = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/timeline/${id}`,
    authConfig()
  );
  return response.data;
};

export const createTimelines = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/timeline`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateTimelines = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/timeline/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteTimelines = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/timeline/${id}`,
    authConfig()
  );
  return response.data;
};

const timelinesAPI = {
  fetchAllTimelines,
  fetchTimelines,
  createTimelines,
  updateTimelines,
  deleteTimelines,
};
export default timelinesAPI;
