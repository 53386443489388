import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllOverallscores = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/overallscores`,
    authConfig()
  );
  return response.data.results;
};

export const fetchOverallscores = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/overallscores/${id}`,
    authConfig()
  );
  return response.data;
};

export const createOverallscores = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/overallscores`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateOverallscores = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/overallscores/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteOverallscores = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/overallscores/${id}`,
    authConfig()
  );
  return response.data;
};

const overallscoresAPI = {
  fetchAllOverallscores,
  fetchOverallscores,
  createOverallscores,
  updateOverallscores,
  deleteOverallscores,
};
export default overallscoresAPI;
