import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllActionLink = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/actionlink`,
    authConfig()
  );
   return response.data;
};

export const fetchActionLink = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/actionlink/${id}`,
    authConfig()
  );
  return response.data;
};

export const createActionLink = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/actionlink`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateActionLink = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/actionlink/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteActionLink = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/actionlink/${id}`,
    authConfig()
  );
  return response.data;
};

const actionlinkAPI = {
  fetchAllActionLink,
  fetchActionLink,
  createActionLink,
  updateActionLink,
  deleteActionLink,
};
export default actionlinkAPI;
