import React, {Fragment, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {_} from 'lodash'
import {filter} from 'lodash'
import { Container } from "./sections/container";

import ConfirmDeleteBox from "./../../../components/common/ConfirmDeleteBox";
import { selectSectionById, fetchAllSections } from "../../../redux/toolkit/features/roadmapsection";
import { closeRoadmapSectionsModal, openRoadmapSectionsDetailModal } from "../../../redux/toolkit/features/roadmaps_state";

function SectionsModal() {
    const tabs = ['Section', 'Table', 'Timeline', 'Charts']
    //const sectionpk = useSelector((state) => state.sections.sectionpk)
    const sections = (Object.values(useSelector((state) => state.sections.entities))).sort((a, b) => a.order - b.order);
    const showModalDelete = useSelector((state) => state.deleteConfirmModal.isOpen)
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const showSectionsModal = useSelector((state) => state.roadmaps_state.isOpen)
    const [showDeleteModal, setShowDeleteModal]= useState(false)
    const tabsshort = ['Section']
    const [activetab, setActivetab] = useState(tabs[0]);
    const [name, setName] = useState('');
    const [narrative, setNarrative] = useState('');
    const [category, setCategory] = useState('');
    var categorylist = [...new Set(sections.flatMap(({category}) => category))]
    const dispatch = useDispatch();

    let modalClass = 'modal';
    if (showSectionsModal) {
        modalClass += ' is-active'
    }

    useEffect(() => {
        dispatch(fetchAllSections());
    }, []);

    useEffect(() => {
        if (!showModalDelete) {
            dispatch(fetchAllSections());
        }
    }, [showModalDelete]);

    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card' key='modalCard'>
                    
                    <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className='modal-card-title' style={{fontSize:"17px", color: textcolor}}>ROADMAP SECTIONS</p>
                        <button className='delete' aria-label='close' onClick={() => dispatch(closeRoadmapSectionsModal())}>
                            {' '}
                        </button>
                    </header>
                    
                    <section className='modal-card-body' key='modalBody' style={{minHeight: '350px'}}>

                        {sections.length !== 0 &&
                            <Container sections={sections}/>}
                        {sections.length === 0 &&
                        <p className = "pt-5" style={{fontSize:"16.5px"}}>No Records Returned</p>}

                    </section>
                    
                    <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>
                                
                                    <button
                                        style={{borderRadius:"4px", backgroundColor: backgroundcolor, color: textcolor}}
                                        className='button is-small'
                                        key='save'
                                        onClick={async () => {
                                            dispatch(openRoadmapSectionsDetailModal({sectionpk:null, isAddNewSection:true}));
                                            dispatch(closeRoadmapSectionsModal());
                                        }}>
                                        Add Section
                                    </button>

                        <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => dispatch(closeRoadmapSectionsModal())}>
                            Cancel
                        </button>
                </footer>
                </div>
                </div>


        </Fragment>
    );
}

export default SectionsModal;
