import api from "../../api/organization/metrics";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const metricsAdapter = createEntityAdapter({
  selectId: (person) => person.pk,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = metricsAdapter.getInitialState({
  status: "idle", isOpen: false, peoplepk: null
});

export const fetchAllMetrics = createAsyncThunk("metrics/fetch", () => api.fetchAllMetrics());

export const updateMetrics = createAsyncThunk("metrics/update", api.updateMetrics);

export const createMetrics = createAsyncThunk("metrics/create", api.createMetrics);

export const deleteMetrics = createAsyncThunk("metrics/delete", api.deleteMetrics);

const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {
    openPeopleModal(state, action) {
      state.isOpen = true;
      state.peoplepk = action.payload;
    },
    closePeopleModal(state) {
      state.isOpen = false;
      state.peoplepk = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMetrics.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllMetrics.fulfilled, (state, { payload }) => {
        metricsAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateMetrics.fulfilled, metricsAdapter.updateOne)
      .addCase(deleteMetrics.fulfilled, metricsAdapter.removeOne)
      .addCase(createMetrics.fulfilled, metricsAdapter.addOne);
  },
});

export const { openPeopleModal, closePeopleModal } = metricsSlice.actions;

export default metricsSlice.reducer;

const selectState = (state) => state.metrics;

export const {
  selectAll: selectMetrics,
  selectById: selectMetricById,
} = metricsAdapter.getSelectors(selectState);

export const selectMetricsStatus = createSelector(
  selectState,
  (state) => state.status
);
