import update from 'immutability-helper'
import React, { useCallback, useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'

import {Card} from './Card.js'
import { orderGantt, fetchAllGantt } from '../../../../redux/toolkit/features/roadmaps/gantt.js';

export const GanttContainer = (props) => {
    const [rows, setRows] = useState(props.rows);
    const {sectionpk} = props;
    const dispatch = useDispatch();

    console.log("Gannt Rows", rows)
    useEffect(() => {
        setRows(props.rows);
    }, [props]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setRows((prevGantt) => {
                const newGantt = update(prevGantt, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevGantt[dragIndex]],
                    ],
                });
                const newGanttPks = newGantt.map(gantt => gantt.pk);
                dispatch(orderGantt({'sorted_pks':newGanttPks, section:props.sectionpk}))
                .unwrap()
                dispatch(fetchAllGantt());

                return newGantt;
            }
        )
    }, [])

    const renderGantt = useCallback(
        (row, index) => {
            return (
                <Card
                    key={row.pk}
                    sectionpk={sectionpk}
                    index={index}
                    id={row.pk}
                    text={row.taskname}
                    moveCard={moveCard}
                    rowPk={row.pk}
                />
            )
        },
        [],
    )

    return (
        <>
            <div className="m-0 p-0">{rows.map((card, i) => renderGantt(card, i))}</div>
        </>
    )
}