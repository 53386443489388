import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllProgressbar = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/progressbar`,
    authConfig()
  );
  return response.data.results;
};

export const fetchProgressbar = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/progressbar/${id}`,
    authConfig()
  );
  return response.data;
};

export const createProgressbar = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/progressbar`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateProgressbar = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/progressbar/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteProgressbar = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/progressbar/${id}`,
    authConfig()
  );
  return response.data;
};

const progressbarAPI = {
  fetchAllProgressbar,
  fetchProgressbar,
  createProgressbar,
  updateProgressbar,
  deleteProgressbar,
};
export default progressbarAPI;
