import api from "../../api/organization/positionparent";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const positionparentAdapter = createEntityAdapter({
  selectId: (positionparent) => positionparent.pk,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = positionparentAdapter.getInitialState({
  status: "idle", isOpen: false, peoplepk: null
});

export const fetchAllPositionparent = createAsyncThunk("positionparent/fetch", () => api.fetchAllPositionparent());

export const updatePositionparent = createAsyncThunk("positionparent/update", api.updatePositionparent);

export const createPositionparent = createAsyncThunk("positionparent/create", api.createPositionparent);

export const deletePositionparent = createAsyncThunk("positionparent/delete", api.deletePositionparent);

const positionparentSlice = createSlice({
  name: "positionparent",
  initialState,
  reducers: {
    openPeopleModal(state, action) {
      state.isOpen = true;
      state.peoplepk = action.payload;
    },
    closePeopleModal(state) {
      state.isOpen = false;
      state.peoplepk = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPositionparent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPositionparent.fulfilled, (state, { payload }) => {
        positionparentAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updatePositionparent.fulfilled, positionparentAdapter.updateOne)
      .addCase(deletePositionparent.fulfilled, positionparentAdapter.removeOne)
      .addCase(createPositionparent.fulfilled, positionparentAdapter.addOne);
  },
});

export const { openPeopleModal, closePeopleModal } = positionparentSlice.actions;

export default positionparentSlice.reducer;

const selectState = (state) => state.positionparent;

export const {
  selectAll: selectPositionparent,
  selectById: selectPositionparentById,
} = positionparentAdapter.getSelectors(selectState);

export const selectPositionparentStatus = createSelector(
  selectState,
  (state) => state.status
);
