import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllFormula = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/formula`,
    authConfig()
  );
  return response.data.results;
};

export const fetchFormula = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/formula/${id}`,
    authConfig()
  );
  return response.data;
};

export const createFormula = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/formula`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateFormula = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/formula/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteFormula = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/formula/${id}`,
    authConfig()
  );
  return response.data;
};

const formulaAPI = {
  fetchAllFormula,
  fetchFormula,
  createFormula,
  updateFormula,
  deleteFormula,
};
export default formulaAPI;
