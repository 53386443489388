import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";
import {authConfig} from "./helpers";
import axios from "axios";

class ValuesService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/values`;
    }

    async getValues() {
        const values = await this.getAll();
        return values.results;
    }

    async sortValues(valuePkList){
        const url = this.listUrl() + "/sort-values";
        const config = authConfig();
        const response = await axios.post(url, {"sorted_pks": valuePkList}, config);
        return response.data;
    }
}

export const valuesService = new ValuesService();
