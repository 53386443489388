import React, {Fragment, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {_} from 'lodash'
import {filter} from 'lodash'
import { RowContainer } from "./rows/container";
import { GanttContainer } from "./gantt/Container";
import { selectSectionById, fetchAllSections, createSections, updateSections } from "../../../redux/toolkit/features/roadmapsection";
import { openRoadmapSectionsModal, closeRoadmapSectionsDetailModal, openEditRowsModal, openGanttModal } from "../../../redux/toolkit/features/roadmaps_state";
import { fetchAllRows } from "../../../redux/toolkit/features/roadmaprows";
import { fetchAllGantt } from "../../../redux/toolkit/features/roadmaps/gantt";
import { openDeleteModal } from "../../../redux/toolkit/features/modals/deleteConfirmModal";

function SectionsModalAddSection() {
    const tabs = ['Section', "Table Rows", "Gantt Rows"]
    const showAddSectionsModal = useSelector((state) => state.roadmaps_state.isDetailModalOpen)
    const isAddNewSection = useSelector((state) => state.roadmaps_state.isAddNewSection)
    const sectionpk = useSelector((state) => state.roadmaps_state.sectionpk)
    const sections = Object.values(useSelector((state) => state.sections.entities)).sort((a, b) => a.order - b.order);
    const rows = Object.values(useSelector((state) => state.roadmaprows.entities)).sort((a, b) => a.order - b.order);
    const gantt = Object.values(useSelector((state) => state.gantt.entities)).sort((a, b) => a.order - b.order);
    const sectionbyid = useSelector((state)=>selectSectionById(state, sectionpk))  
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const [activetab, setActivetab] = useState(tabs[0]);
    const [name, setName] = useState('');
    const [narrative, setNarrative] = useState('');
    const [category, setCategory] = useState('');
    const [isGantt, setIsGantt] = useState(false);
    const [isTable, setIsTable] = useState(false);
    const [newcategory, setNewCategory] = useState('');
    const [charts, setCharts] = useState(false);
    var categorylist = [...new Set(sections.flatMap(({category}) => category))].sort(); 
    const dispatch = useDispatch();
    var sectionrows = filter(rows, {section:sectionpk });
    var sectiongantt = filter(gantt, {section:sectionpk });
    let modalClass = 'modal';
    if (showAddSectionsModal) {
        modalClass += ' is-active'
    }
    let modalTitle=null;
    if (sectionpk) { modalTitle='EDIT ROADMAP SECTION'} else {modalTitle='NEW ROADMAP SECTION'}
   
   
    useEffect(() => {
        dispatch(fetchAllSections());
        dispatch(fetchAllGantt())
        setName(sectionbyid?sectionbyid.name:"")
        setNarrative(sectionbyid?sectionbyid.narrative:"")
        setCategory(sectionbyid?sectionbyid.category:"")
        setCharts(sectionbyid?sectionbyid.showcharts:false)
        setIsGantt(sectionbyid?sectionbyid.showgantt:false)
        setIsTable(sectionbyid?sectionbyid.showtable:false)
        //setSectionRows(filter(rows, {section:sectionpk }));
    }, [sectionpk]);

    useEffect(() => {
        dispatch(fetchAllSections());
        dispatch(fetchAllRows());
        dispatch(fetchAllGantt())
    }, []);


    async function updateGanttStatus(ischecked) {
        console.log("Check Statis", ischecked)
        setIsGantt(ischecked)
        await dispatch(updateSections({id:sectionpk, showgantt:ischecked, roadmap: 1}))
        .unwrap()
        dispatch(fetchAllSections());
        console.log("Check Statis", ischecked)
    }


    function renderSection() {
        return (
            <Fragment>
                
                <p className="mb-1" style={{fontSize:"smaller"}}>Roadmap Title</p>
                <div className='mb-4 field is-horizontal is-align-items-center'>
                    <div className='field-body is-flex'>
                        <div className='control is-flex-grow-1'>
                        {name !== undefined &&
                            <input
                                className='input'
                                type='text'
                                placeholder='Roadmap Title'
                                value={name}
                                onChange={async (event) => {setName(event.target.value)}}
                            />}
                        </div>
                    </div>
                </div>
    
                <p className="mb-1" style={{fontSize:"smaller"}}>Roadmap Narrative</p>
                <div className='mb-4 field is-horizontal is-align-items-center'>
                    <div className='field-body is-flex'>
                        <div className='control is-flex-grow-1'>
                        {narrative !== undefined &&    
                            <textarea
                                className='textarea'
                                rows='6'
                                placeholder='Roadmap Narrative'
                                value={narrative}
                                onChange={async (event) => {setNarrative(event.target.value)}}
                            />}
                        </div>
                    </div>
                </div>
    
                <p className="mb-1" style={{fontSize:"smaller"}}>Category</p>
                <div className='field is-horizontal is-align-items-center'>
                    <div className='field-body is-flex'>
                        <div className='control is-flex-grow-1'>
                        {category !== undefined &&
                            <Fragment>
                                <div className='select is-flex'>
                                    <select
                                        className='select is-fullwidth'
                                        value={category}
                                        onChange={async (event) => {setCategory(event.target.value)}}
                                    >
                                     {category === '' && <option selected>{'<Select Category>'}</option>}
                                    {categorylist &&
                                        categorylist.map((p) => (
                                        <option key={p} value={p}>
                                            {p}
                                        </option>
                                        ))}
                                        <option key='new'>
                                        &lt;New&gt;</option>
                                    </select>
                                </div>
                            </Fragment>}
                        </div>
                    </div>
                </div>

                {category==='<New>' &&
                <div className='mb-4 field is-horizontal is-align-items-center'>
                    <div className='field-body is-flex'>
                        <div className='control is-flex-grow-1'>
                        {name !== undefined &&
                            <input
                                className='input'
                                type='text'
                                placeholder='Enter New Category'
                                value={newcategory}
                                onChange={async (event) => {setNewCategory(event.target.value)}}
                            />}
                        </div>
                    </div>
                </div>
                }
    
            </Fragment>
        )
    }

    function formtabs() {
        return (
            <Fragment>
            <div className='tabs mt-0 pt-0 pb-0 mb-0 pl-1' style={{backgroundColor:"rgb(238, 238, 238)"}}>
                <div className="pl-1">
                <ul>
                    <li className ={activetab === 'Section' ? 'is-active' : ''}
                        key={'tab_Section'}
                        onClick={() => setActivetab('Section')}
                    >
                    <a>Section</a>
                    </li>
                    <li className ={activetab === 'Table Rows' ? 'is-active' : ''}
                        key={'tab_TableRows'}
                        onClick={() => setActivetab('Table Rows')}
                    >
                    <a>Table Rows</a>
                    </li>
                    <li className ={activetab === 'Gantt Rows' ? 'is-active' : ''}
                        key={'tab_GanttRows'}
                        onClick={() => setActivetab('Gantt Rows')}
                    >
                    <a>Gantt Rows</a>
                    </li>
                </ul>
            </div>
            </div>
            <hr style={{borderTop:"1px solid lightgrey"}}className="mt-0 pt-0"/>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card' key='modalCard' style={{width: "550px"}}>
                    
                    <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className='modal-card-title ml-1' style={{fontSize:"17px", color: textcolor}}>{modalTitle}</p>
                        <button className='delete' aria-label='close' onClick={() => dispatch(closeRoadmapSectionsDetailModal())}>
                            {' '}
                        </button>
                    </header>
                    
                    <section className="modal-card-body pt-0 pl-0 pr-0" key='modalBody' style={{minHeight: '450px', maxHeight: "600px"}}>
                    {sectionpk !== null &&
                        formtabs()
                    }
                    <div className="ml-5 mr-5 mt-0">
                    {activetab==="Section" && sectionpk === null && <div className="mt-4">{renderSection()}</div>}
                    {activetab==="Section" && sectionpk !== null && <div className="mt-0">{renderSection()}</div>}
                    {activetab==="Table Rows" && <Fragment>
                        <div className="mt-4 mb-3">
                            <label className="checkbox">
                            <input className="mr-3" type="checkbox" checked={isTable} onChange={async(e) => {
                                setIsTable(e.target.checked)
                                await dispatch(updateSections({id:sectionpk, showtable:e.target.checked, roadmap: 1}))
                                .unwrap()
                                dispatch(fetchAllSections());
                                }}/>
                            <span>Show Roadmap Table</span>
                            </label>
                        </div>
                        <div>
                            {isTable &&
                            <label className="checkbox">
                            <input className="mr-3" type="checkbox" checked={charts} onChange={async(e) => {
                                setCharts(e.target.checked)
                                await dispatch(updateSections({id:sectionpk, showcharts:e.target.checked, roadmap: 1}))
                                .unwrap()
                                dispatch(fetchAllSections());
                            }}/>
                            <span>Include Graphs</span>
                            </label>}

                            <hr className="mt-2 pt-0" style={{backgroundColor:'rgb(150,150,150)'}}/>
                        </div>
                        {sectionrows.length !== 0 &&
                            <RowContainer rows={sectionrows} sectionpk={sectionpk}/>
                        }
                        {sectionrows.length === 0 &&
                            <p className = "pt-2" style={{fontSize:"14.5px", fontWeight: "400"}}>No Records Returned</p>
                        }
                    </Fragment>}
                    {activetab==="Gantt Rows" && <Fragment>
                        <div className="mt-4 mb-3">
                            <label className="checkbox">
                            <input className="mr-3" type="checkbox" checked={isGantt} onChange={async(e) => {
                            setIsGantt(e.target.checked)
                            await dispatch(updateSections({id:sectionpk, showgantt:e.target.checked, roadmap: 1}))
                            .unwrap()
                            dispatch(fetchAllSections());
                            }}/>
                            <span>Show Gantt Chart</span>
                            </label>
                            <hr className="mt-2 pt-0" style={{backgroundColor:'rgb(150,150,150)'}}/>
                        </div>
                        {sectionrows.length !== 0 &&
                            <GanttContainer rows={sectiongantt} sectionpk={sectionpk}/>
                        }
                        {sectionrows.length === 0 &&
                            <p className = "pt-2" style={{fontSize:"14.5px", fontWeight: "400"}}>No Records Returned</p>
                        }
                    </Fragment>}
                    </div>
                    </section>
                    
                    <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>

                    <nav className="level">
                        <div className="level-left">
                            <div className="level-item">

                                {activetab === "Section" && sectionpk === null && category !== "" && category !== '<New>' &&
                                              
                                    <button
                                        style={{borderRadius:"4px"}}
                                        className='button is-success is-small'
                                        key='save'
                                        onClick={async () => {
                                                await dispatch(createSections({name:name, narrative: narrative, category:category, roadmap: 1, showcharts: charts, showgantt:isGantt, showtable:isTable  }))
                                                .unwrap()
                                                    dispatch(fetchAllSections());
                                                    dispatch(closeRoadmapSectionsDetailModal())
                                                    {isAddNewSection && dispatch(openRoadmapSectionsModal()) }  
                                        }}
                                    >
                                
                                        Save Section
                                    </button>
                                }

                                        {activetab === "Section" && sectionpk === null && category === '<New>' && newcategory !=="" &&
                                              
                                              <button
                                                  style={{borderRadius:"4px"}}
                                                  className='button is-success is-small'
                                                  key='save'
                                                  onClick={async () => {
                                                          await dispatch(createSections({name:name, narrative: narrative, category:newcategory, roadmap: 1, showcharts: charts, showgantt:isGantt, showtable:isTable  }))
                                                          .unwrap()
                                                              dispatch(fetchAllSections());
                                                              dispatch(closeRoadmapSectionsDetailModal());
                                                              {isAddNewSection && dispatch(openRoadmapSectionsModal())}  
                                                  }}
                                              >
                                          
                                                  Save Section
                                              </button>
                                          }


                                    {activetab === "Table Rows" && sectionpk !== null &&          
                                              
                                              <button
                                                  style={{borderRadius:"4px", backgroundColor: backgroundcolor, color: textcolor}}
                                                  className='button is-small'
                                                  key='save'
                                                  onClick={async () => {
                                                        //dispatch(closeRoadmapSectionsDetailModal())
                                                        dispatch(openEditRowsModal({editrowpk: null,isAddNewRow: true, editsectionpk:sectionpk}))
                                                  }}
                                              >
                                          
                                                  Add Row
                                              </button>
                                          }

                                    {activetab === "Gantt Rows" && sectionpk !== null &&          
                                              
                                              <button
                                                  style={{borderRadius:"4px", backgroundColor: backgroundcolor, color: textcolor}}
                                                  className='button is-small'
                                                  key='save'
                                                  onClick={async () => {
                                                        //dispatch(closeRoadmapSectionsDetailModal())
                                                        dispatch(openGanttModal({ganttrowpk: null, ganttsectionpk:sectionpk}))
                                                  }}
                                              >
                                          
                                                  Add Row
                                              </button>
                                          }

                                {activetab === "Section" && sectionpk !== null &&          
                                              
                                    <button
                                        style={{borderRadius:"4px"}}
                                        className='button is-success is-small'
                                        key='save'
                                        onClick={async () => {
                                                await dispatch(updateSections({id:sectionpk, name:name, narrative: narrative, category:category, roadmap: 1, showcharts: charts, showgantt:isGantt, showtable:isTable}))
                                                .unwrap()   
                                                    dispatch(fetchAllSections());
                                                    dispatch(closeRoadmapSectionsDetailModal());
                                        }}
                                    >
                                
                                        Save Section
                                    </button>
                                }
                                    <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => dispatch(closeRoadmapSectionsDetailModal())}>
                                        Cancel
                                    </button>
                                </div>
                            </div>

                            {activetab === "Section" && sectionpk !== null && 
                            <div className="level-right">
                                <div className="level-item">
                                <button className="button is-small is-danger" key="cancel" style={{borderRadius:"4px"}} 
                                onClick={async () => {
                                    dispatch(openDeleteModal({message: name,recordpk: sectionpk, recordtype: 'section'}))
                                    dispatch(closeRoadmapSectionsDetailModal());
                                    }}>
                                Delete Section
                            </button>
                                </div>
                            </div>}
                        </nav>

                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export {SectionsModalAddSection};
