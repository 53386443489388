import api from "../../api/stakeholders/stakeholders";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const stakeholdersAdapter = createEntityAdapter({
  selectId: (stakeholder) => stakeholder.pk,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = stakeholdersAdapter.getInitialState({
  status: "idle",
});

export const fetchAllStakeholders = createAsyncThunk("stakeholders/fetch", () =>
  api.fetchAllStakeholders()
);

export const updateStakeholder = createAsyncThunk(
  "stakeholders/update",
  api.updateStakeholder
);

export const createStakeholder = createAsyncThunk(
  "stakeholders/create",
  api.createStakeholder
);

export const deleteStakeholder = createAsyncThunk(
  "stakeholders/delete",
  api.deleteStakeholder
);

const stakeholdersSlice = createSlice({
  name: "stakeholders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllStakeholders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllStakeholders.fulfilled, (state, { payload }) => {
        stakeholdersAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateStakeholder.fulfilled, stakeholdersAdapter.updateOne)
      .addCase(deleteStakeholder.fulfilled, stakeholdersAdapter.removeOne)
      .addCase(createStakeholder.fulfilled, stakeholdersAdapter.addOne);
  },
});

export default stakeholdersSlice.reducer;

const selectState = (state) => state.stakeholders;

export const {
  selectAll: selectStakeholders,
  selectById: selectStakeholderById,
} = stakeholdersAdapter.getSelectors(selectState);

export const selectStakeholdersStatus = createSelector(
  selectState,
  (state) => state.status
);
