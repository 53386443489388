import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { closeGanttBaselineModal } from "./../../../redux/toolkit/features/success_state";
import { fetchAllGantt, updateGantt, selectGanttById } from "./../../../redux/toolkit/features/roadmaps/gantt";
import { filter } from "lodash";

function SuccessGanttBaselineModal() {
    const showGanttBaselineModal = useSelector((state) => state.success_state.isGanttBaselineModalOpen)
    const baselinePk = useSelector((state) => state.success_state.ganttbaselineId)
    const sectionPk = useSelector((state) => state.success_state.ganttbaselinesectionId)
    const GanttById = useSelector((state)=>selectGanttById(state, baselinePk));
    const gantt = Object.values(useSelector((state) => state.gantt.entities)).sort((a, b) => a.order - b.order);
    const ganttrows = filter(gantt, {section: sectionPk}).sort((a, b) => a.order - b.order);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const [ganttpk, setGanttpk] = useState(null); 
    const [baselinestart, setBaselinestart] = useState(null);
    const [baselineend, setBaselineend] = useState(null); 
    const [days, setDays] = useState(0); 
    const dispatch = useDispatch();

    useEffect(() => {
        setGanttpk(baselinePk)
        setBaselinestart(GanttById?GanttById.baselinestart:null)
        setBaselineend(GanttById?GanttById.baselineend:'24-3-19')
      }, [GanttById])    

      useEffect(() => {
        dispatch(fetchAllGantt())
      }, [])   


    let modalClass = 'modal';
    if (showGanttBaselineModal) {
        modalClass += ' is-active';
    }

    console.log("Gantt Modal", ganttpk, baselinestart, baselineend)

    function renderModal() {

        return (
            <Fragment>


                <p className="mb-2" style={{fontSize:'12px', fontWeight: '600'}}>Task</p>
                <div className="mb-4">
                    <div className="field is-horizontal is-align-items-center">        
                        <div className="field-body is-flex">
                                <p>{GanttById.taskname}</p>
                        </div>
                    </div>  
                </div>

                <p className="mb-2" style={{fontSize:'11px', fontWeight: '600'}}>Baseline Start Date</p>
                <div className="mb-4">
                    <div className="field is-horizontal is-align-items-center">        
                        <div className="field-body is-flex">
                            <input  className=" input mr-2" type='date' value={baselinestart} onChange={(e) => setBaselinestart(e.target.value)}/>
                        </div>
                    </div>  
                </div>

                <p className="mb-2" style={{fontSize:'11px', fontWeight: '600'}}>Baseline End Date</p>
                <div className="mb-4">
                    <div className="field is-horizontal is-align-items-center">        
                        <div className="field-body is-flex">
                            <input  className="input mr-2" type='date' value={baselineend} onChange={(e) => setBaselineend(e.target.value)}/>
                        </div>
                    </div>  
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card' style={{width:'360px'}} key='modalCard'>
                    <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className='modal-card-title' style={{fontSize:"17px", color: textcolor}}>{baselinePk!==null?'EDIT GANTT BASELINE': 'NEW GANTT BASELINE'}</p>
                        <button className='delete' aria-label='close' onClick={() => dispatch(closeGanttBaselineModal())}>
                            {' '}
                        </button>
                    </header>
                    <section className='modal-card-body' key='modalBody' style={{minHeight: '130px'}}>                  
                        {renderModal()}
                    </section>
                    <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>
                        <nav className="level">
                            <div className="level-left">
                                <div className="level-item">
                                    {ganttpk!==null && baselineend!==null && baselinestart!==null &&
                                    <button
                                        style={{borderRadius:"4px" }}
                                        className="button is-small is-success"
                                        key="new"
                                        onClick={async () => {
                                            dispatch(updateGantt({id: ganttpk, section: sectionPk, baselinestart: baselinestart, baselineend: baselineend, alertdays: 0}))
                                            dispatch(fetchAllGantt())
                                            dispatch(closeGanttBaselineModal());
                                        }}
                                    >
                                        Save
                                    </button>}
                                </div>
                                <div className="level-item">
                                    <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => dispatch(closeGanttBaselineModal())}>
                                        Cancel
                                    </button>
                                </div>
                            </div>

                            <div className="level-left">
                                <div className="level-right">
                                    {baselinestart!==null && baselineend!==null &&
                                    <button
                                        style={{borderRadius:"4px" }}
                                        className="button is-small is-danger"
                                        key="new"
                                        onClick={async () => {
                                            dispatch(updateGantt({id: ganttpk, section: sectionPk,baselinestart: null, baselineend: null, alertdays: 0}))
                                            dispatch(fetchAllGantt())
                                            dispatch(closeGanttBaselineModal());
                                        }}
                                    >
                                        Remove
                                    </button>}
                                </div>
                            </div>
                        </nav>

                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SuccessGanttBaselineModal;
