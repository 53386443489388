import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {filter} from "lodash"
import { fetchAllHelp } from "../../src/redux/toolkit/features/tenants/help";
import { closeHelpModal } from "../../src/redux/toolkit/features/tenants/help";
import {FXRates} from "./help/HelpContent"
import {DateRanges} from "./help/HelpContent"
import {Values} from "./help/HelpContent"
import {Timeline} from "./help/HelpContent"
import {GeneralSettings} from "./help/HelpContent"
import {ApplicationSetup} from "./help/HelpContent"
import {Company} from "./help/HelpContent"
import {Facts} from "./help/HelpContent"
import {IntroducingStrat2gy} from "./help/HelpContent"
import {SuccessVisions} from "./help/HelpContent"
import {SuccessVisionTimeframe} from "./help/HelpContent"
import {ChoosingSuccessVisions} from "./help/HelpContent"
import {Innovation} from "./help/HelpContent"
import {InnovationThemes} from "./help/HelpContent"
import {Ideas} from "./help/HelpContent"
import {InnovationCharts} from "./help/HelpContent"
import {Investments} from "./help/HelpContent"
import {ExportExcel} from "./help/HelpContent"
import {ExportPPT} from "./help/HelpContent"
import {Roadmaps} from "./help/HelpContent"
import {Formulas } from "./help/HelpContent";
import {Sprints} from "./help/HelpContent";
import {SprintReview} from "./help/HelpContent";
import {Stakeholders} from "./help/HelpContent";
import {Reports} from "./help/HelpContent";
import {People} from "./help/HelpContent";
import {RoadmapSections} from "./help/HelpContent";
import {RoadmapRows} from "./help/HelpContent";
import {RoadmapGantt} from "./help/HelpContent";
import {RoadmapCharts} from "./help/HelpContent";
import {Baselines} from "./help/HelpContent";
import {Linkages} from "./help/HelpContent";
import "./help/help.css"

function Help() {
  const help = Object.values(useSelector((state) =>  state.help.entities));
  const isHelpModalOpen = useSelector((state) => state.help.isHelpModalOpen);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const dispatch = useDispatch(); 
  const [text, setText] = useState(IntroducingStrat2gy);
  const [header, setHeader] = useState('Introducing Strat2gy');
  const [activemenu, setActiveMenu]= useState('IntroducingStrat2gy');
  const activestyle = {backgroundColor:backgroundcolor, color: textcolor}
  var helpdata=[];
console.log("SetText", setText)

  const [companystatus, setCompanyStatus] = useState(false);
  const [applicationstatus, setApplicationStatus] = useState(false);
  const [reportstatus, setReportStatus] = useState(false);
  //const [reportstatus, setReportStatus] = useState(false);
  const [successvisionstatus, setSuccessVisionStatus] = useState(false);
  const [roadmapstatus, setRoadmapStatus] = useState(false);
  const [sprintstatus, setSprintStatus] = useState(false);
  const [innovationstatus, setInnovationStatus] = useState(false);
  const [peoplestatus, setPeopleStatus] = useState(false);

  let modalClass = "modal";
    if (isHelpModalOpen) {
        modalClass += " is-active";
    }

  useEffect(() => {
    dispatch(fetchAllHelp());
  },[]);

  function renderHelp() {
    return (

        <div className="columns" style={{height: '100%'}}>
          <div className="column is-one-quarter" style={{borderRight:"1px solid rgb(240,240,240)", height: '100vh-3.5em'}}>
            <div className="ml-0" style={{backgroundColor:"rbg(240,240,240)"}}>
              <aside className="menu mt-2">
                <ul className="menu-list">
                  <li onClick={()=> {
                    setText(IntroducingStrat2gy) 
                    setHeader('Introducing Strat2gy')
                    setActiveMenu('IntroducingStrat2gy')}
                  }>
                    <a style={activemenu==='IntroducingStrat2gy'?activestyle:null}><i className="far fa-plus-square mr-2"></i>Introducing Strat2gy</a></li>              
                <li><a onClick={()=>{ 
                    setText(ApplicationSetup) 
                    setHeader('Setting Up The Application')
                    setActiveMenu('ApplicationSetup')
                    setApplicationStatus(applicationstatus?false:true)}
                    } 
                    style={activemenu==='ApplicationSetup'?activestyle:null}><i className="far fa-plus-square mr-2"></i>Setting Up The Application</a>
                {applicationstatus &&
                <ul>
                <li onClick={()=>{ 
                    setText(GeneralSettings) 
                    setHeader('General Settings')
                    setActiveMenu('CompanyInformation')}
                    }>
                    <a style={activemenu==='CompanyInformation'?activestyle:null}>General Settings</a></li>                  
                  <li onClick={()=>{ 
                    setText(DateRanges) 
                    setHeader('Setting Date Ranges')
                    setActiveMenu('DateRanges')}
                    }>
                    <a style={activemenu==='DateRanges'?activestyle:null}>Date Ranges</a></li>
                  <li onClick={()=>{ 
                    setText(FXRates) 
                    setHeader('FX Rates')
                    setActiveMenu('FXRates')}
                    }>
                    <a style={activemenu==='FXRates'?activestyle:null}>FX Rates</a></li>
                </ul>}</li>
                </ul>
                <ul className="menu-list">
                  <li>
                    <a onClick={()=>{ 
                        setText(Company) 
                        setHeader('The Company Page')
                        setActiveMenu('Company')
                        setCompanyStatus(companystatus?false:true)}
                      }style={activemenu==='Company'?activestyle:null}><i className="far fa-plus-square mr-2"></i>Company Page</a>
                    {companystatus &&
                    <ul>
                      <li onClick={()=>{ 
                        setText(Values) 
                        setHeader('Values')
                        setActiveMenu('Values')}
                      }>
                      <a style={activemenu==='Values'?activestyle:null}>Values</a></li>
                      <li onClick={()=>{ 
                        setText(Timeline) 
                        setHeader('History Timeline')
                        setActiveMenu('Timeline')}
                      }>
                      <a style={activemenu==='Timeline'?activestyle:null}>Timeline</a></li>
                      <li onClick={()=>{ 
                        setText(Facts) 
                        setHeader('Facts')
                        setActiveMenu('Facts')}
                      }><a style={activemenu==='Facts'?activestyle:null}>Facts</a></li>
                    </ul>}
                  </li>


                  {/*Success Help Component */}
                  <li><a onClick={()=>{ 
                        setText(SuccessVisions) 
                        setHeader('Success Visions')
                        setActiveMenu('SuccessVisions')
                        setSuccessVisionStatus(successvisionstatus?false:true)}
                      }
                      style={activemenu==='SuccessVisions'?activestyle:null}><i className="far fa-plus-square mr-2"></i>Success Visions</a>
                    {successvisionstatus && 
                    <ul>
                        <li onClick={()=>{ 
                        setText(SuccessVisionTimeframe) 
                        setHeader('Setting the Timeline')
                        setActiveMenu('SuccessVisionTimeframe')}
                      }><a style={activemenu==='SuccessVisionTimeframe'?activestyle:null}>Setting the Timeline</a></li>
                        <li onClick={()=>{ 
                        setText(Linkages) 
                        setHeader('Creating Linkages')
                        setActiveMenu('Linkages')}
                        }><a style={activemenu==='Linkages'?activestyle:null}>Creating Linkages</a></li>
                        <li onClick={()=>{ 
                        setText(Baselines) 
                        setHeader('Roadmap Baselines')
                        setActiveMenu('Baselines')}
                        }><a style={activemenu==='Baselines'?activestyle:null}>Baselines</a></li>
                      </ul>}
                      </li>





                  {/* Roadmaps Help Component */}
                  <li><a onClick={()=>{ 
                    setText(Roadmaps) 
                    setHeader('Roadmaps')
                    setActiveMenu('Roadmaps')
                    setRoadmapStatus(roadmapstatus?false:true)
                    }}
                    style={activemenu==='Roadmaps'?activestyle:null}><i className="far fa-plus-square mr-2"></i>Roadmaps</a>
                    {roadmapstatus && 
                    <ul>
                        <li onClick={()=>{ 
                        setText(RoadmapSections) 
                        setHeader('Roadmap Sections')
                        setActiveMenu('RoadmapSections')}
                      }><a style={activemenu==='RoadmapSections'?activestyle:null}>Sections</a></li>
                        <li onClick={()=>{ 
                        setText(RoadmapRows) 
                        setHeader('Roadmap Rows')
                        setActiveMenu('RoadmapRows')}
                      }><a style={activemenu==='RoadmapRows'?activestyle:null}>Rows</a></li>
                        <li onClick={()=>{ 
                        setText(RoadmapGantt) 
                        setHeader('Gantt Charts')
                        setActiveMenu('GanttCharts')}
                      }><a style={activemenu==='GanttCharts'?activestyle:null}>Gantt Charts</a></li>
                        <li onClick={()=>{ 
                        setText(Formulas) 
                        setHeader('Formulas')
                        setActiveMenu('Formulas')}
                        }><a style={activemenu==='Formulas'?activestyle:null}>Formulas</a></li>
                        <li onClick={()=>{ 
                        setText(RoadmapCharts) 
                        setHeader('Charts')
                        setActiveMenu('Charts')}
                        }><a style={activemenu==='Charts'?activestyle:null}>Charts</a></li>
                      </ul>}
                      </li>

                  {/* Sprint Help Component */}
                      <li><a onClick={()=>{ 
                    setText(Sprints) 
                    setHeader('Sprints')
                    setActiveMenu('Sprints')
                    setSprintStatus(sprintstatus?false:true)
                    }}
                    style={activemenu==='Sprints'?activestyle:null}><i className="far fa-plus-square mr-2"></i>Sprints</a>
                    {sprintstatus && 
                    <ul>
                        <li onClick={()=>{ 
                        setText(SprintReview) 
                        setHeader('Sprint Reviews')
                        setActiveMenu('SprintReview')}
                      }><a style={activemenu==='SprintReview'?activestyle:null}>Sprint Review Date</a></li>
                      </ul>}</li>




                  {/*Innovation Help Component */}
                  <li><a onClick={()=>{ 
                    setText(Innovation) 
                    setHeader('Innovation')
                    setActiveMenu('Innovation')                        
                    setInnovationStatus(innovationstatus?false:true)
                    }}
                    style={activemenu==='Innovation'?activestyle:null}><i className="far fa-plus-square mr-2"></i>Innovation</a>
                    {innovationstatus && 
                    <ul>
                        <li onClick={()=>{ 
                        setText(InnovationThemes) 
                        setHeader('Innovation Themes')
                        setActiveMenu('InnovationThemes')}
                      }><a style={activemenu==='InnovationThemes'?activestyle:null}>Innovation Themes</a></li>
                        <li onClick={()=>{ 
                        setText(Ideas) 
                        setHeader('Ideation')
                        setActiveMenu('Ideation')}
                        }><a style={activemenu==='Ideation'?activestyle:null}>Ideation</a></li>
                        <li onClick={()=>{ 
                        setText(InnovationCharts)
                        setHeader('Charts')
                        setActiveMenu('InnovationCharts')}
                        }><a style={activemenu==='InnovationCharts'?activestyle:null}>Charts</a></li>
                      </ul>}</li>

                  
                  {/* People Help Component */}
                  <li><a onClick={()=>{ 
                    setText(People) 
                    setHeader('People')
                    setActiveMenu('People')
                    setPeopleStatus(peoplestatus?false:true)
                    }}
                    style={activemenu==='People'?activestyle:null}><i className="far fa-plus-square mr-2"></i>People</a>
                  </li>



                  {/* Stakeholder Help Component */}
                  <li><a onClick={()=>{ 
                    setText(Stakeholders) 
                    setHeader('Stakeholders')
                    setActiveMenu('Stakeholders')
                    }}
                    style={activemenu==='Stakeholders'?activestyle:null}><i className="far fa-plus-square mr-2"></i>Stakeholders</a></li>


                  {/* Investments Help Component */}                    
                  <li><a onClick={()=>{ 
                    setText(Investments) 
                    setHeader('Investments')
                    setActiveMenu('Investments')
                    }}
                    style={activemenu==='Investments'?activestyle:null}><i className="far fa-plus-square mr-2"></i>Investments</a></li>

                  {/* Reports Help Component */}
                  <li><a onClick={()=>{ 
                    setText(Reports) 
                    setHeader('Reports')
                    setActiveMenu('Reports')
                    setReportStatus(reportstatus?false:true)
                    }}
                    style={activemenu==='Reports'?activestyle:null}><i className="far fa-plus-square mr-2"></i>Reports</a>
                    {reportstatus &&
                    <ul>
                        <li onClick={()=>{ 
                        setText(ExportExcel) 
                        setHeader('Exporting to Excel')
                        setActiveMenu('ExportExcel')}
                      }><a style={activemenu==='ExportExcel'?activestyle:null}>Exporting to Excel</a></li>
                        <li onClick={()=>{ 
                        setText(ExportPPT) 
                        setHeader('Exporting to PowerPoint')
                        setActiveMenu('ExportPPT')}
                      }><a style={activemenu==='ExportPPY'?activestyle:null}>Exporting to PowerPoint</a></li>
                    </ul>}
                  </li>
                </ul>
              </aside>
            </div>
          </div>
          <div className="column">
            <div className="mt-3 ml-5 mb-1">
            <p className="mb-2" style={{fontSize:'20px', fontWeight:'500', color: backgroundcolor}}>{header}</p>
            {/* <div dangerouslySetInnerHTML={{__html: text}}/> */}
            {text}
          </div>
          </div>
        </div>

    )
  }

    return (
      <Fragment>


<div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
    <div className="modal-background" key="modalBackground">
        {" "}
    </div>
    <div className="modal-card" key="modalCard" style={{minWidth:"1200px", minHeight:'95%'}}>
        <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
            <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>STRAT2GY MANUAL</p>
            <button className="delete" aria-label="close" onClick={() => dispatch(closeHelpModal())}>
                {" "}
            </button>
        </header>
        <section className="modal-card-body" key="modalBody" style={{minHeight: '200px'}}>
          {renderHelp()}
        </section>
        <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"10px"}}>
        </footer>
    </div>
</div>

</Fragment>




    )
}

export { Help };
