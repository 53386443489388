import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllRoleview = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roleview`,
    authConfig()
  );
  return response.data.results;
};

export const fetchRoleview = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roleview/${id}`,
    authConfig()
  );
  return response.data;
};

export const createRoleview = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roleview`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateRoleview = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/roleview/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteRoleview = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/roleview/${id}`,
    authConfig()
  );
  return response.data;
};

const roleviewAPI = {
  fetchAllRoleview,
  fetchRoleview,
  createRoleview,
  updateRoleview,
  deleteRoleview,
};
export default roleviewAPI;
