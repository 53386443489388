import { React, Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Container } from "./CompanyFacts/Container";
import { fetchAllFacts } from "./../../../redux/toolkit/features/company/facts";
import { closeFactsModal, openFactsRecordModal } from "./../../../redux/toolkit/features/company_state";

function CompanyFactsModal() {
    const facts = Object.values(useSelector((state) => state.facts.entities)).sort((a, b) => a.order - b.order);
    const isFactsModalOpen = useSelector((state) => state.company_state.isFactsModalOpen)
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch(); 

    let modalClass = "modal";
    if (isFactsModalOpen) {
        modalClass += " is-active";
    }

    async function getFacts() {
        dispatch(fetchAllFacts());
    }

    useEffect(() => {
            getFacts();
      },[]);

    return (

        <Fragment>

            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>COMPANY FACTS</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeFactsModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: '200px'}}>
                        {facts.length !== 0 &&
                        <Container facts={facts}/>}
                        {facts.length === 0 &&
                        <p className = "pt-5" style={{fontSize:"16.5px"}}>No Records Returned</p>}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <button
                            style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}}
                            className="button is-small"
                            key="new"
                            onClick={async () => {
                                dispatch(openFactsRecordModal({factPK:null}));
                                dispatch(closeFactsModal());
                            }}
                        >
                            Add New
                        </button>
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeFactsModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>

        </Fragment>
    );
}

export default CompanyFactsModal;
