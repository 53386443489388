import api from "../../api/roadmaps/roadmap";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const roadmapAdapter = createEntityAdapter({
  selectId: (roadmap) => roadmap.pk,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = roadmapAdapter.getInitialState({status: "idle"});

export const fetchAllRoadmap = createAsyncThunk("roadmap/fetch", () =>
  api.fetchAllRoadmap()
);

export const updateRoadmap = createAsyncThunk("roadmap/update", api.updateRoadmap);

export const createRoadmap = createAsyncThunk("roadmap/create", api.createRoadmap);

export const deleteRoadmap = createAsyncThunk("roadmap/delete", api.deleteRoadmap);


const roadmapSlice = createSlice({
  name: "roadmap",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRoadmap.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRoadmap.fulfilled, (state, { payload }) => {
        roadmapAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateRoadmap.fulfilled, roadmapAdapter.updateOne)
      .addCase(deleteRoadmap.fulfilled, roadmapAdapter.removeOne)
      .addCase(createRoadmap.fulfilled, roadmapAdapter.addOne);
  },
});

export const { } = roadmapSlice.actions;

export default roadmapSlice.reducer;

const selectState = (state) => state.roadmap;

export const {
  selectAll: selectRoadmap,
  selectById: selectRoadmapById,
} = roadmapAdapter.getSelectors(selectState);

export const selectRoadmapStatus = createSelector(
  selectState,
  (state) => state.status
);

