import React, {Fragment, useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { selectChartById } from "../../../redux/toolkit/features/roadmapcharts";
import { openGraphsModal, closeGraphsEditModal } from "../../../redux/toolkit/features/roadmaps_state";
import {filter} from 'lodash'
import { createChartRows, fetchAllChartRows, deleteChartRows } from "../../../redux/toolkit/features/roadmapchartrows";
import { updateCharts, createCharts, fetchAllCharts } from "../../../redux/toolkit/features/roadmapcharts";

function SectionsModalEditCharts() {
    const tabs = ['Chart', 'Rows']
    const [activetab, setActivetab] = useState(tabs[0]);
    const chartpk = useSelector((state) => state.roadmaps_state.chartpk);
    const chartsectionpk = useSelector((state) => state.roadmaps_state.charteditsectionpk);
    const chartbyid = useSelector((state)=>selectChartById(state, chartpk));  
    const rows = filter((Object.values(useSelector((state) => state.roadmaprows.entities))).sort((a, b) => a.order - b.order),{section:chartsectionpk});
    const chartrows = filter((Object.values(useSelector((state) => state.roadmapchartrows.entities))),{chart_id:chartpk});
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
    const textcolor = useSelector((state) => state.colors.textcolor);
    const showSectionsChartEditModal = useSelector((state) => state.roadmaps_state.isGraphEditModalOpen);

    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')    
    const [yaxis, setYaxis] = useState('') 
    const [type, setType] = useState('')
    const [rowarray, setRowArray] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllChartRows)
        setTitle(chartbyid?chartbyid.chart_title:"");
        setSubtitle(chartbyid?chartbyid.chart_subtitle:"")
        setYaxis(chartbyid?chartbyid.chart_yaxislabel:"")
        setType(chartbyid?chartbyid.chart_type:"")
    },[chartpk]);

    useEffect(() => {
        setRowArray([])
        rows.forEach( (d, index)  => buildArray(d.name, d.pk, d.section, index))
    },[chartsectionpk]);

   
    let modalClass = "modal";
    if (showSectionsChartEditModal) {
        modalClass += " is-active";
    }

    function saveChartRows() {
        //console.log("Row Array",rowarray)
        rowarray.forEach(row => {
            if (row.changed) {
                console.log("Row Array","RowID: ",row.row, "SectionID", chartsectionpk, "ChartPK", chartpk)
                if(row.state && !row.checked) {
                    dispatch(deleteChartRows(row.pk));
                }
                if(!row.state && row.checked) {
                    dispatch(createChartRows({row_id:row.row ,section_id:chartsectionpk ,chart:chartpk, series_id: 1}));
                }
            }
        })
    }

    function updateCheckbox(checkbox, index) {
        if(rowarray[index].checked !== checkbox) { 
            setRowArray(rowarray.map(rowdata => {
                console.log(rowdata)
                return (
                rowdata.id===index 
                ? {...rowdata , row:rowdata.row, state: rowdata.state, checked: checkbox, changed: true, name: rowdata.name, section: rowdata.section, pk:rowdata.pk}
                : rowdata
                )
            }));
        }
    }

    function buildArray(name, pk, section, index) {
        let rowdata = filter(chartrows, {row_id:pk})
        if (rowdata.length !==0) {
            setRowArray(rowarray => [...rowarray, {id:index, row:pk, state: true, checked: true, changed: false, name: name, section: section, pk:rowdata[0].pk }])
        } else {
            setRowArray(rowarray => [...rowarray, {id: index, row:pk, state: false, checked: false, changed: false, name: name, section: section, pk:null }])
        }
    }

    function formtabs() {
        return (
            <Fragment>
            <div className='tabs mt-0 pt-0 pb-0 mb-0' style={{backgroundColor:"rgb(238, 238, 238)"}}>
                <div className="pl-1">
                <ul>
                    {tabs.map(tab => (
                        <li className ={activetab === tab ? 'is-active' : ''}
                            key={'tab_'+tab}
                            onClick={() => setActivetab(tab)}
                        >
                        <a>{tab}</a>
                        </li>
                    ))}
                </ul>
            </div>
            </div>
            <hr style={{borderTop:"1px solid lightgrey"}}className="mt-0 pt-0"/>
            </Fragment>
        )
    }

    function maprows() {
        return (
            <Fragment>
                {rowarray.map((row, index) => (
                    renderrows(row, index)
                    ))}
            </Fragment>
        )
    }

    function renderrows(row, index) {
        return(
            <Fragment key={"Fragment_"+index}>
                <div className="ml-1">
                    <label class="checkbox">
                    <input type="checkbox" checked={row.checked} onChange={e => updateCheckbox(e.target.checked,index)} className="mr-4" />
                    <span style={{fontSize: "1.08em"}}>{row.name}</span>
                    </label>
                    <br/>
                </div>
            </Fragment>
        )
    }


    function modalform() {
        return (
            <Fragment>
                        <p className="pb-1 pl-1" style={{fontSize:"small"}}>Chart Title</p>
                        <input className="input" type="text" value={title} onChange={e => setTitle(e.target.value)} />

                        <p className="pb-1 pl-1 pt-3" style={{fontSize:"small"}}>Chart Subtitle</p>
                        <input className="input" type="text" value={subtitle} onChange={e => setSubtitle(e.target.value)} />

                        <p className="pb-1 pl-1 pt-3" style={{fontSize:"small"}}>Chart Y-Axis Label</p>
                        <input className="input" type="text" value={yaxis} onChange={e => setYaxis(e.target.value)} />

                        <p className="pb-1 pl-1 pt-3" style={{fontSize:"small"}}>Chart Type</p>
                        <div className="field is-horizontal is-align-items-center">
                            <div className="field-body is-flex">
                                <div className="control is-flex-grow-1">
                                    <div className="select is-flex">
                                        <select className="select is-fullwidth" defaultValue={'Line'} value={type} onChange={e => setType(e.target.value)}>
                                            <option selected value={'Line'}>Line Chart</option>    
                                            <option value={'Combo'}>Combo Chart</option>
                                            {/* <option value={'Stacked'}>Stacked Chart</option> */}
                                            <option value={'Waterfall'}>Waterfall Chart</option>                                
                                        </select>    
                                    </div>
                                </div>
                            </div>
                        </div>              
 
        </Fragment>
        )
    }


    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                    <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>CHART SETUP</p>
                    <button className="delete" aria-label="close" onClick={() => {
                        dispatch(closeGraphsEditModal())
                        dispatch(openGraphsModal({chartsectionpk:chartsectionpk}));
                    }}>
                        {" "}
                    </button>
                </header>
                <section className="modal-card-body pt-0 pl-0 pr-0" key="modalBody" style={{minHeight: "450px"}}>
                  {formtabs()}
                  {activetab==="Chart" && <div className="pl-4 pr-4 mt-0">{modalform()}</div>}
                  {activetab==="Rows" && <div className="pl-4 pr-4 mt-0">{maprows()}</div>}
                  
                </section>
                <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    {(chartpk===null) &&
                        <Fragment>
                            <button
                                style={{borderRadius:"4px"}}
                                className="button is-success is-small"
                                key="save"
                                onClick={async () => {
                                    saveChartRows();
                                    await dispatch(createCharts({chart_title: title , chart_subtitle: subtitle, chart_yaxislabel: yaxis , chart_type: type, section:chartsectionpk }))
                                    .unwrap()
                                        dispatch(fetchAllCharts());
                                        dispatch(closeGraphsEditModal());
                                        dispatch(openGraphsModal({chartsectionpk:chartsectionpk}));
                                }}
                            >
                                Save changes
                            </button>
                        </Fragment>
                    }

                    {(chartpk!==null) &&
                        <Fragment>
                            <button
                                style={{borderRadius:"4px"}}
                                className="button is-success is-small"
                                key="save"
                                onClick={async () => {
                                    saveChartRows();
                                    await dispatch(updateCharts({id: chartpk,chart_title: title , chart_subtitle: subtitle, chart_yaxislabel: yaxis , chart_type: type, section:chartsectionpk }))
                                    .unwrap()
                                        dispatch(fetchAllCharts());
                                        dispatch(closeGraphsEditModal());
                                        dispatch(openGraphsModal({chartsectionpk:chartsectionpk}));
                                }}
                            >
                                Save changes
                            </button>
                        </Fragment>
                    }

                    <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => {
                        dispatch(closeGraphsEditModal())
                        dispatch(openGraphsModal({chartsectionpk:chartsectionpk}));
                    }}>
                        Cancel
                    </button>

                </footer>
            </div>
        </div>

    </Fragment>
    );
}

export {SectionsModalEditCharts};
