import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllSprintlinks = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/linkedsprints`,
    authConfig()
  );
  return response.data.results;
};

export const fetchSprintlinks = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/linkedsprints/${id}`,
    authConfig()
  );
  return response.data;
};

export const createSprintlinks = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/linkedsprints`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateSprintlinks = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/linkedsprints/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteSprintlinks = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/linkedsprints/${id}`,
    authConfig()
  );
  return response.data;
};

const sprintlinksAPI = {
  fetchAllSprintlinks,
  fetchSprintlinks,
  createSprintlinks,
  updateSprintlinks,
  deleteSprintlinks,
};
export default sprintlinksAPI;
