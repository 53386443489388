import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllCells = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-cellvalues`,
    authConfig()
  );
  return response.data.results;
};

export const fetchCells = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-cellvalues/${id}`,
    authConfig()
  );
  return response.data;
};

export const createCells = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roadmap-cellvalues`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateCells = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/roadmap-cellvalues/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteCells = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/roadmap-cellvalues/${id}`,
    authConfig()
  );
  return response.data;
};

const roadmapcellsAPI = {
  fetchAllCells,
  fetchCells,
  createCells,
  updateCells,
  deleteCells,
};
export default roadmapcellsAPI;
