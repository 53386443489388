import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTenants, updateTenants } from "../../redux/toolkit/features/tenants";
import { fetchAllFX, deleteFX } from "../../redux/toolkit/features/tenants/fx";
import { fetchAllFXRow, updateFXRow } from "../../redux/toolkit/features/tenants/fxrow";
import {filter} from "lodash"
import { FXFormDetailCell } from "./FXFormDetailCell"
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';


function FXFormDetail(props) {
  const {f} = props;
  const tenant = Object.values(useSelector((state) =>  state.tenants.entities))[0];
  const fxrow = Object.values(useSelector((state) =>  state.fxrow.entities));
  const [editmode, setEditMode] = useState(false);
  const [fxrate, setFXRate] = useState(null)
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(fetchAllFX());
    dispatch(fetchAllFXRow());
    dispatch(fetchAllTenants());
  }, []);


  if (!tenant) {
    return null;
  }

  async function DeleteFXRow(PK) {
    await dispatch(deleteFX(PK))
    .unwrap()
    dispatch(fetchAllFX())
  }

  async function UpdateFXRates(e) {
    if (e.keyCode === 13){
      await dispatch(updateFXRow({id:tenant.pk,basecurrency:e.target.value.toUpperCase()}))
      .unwrap()
      dispatch(fetchAllTenants())
    }
  }


  function TableRowsColumns(PK) {
    let fxcolumns=[];
    for (let year = new Date().getFullYear(); year <= 2028; year++) {

        let fxarray=filter(fxrow,{pair:PK, year:year})
        let fxvalue=fxarray[0]?fxarray[0].rate:'-'
        let cellPK=fxarray[0]?fxarray[0].pk:null
        console.log("Detail:",fxvalue, PK, cellPK, year)
        if(!editmode) {
          fxcolumns.push(<td className="has-text-centered">{fxvalue}</td>)    
        } else {
        fxcolumns.push(
          <FXFormDetailCell rowPK={f.pk} cellPK={cellPK} editmode={editmode} fxvalue={fxvalue} year={year} pair={f.pair}/>
        ) 
        } 
    }

    return (
      <Fragment>
        {fxcolumns}
      </Fragment>
    )
  }



  return (
    <Fragment>
      <tr className='ml-0 pl-0'>
        <td className='ml-0 pl-0'>{f.pair}</td>
        {TableRowsColumns(f.pk)}
        {!editmode && <td className="pl-0 pr-0" style={{width:'12px'}} onClick={()=>setEditMode(true)}><i className="far fa-edit mt-1" style={{color:"gray"}}/></td>}
        {editmode && <td className="pl-0 pr-0" style={{width:'12px'}} onClick={()=>setEditMode(false)}><i className="far fa-edit mt-1" style={{color:backgroundcolor}}/></td>}
        <td onClick={()=> DeleteFXRow(f.pk) }><i className="far fa-trash-alt mt-1" style={{color:"gray"}} /></td>
      </tr>
      </Fragment>

      
  );

}

export { FXFormDetail };
