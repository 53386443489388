import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllTimelines, selectTimelineById, createTimelines, updateTimelines } from "./../../../redux/toolkit/features/company/timelines";
import { closeTimelineRecordModal, openTimelineModal } from "../../../redux/toolkit/features/company_state";

function CompanyTimelineRecordModal() {
    const timelinePK = useSelector((state) => state.company_state.timelinePK);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
    const textcolor = useSelector((state) => state.colors.textcolor);
    const timelinebyid = useSelector((state)=>selectTimelineById(state, timelinePK))  ;
    const isTimelineRecordModalOpen = useSelector((state) => state.company_state.isTimelineRecordModalOpen);
    const dispatch = useDispatch();

    const currentyear = (new Date()).getFullYear();
    const [event, setEvent] = useState("");
    const [year, setYear] = useState("");

    const years = Array.from(new Array(100),(val, index) => currentyear - index);

    useEffect(() => {
        if (timelinebyid) {
        setEvent(timelinebyid.event)
        setYear(timelinebyid.year)
        } else {
        setEvent("")
        setYear(currentyear)
        }
      }, [timelinebyid])

    var modaltitle='';
    var placeholdertext='';
    if (timelinePK===null) {
        modaltitle="ADD COMPANY MILESTONE"
        placeholdertext="New Milestone"
    } else {
        modaltitle="COMPANY MILESTONE"
    }

     let modalClass = "modal";
    if (isTimelineRecordModalOpen) {
        modalClass += " is-active";
    }
   
    if (!timelinebyid && timelinePK!==null) {
        return null;
    }
 
    function renderForm() {
        return (
            <Fragment>
                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Year</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <div className="select is-flex">
                                <select
                                    className="select is-fullwidth"
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                >
                                    {years &&
                                    years.map((p, index) => (
                                        <option key={"YearList_"+index} value={p}>
                                            {p}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                    </div>
                </div>     
                     

                <p className="mb-2 pl-1" style={{fontSize:"smaller", fontWeight:"600"}}>Milestone</p>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <textarea 
                                className="textarea"
                                rows="6"
                                placeholder={placeholdertext}
                                value={event}
                                onChange={(e) => setEvent(e.target.value)}
                                />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{modaltitle}</p>
                        <button className="delete" aria-label="close" onClick={() => {
                                dispatch(closeTimelineRecordModal());
                                dispatch(openTimelineModal());
                        }}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    {timelinePK !== null &&
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(updateTimelines({id: timelinePK, year:year, event:event}))
                                .unwrap()
                                    dispatch(fetchAllTimelines());
                                    setEvent("");
                                    setYear((new Date()).getFullYear());
                                    dispatch(closeTimelineRecordModal());
                                    dispatch(openTimelineModal());
                            }}>
                            Save Changes
                        </button>
                        </Fragment>
                        }
                    {timelinePK === null &&
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(createTimelines({year:year, event:event}))
                                .unwrap()
                                    dispatch(fetchAllTimelines());
                                    setEvent("");
                                    setYear((new Date()).getFullYear());
                                    dispatch(closeTimelineRecordModal());
                                    dispatch(openTimelineModal());
                            }}>
                            Save Changes
                        </button>
                        </Fragment>
                        }
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => {
                                dispatch(closeTimelineRecordModal());
                                dispatch(openTimelineModal());
                                }}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default CompanyTimelineRecordModal;
