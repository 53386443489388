import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";
import noLogo from "../../../../components/common/images/noLogo.png";

export const fetchAllUsers = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/users`,
    authConfig()
  );
  return response.data.results;
};

export const fetchUsers = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/users/${id}`,
    authConfig()
  );
  return response.data;
};

export const createUsers = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/users`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateUsers = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/users/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteUsers = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/users/${id}`,
    authConfig()
  );
  return response.data;
};

export const uploadLogo = async (data) => {
  const url = `${apiUtils.getBaseURL()}/users/` + (data.id) + "/upload-logo";
  const formData = new FormData();
  formData.append("file", data.file);
  const config = authConfig();
  config.headers["content-type"] = "multipart/form-data";
  const response = await axios.put(url, formData, config);
  return response.data;
};

export const logoUrl = (tenant) => {
  if (tenant.image) {
    return tenant.image.startsWith("http")
        ? tenant.image
        :  `${apiUtils.getBaseURL()}` + tenant.image;
  }
  return noLogo;
}

const usersAPI = {
  fetchAllUsers,
  fetchUsers,
  createUsers,
  updateUsers,
  deleteUsers,
  uploadLogo,
  logoUrl
};
export default usersAPI;
