import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllCompensationindex = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/compensationindex`,
    authConfig()
  );
  return response.data.results;
};

export const fetchCompensationindex = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/compensationindex/${id}`,
    authConfig()
  );
  return response.data;
};

export const createCompensationindex = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/compensationindex`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateCompensationindex = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/compensationindex/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteCompensationindex = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/compensationindex/${id}`,
    authConfig()
  );
  return response.data;
};

const compensationindexAPI = {
  fetchAllCompensationindex,
  fetchCompensationindex,
  createCompensationindex,
  updateCompensationindex,
  deleteCompensationindex,
};
export default compensationindexAPI;
