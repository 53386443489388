import React, { useState, useEffect, Fragment } from "react";
import "../common/css/masterstyles.css";
import { useDispatch, useSelector } from "react-redux";
import { tenantsActions } from "../../redux/actions/tenants";
import { tenantsService } from "../../services/TenantsService";
import BaseService from "../../services/BaseService";
import { fetchAllTenants, updateTenants, uploadLogo,  } from "../../redux/toolkit/features/tenants";
import ColorModal from "./modals/ColorModal"

function TenantForm() {
  const tenant = Object.values(useSelector((state) =>  state.tenants.entities))[0];
  const [emaildomain, setEmaildomain] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [background, setBackground] = useState("");
  const [text, setText] = useState("");
  const tenantError = useSelector((state) => state.tenants.error);
  const [nameError, setNameError] = useState("");
  const [saved, setSaved] = useState(false);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false)
  const [colortype, setColortype] = useState(background)

  useEffect(() => {
    setEmaildomain(tenant?tenant.emaildomain:"")
    setCompanyname(tenant?tenant.name:"")
    setBackground(tenant?tenant.backgroundcolor:"")
    setText(tenant?tenant.textcolor:"")
  }, [tenant]);

  useEffect(() => {
  }, [background, text]);

  async function onSave() {
    if (companyname.trim() === "") {
      setNameError("Name is mandatory");
      return;
    }
    await dispatch(updateTenants({id:tenant.pk, name: companyname, emaildomain: emaildomain, textcolor: text, backgroundcolor: background}))
      .unwrap()
    dispatch(fetchAllTenants({id:tenant.pk, name: companyname, emaildomain: emaildomain, textcolor: text, backgroundcolor: background}))
    setSaved(true);
  };

  // async function onLogoFileSelected(event) {
  //   const files = event.target.files;
  //   const result = await tenantsService.uploadLogo(tenant.pk, files[0]);
  //   dispatch(tenantsActions.getCurrentTenant());
  

  async function onLogoFileSelected(event) {
    const files = event.target.files[0];
    await dispatch(uploadLogo({id:tenant.pk, file:files}))
    .unwrap()
    dispatch(fetchAllTenants());
  }

  
  if (!tenant) {
    return null;
  }

  return (
    <Fragment>
    <>
      {tenantError && (
        <div className="notification is-danger">{tenantError}</div>
      )}

      {saved && !tenantError && (
        <div className="notification is-success">
          <button className="delete" onClick={() => setSaved(false)}>
            {" "}
          </button>
          Data saved
        </div>
      )}

        <p className="mb-2" style={{fontSize:"17px", fontWeight:"600"}}>Company Name</p>
        <div className="field is-grouped">
          <div className="control is-expanded">
            <input 
              name={"name"}
              className="input"
              type="text"
              value={companyname}
              onChange={(e)=> setCompanyname(e.target.value)}
            />
          </div>
          {nameError !== undefined && 
          <p className="help is-danger">{nameError}</p>}
        </div>

        <p className="mb-2" style={{fontSize:"17px", fontWeight:"600"}}>Email Domain</p>
        <div className="field">
          <div className="field has-addons">
            <p className="control">
              <a className="button is-static">
                @
              </a>
            </p>
            <div className="control is-expanded has-icons-left has-icons-right">
            <input
              className="input pl-2"
              type="email"
              value={emaildomain}
              onChange={(e) => setEmaildomain(e.target.value)}
            />
            </div>
        </div>
        </div>





      {/* <label className="label">User Authentication Method</label>
      <div className="field is-grouped">
        <div className="control is-expanded">
          <div className="select is-fullwidth">
            <select name="Authenication">
            <option value="Application">Application</option>
            <option value="Google">Google</option>
            <option value="Microsoft">Microsoft</option>
            </select>
          </div>
        </div>
        <div className="control">
            <button className="button is-link">
              Save
            </button>
        </div>
    </div> */}




<p className="mb-3 mt-2" style={{fontSize:"17px", fontWeight:"600"}}>Company Logo</p>
    <div className="field">
          <div className="field has-addons">
         
            <div className="control">
            <div className="file has-name">
                                <label className="file-label">
                                    <input 
                                    className="file-input"
                                    type="file"
                                    name="logo"
                                    accept="image/gif, image/jpeg, image/png"
                                    onChange={onLogoFileSelected}
                                   />
                                        <span className="file-cta" style={{backgroundColor:'transparent'}}>
                                            <span className="file-icon">
                                                <i className="fas fa-upload"></i>
                                            </span>
                                            <span className="file-label">
                                                Upload                                            </span>
                                        </span>
                                </label>
                            </div>
                            </div>
            <div className="control pl-2 pr-1 is-flex">
        {tenant && (
          <img
            style={{marginTop: "auto", marginBottom: "auto"}}
            className="menuimage"
            alt="tenant logo"
            src={
              tenant.image.startsWith("http")
                ? tenant.image
                : BaseService.baseServer + tenant.image
            }
          />
        )}
        </div>
        </div>
        </div>



        <p className="mb-3 mt-2" style={{fontSize:"17px", fontWeight:"600"}}>Company Branding</p>
        
        <div className="field">
          <div className="field has-addons">
           <div className="control" >
              <button className="button" style={{backgroundColor:'transparent', width:200, justifyContent:'left'}} 
              onClick={()=> {
                setShowModal(true)
                setColortype('background')
                }}>
                  <span className="file-icon" ><i className="fas fa-palette"></i></span>
                  <span className="file-label" >Background Color</span>
              </button>
           </div>
           <div className="control">
            <button className="button" style={{backgroundColor: background}} 
              onClick={()=> {
                setShowModal(true)
                setColortype('background')
                }}/>
           </div>
           <input className="input" style={{width:'140px'}} value={background} onChange={(e) => setBackground(e.target.value)}/>
          </div>
        </div>

        <div className="field">
          <div className="field has-addons" >
           <div className="control"  >
              <button className="button " style={{backgroundColor:'transparent', width:200, justifyContent:'left'}} 
              onClick={()=> {
                setShowModal(true)
                setColortype('text')
                }}>
                  <span className="file-icon"><i className="fas fa-palette"></i></span>
                  <span className="file-label" >Text Color</span>
              </button>
           </div>
           <div className="control">
            <button className="button" style={{backgroundColor: text}} 
              onClick={()=> {
                setShowModal(true)
                setColortype('text')
                }}/>
           </div>
           <input className="input" style={{width:'140px'}} value={text} onChange={(e) => setText(e.target.value)}/>
          </div>
        </div>


        <div className="field is-grouped">
          <div className="control">
            <button className="button is-success"onClick={onSave}>
              Save
            </button>
          </div>
        </div>
    </>
      <ColorModal showModal={showModal} background={background} setBackground={setBackground} setText={setText} setShowModal={setShowModal} text={text} colortype={colortype}/>
      </Fragment>
  );

}

export { TenantForm };
