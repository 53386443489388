import api from "../api/roadmapcagr";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const roadmapcagrAdapter = createEntityAdapter({
  selectId: (cagr) => cagr.pk,
  //sortComparer: (a, b) => a.section.localeCompare(b.section),
});

const initialState = roadmapcagrAdapter.getInitialState({
  status: "idle"
});

export const fetchAllCagr = createAsyncThunk("cagr/fetch", () =>
  api.fetchAllCagr()
);

export const updateCagr = createAsyncThunk("cagr/update", api.updateCagr);

export const createCagr = createAsyncThunk("cagr/create", api.createCagr);

export const deleteCagr = createAsyncThunk("cagr/delete", api.deleteCagr);

const roadmapcagrSlice = createSlice({
  name: "roadmapcagr",
  initialState,
  reducers: {
   },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCagr.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCagr.fulfilled, (state, { payload }) => {
        roadmapcagrAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateCagr.fulfilled, roadmapcagrAdapter.updateOne)
      .addCase(deleteCagr.fulfilled, roadmapcagrAdapter.removeOne)
      .addCase(createCagr.fulfilled, roadmapcagrAdapter.addOne);
  },
});

export const { } = roadmapcagrSlice.actions;

export default roadmapcagrSlice.reducer;

const selectState = (state) => state.roadmapcagr;

export const {
  selectAll: selectroadmapcagr,
  selectById: selectRowById,
} = roadmapcagrAdapter.getSelectors(selectState);

export const selectCagrStatus = createSelector(
  selectState,
  (state) => state.status
);
