import api from "../../api/tenants/users";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const usersAdapter = createEntityAdapter({
  selectId: (user) => user.id,
  //sortComparer: (a, b) => a.fact.localeCompare(b.fact),
});

const initialState = usersAdapter.getInitialState({status: "idle"});

export const fetchAllUsers = createAsyncThunk("users/fetch", () => api.fetchAllUsers());

export const updateUsers = createAsyncThunk("users/update", api.updateUsers);

export const createUsers = createAsyncThunk("users/create", api.createUsers);

export const deleteUsers = createAsyncThunk("users/delete", api.deleteUsers);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
        usersAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateUsers.fulfilled, usersAdapter.updateOne)
      .addCase(deleteUsers.fulfilled, usersAdapter.removeOne)
      .addCase(createUsers.fulfilled, usersAdapter.addOne);
  },
});

export const {} = usersSlice.actions;

export default usersSlice.reducer;

const selectState = (state) => state.users;

export const {
  selectAll: selectUsers,
  selectById: selectUserById,
} = usersAdapter.getSelectors(selectState);

export const selectUsersStatus = createSelector(
  selectState,
  (state) => state.status
);


