import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {fetchAllIdeas, updateIdeas,createIdeas, selectIdeaById} from "./../../../redux/toolkit/features/innovation/ideas";
import {closeIdeasModal} from "./../../../redux/toolkit/features/innovation_state";

function IdeasModal() {
  const isIdeasModalOpen = useSelector((state) => state.innovation_state.isIdeasModalOpen)  
  const ideaPK = useSelector((state) => state.innovation_state.ideaPK)
  const themePK = useSelector((state) => state.innovation_state.themeModalPK)
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const ideabyid = useSelector((state)=>selectIdeaById(state, ideaPK))  
  const [title, setTitle] = useState('')
  const [ideation, setIdeation] = useState('')
  const [impact, setImpact] = useState(0)
  const [cost, setCost] = useState(0)
  const [modaltitle, setModalTitle] = useState('')
  const dispatch = useDispatch(); 

  useEffect(() => {
    if (ideabyid) {
      setTitle(ideabyid.title);
      setIdeation(ideabyid.comment);
      setImpact(ideabyid.impact);
      setCost(ideabyid.cost);    
      setModalTitle("EDIT IDEA");
    } else {
      setTitle("");
      setIdeation("");
      setImpact(0);
      setCost(0);   
      setModalTitle("ADD NEW IDEA" );
    }
  }, [ideabyid])

  let modalClass = "modal";
  if (isIdeasModalOpen) {
    modalClass += " is-active";
  }

  if (themePK===null) {
    return null;
}

  function renderContent() {
    return (
      <Fragment>
        <p className="pb-1 pl-1" style={{fontSize:"12px", fontWeight: "500"}}>Idea Title</p>
        <input
            className="input"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
        />

        <p className="pb-1 pl-1 pt-2" style={{fontSize:"12px", fontWeight: "500"}}>Idea Description</p>
        <input
            className="input"
            type="text"
            value={ideation}
            onChange={(e) => setIdeation(e.target.value)}
        />

      <p className="pb-1 pl-1 pt-2" style={{fontSize:"12px", fontWeight: "500"}}>Potential Impact</p>
        <input
            className="input"
            type="number"
            value={impact}
            onChange={(e) => setImpact(e.target.value)}
        />

      <p className="pb-1 pl-1 pt-2" style={{fontSize:"12px", fontWeight: "500"}}>Cost Estimate</p>
        <input
            className="input"
            type="number"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
        />
      </Fragment>
    );
  }

  if (!themePK) {
    return null;
  }

  return (
    <Fragment>
      <div className={modalClass} key="categoryModal">
        <div className="modal-background" key="modalBackground">
          {" "}
        </div>
        <div className="modal-card" key="modalCard">
          <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
            <p className="modal-card-title" style={{fontSize:"16px", color: textcolor}}>{modaltitle}</p>
            <button className="delete" aria-label="close" onClick={() => dispatch(closeIdeasModal())}>
              {" "}
            </button>
          </header>
          <section className="modal-card-body" key="modalBody">
            {renderContent()}
          </section>
          <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
            {ideaPK === null &&
              <Fragment>
                <button
                    style={{borderRadius:"4px"}}
                    className="button is-success is-small"
                    key="save"
                    onClick={async () => {
                      dispatch(createIdeas({title: title, comment: ideation, impact: impact, cost: cost, theme: themePK}))
                      .unwrap()
                      .then(() => {
                          dispatch(fetchAllIdeas);
                          dispatch(closeIdeasModal());
                      })
                  }}
                >
                    Save Changes
                </button>
              </Fragment>}

            {ideaPK !== null &&
              <Fragment>
              <button
                  style={{borderRadius:"4px"}}
                  className="button is-success is-small"
                  key="save"
                  onClick={async () => {
                    dispatch(updateIdeas({id:ideaPK, title: title, comment: ideation, impact: impact, cost: cost, theme: themePK}))
                    .unwrap()
                    .then(() => {
                        dispatch(fetchAllIdeas);
                        dispatch(closeIdeasModal());
                    })
                }}
              >
                  Save Changes
              </button>
              </Fragment>}

            <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeIdeasModal())}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default IdeasModal;
