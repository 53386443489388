import api from "../api/sprintcycle";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const sprintcycleAdapter = createEntityAdapter({
  selectId: (sprintcycle) => sprintcycle.pk,
  sortComparer: (a, b) => a.sprintcycle.localeCompare(b.sprintcycle)
});

const initialState = sprintcycleAdapter.getInitialState({
  status: "idle", isOpen: false
});

export const fetchAllSprintCycle = createAsyncThunk("sprintcycle/fetch", () =>
  api.fetchAllSprintCycle()
);

export const updateSprintCycle = createAsyncThunk("sprintcycle/update", api.updateSprintCycle);

export const createSprintCycle = createAsyncThunk("sprintcycle/create", api.createSprintCycle);

export const deleteSprintCycle = createAsyncThunk("sprintcycle/delete", api.deleteSprintCycle);


const sprintcycleSlice = createSlice({
  name: "sprintcycle",
  initialState,
  reducers: {
    openSprintDate(state) {
      state.isOpen = true;
    },
    closeSprintDate(state) {
      state.isOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSprintCycle.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllSprintCycle.fulfilled, (state, { payload }) => {
        sprintcycleAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateSprintCycle.fulfilled, sprintcycleAdapter.updateOne)
      .addCase(deleteSprintCycle.fulfilled, sprintcycleAdapter.removeOne)
      .addCase(createSprintCycle.fulfilled, sprintcycleAdapter.addOne);
  },
});

export const { openSprintDate, closeSprintDate} = sprintcycleSlice.actions;

export default sprintcycleSlice.reducer;

const selectState = (state) => state.sprintcycle;

export const {
  selectAll: selectSprintCycle,
  selectById: selectSprintCycleById,
} = sprintcycleAdapter.getSelectors(selectState);

export const selectSprintCycleStatus = createSelector(
  selectState,
  (state) => state.status
);

