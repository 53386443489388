import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllBusinessunit = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/businessunit`,
    authConfig()
  );
  return response.data;
};

export const fetchBusinessunit = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/businessunit/${id}`,
    authConfig()
  );
  return response.data;
};

export const createBusinessunit = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/businessunit`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateBusinessunit = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/businessunit/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteBusinessunit = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/businessunit/${id}`,
    authConfig()
  );
  return response.data;
};

const businessunitAPI = {
  fetchAllBusinessunit,
  fetchBusinessunit,
  createBusinessunit,
  updateBusinessunit,
  deleteBusinessunit,
};
export default businessunitAPI;
