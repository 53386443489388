import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllSections = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-sections`,
    authConfig()
  );
  return response.data.results;
};

export const fetchSections = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-sections/${id}`,
    authConfig()
  );
  return response.data;
};

export const createSections = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roadmap-sections`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateSections = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/roadmap-sections/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteSections = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/roadmap-sections/${id}`,
    authConfig()
  );
  return response.data;
};

export const orderSections = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roadmap-sections/sort-sections`,
    data,
    authConfig()
  );
  return response.data;
};

const roadmapsectionAPI = {
  fetchAllSections,
  fetchSections,
  createSections,
  updateSections,
  deleteSections,
  orderSections
};
export default roadmapsectionAPI;
