import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllSuccessyear = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/successyear`,
    authConfig()
  );
  return response.data.results;
};

export const fetchSuccessyear = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/successyear/${id}`,
    authConfig()
  );
  return response.data;
};

export const createSuccessyear = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/successyear`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateSuccessyear = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/successyear/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteSuccessyear = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/successyear/${id}`,
    authConfig()
  );
  return response.data;
};

const successyearAPI = {
  fetchAllSuccessyear,
  fetchSuccessyear,
  createSuccessyear,
  updateSuccessyear,
  deleteSuccessyear,
};
export default successyearAPI;
