import React, { Fragment, useState, memo } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { setCategory, openRoadmapSectionsModal } from "./../../redux/toolkit/features/roadmaps_state";

const Header = memo(
function RenderHeader() {
    const sections = Object.values(useSelector((state) => state.sections.entities)).sort((a, b) => (a.category.localeCompare(b.category)));
    const isOpen = useSelector((state) => state.sections.isOpen);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const [selectedTab, setSelectedTab] = useState("All");
    const dispatch = useDispatch();

    const categories = [...new Set(sections.flatMap(({category}) => category))]; 

    return (
        <Fragment>
          <div className="ml-3" style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "32px",lineHeight: "26px",paddingBottom: "28px"}}>
            <div className="pr-1">
              ROADMAPS <button className="button is-small is-pulled-right" style={{borderColor: backgroundcolor, color: backgroundcolor, borderRadius: "5px"}} onClick={() => dispatch(openRoadmapSectionsModal({sectionpk:null}))}>Edit Roadmaps</button>
            </div>


            {sections.length !== 0 &&
            <Fragment key={"Category_Header"}>
            <div className="tabs ml-0 pl-0" >
              <ul className="pl-0 ml-0" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}>
                <li 
                  key="Categories_All" 
                  className={selectedTab === "All" ? "is-active" : ""}
                  onClick={() => {
                    setSelectedTab("All")
                    dispatch(setCategory({category:null}))
                  }  }
                  >           
                  <a className="ml-0 pl-0">ALL</a>
                </li>
                {categories && categories.map((categories, index) => (
                  <Fragment key={"Category_Header_"+index}>
                    |
                <li
                key={"Categories_"+index} style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                className={selectedTab === categories ? "is-active" : ""}
                onClick={() => {
                  setSelectedTab(categories)
                  dispatch(setCategory({category:categories}))
                }}
                >       
                  <a>{categories.toUpperCase()}</a>
                </li>
                </Fragment>
            ))}
              </ul>
            </div>
            </Fragment>}
          </div>

          {sections.length === 0 &&
          <p className="pt-6" style={{fontSize: "16.5px"}}>No Roadmaps Returned</p>}
        </Fragment>
    )
})

export { Header };
