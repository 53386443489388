import api from "../../api/roadmaps/formula";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const formulaAdapter = createEntityAdapter({
  selectId: (formula) => formula.row,
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const initialState = formulaAdapter.getInitialState({status: "idle"});

export const fetchAllFormula = createAsyncThunk("formula/fetch", () =>
  api.fetchAllFormula()
);

export const updateFormula = createAsyncThunk("formula/update", api.updateFormula);

export const createFormula = createAsyncThunk("formula/create", api.createFormula);

export const deleteFormula = createAsyncThunk("formula/delete", api.deleteFormula);


const formulaSlice = createSlice({
  name: "formula",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFormula.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllFormula.fulfilled, (state, { payload }) => {
        formulaAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateFormula.fulfilled, formulaAdapter.updateOne)
      .addCase(deleteFormula.fulfilled, formulaAdapter.removeOne)
      .addCase(createFormula.fulfilled, formulaAdapter.addOne);
  },
});

export const { } = formulaSlice.actions;

export default formulaSlice.reducer;

const selectState = (state) => state.formula;

export const {
  selectAll: selectFormula,
  selectById: selectFormulaById,
} = formulaAdapter.getSelectors(selectState);

export const selectFormulaStatus = createSelector(
  selectState,
  (state) => state.status
);

