import React, {Fragment, useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {openCompetencyModal, fetchAllCompetencies} from "./../../redux/toolkit/features/competencies";
import { openDeleteModal, closeDeleteModal } from "./../../redux/toolkit/features/modals/deleteConfirmModal";


function Competencies() {
    const competencies = Object.values(useSelector((state) => state.competencies.entities));
    const showModalCompetencies = useSelector((state) => state.competencies.isOpen)
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);  
    const deleteModalStatus = useSelector((state) => state.deleteConfirmModal.isOpen);
    const [textfilter, setfilterquery] = useState('');
    const dispatch = useDispatch(); 
    var [filteredCompetencies] = [];

    async function getCompetencies() {
      dispatch(fetchAllCompetencies());
    }

    useEffect(() => {
      if (!showModalCompetencies) {
        getCompetencies()
      }
    },[showModalCompetencies]);

    useEffect(() => {
      if (!deleteModalStatus && recordtype==='competency') {
        getCompetencies()
        dispatch(closeDeleteModal())
      }
    },[deleteModalStatus]);


    function handleChangeFilter(event) {
      setfilterquery(event.target.value);
  }


    filteredCompetencies = competencies.filter((competency) => {
        if (competency.competency.toLowerCase().includes(textfilter.toLowerCase()) || competency.description.toLowerCase().includes(textfilter.toLowerCase())){
          return true;
        }
        return filteredCompetencies;
      })


    function competencylist(row){
       return (
        <tr>
        <td className="pl-0" style={{cursor:'pointer'}} onClick={() => dispatch(openCompetencyModal(row.pk))}>{row.competency}</td>
        <td style={{cursor:'pointer'}} onClick={() => dispatch(openCompetencyModal(row.pk))}>{row.description}</td>
        <td><a onClick={async () => {
                        dispatch(openDeleteModal({message: row.competency,recordpk: row.pk, recordtype: 'competency'}));
                        }}><i className="far fa-trash-alt mt-1" style={{color:"gray"}}/>
          </a>
        </td>
        </tr>
      )
    }

    if (!competencies) {
      return null
    }

    return (
        <Fragment>
          <section>
              <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "20px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        COMPETENCIES  ({filteredCompetencies.length})<br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="level-right">
                            <div className="level-item pt-4 pr-0">
                                <div className="field has-addons">
                                    <div className="control is-rounded is-small" style={{width: "140px",userSelect: "none"}}>
                                        <span> <input className="input is-rounded is-small"  onChange={handleChangeFilter} type="search" placeholder="Filter" style={{ color: "rgb(128,128,128)",userSelect: "none"}}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </nav>
                    
                    <hr className="pt-0 mt-0 pb-0 mb-0" style={{borderTop: "2px solid lightgrey"}} />
              <table className="table is-fullwidth">
                <tr>
                  <th className="pl-0">Competency</th>
                  <th>Description</th>
                  <th style={{width:"8px"}} ><a onClick={() => dispatch(openCompetencyModal(null))}><button className="fas fa-plus-circle" style={{color: backgroundcolor}}></button></a></th>
                </tr>

              {filteredCompetencies.length !== 0 && 
                <Fragment>
                  {filteredCompetencies && (filteredCompetencies.sort((a, b) => (a.competency.localeCompare(b.competency)))).map(row => (
                  competencylist(row)
                  ))}
                </Fragment>
              }

              {filteredCompetencies.length === 0 && 
                <Fragment> 

                    <p className="pt-4" style={{fontWeight:'500'}}>No Competencies Found</p>

                </Fragment>
              }
              </table>
                


            </section>
        </Fragment>
    )
}

export {Competencies};