import React, { useEffect, Fragment, useState, useContext } from "react";
import { Chart } from "react-google-charts";
import { useSelector, useDispatch } from "react-redux";
//import { sectionsService } from "../../services/SectionsService";
import {filter} from "lodash"
import { fetchAllGantt } from '../../redux/toolkit/features/roadmaps/gantt';
import { openGanttModal } from "../../redux/toolkit/features/roadmaps_state";
import { GanttComponent, Inject, Selection, ColumnsDirective, ColumnDirective, Toolbar, Edit } from '@syncfusion/ej2-react-gantt';
import './gantt.css'
import '../../../node_modules/@syncfusion/ej2-gantt/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-gantt/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-grids/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-treegrid/styles/material.css';


function Gantt(props) {
    const sectionPk = props.sectionPk;
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const gantt = Object.values(useSelector((state) => state.gantt.entities)).sort((a, b) => a.order - b.order);
    const [baseline, setBaseline] = useState(false)
    const [ baselinemessage, setBaselinemessage] = useState('Show Baseline')
    const dispatch = useDispatch();

    const ganttrows = filter(gantt, {section: sectionPk}).sort((a, b) => a.order - b.order);


    useEffect(() => {
        dispatch(fetchAllGantt());
    }, []);

    useEffect(() => {
        dispatch(fetchAllGantt());
    }, [sectionPk]);

    function toolbarClick(args) {
        if (args.item.id === 'showbaseline') {
            baseline?setBaselinemessage('Show Baseline'):setBaselinemessage('Hide Baseline')
            baseline?setBaseline(false):setBaseline(true)
       }
       if (args.item.id === 'addtask') {
        dispatch(openGanttModal({ganttsectionpk:sectionPk ,ganttrowpk: null }))   
       }
    };

    function rowSelecting(args) {
        dispatch(openGanttModal({ganttsectionpk:sectionPk ,ganttrowpk: args.data.TaskID }))        
    }


    function GanttBody() {
        const taskFields = {
            id: 'TaskID',
            name: 'TaskName',
            startDate: 'StartDate',
            endDate: 'EndDate',
            duration: 'Duration',
            progress: 'Progress',
            dependency: 'Predecessor',
            baselineStartDate: 'BaselineStartDate',
            baselineEndDate: 'BaselineEndDate',
            child: 'subtasks'
          };
          const labelSettings = {
            leftLabel: 'TaskName',
            taskLabel: '${Progress}%'
          };
          const splitterSettings = {
            position: "180px"
        };

        const editOptions = {
            allowAdding: false,
            allowEditing: false,
            allowDeleting: false
        };
          const projectStartDate= new Date('03/24/2024');
          //const projectEndDate = new Date('07/06/2024');
          var ganttdata=[];
          ganttrows.map((row) => {
            ganttdata.push({TaskID: row.pk,TaskName: row.taskname, StartDate: row.startdate, EndDate: row.enddate, Duration: row.duration, Progress:row.percentcomplete,BaselineStartDate:row.baselinestart, BaselineEndDate: row.baselineend, Predecessor: row.dependencies })
          })
          const toolbar = ['ZoomIn', 'ZoomOut', 'ZoomToFit', {text: baselinemessage, tooltipText: 'Show baseline', id: 'showbaseline', prefixIcon: 'e-field-settings' },{text: 'Add task', tooltipText: 'Add Task', align: 'right', id: 'addtask', prefixIcon: 'e-circle-add' }]

        return (
            <Fragment>

                <GanttComponent id='Default' taskFields={taskFields} toolbarClick={toolbarClick} editSettings={editOptions} labelSettings={labelSettings} rowSelected={rowSelecting} renderBaseline={baseline} baselineColor='red' dataSource={ganttdata} toolbar={toolbar} height='450px'projectStartDate={projectStartDate} splitterSettings={splitterSettings}>
                          <ColumnsDirective>
                          <ColumnDirective field='TaskID' width='0' ></ColumnDirective>
                          <ColumnDirective field='TaskName' headerText='Task Name' width='180px' clipMode='EllipsisWithTooltip'></ColumnDirective>
                          <ColumnDirective field='StartDate'></ColumnDirective>
                          <ColumnDirective field='Duration'></ColumnDirective>
                          <ColumnDirective field='Progress'></ColumnDirective>
                          <ColumnDirective field='Predecessor'></ColumnDirective>
                          <ColumnDirective field='BaselineStartDate' headerText='Planned start time' ></ColumnDirective>
                          <ColumnDirective field='BaselineEndDate' headerText='Planned end time' ></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Edit, Selection, Toolbar]} />
                </GanttComponent>

            </Fragment>
        )
    }
   


    return (
        <Fragment>
            {GanttBody()}
        </Fragment>
    )
}

export { Gantt };
