import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllChartRows = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-chartrows`,
    authConfig()
  );
  return response.data.results;
};

export const fetchChartRows = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/roadmap-chartrows/${id}`,
    authConfig()
  );
  return response.data;
};

export const createChartRows = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/roadmap-chartrows`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateChartRows = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/roadmap-chartrows/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteChartRows = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/roadmap-chartrows/${id}`,
    authConfig()
  );
  return response.data;
};

const roadmapchartrowsAPI = {
  fetchAllChartRows,
  fetchChartRows,
  createChartRows,
  updateChartRows,
  deleteChartRows,
};
export default roadmapchartrowsAPI;
