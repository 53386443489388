import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllPositionperson = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/positionperson`,
    authConfig()
  );
  return response.data.results;
};

export const fetchPositionperson = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/positionperson/${id}`,
    authConfig()
  );
  return response.data;
};

export const createPositionperson = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/positionperson`,
    data,
    authConfig()
  );
  return response.data;
};

export const updatePositionperson = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/positionperson/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deletePositionperson = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/positionperson/${id}`,
    authConfig()
  );
  return response.data;
};

const positionpersonAPI = {
  fetchAllPositionperson,
  fetchPositionperson,
  createPositionperson,
  updatePositionperson,
  deletePositionperson,
};
export default positionpersonAPI;
