import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter} from "lodash";
import { GanttComponent, Inject, Selection, ColumnsDirective, ColumnDirective, Toolbar, Edit } from '@syncfusion/ej2-react-gantt';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { fetchAllGantt } from "../../redux/toolkit/features/roadmaps/gantt"
import '../roadmaps/gantt.css'
import '../../../node_modules/@syncfusion/ej2-gantt/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-gantt/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-grids/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
import { openGanttBaselineModal } from "../../redux/toolkit/features/success_state";

function RenderGantt(props) {
    const sectionPk = props.sectionid;
    const gantt = Object.values(useSelector((state) => state.gantt.entities)).sort((a, b) => a.order - b.order);
    const ganttrows = filter(gantt, {section: sectionPk}).sort((a, b) => a.order - b.order);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllGantt());
    }, []);

    // function toolbarClick(args) {
    //     if (args.item.text === 'Add baseline') {
    //      dispatch(openGanttBaselineModal({ganttbaselineId:null, ganttbaselinesectionId: sectionPk}))
    //    }
    //  };

     function rowSelecting(args) {
        dispatch(openGanttBaselineModal({ganttbaselineId:args.data.TaskID, ganttbaselinesectionId: sectionPk}))   
    }

    function GanttBody() {
        const taskFields = {
            id: 'TaskID',
            name: 'TaskName',
            startDate: 'StartDate',
            endDate: 'EndDate',
            duration: 'Duration',
            progress: 'Progress',
            dependency: 'Predecessor',
            baselineStartDate: 'BaselineStartDate',
            baselineEndDate: 'BaselineEndDate',
            child: 'subtasks'
          };
          const labelSettings = {
            leftLabel: 'TaskName',
            taskLabel: '${Progress}%'
          };
          const splitterSettings = {
            position: "180px"
        };

        const editOptions = {
            allowAdding: false,
            allowEditing: false,
            allowDeleting: false
        };
          const projectStartDate= new Date('03/24/2024');
          //const projectEndDate = new Date('07/06/2024');
          var ganttdata=[];
          ganttrows.map((row) => {
            ganttdata.push({TaskID: row.pk,TaskName: row.taskname, StartDate: row.startdate, EndDate: row.enddate, Duration: row.duration, Progress:30,BaselineStartDate:row.baselinestart, BaselineEndDate: row.baselineend  })
          })
          const toolbar = ['ZoomIn', 'ZoomOut', 'ZoomToFit']

        return (
            <Fragment>
                <div className='ml-3 mr-3'>
                <GanttComponent id='Default' taskFields={taskFields} renderBaseline={true} editSettings={editOptions} labelSettings={labelSettings}  rowSelected={rowSelecting}  dataSource={ganttdata} toolbar={toolbar} height='450px'projectStartDate={projectStartDate} splitterSettings={splitterSettings}>
                          <ColumnsDirective>
                          <ColumnDirective field='TaskID' width='0' ></ColumnDirective>
                          <ColumnDirective field='TaskName' headerText='Task Name' width='180px' clipMode='EllipsisWithTooltip'></ColumnDirective>
                          <ColumnDirective field='StartDate'></ColumnDirective>
                          <ColumnDirective field='Duration'></ColumnDirective>
                          <ColumnDirective field='Progress'></ColumnDirective>
                          <ColumnDirective field='Predecessor'></ColumnDirective>
                          <ColumnDirective field='BaselineStartDate' headerText='Baseline start time' ></ColumnDirective>
                          <ColumnDirective field='BaselineEndDate' headerText='Baseline end time' ></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Edit, Selection, Toolbar]} />
                </GanttComponent>
                </div>

            </Fragment>
        )
    }



    return (
       GanttBody()
    )
}

export { RenderGantt }; 
