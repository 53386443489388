import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class BaselineValuesService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/baseline-cellvalues`;
    }

    async getCells() {
        const baselinevalues = await this.getAll();
        return baselinevalues.results;
    }
}

export const baselineValuesService = new BaselineValuesService();
