import {
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

const stakeholder_stateAdapter = createEntityAdapter();

const initialState = stakeholder_stateAdapter.getInitialState({
  isCategoriesModalOpen: false,
  categoryId: null,
  isStakeholdersModalOpen: false,
  stakeholderId: null,
  isMatrixModalOpen: false,
  isCompanyMatrixModalOpen: false,
  matrixstakeholderId: null,
  matrixcategoryId: null,

});

const stakeholder_stateSlice = createSlice({
  name: "stakeholder_state",
  initialState,
  reducers: {
    openCategoriesModal(state, action) {
      state.isCategoriesModalOpen = true;
      state.categoryId = action.payload.categoryId;
    },
    closeCategoriesModal(state) {
      state.isCategoriesModalOpen = false;
      state.categoryId = null;
    },
    openStakeholdersModal(state, action) {
      state.isStakeholdersModalOpen = true;
      state.stakeholderId = action.payload.stakeholderId;
    },
    closeStakeholdersModal(state) {
      state.isStakeholdersModalOpen = false;
      state.stakeholderId = null;
    },
    openMatrixModal(state, action) {
      state.isMatrixModalOpen = true;
      state.isCompanyMatrixModalOpen = action.payload.isCompanyMatrixModalOpen;
      state.matrixstakeholderId = action.payload.matrixstakeholderId;
      state.matrixcategoryId = action.payload.matrixcategoryId;
    },
    closeMatrixModal(state) {
      state.isMatrixModalOpen = false;
      state.isCompanyMatrixModalOpen = false;
      state.matrixstakeholderId = null;
      state.matrixcategoryId = null;
    },
  
  },
});

export const { 
  openCategoriesModal,
  closeCategoriesModal,
  openStakeholdersModal,
  closeStakeholdersModal, 
  openMatrixModal,
  closeMatrixModal,
} = stakeholder_stateSlice.actions;

export default stakeholder_stateSlice.reducer;



